import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TranslatedComponent from "../../translator/TranslatedComponent";

const SidebarLeft = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { currentUser, currentUserInfo } = useSelector((state) => state.Auth);

  useEffect(() => {
    const Sidebar = document.getElementById("sidebar");
    // Check if open is true
    if (Sidebar) {
      if (open) {
        Sidebar.classList.add("active");
      } else {
        Sidebar.classList.remove("active");
      }
    }
  }, [open]);

  return (
    <>
      <button
        type="button"
        id="sidebarCollapse"
        className="btn btn-info"
        onClick={() => setOpen(true)}
      >
        <i className="fas fa-align-left"></i>
      </button>

      <nav id="sidebar">
        <div className="sidebar-header">
          {currentUserInfo.role === "Org" ? (
            <a href="/org-dashboard">
              <img src="/Assets/images/logo.png" alt="Logo" />
            </a>
          ) : (
            <a href="/Jury-dashboard">
              <img src="/Assets/images/logo.png" alt="Logo" />
            </a>
          )}

          {/* <a href="/org-dashboard">
            <img src="/Assets/images/logo.png" alt="Logo" />
          </a> */}
          {open && (
            <IconButton
              id="close-ham-menu"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>

        <ul className="list-unstyled components">
          <li className="active">
            <button
              onClick={() => {
                currentUserInfo.role === "Org"
                  ? navigate("/org-dashboard")
                  : navigate("/jury-dashboard");
              }}
            >
              <i className="fa-sharp fa-solid fa-gauge"></i>
              <TranslatedComponent>Dashboard</TranslatedComponent>
            </button>
          </li>
          {/* <TranslatedComponent> */}

          {/* <li>
            <button
              onClick={() => {
                navigate("/my-account-organizer");
              }}
            >
              <i className="fa-regular fa-handshake"></i> My Account{" "}
            </button>
          </li> */}
          {currentUserInfo.role === "Org" ? (
            <>
              {/* <li>
                        <a href='/' data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fa-regular fa-handshake"></i>Create Competition</a>
                        <ul className="sub-list collapse list-unstyled" id="pageSubmenu">
                            <li>
                                <button>OneShot</button>
                            </li>
                            <li>
                                <button>Tournament</button>
                            </li>
                        </ul>
                    </li> */}
              <TranslatedComponent>
                <li>
                  <button
                    onClick={() => {
                      navigate("/competition-type");
                    }}
                  >
                    <i className="fa-regular fa-handshake"></i>Create
                    Competition
                  </button>
                </li>
              </TranslatedComponent>

              {/* 
          <li>
          <button onClick={() => { navigate("/word-list") }}><i className="fa-regular fa-handshake"></i>Word</button>
          </li> 
          */}
              <TranslatedComponent>
                <li>
                  <button
                    onClick={() => {
                      navigate("/participant-list");
                    }}
                  >
                    <i className="fa-regular fa-handshake"></i>All Participant</button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      navigate("/jury-members-list");
                    }}
                  >
                    <i className="fa-regular fa-handshake"></i>All Jury
                  </button>
                </li>
              </TranslatedComponent>
            </>
          ) : null}
        </ul>
        {/* </TranslatedComponent> */}
      </nav>
    </>
  );
};

export default SidebarLeft;
