/* import tytpes */
import {
  //   ROUND_GET_SUCCESS,
  //   ROUND_GET_ERROR,
  ROUND_CURRENT_GET_SUCCESS,
  ROUND_CURRENT_GET_ERROR,
  ROUND_SUMMARY_GET_SUCCESS,
  ROUND_SUMMARY_GET_ERROR,
  ROUND_UNUSED_WORD_GET_SUCCESS,
  ROUND_UNUSED_WORD_GET_ERROR,
  ROUND_ADD_SUCCESS,
  ROUND_ADD_ERROR,
  ROUND_UPDATE_SUCCESS,
  ROUND_UPDATE_ERROR,
  //   ROUND_REMOVE_SUCCESS,
  //   ROUND_REMOVE_ERROR,
  //   ROUND_DELETE_SUCCESS,
  //   ROUND_DELETE_ERROR,
  ROUND_SUCCESS_MSG_CLEAR,
  ROUND_ERROR_MSG_CLEAR,
  ROUND_ACTION_LOADER_START,
  ROUND_DLD_CSV_ERROR,
  ROUND_DLD_CSV_SUCCESS,
  ROUND_DLD_CSV_CLR_MSG,
  ROUND_OVR_ALL_COM_STATICS_SUCC,
  ROUND_OVR_ALL_COM_STATICS_ERR,
  LIST_OF_WORDS_USED_SUCC,
  LIST_OF_WORDS_USED_ERR,
  LIST_OF_CANDIDATE_PRST_SUCC,
  LIST_OF_CANDIDATE_PRST_ERR,
  GET_SPECIAL_TABLE_DATA_SUCCESS_MESSAGE,
  GET_SPECIAL_TABLE_DATA_ERROR_MESSAGE,
  CREATE_NEW_ROUND_SUCCESS_MESSAGE,
  CREATE_NEW_ROUND_ERROR_MESSAGE,
} from "../Types/roundTypes";

import { RESET_REDUX } from "../Types/customTypes";

const initialState = {
  round_Loading: true,
  round_Summary_Data: null,
  round_Comp_Data: [],
  OverallCompetitionStatistics: [],
  listOfWords:[],
  specialTableData:[],
  listOfCandidatePresent:[],
  current_round_unused_word: [],
  current_round_Details: {}, //current round
  round_Action_Loader: true,
  round_SuccessMessage: null,
  round_ErrorMessage: null,
  round_Get_Error_Message: null,
  No_Words_Error_Message: null,
  CSVarray: [],
  download_Success: null,
  download_Error: null,
  createSpecialRoundSuccess: null,
  createSpecialRoundError: null

};

export const RoundReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /* Create */
  if (type === ROUND_ADD_SUCCESS) {
    return {
      ...state,
      round_SuccessMessage: payload.successMessage,
      current_round_Details: payload.data,
      round_ErrorMessage: null,
      round_Loading: false,
      round_Action_Loader: false,
    };
  }
  if (type === ROUND_ADD_ERROR) {
    return {
      ...state,
      round_SuccessMessage: null,
      round_ErrorMessage: payload.errorMessage,
      current_round_Details: {},
      round_Loading: false,
      round_Action_Loader: false,
    };
  }

  /* Get Current Round */
  if (type === ROUND_CURRENT_GET_SUCCESS) {
    return {
      ...state,
      // round_SuccessMessage: payload.successMessage,
      current_round_Details: payload.data,
      round_ErrorMessage: null,
      round_Loading: false,
      round_Action_Loader: false,
      round_Loading: false,
    };
  }
  if (type === ROUND_CURRENT_GET_ERROR) {
    return {
      ...state,
      round_Get_Error_Message: payload.errorMessage,
      current_round_Details: {},
      round_SuccessMessage: null,
      round_Loading: false,
      round_Action_Loader: false,
    };
  }

  /* Unused Word Get -- For Current Round */
  if (type === ROUND_UNUSED_WORD_GET_SUCCESS) {
    return {
      ...state,
      // round_SuccessMessage: payload.successMessage,
      current_round_unused_word: payload.data,
      round_ErrorMessage: null,
      round_Loading: false,
      round_Action_Loader: false,
    };
  }
  if (type === ROUND_UNUSED_WORD_GET_ERROR) {
    console.log(payload);
    return {
      ...state,
      round_Get_Error_Message: payload.errorMessage,
      No_Words_Error_Message: payload.errorMessage,
      current_round_unused_word: [],
      round_SuccessMessage: null,
      round_Loading: false,
      round_Action_Loader: false,
    };
  }

  /* Get */
  //   if (type === ROUND_GET_SUCCESS) {
  //     return {
  //       ...state,
  //       // round_SuccessMessage: payload.successMessage,
  //       current_round_Details: payload.data,
  //       round_ErrorMessage: null,
  //       round_Loading: false,
  //       round_Action_Loader: false,
  //     };
  //   }
  //   if (type === ROUND_GET_ERROR) {
  //     return {
  //       ...state,
  //       round_Get_Error_Message: payload.errorMessage,
  //       current_round_Details: {},
  //       round_SuccessMessage: null,
  //       round_Loading: false,
  //       round_Action_Loader: false,
  //     };
  //   }

  /* Get */
  if (type === ROUND_SUMMARY_GET_SUCCESS) {
    return {
      ...state,
      // round_SuccessMessage: payload.successMessage,
      round_Summary_Data: payload.data,
      round_ErrorMessage: null,
      round_Loading: false,
      round_Action_Loader: false,
    };
  }
  if (type === ROUND_SUMMARY_GET_ERROR) {
    return {
      ...state,
      round_ErrorMessage: payload.errorMessage,
      round_Summary_Data: null,
      round_SuccessMessage: null,
      round_Loading: false,
      round_Action_Loader: false,
    };
  }

  /* Update*/
  if (type === ROUND_UPDATE_SUCCESS) {
    return {
      ...state,
      round_SuccessMessage: payload.successMessage,
      round_ErrorMessage: null,
      round_Action_Loader: false,
    };
  }
  if (type === ROUND_UPDATE_ERROR) {
    return {
      ...state,
      round_SuccessMessage: null,
      round_ErrorMessage: payload.errorMessage,
      round_Action_Loader: false,
    };
  }

  /* Loader */
  if (type === ROUND_ACTION_LOADER_START) {
    return {
      ...state,
      round_Action_Loader: true,
    };
  }

  /* =================|| DOWNLOAD CSV ||========================== */
  if (type === ROUND_DLD_CSV_SUCCESS) {
    return {
      ...state,
      CSVarray: payload.data,
      download_Success: payload.message
    }
  }
  if (type === ROUND_DLD_CSV_ERROR) {
    return {
      ...state,
      download_Error: payload.message
    }
  }


  if (type === ROUND_OVR_ALL_COM_STATICS_SUCC) {
    
    return {
      ...state,
      OverallCompetitionStatistics: payload.data,
      download_Success: payload.message
    }
  }

  if (type === ROUND_OVR_ALL_COM_STATICS_ERR) {
    return {
      ...state,
      download_Error: payload.message
    }
  }

  if(type === LIST_OF_WORDS_USED_SUCC){
    return {
      ...state,
      listOfWords: payload.data,
      download_Success: payload.message
    }
  }
  if(type === LIST_OF_WORDS_USED_ERR){
    return {
      ...state,
      download_Error: payload.message
    }
  }
  
  if(type === LIST_OF_CANDIDATE_PRST_SUCC){
    return {
      ...state,
      listOfCandidatePresent: payload.data,
      download_Success: payload.message
    }
  }
  if(type === LIST_OF_CANDIDATE_PRST_ERR){
    return {
      ...state,
      download_Error: payload.message
    }
  }

  // round_SuccessMessage: null,
  // round_ErrorMessage: null,
  // specialTableData:[],
  if(type === GET_SPECIAL_TABLE_DATA_SUCCESS_MESSAGE){
    return {
      ...state,
      specialTableData: payload.data,
      round_SuccessMessage: payload.message
    }
  }

  if(type === GET_SPECIAL_TABLE_DATA_ERROR_MESSAGE){
    return {
      ...state,
      round_ErrorMessage: payload.message
    }
  }


  if(type === CREATE_NEW_ROUND_SUCCESS_MESSAGE){
    return {
      ...state,
      createSpecialRoundSuccess: payload.message,
      current_round_Details: payload.createdSpecialRounds
    }
  }
  if(type === CREATE_NEW_ROUND_ERROR_MESSAGE){
    return {
      ...state,
      createSpecialRoundError: payload.message
    }
  }

  /*=================|| clear CSV DOWNLOAD message ||====================  */
  if (type === ROUND_DLD_CSV_CLR_MSG) {
    return {
      ...state,
      download_Success: null,
      download_Error: null,
      CSVarray:[],
      OverallCompetitionStatistics:[],
      listOfWords:[],
      listOfCandidatePresent:[]
    }
  }



  /* Msg Clear */
  if (type === ROUND_SUCCESS_MSG_CLEAR) {
    return {
      ...state,
      round_SuccessMessage: null,
      round_ErrorMessage: null,
      round_Action_Loader: false,
      createSpecialRoundSuccess: null,
      createSpecialRoundError: null
    };
  }
  if (type === ROUND_ERROR_MSG_CLEAR) {
    return {
      ...state,
      round_SuccessMessage: null,
      round_ErrorMessage: null,
      round_Action_Loader: false,
      round_Get_Error_Message: null,
      No_Words_Error_Message: null,
    };
  }

  /* Reset State */
  if (type === RESET_REDUX) {
    // console.log("here to clear round reducer");

    return {
      ...state,
      round_Loading: true,
      round_Summary_Data: null,
      round_Comp_Data: [],
      current_round_unused_word: [],
      current_round_Details: {}, //current round
      round_Action_Loader: true,
      round_SuccessMessage: null,
      round_ErrorMessage: null,
      round_Get_Error_Message: null,
    };
  }

  return state;
};
