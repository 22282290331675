import React, { useEffect, useState } from "react";
import Header from "../../component/Header/Header";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TranslatedComponent from "../../translator/TranslatedComponent";
import PageTitle from "../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../HelperFunctions/MsgTranslator";

const CompetitionType = () => {
  const navigate = useNavigate();
  const [type, setTypes] = useState("OneShot");
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const [language,setLanguage] = useState("fr");

  const handleSelectChange = (event) => {
    setTypes(event.target.value);
  };

  const handleCreateCompetition = (event) => {
    event.preventDefault();
    // console.log(type);
    if (type === "Tournament") {
      navigate("/create-tournament");
    } else if (type === "OneShot") {
      navigate("/create-one-shot-competition");
    }
  };

  useEffect(() =>{
    if(currentUser && Object.keys(currentUser).length > 0){
      const {language} = currentUser;
      setLanguage(language);
    }
  },[currentUser]);

  return (
    <>
      <div className="wrapper">
        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard",language),
                  link: 
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                {
                  page: MsgTranslator("Create Competition",language),
                  link: "/competition-type",
                },
              ]}
            />

            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em>Create</em>
                  </TranslatedComponent>{" "}
                  <TranslatedComponent>Competition</TranslatedComponent>

                </span> */}
                  <PageTitle
                    title={"<em>Create</em> Competition"}
                    lang={language}
                  />
              </h3>
            </div>
            <div className="login-section">
              <TranslatedComponent>
                <h3>Competition Type</h3>
              </TranslatedComponent>
              <div className="login-page">
                <div className="form">
                  <form>
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <select value={type} onChange={handleSelectChange}>
                          <TranslatedComponent>
                            <option value="OneShot">One Shot</option>
                          </TranslatedComponent>
                          <TranslatedComponent>
                            <option value="Tournament">Tournament</option>
                          </TranslatedComponent>
                        </select>
                      </div>
                    </div>
                    <TranslatedComponent>
                      <button type="button" onClick={handleCreateCompetition}>
                        Create competition
                      </button>
                    </TranslatedComponent>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompetitionType;
