import React, { useState, useEffect } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../Config/Config";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import {
  GetCompWisePartcipants,
  RemovePartcipants,
  BulkRemovePartcipants,
} from "../../../Store/Action/participantAction";
import {
  PARTICIPANT_ACTION_LOADER_START,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
} from "../../../Store/Types/participantTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";
import { calculateAge } from "../../../HelperFunctions/AgeFromDob";
import FileUploader from "../../../component/FileUploader/FileUploader";

import OneShot_SubComp_Name from "../../../component/CompetitionName/OneShot_SubComp_Name";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AllParticipant = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const params = useParams();
  const { comp_id } = params;

  // const { search } = useLocation();
  // const query = new URLSearchParams(search);
  // const showParam = query.get("show");

  // console.log(comp_id);

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const {
    Participant_Loading,
    Participant_Action_Loader,
    Participants_Data,
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  } = useSelector((state) => state.Participant);
  const {
    OneShot_Loading,
    OneShotDetails,
    OneShot_SuccessMessage,
    OneShot_ErrorMessage,
    OneShot_Get_Error_Message,
  } = useSelector((state) => state.OneShot);

  const [rows, setrows] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    if (!Participant_Loading && Participants_Data) {
      const transformedData = Participants_Data.map((participant, i) => ({
        index: String(i + 1).padStart(2, "0"),
        id: participant._id,
        // createdBy: participant.createdBy,
        firstName: participant.firstName,
        lastName: participant.lastName,
        dob: participant.dob,
        age: participant.dob ? calculateAge(participant.dob) || null : null,
        grade: participant.grade,
        // sex: participant.sex,
        country: participant.country ? participant.country.label : null,
        school: participant.school,
        city: participant.city,
        phone: participant.phone,
        email: participant.email,
        // img: participant.img,
        // isDeleted: participant.isDeleted,
        // additionalNote: participant.additionalNote,
        // createdAt: participant.createdAt,
        // updatedAt: participant.updatedAt,
      }));
      setrows(transformedData);
    }
  }, [Participants_Data, Participant_Loading]);

  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
    dispatch(
      GetCompWisePartcipants({
        createdBy: currentUserInfo._id,
        compId: comp_id,
      })
    );
  }, [comp_id]);

  /* Delete Participant */
  const handleParticipantDelete = (participantId) => {
    Swal.fire({
      title: MsgTranslator("Are you sure?", language),
      text: MsgTranslator("You won't be able to revert this!", language),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: MsgTranslator("Yes, delete it!", language),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          RemovePartcipants({
            createdBy: currentUserInfo._id,
            compId: comp_id,
            participantId: participantId,
          })
        ).then(() => {
          dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
          dispatch(
            GetCompWisePartcipants({
              createdBy: currentUserInfo._id,
              compId: comp_id,
            })
          );
        });
      }
    });
  };

  const handleBulkDelte = () => {
    Swal.fire({
      title: MsgTranslator("Are you sure?", language),
      text: MsgTranslator("You won't be able to revert this!", language),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: MsgTranslator("Yes, delete it!", language),
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire({
        //   title: "Deleted!",
        //   text: "Your file has been deleted.",
        //   icon: "success"
        // });
        dispatch(
          BulkRemovePartcipants({
            createdBy: currentUserInfo._id,
            compId: comp_id,
            participantIdArray: selectedParticipants,
          })
        ).then(() => {
          dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
          dispatch(
            GetCompWisePartcipants({
              createdBy: currentUserInfo._id,
              compId: comp_id,
            })
          );
        });
      }
    });
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Participant_SuccessMessage) {
      const text = MsgTranslator(Participant_SuccessMessage, language);
      notify(text, "success");
      dispatch({ type: PARTICIPANT_SUCCESS_MSG_CLEAR });
      dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
      dispatch(
        GetCompWisePartcipants({
          createdBy: currentUserInfo._id,
          compId: comp_id,
        })
      );
      // navigate(`/all-participant/${comp_id}`);
    }

    if (Participant_ErrorMessage) {
      const text = MsgTranslator(Participant_ErrorMessage, language);
      notify(text, "error");
      dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
    }

    if (Participant_Get_Error_Message) {
      const text = MsgTranslator(Participant_Get_Error_Message,language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
      });
    }
  }, [
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  ]);

  const handleCellClick = async (e) => {
    if (e.field != "__check__") {
      window.location.href.includes("/phase/")
        ? navigate(`/phase/participant-details/${e.id}`)
        : navigate(`/participant-details/${e.id}`);
    }
  };

  const columns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: "index",
      headerName: "",
      width: 90,
      renderCell: (params) => (
        <span style={{ fontWeight: "bold" }}>{params.row.index}</span>
      ),
    },
    {
      field: "firstName",
      headerName: MsgTranslator("First Name", language),
      width: 200,
      //align: 'center',
    },
    {
      field: "lastName",
      headerName: MsgTranslator("Last Name", language),
      width: 200,
      //align: 'center',
    },
    {
      field: "dob",
      headerName: MsgTranslator("Birthday", language),
      // type: "text",
      width: 200,
      // headerAlign: "left",
      //align: 'center',
    },
    {
      field: "age",
      headerName: MsgTranslator("Age", language),
      // type: "text",
      width: 200,
      // headerAlign: "left",
      //align: 'center',
    },
    // {
    //   field: "fullName",
    //   headerName: "Full Name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 200,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // },
    {
      field: "school",
      headerName: MsgTranslator("School", language),
      width: 200,
      //align: 'center',
    },
    {
      field: "grade",
      headerName: MsgTranslator("Grade", language),
      width: 200,
      //align: 'center',
    },
    {
      field: "country",
      headerName: MsgTranslator("Country", language),
      width: 200,
      //align: 'center',
    },
    {
      field: "city",
      headerName: MsgTranslator("City", language),
      
      width: 200,
      //align: 'center',
    },
    {
      field: "phone",
      headerName: MsgTranslator("Phone", language),
      width: 200,
      //align: 'center',
    },
    {
      field: "email",
      headerName: MsgTranslator("Email", language),
      width: 200,
      //align: 'center',
    },
  ];

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {OneShotDetails?.tournamentId ? (
            <Box sx={style}>
              <TranslatedComponent>
                <Box
                  sx={{ border: 4, m: 2, p: 2 }}
                  onClick={() => {
                    navigate(`/phase/existing-participant/${comp_id}`);
                  }}
                >
                  Add Excisting Participants{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
              </TranslatedComponent>
            </Box>
          ) : (
            <Box sx={style}>
              <TranslatedComponent>
                <Box
                  sx={{ border: 4, m: 2, p: 2 }}
                  onClick={() => {
                    navigate(`/manual-add-participant/${comp_id}`);
                  }}
                >
                  Manual Add{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
                <Box
                  sx={{ border: 4, m: 2, p: 2 }}
                  onClick={() => {
                    navigate(`/quick-mode-add-participant/${comp_id}`);
                  }}
                >
                  Quick Add{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
                <Box
                  sx={{ border: 4, m: 2, p: 2 }}
                  onClick={() => {
                    navigate(`/existing-participant/${comp_id}`);
                  }}
                >
                  Add Excisting Participants{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
                {/* <Box sx={{ border: 4, m: 2, p: 2 }} onClick={() => { navigate(``) }}>Import CSV or Excel <i className="fa -regular fa-arrow-right" style={{ color: "#000000" }}></i></Box> */}
                <FileUploader compId={comp_id} />
              </TranslatedComponent>
            </Box>
          )}
        </Modal>
      </div>

      <div className="wrapper">
        <SidebarLeft />

        <div id="content-thrr">
          {!Participant_Loading ? (
            <div className="main-container-section">
              <Header
                breadcrumb={[
                  {
                    page: MsgTranslator("Dashboard", language),
                    link:
                      currentUserInfo.role === "Org"
                        ? "/org-dashboard"
                        : "/jury-dashboard",
                  },
                  ...(window.location.href.includes("/phase/")
                    ? [
                        {
                          page: MsgTranslator("Phase Manage", language),
                          link: `/phase-manage/${OneShotDetails?.tournamentId}`,
                        },
                      ] // Include this item only if the condition is met
                    : []), // Empty array if the condition is not met, effectively skipping this item
                  {
                    page: MsgTranslator("Participant List", language),
                    link: `/all-participant/${comp_id}`,
                  },
                ]}
              />

              <div className="upcoming-title">
                <h3>
                  <PageTitle
                    title={"<em>All</em> Participant"}
                    lang={language}
                  />
                  <span>
                    {/* <TranslatedComponent>
                      <em>All</em>
                    </TranslatedComponent>
                    <TranslatedComponent>Participant</TranslatedComponent>{" "} */}
                    <OneShot_SubComp_Name compId={comp_id} />
                  </span>
                </h3>
              </div>

              <div className="candit-sectied-from">
                {selectedParticipants && selectedParticipants.length > 0 && (
                  <div>
                    <button
                      className="btn-with-pic"
                      onClick={() => handleBulkDelte()}
                    >
                      <img
                        src={
                          window.location.origin +
                          `/Assets/images/delete-green.png `
                        }
                        alt="Remove Green"
                      />
                    </button>
                  </div>
                )}
                <div className="table1002 ver2 m-b-110">
                  <Box sx={{ height: 500, width: "100%" }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      // autoHeight={true}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20, 30, 40, 50]}
                      checkboxSelection
                      disableRowSelectionOnClick
                      onCellClick={(event) => handleCellClick(event)}
                      onRowSelectionModelChange={(newRowSelectionModel) => {
                        setSelectedParticipants(newRowSelectionModel);
                      }}
                    />
                  </Box>
                </div>
                <div className="buttaon-lane">
                  <div className="back-name">
                    <TranslatedComponent>
                      <button className="back-g" onClick={() => navigate(-1)}>
                        Back
                      </button>
                    </TranslatedComponent>
                  </div>
                  <div className="right-edit">
                    <TranslatedComponent>
                      <button className="start-g" onClick={handleOpen}>
                        Add More Participant
                      </button>
                    </TranslatedComponent>
                    {OneShotDetails && Object.keys(OneShotDetails) && (
                      <>
                        <TranslatedComponent>
                          <button
                            className="start-g"
                            onClick={() =>
                              window.location.href.includes("/phase/")
                                ? navigate(
                                    `/phase/organiser-word-list/${comp_id}`
                                  )
                                : navigate(`/organiser-word-list/${comp_id}`)
                            }
                          >
                            WordList
                          </button>
                          <button
                            className="start-g"
                            onClick={() =>
                              window.location.href.includes("/phase/")
                                ? navigate(`/phase/all-jury/${comp_id}`)
                                : navigate(`/all-jury/${comp_id}`)
                            }
                          >
                            Jury Members
                          </button>
                        </TranslatedComponent>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ComponentLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default AllParticipant;
