const Config = {
  SERVER_URL: "https://epelle-moi-server-9yey.onrender.com/api/v1",
  SOCKET_URL: "https://epelle-socket-7z36.onrender.com",
  BASE_URL: "https://epellemoi.org",

  // SERVER_URL: "https://epelle-server.azurewebsites.net/api/v1",
  // BASE_URL: "https://epelle-client.azurewebsites.net",

  // SERVER_URL: "http://localhost:8080/api/v1",
  // SOCKET_URL: "http://localhost:5000",
  // BASE_URL: 'http://localhost:3000/'
};

module.exports = Config;
