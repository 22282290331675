import React, { useState, useEffect } from "react";
import SidebarLeft from "../../../../component/Sideber/SidebarLeft";
import Header from "../../../../component/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { Create_Tournament_SubComp } from "../../../../Store/Action/tournamentAction";
import {
  TOURNAMENT_ACTION_LOADER,
  TOURNAMENT_SUCCESS_CLEAR,
  TOURNAMENT_ERROR_CLEAR,
} from "../../../../Store/Types/tournamentTypes";

import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../../component/ToastMsg/ToastMsg";

import axios from "axios";
import { SERVER_URL } from "../../../../Config/Config";
import TournamentName from "../../../../component/CompetitionName/TournamentName";
import TranslatedComponent from "../../../../translator/TranslatedComponent";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../../HelperFunctions/MsgTranslator";

const formatTime = (totalSeconds) => {
  if(totalSeconds === undefined) return;
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  // console.log(`${formattedMinutes}:${formattedSeconds}`)
  return `${formattedMinutes}:${formattedSeconds}`;
};

const CreateSubCompetition = () => {
  const { toornamentId, phaseId } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const {
    tournament_New_Id,
    tournament_Action_Loader,
    tournament_SuccessMessage,
    tournament_ErrorMessage,
  } = useSelector((state) => state.Tournament);

  const obj = {
    adminId: currentUserInfo?._id || null,
    orgId: currentUserInfo?._id || null,
    tournamentId: toornamentId,
    phaseId: phaseId,
    name: "",
    date: "",
    time: "",
    themeOfWords: "",
    wordContext: "",
    difficulty: "Easy",
    totalParticipants: 0,
    qualifiedParticipants: 0,
    allotedTime: currentUser?.default_alt_Time || "",
    altTime: currentUser?.default_alt_Time ? formatTime(currentUser?.default_alt_Time) || "" : "",
  };

  const [state, setState] = useState(obj);

  useEffect(() => {
    if (currentUser) {
      setState({ 
        ...obj, 
        allotedTime: currentUser?.default_alt_Time || "", 
        altTime: formatTime(currentUser?.default_alt_Time) || "" 
      });
    }
  }, [currentUser]);

  const [themeContextOptions, setThemeContext] = useState({
    themes: [],
    contexts: [],
  });
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    axios.get(`${SERVER_URL}/word/get-theme-context`)
      .then((res) => {
        const uniqueThemes = [...new Set(res.data.data.themes.map(theme => theme.toLowerCase()))];
        const uniqueContexts = [...new Set(res.data.data.contexts.map(context => context.toLowerCase()))];
        setThemeContext({
          themes: uniqueThemes,
          contexts: uniqueContexts
        });

      })
      .catch((err) => {
        console.log("Error: " + err);
      });
  }, []);

  const handleChange = (name, value) => {
    if (name === "date") {
      const formattedDate = dayjs(value).format("DD-MM-YYYY");
      // console.log(41, formattedDate);
      setState({
        ...state,
        [name]: formattedDate,
      });
    } else if (name === "time") {
      const formattedDate = dayjs(value).format("hh:mm a");
      setState({
        ...state,
        [name]: formattedDate,
      });
    } else {
      setState({
        ...state,
        [name]: value,
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    let totalsec = 0;
    if (!state.time || !state.date || !state.altTime) {
      const text = MsgTranslator("All fields are required",language)
      notify(text, "error");
      return;
    }
    if (state.altTime) {
      // console.log(state.altTime);
      const time = state.altTime.split(":");
      if (time.length !== 2) {
        const text = MsgTranslator("Alloted time format mismatched, i.e. 03:20 - 03min 20sec",language)
        notify(
          text,
          "error"
        );
        setState({
          ...state,
          altTime: "",
        });
        return;
      }
      let timer = {
        min: Number(time[0].trim()),
        sec: Number(time[1].trim()),
      };
      // console.log(timer, time[0].trim().length, time[1].trim().length);
      if (
        timer.min >= 60 ||
        timer.sec >= 60 ||
        time[0].trim().length > 2 ||
        time[1].trim().length !== 2
      ) {
        const text = MsgTranslator("Alloted time format mismatched, i.e. 03:20 - 03min 20sec",language)
        notify(
          text,
          "error"
        );
        setState({
          ...state,
          altTime: "",
        });
        return;
      }
      totalsec = timer.min * 60 + timer.sec;
      // console.log(totalsec);
      setState({
        ...state,
        allotedTime: totalsec,
      });
    }
    // console.log({ ...state, allotedTime: totalsec });

    dispatch({ type: TOURNAMENT_ACTION_LOADER });
    dispatch(Create_Tournament_SubComp({ ...state, allotedTime: totalsec }));
  };

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  useEffect(() => {
    if (tournament_SuccessMessage) {
      const text = MsgTranslator(tournament_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: TOURNAMENT_SUCCESS_CLEAR });
      navigate(-1);
    }
    if (tournament_ErrorMessage) {
      const text = MsgTranslator(tournament_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: TOURNAMENT_ERROR_CLEAR });
    }
  }, [tournament_SuccessMessage, tournament_ErrorMessage]);

  const dateFormat = (date) => {
    return date.split("-").reverse().join("-");
  };

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                  <PageTitle title={"<em>Create Sub</em> Competition"} lang={language} />
              </h3>
            </div>
            <div className="candit-sectied-from">
              <TranslatedComponent>
                <h4>Create Sub Competition</h4>
              </TranslatedComponent>
              <p className="tournament-rt">
                <TranslatedComponent>Tournament Name :</TranslatedComponent>
                <span>
                  <TournamentName compId={toornamentId} />
                </span>
              </p>

              <div className="ft-create-section">
                <div className="text-from-one">
                  <TranslatedComponent>
                    <form method="POST" onSubmit={handleSave}>
                      <div className="row">
                        {/* name */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Name :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                value={state.name}
                                name="name"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        {/* Date */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Date :
                            </label>
                            <div className="col-sm-7">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["DatePicker", "DatePicker"]}
                                >
                                  <DatePicker
                                    value={
                                      state.date
                                        ? dayjs(dateFormat(state.date))
                                        : null
                                    }
                                    onChange={(e) => handleChange("date", e)}
                                    format="DD-MM-YYYY"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>

                        {/* Time */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Time:
                            </label>
                            <div className="col-sm-7">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["TimePicker", "TimePicker"]}
                                >
                                  <TimePicker
                                    // label="Controlled picker"
                                    value={state.time}
                                    onChange={(newValue) =>
                                      handleChange("time", newValue)
                                    }
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* word theme */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Word Theme :
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                // className="form-control"
                                placeholder="Theme of The Words"
                                name="themeOfWords"
                                value={state.themeOfWords}
                                options={themeContextOptions?.themes.filter(
                                  Boolean
                                )}
                                // sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // label={"Select a theme"}
                                    required
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  handleChange("themeOfWords", newValue);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* word context */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Word Context :
                            </label>
                            <div className="col-sm-7">
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                // className="form-control"
                                placeholder="Theme of The Words"
                                name="wordContext"
                                value={state.wordContext}
                                options={themeContextOptions?.contexts.filter(
                                  Boolean
                                )}
                                // sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // label={"Select a Context"}
                                    required
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  handleChange("wordContext", newValue); // Pass the name directly
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Word Level of Difficulty*/}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Word Level of Difficulty :
                            </label>
                            <div className="col-sm-7">
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                value={state.difficulty}
                                name="difficulty"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                                required
                              >
                                <option selected="Easy">Easy</option>
                                <option value="Moderate">Moderate</option>
                                <option value="Hard">Hard</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* Number of Participants */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Number of Participants :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Number of Participants "
                                name="totalParticipants"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        {/* Number of Candidates To Qualify */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Number of Candidates To Qualify :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Number of Candidates To Qualify"
                                name="qualifiedParticipants"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        {/* alloted time */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Alloted Time Per Candidate :
                            </label>
                            <div className="col-sm-7">
                              <input
                                // type="number"
                                className="form-control"
                                placeholder="e.g - 02:10"
                                name="altTime"
                                value={state.altTime}
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="buttaon-lane">
                        <div className="back-name">
                          <button
                            className="back-g"
                            onClick={() => navigate(-1)}
                          >
                            Back
                          </button>
                        </div>
                        <div className="right-edit">
                          <button className="start-g" type="submit">
                            {tournament_Action_Loader && (
                              <CircularProgress
                                size={24}
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "-10%",
                                  marginTop: "-12px",
                                  marginLeft: "-12px",
                                }}
                              />
                            )}{" "}
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </TranslatedComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSubCompetition;
