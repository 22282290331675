/* import tytpes */
import {
  COMP_START_DATA_GET_SUCCESS,
  COMP_START_DATA_GET_ERROR,
  COMP_START_DATA_UPDATE_SUCCESS,
  COMP_START_DATA_UPDATE_ERROR,
  COMP_RESULT_GET_SUCCESS,
  COMP_RESULT_GET_ERROR,
  COMP_START_SUCCESS_MSG_CLEAR,
  COMP_START_ERROR_MSG_CLEAR,
  COMP_START_ACTION_LOADER_START,
} from "../Types/competitionTypes";

import { RESET_REDUX } from "../Types/customTypes";

const initialState = {
  comp_start_Loading: true,
  comp_start_Data: {},
  comp_start_Action_Loader: false,
  comp_start_SuccessMessage: null,
  comp_start_ErrorMessage: null,
  comp_results: {},
  //   comp_start_Get_Error_Message: null,
};

export const CompetitionReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /* Get */
  if (type === COMP_START_DATA_GET_SUCCESS) {
    return {
      ...state,
      // comp_start_SuccessMessage: payload.successMessage,
      comp_start_Data: payload.data,
      comp_start_ErrorMessage: null,
      comp_start_Loading: false,
      comp_start_Action_Loader: false,
    };
  }
  if (type === COMP_START_DATA_GET_ERROR) {
    return {
      ...state,
      comp_start_ErrorMessage: payload.errorMessage,
      comp_start_Data: {},
      comp_start_SuccessMessage: null,
      comp_start_Loading: false,
      comp_start_Action_Loader: false,
    };
  }

  /* Get - RESULT*/
  if (type === COMP_RESULT_GET_SUCCESS) {
    return {
      ...state,
      // comp_start_SuccessMessage: payload.successMessage,
      comp_results: payload.data,
      comp_start_ErrorMessage: null,
      comp_start_Loading: false,
      comp_start_Action_Loader: false,
    };
  }
  if (type === COMP_RESULT_GET_ERROR) {
    return {
      ...state,
      comp_start_ErrorMessage: payload.errorMessage,
      comp_results: {},
      comp_start_SuccessMessage: null,
      comp_start_Loading: false,
      comp_start_Action_Loader: false,
    };
  }
  /* Update*/
  if (type === COMP_START_DATA_UPDATE_SUCCESS) {
    return {
      ...state,
      comp_start_SuccessMessage: payload.successMessage,
      comp_start_ErrorMessage: null,
      comp_start_Action_Loader: false,
    };
  }
  if (type === COMP_START_DATA_UPDATE_ERROR) {
    return {
      ...state,
      comp_start_SuccessMessage: null,
      comp_start_ErrorMessage: payload.errorMessage,
      comp_start_Action_Loader: false,
    };
  }

  /* Loader */
  if (type === COMP_START_ACTION_LOADER_START) {
    return {
      ...state,
      comp_start_Action_Loader: true,
    };
  }

  /* Msg Clear */
  if (type === COMP_START_SUCCESS_MSG_CLEAR) {
    return {
      ...state,
      comp_start_SuccessMessage: null,
      comp_start_ErrorMessage: null,
      comp_start_Action_Loader: false,
    };
  }
  if (type === COMP_START_ERROR_MSG_CLEAR) {
    return {
      ...state,
      comp_start_SuccessMessage: null,
      comp_start_ErrorMessage: null,
      comp_start_Action_Loader: false,
      Jury_Get_Error_Message: null,
    };
  }

  /* Reset State */
  if (type === RESET_REDUX) {
    return {
      ...state,
      comp_start_Loading: true,
      comp_start_Data: {},
      comp_start_Action_Loader: false,
      comp_start_SuccessMessage: null,
      comp_start_ErrorMessage: null,
      comp_results: {},
    };
  }

  return state;
};
