import React, { useEffect, useState, useRef } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import { BASE_URL } from "../../../Config/Config";
import TextField from "@mui/material/TextField";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  GetWordDetails,
  EditWord,
} from "../../../Store/Action/wordAction";
import {
  WORD_ACTION_LOADER_START,
  WORD_SUCCESS_CLEAR,
  WORD_ERROR_CLEAR,
} from "../../../Store/Types/wordTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { MagnifyingGlass } from "react-loader-spinner";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { AiCall } from "../../../HelperFunctions/AiCall";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const EditWordDetails = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const { word_id } = params;

  const dispatch = useDispatch();
  const { currentUserInfo , currentUser } = useSelector((state) => state.Auth);
  const {
    Word_Loading,
    Word_Action_Loader,
    Words_All_Data,
    Words_Details,
    Word_SuccessMessage,
    Word_ErrorMessage,
    Word_Get_Error_Message,
  } = useSelector((state) => state.Word);

  useEffect(() => {
    dispatch(GetWordDetails({ wordId: word_id }));
  }, []);


  const [language, setLanguage] = useState("fr");


  const inputRefs = {
    createdBy: useRef(null),
    wordId: useRef(null),
    theme: useRef(null),
    context: useRef(null),
    nature: useRef(null),
    word: useRef(null),
    origin: useRef(null),
    spellingAlternatives: useRef(null),
    definition: useRef(null),
    word_phonetics: useRef(null),
    illustrativeSentence: useRef(null),
    homonyms: useRef(null),
    gender: useRef(null),
    isApproved: useRef(null),
    isDeleted: useRef(null),
  };

  const obj = {
    createdBy: currentUserInfo._id,
    wordId: word_id,
    theme: "",
    context: "",
    nature: "",
    word: "",
    origin: "",
    spellingAlternatives: "",
    definition: "",
    word_phonetics: "",
    illustrativeSentence: "",
    homonyms: "",
    gender: "",
    difficulty: "",
    isApproved: false,
    isDeleted: false,
  };

  const [state, setState] = useState(obj);
  const [AiBtn, setAiBtn] = useState({
    definition: false,
    illustrativeSentence: false,
  });

  useEffect(() => {
    if (Words_Details) {
      setState({
        ...state,
        ...Words_Details,
      });
    }
  }, [Words_Details]);

  /* input change */
  const handleInputChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  /* handle edit click */
  const handleEditClick = (fieldName) => {
    // Focus on the input field when the "Edit" button is clicked
    const inputRef = inputRefs[fieldName];
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  /* Ai response handler */
  const handleAIResponse = async (fieldname) => {
    // console.log(fieldname);
    setAiBtn({ ...AiBtn, [fieldname]: true });

    const prompt = `I want to generate only the field ${fieldname} for the word ${state.word}.Try to answer simple and short.The ${fieldname} should never reference a word different from ${state.word}, not even derivatives. It should be the word itself.`;
    let AiRes = await AiCall(prompt);
    // console.log(114,AiRes);
    setState({ ...state, [fieldname]: AiRes });

    setAiBtn({ ...AiBtn, [fieldname]: false });
  };

  // console.log(state);
  // console.log(AiBtn);

  /* EDit */
  const handleEditWord = () => {
    dispatch({ type: WORD_ACTION_LOADER_START });
    dispatch(EditWord(state));
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Word_SuccessMessage) {
      const text = MsgTranslator(Word_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: WORD_SUCCESS_CLEAR });
      navigate(-2);
    }
    if (Word_ErrorMessage) {
      const text = MsgTranslator(Word_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: WORD_ERROR_CLEAR });
    }
  }, [Word_SuccessMessage, Word_ErrorMessage]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />
        <div id="content-two">
          <div className="main-container-section">
          <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard",language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                ...(window.location.href.includes("/phase/")
                  ? [
                      {
                        page: MsgTranslator("Phase Manage",language),
                        link: 3,
                      },
                    ]
                  : []), // conditional
                {
                  page: MsgTranslator("Word List",language),
                  link: 2,
                },
                {
                  page: MsgTranslator("Word Details",language),
                  link: 1,
                },
                {
                  page: MsgTranslator("Edit Word",language),
                  link: 0,
                },
              ]}
            />

            <div className="upcoming-title">
              <h3>
                {/* <TranslatedComponent><span>Edit</span> </TranslatedComponent>
                <TranslatedComponent>Word</TranslatedComponent> */}
                <PageTitle title={"<em>Edit</em> Word"} lang={language} />
              </h3>
            </div>
            <div className="candit-sectied-from">
              <TranslatedComponent>
              <div className="row">
                <div className="col-lg-12">
                  <div className="theme-m">
                    <strong>Theme :</strong>
                    <p>{state.theme}</p>
                  </div>
                  <div className="theme-m">
                    <strong>Context :</strong>
                    <p>{state.context}</p>
                  </div>
                </div>
              </div>
              <div className="ft-create-section">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="word-details-st">
                      <form>
                        {/* word */}
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Word :
                          </label>
                          <div className="col-sm-7">
                            <div className="st-6-stt">
                              <div className="st-2-sr">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Word"
                                  name="word"
                                  value={state.word}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  ref={inputRefs.word}
                                />
                              </div>
                              <div className="st-edit-fild">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick("word");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/./Assets/images/edit-dreen.png`
                                    }
                                    alt="Edit Dreen"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleInputChange("word", "");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/Assets/images/delete-green.png`
                                    }
                                    alt="delete green"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* spellingAlternatives */}
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                            onClick={(e) => {
                              e.preventDefault();
                              handleEditClick("spellingAlternatives");
                            }}
                          >
                            Spelling Alternatives :
                          </label>
                          <div className="col-sm-7">
                            <div className="st-6-stt">
                              <div className="st-2-sr">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Spelling Alternatives"
                                  name="spellingAlternatives"
                                  value={state.spellingAlternatives}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  ref={inputRefs.spellingAlternatives}
                                />
                              </div>
                              <div className="st-edit-fild">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick("spellingAlternatives");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/./Assets/images/edit-dreen.png`
                                    }
                                    alt="Edit green"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleInputChange(
                                      "spellingAlternatives",
                                      ""
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/Assets/images/delete-green.png`
                                    }
                                    alt="delete green"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* word phonetics */}
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Word Phonetics :
                          </label>
                          <div className="col-sm-7">
                            <div className="st-6-stt">
                              <div className="st-2-sr">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Number of The Participants"
                                  name="word_phonetics"
                                  value={state.word_phonetics}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  ref={inputRefs.word_phonetics}
                                />
                              </div>
                              <div className="st-edit-fild">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick("word_phonetics");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/./Assets/images/edit-dreen.png`
                                    }
                                    alt="Edit green"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleInputChange("word_phonetics", "");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/Assets/images/delete-green.png`
                                    }
                                    alt="Edit Dreen"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* origin */}
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Origin :
                          </label>
                          <div className="col-sm-7">
                            <div className="st-6-stt">
                              <div className="st-2-sr">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Origin"
                                  name="origin"
                                  value={state.origin}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  ref={inputRefs.origin}
                                />
                              </div>
                              <div className="st-edit-fild">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick("origin");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/./Assets/images/edit-dreen.png`
                                    }
                                    alt="Edit green"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleInputChange("origin", "");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/Assets/images/delete-green.png`
                                    }
                                    alt="Delete Green"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* homonyms */}
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Homonyms :
                          </label>
                          <div className="col-sm-7">
                            <div className="st-6-stt">
                              <div className="st-2-sr">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Homonyms"
                                  name="homonyms"
                                  value={state.homonyms}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  ref={inputRefs.homonyms}
                                />
                              </div>
                              <div className="st-edit-fild">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick("homonyms");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/./Assets/images/edit-dreen.png`
                                    }
                                    alt="Edit green"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleInputChange("homonyms", "");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/Assets/images/delete-green.png`
                                    }
                                    alt="Delete Green"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Gender */}
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Gender :
                          </label>
                          <div className="col-sm-7">
                            <div className="st-6-stt">
                              <div className="st-2-sr">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Gender"
                                  name="gender"
                                  value={state.gender}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  ref={inputRefs.gender}
                                />
                              </div>
                              <div className="st-edit-fild">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick("gender");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/./Assets/images/edit-dreen.png`
                                    }
                                    alt="Edit green"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleInputChange("gender", "");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/Assets/images/delete-green.png`
                                    }
                                    alt="Delete Green"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="word-details-st-2">
                      <form>
                        {/* nature */}
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Nature :
                          </label>
                          <div className="col-sm-7">
                            <div className="st-6-stt">
                              <div className="st-2-sr">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Nature"
                                  name="nature"
                                  value={state.nature}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  ref={inputRefs.nature}
                                />
                              </div>
                              <div className="st-edit-fild">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick("nature");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/./Assets/images/edit-dreen.png`
                                    }
                                    alt="Edit green"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleInputChange("nature", "");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/Assets/images/delete-green.png`
                                    }
                                    alt="Delete Green"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* difficulty */}
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Word difficulty level :
                          </label>
                          <div className="col-sm-7">
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              name="difficulty"
                              value={state.difficulty}
                              // onChange={(e) =>
                              //   handleInputChange(e.target.name, e.target.value)
                              // }
                              required
                              disabled
                            >
                              <option value="Easy">Easy</option>
                              <option value="Moderate">Moderate</option>
                              <option value="Hard">Hard</option>
                            </select>
                          </div>
                        </div>
                        {/* definition */}
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Definition :
                          </label>
                          <div className="col-sm-7">
                            <div
                              className="st-6-stt"
                              style={{ height: "unset" }}
                            >
                              <div className="st-2-sr">
                                <textarea
                                  type="text"
                                  rows="5"
                                  placeholder="Definition"
                                  className="form-control"
                                  name="definition"
                                  value={state.definition}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  ref={inputRefs.definition}
                                  style={{ resize: "none", border: "unset" }}
                                />
                              </div>
                              <div className="st-edit-fild">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick("definition");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/./Assets/images/edit-dreen.png`
                                    }
                                    alt="Edit Green"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleAIResponse("definition");
                                  }}
                                >
                                  {AiBtn.definition ? (
                                    <div>
                                      <CircularProgress
                                        style={{
                                          height: "1rem",
                                          width: "1rem",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      src={
                                        window.location.origin +
                                        `/./Assets/images/refrace.png`
                                      }
                                      alt="ai"
                                    />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* illustrative sentence */}
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Illustrative Sentence :
                          </label>
                          <div className="col-sm-7">
                            <div
                              className="st-6-stt"
                              style={{ height: "unset" }}
                            >
                              <div className="st-2-sr">
                                <textarea
                                  rows="5"
                                  type="text"
                                  placeholder="Illustrative Sentence"
                                  className="form-control"
                                  name="illustrativeSentence"
                                  value={state.illustrativeSentence}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                  ref={inputRefs.illustrativeSentence}
                                  style={{ resize: "none", border: "unset" }}
                                />
                              </div>
                              <div className="st-edit-fild">
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditClick("illustrativeSentence");
                                  }}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/./Assets/images/edit-dreen.png`
                                    }
                                    alt="Edit Green"
                                  />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleAIResponse("illustrativeSentence");
                                  }}
                                >
                                  {AiBtn.illustrativeSentence ? (
                                    <div>
                                      <CircularProgress
                                        style={{
                                          height: "1rem",
                                          width: "1rem",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <img
                                      src={
                                        window.location.origin +
                                        `/./Assets/images/refrace.png`
                                      }
                                      alt="ai"
                                    />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="buttaon-lane">
                  <div className="back-name">
                    <button className="back-g" onClick={() => navigate(-1)}>
                      Back
                    </button>
                  </div>
                  <div className="right-edit">
                    <button
                      className="start-g"
                      onClick={() => handleEditWord()}
                    >
                      {Word_Action_Loader && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "-10%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}{" "}
                      Save
                    </button>
                  </div>
                </div>
              </div>
              </TranslatedComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditWordDetails;
