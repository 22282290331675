export const AiCall = async (myprompt) => {

    try {
        const apiKey = 'sk-proj-kohaGn7egyEsPNE1U9fRT3BlbkFJxRKYoSfs52rfFzPkmLWj';
        const apiUrl = 'https://api.openai.com/v1/chat/completions'; // Adjust the endpoint based on your use case
        // 'https://api.openai.com/v1/assistants'
        // 'https://api.openai.com/v1/chat/completions'
        // const prompt = myprompt;

        const messages = [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: myprompt },
        ];

        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
                // 'model': 'gpt-3.5-turbo'
            },
            body: JSON.stringify({
                model: 'gpt-4-0125-preview',
                // prompt,
                messages,
            }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // console.log('Response:', data.choices[0].message.content);

        return data.choices[0].message.content;

    } catch (error) {
        console.error('Error:', error.message);
        return { status: false, data: error.message };
    }
};