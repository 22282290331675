import React, { useEffect, useState } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPartcipantDetails,
  UpdatePartcipants,
} from "../../../Store/Action/participantAction";
import {
  PARTICIPANT_ACTION_LOADER_START,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
} from "../../../Store/Types/participantTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";
import { calculateAge } from "../../../HelperFunctions/AgeFromDob";
import { BASE_URL } from "../../../Config/Config";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const ParticipantDetails = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentUserInfo , currentUser} = useSelector((state) => state.Auth);
  const {
    Participant_Loading,
    Participant_Action_Loader,
    participant_Details,
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  } = useSelector((state) => state.Participant);

  const { participant_id } = useParams();
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
    dispatch(
      GetPartcipantDetails({
        createdBy: currentUserInfo._id,
        participantId: participant_id,
      })
    );
  }, [participant_id]);

  // const handleSubmit = () => {
  //   dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
  //   dispatch(UpdatePartcipants({ createdBy: currentUserInfo._id, participantId: participant_id, additionalNote: note }));
  // }

  /* success msg and error msg handling */
  useEffect(() => {
    if (Participant_SuccessMessage) {
      const text = MsgTranslator(Participant_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: PARTICIPANT_SUCCESS_MSG_CLEAR });
      navigate(-1);
    }
    if (Participant_ErrorMessage) {
      const text = MsgTranslator(Participant_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
    }
    if (Participant_Get_Error_Message) {
      const text = MsgTranslator(Participant_Get_Error_Message,language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
      });
    }
  }, [
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  ]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          {!Participant_Loading && !Participant_Action_Loader ? (
            <div className="main-container-section">
              <Header
                breadcrumb={[
                  {
                    page: MsgTranslator("Dashboard",language),
                    link:
                      currentUserInfo.role === "Org"
                        ? "/org-dashboard"
                        : "/jury-dashboard",
                  },
                  {
                    page: MsgTranslator("Participant List",language),
                    link: 1,
                  },
                  {
                    page: MsgTranslator("Participant Details",language),
                    link: `/participant-details/${participant_id}`,
                  },
                ]}
              />

              <div className="upcoming-title">
                <h3>
                    <PageTitle title={"<em>Participant</em> Details"} lang={language} />
                </h3>
              </div>
              <div className="candit-sectied-from">
                <div className="user-part-section">
                  <div className="user-part">
                    <div className="user-pict">
                      <img
                        src={
                          participant_Details?.img ||
                          window.location.origin +
                            `/Assets/images/blank-profile-picture.webp`
                        }
                        alt="Testimonial"
                      />
                    </div>
                    <div className="user-name-p">
                      <h2>{`${participant_Details?.firstName}  ${participant_Details?.lastName}`}</h2>
                      <p>{participant_Details?.phone || ""}</p>
                    </div>
                  </div>
                </div>
                <div className="ft-create-section-two">
                  <div className="row">
                   
                      <div className="col-lg-6">
                        <div className="text-from-two">
                          <form>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">01</span>  <TranslatedComponent>First Name :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.firstName || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">02</span>  <TranslatedComponent>Last Name :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.lastName || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">03</span>  <TranslatedComponent>Birthday :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.dob || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">04</span>  <TranslatedComponent>Age :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {calculateAge(participant_Details?.dob) || ""}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">05</span>  <TranslatedComponent>Sex :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.sex || ""}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">06</span>  <TranslatedComponent>Grade :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.grade || ""}
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="text-from-two">
                          <form>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">07</span>  <TranslatedComponent>School :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.school || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">08</span>  <TranslatedComponent>City :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.city || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">09</span>  <TranslatedComponent>Country :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.country?.label || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">10</span> <TranslatedComponent>Phone Number :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.phone || ""}
                                </p>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-5 col-form-label"
                              >
                                <span className="nt">11</span>  <TranslatedComponent>Email :</TranslatedComponent>
                              </label>
                              <div className="col-sm-7">
                                <p className="text-y">
                                  {participant_Details?.email || ""}
                                </p>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-outline">
                          <label
                            className="form-label"
                            htmlFor="textAreaExample"
                          >
                             <TranslatedComponent>Additional Note</TranslatedComponent>
                            
                          </label>
                          <textarea
                            className="form-control textarea"
                            id="textAreaExample1"
                            rows={4}
                            name="additionalNote"
                            value={participant_Details?.additionalNote || ""}
                          />
                        </div>
                      </div>
                      <div className="buttaon-lane">
                        <div className="back-name">
                          <button
                            className="back-g"
                            onClick={() => navigate(-1)}
                          >
                             <TranslatedComponent>Back</TranslatedComponent>
                            
                          </button>
                        </div>
                        <div className="right-edit">
                          <button
                            className="start-g"
                            onClick={() =>
                              window.location.href.includes("/phase/")
                                ? navigate(
                                    `/phase/edit-participant-details/${participant_id}`
                                  )
                                : navigate(
                                    `/edit-participant-details/${participant_id}`
                                  )
                            }
                            // onClick={() =>
                            //   navigate(
                            //     `/edit-participant-details/${participant_id}`
                            //   )
                            // }
                          >
                             <TranslatedComponent>Edit</TranslatedComponent>
                            
                          </button>
                        </div>
                      </div>
                    {/* </TranslatedComponent> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ComponentLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default ParticipantDetails;
