import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import routeConfig from "./views/Routes/routes";
import { useSelector, useDispatch } from "react-redux";
import { GetUserDetails } from "./Store/Action/authAction";
import UnauthorizedAccess from "./views/pages/Helping_Pages/UnauthorizeAccess";
import Login from "./views/pages/Auth/Login";
import { WORD_ERROR_CLEAR, WORD_SUCCESS_CLEAR } from "./Store/Types/wordTypes";
import { notify } from "./component/ToastMsg/ToastMsg";
import { jwtDecode } from "jwt-decode";
import { MsgTranslator } from "./HelperFunctions/MsgTranslator";

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

function App() {
  const dispatch = useDispatch();
  const {
    Word_Generate_Info, Word_ErrorMessage
  } = useSelector((state) => state.Word);
  const { currentUserInfo,authenticate, currentUser} = useSelector((state) => state.Auth);
  const [language,setLanguage] = useState('fr');

  useEffect(() => {
      if (Word_Generate_Info) {
        notify(Word_Generate_Info, "success");
        // localStorage.setItem("wordGenerating", false);
        dispatch({ type: WORD_SUCCESS_CLEAR });
      }
      if (Word_ErrorMessage) {
        notify(Word_ErrorMessage, "error");
        // localStorage.setItem("wordGenerating", false);
        dispatch({ type: WORD_ERROR_CLEAR });
      }
  }, [Word_Generate_Info, Word_ErrorMessage]); 

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      dispatch(GetUserDetails(localStorage.getItem("userId")));
    }
  }, [localStorage.getItem("userId")]);

  /*-------------------[Log rocket set up]--------------------------- */
  LogRocket.init('3yggra/epelle-moi', {
    dom: {
      inputSanitizer: true,
    },
  });

  useEffect(() => {
    if (authenticate && currentUser) {
      LogRocket.identify(`${currentUser._id}`, {
        name: `${currentUser.firstName} ${currentUser.lastName}`,
        email: `${currentUser.email}`,
      });
    }
  }, [authenticate,currentUser]);

  setupLogRocketReact(LogRocket);
  /*-------------------------------------------------------------------- */
  // useEffect(() => {
  //   if (token) {
  //     localStorage.setItem("token", token);
  //   }
  // }, [token]);

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     const token = localStorage.getItem("token");
  //     const decodedData = jwtDecode(token);
  //     dispatch({ type: SET_CURRENT_USER, payload: { data: decodedData } });
  //   }
  // }, [localStorage.getItem("token")]);

  const tokenDecode = (token) => {
    if (token) {
      const tokenDecoded = jwtDecode(token);
      const expTime = new Date(tokenDecoded.exp * 1000);
      if (new Date() > expTime) {
        localStorage.clear();
        return null;
      }
      return tokenDecoded;
    } else {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      const decodeAndLog = () => {
        tokenDecode(localStorage.getItem("token"));
      };
      // Call the function initially
      decodeAndLog();
      // Set up interval to call the function every 1 minute
      const interval = setInterval(decodeAndLog, 3000);
      // Clean up the interval when component unmounts or when dependencies change
      return () => clearInterval(interval);
    }
  }, [localStorage.getItem("token")]);


  const renderRouteElement = (route) => {
    if (
      localStorage.getItem("token") && /* window.location.pathname !== "/" && */
      currentUserInfo &&
      route.permission.includes(currentUserInfo.role)
    ) {
      return <route.component />;
    } else if (localStorage.getItem("token") && currentUserInfo) {
      return <UnauthorizedAccess />;
    } else if (route.permission.includes("public")) {
      return <route.component />;
    } else {
      // return <Login />;
      // window.location.href = "/";
      return <Navigate to="/" />
    }
  };
  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);
  return (
    <BrowserRouter>
      <Routes>
        {routeConfig.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={renderRouteElement(route)}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;


// import React, { Suspense, useState, useEffect, startTransition } from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import routeConfig from "./views/Routes/routes";
// import LoadingSpinner from "./LoadingSpinner.jsx";
// import { useSelector, useDispatch } from "react-redux";
// import { GetUserDetails } from "./Store/Action/authAction";
// import UnauthorizedAccess from "./views/pages/Helping_Pages/UnauthorizeAccess";
// import Login from "./views/pages/Auth/Login";

// function App() {
//   const dispatch = useDispatch();
//   const { currentUserInfo } = useSelector((state) => state.Auth);
//   useEffect(() => {
//     if (localStorage.getItem("userId")) {
//       dispatch(GetUserDetails(localStorage.getItem("userId")));
//     }
//   }, [localStorage.getItem("userId")]);

//   const renderRouteElement = (route) => {
//     if (currentUserInfo && route.permission.includes(currentUserInfo.role)) {
//       return <route.component />;
//     } else if (currentUserInfo) {
//       return <UnauthorizedAccess />;
//     } else if (route.permission.includes("public")) {
//       return <AsyncComponentLoader component={route.component} />;
//     } else {
//       return <Login />;
//     }
//   };

//   return (
//     <BrowserRouter>
//       <Suspense fallback={<LoadingSpinner />}>
//         <Routes>
//           {routeConfig.map((route) => (
//             <Route
//               key={route.path}
//               path={route.path}
//               element={renderRouteElement(route)}
//             />
//           ))}
//         </Routes>
//       </Suspense>
//     </BrowserRouter>
//   );
// }

// const AsyncComponentLoader = ({ component: Component }) => {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     startTransition(() => {
//       setLoading(false);
//     });
//   }, []);

//   return loading ? <LoadingSpinner /> : <Component />;
// };

// export default App;
