/* import tytpes */
/* import tytpes */
import {
  TOURNAMENT_GET_SUCCESS,
  TOURNAMENT_GET_ERROR,
  TOURNAMENT_DETAILS_GET_SUCCESS,
  TOURNAMENT_DETAILS_GET_ERROR,
  TOURNAMENT_CREATE_SUCCESS,
  TOURNAMENT_CREATE_ERROR,
  TOURNAMENT_SUBCOMPETITION_CREATE_SUCCESS,
  TOURNAMENT_SUBCOMPETITION_CREATE_ERROR,
  TOURNAMENT_UPDATE_SUCCESS,
  TOURNAMENT_UPDATE_ERROR,
    TOURNAMENT_DELETE_SUCCESS,
    TOURNAMENT_DELETE_ERROR,
  //   TOURNAMENT_SUCCESS_CLEAR,
  //   TOURNAMENT_ERROR_CLEAR,
  //   TOURNAMENT_ACTION_LOADER,
} from "../Types/tournamentTypes";

import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";
import { getInputAdornmentUtilityClass } from "@mui/material";

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const GetAllTournament = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          adminId: data,
          orgId: data,
        },
      };

      const response = await axios.get(
        `${SERVER_URL}/tournament/all-tournament-details`,
        config
      );

      dispatch({
        type: TOURNAMENT_GET_SUCCESS,
        payload: {
          successMessage: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: TOURNAMENT_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetTournamentDetails = (data) => {
  return async (dispatch) => {
    try {
      // console.log(data);
      const config = {
        headers: {
          tournamentId: data.tournamentId,
          orgId: data.orgId,
        },
      };

      const response = await axios.get(
        `${SERVER_URL}/tournament/tournament-details`,
        config
      );

      // console.log(response.data.data);

      dispatch({
        type: TOURNAMENT_DETAILS_GET_SUCCESS,
        payload: {
          successMessage: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: TOURNAMENT_DETAILS_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const Create_Tournament = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/tournament/tournament-add`,
        data
      );

      window.location.href = `/phase-manage/${response.data.data._id}`;

      dispatch({
        type: TOURNAMENT_CREATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
          newCompId: response.data.data._id,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: TOURNAMENT_CREATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const Create_Tournament_SubComp = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/tournament/add-sub-competition`,
        data
      );

      // window.location.href = `/one-shot-details/${response.data.data._id}`;

      dispatch({
        type: TOURNAMENT_SUBCOMPETITION_CREATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: TOURNAMENT_SUBCOMPETITION_CREATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const Update_Tournament = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/tournament/tournament-update`,
        data
      );

      dispatch({
        type: TOURNAMENT_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: TOURNAMENT_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const Update_Tournament_Phase_Status = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/tournament/tournament-phase-status-update`,
        data
      );

      dispatch({
        type: TOURNAMENT_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: TOURNAMENT_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const Delete_Tournament = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/tournament/tournament-delete`,
        data
      );

      dispatch({
        type: TOURNAMENT_DELETE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: TOURNAMENT_DELETE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};
