import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import PlayerSerialNumber from "../../../component/PlayerSerialNumber/PlayerSerialNumber";
import { convertSecondsToTime } from "../../../HelperFunctions/ConvertSec.js";

import {
  GetCurrentRound,
  Record_Word_Answer,
  Update_Result_for_round,
} from "../../../Store/Action/roundAction";
import {
  ROUND_SUCCESS_MSG_CLEAR,
  ROUND_ERROR_MSG_CLEAR,
} from "../../../Store/Types/roundTypes";

import { GetWordDetails } from "../../../Store/Action/wordAction";
import { WORD_ERROR_CLEAR } from "../../../Store/Types/wordTypes";

import Swal from "sweetalert2";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import { GetJuryCapability } from "../../../Store/Action/juryAction";

import {
  StartSocketConnection,
  JoinRoom,
  pageChange,
  pageQuery,
} from "../../../HelperFunctions/Socket/socket.js";
import TranslatedComponent from "../../../translator/TranslatedComponent.jsx";
import PageTitle from "../../../component/PageTitle/PageTitle.jsx";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator.js";

const CandidateSpellingStatus = () => {
  const { round_id, player_id, word_id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);

  const {
    round_Loading,
    current_round_Details,
    round_Action_Loader,
    round_SuccessMessage,
    round_ErrorMessage,
  } = useSelector((state) => state.Round);

  const { Jury_Loading, Jury_Action_Loader, Jury_Capabilities } = useSelector(
    (state) => state.Jury
  );

  const [pageState, setPageState] = useState(false);
  /* Current candidate state */
  const [candidate, setCandidate] = useState({});
  /* Trget Word */
  const [targetWord, setTargetWord] = useState({});
  const [language, setLanguage] = useState("fr");

  /* get current candidate index */
  const CurrentCandidateIndex = (player_id) => {
    const PlayerSerial = current_round_Details?.participants.findIndex(
      (sp) => sp.playerId._id.toString() === player_id
    );
    if (
      PlayerSerial != -1 &&
      current_round_Details?.participants.length > PlayerSerial + 1
    ) {
      const nextPlayerId =
        current_round_Details?.participants[PlayerSerial + 1]?.playerId?._id;
      return {
        curSerial: PlayerSerial + 1,
        nextSerial: nextPlayerId,
      };
    } else if (
      PlayerSerial != -1 &&
      current_round_Details?.participants.length === PlayerSerial + 1
    ) {
      return { curSerial: PlayerSerial + 1, nextSerial: null };
    } else {
      return {
        curSerial: null,
        nextSerial: null,
      };
    }
  };

  /* get round details wrt round_id */
  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(GetCurrentRound({ roundId: round_id }));
    StartSocketConnection();
  }, [round_id]);

  pageQuery(({ competitionId, page }) => {
    // console.log(page);
    setPageState(page);
    // navigate(page);
  });

  useEffect(() => {
    if (pageState) {
      navigate(pageState);
    }
  }, [pageState]);

  /* Get unused Words and get word Details */
  useEffect(() => {
    if (
      Object.keys(current_round_Details).length > 0 &&
      current_round_Details.participants &&
      current_round_Details.participants.length > 0
    ) {
      let targetParticipant = current_round_Details.participants.find(
        (participant) => participant.playerId._id.toString() === player_id
      );

      setCandidate(targetParticipant);

      if (targetParticipant && targetParticipant.words) {
        const wordStatus = targetParticipant.words.find(
          (sw) => sw.wordId === word_id
        );
        setTargetWord(wordStatus || null);
      }
    }
  }, [current_round_Details]);

  /* handle submit */
  const handleSubmit = () => {
    let { curSerial, nextSerial } = CurrentCandidateIndex(player_id);
    const obj = {
      compId: current_round_Details.compId._id,
      playerId: player_id,
      wordId: targetWord.wordId,
      roundId: round_id,
      isCompleted: nextSerial ? false : true,
    };
    dispatch(Update_Result_for_round(obj));

    if (nextSerial) {
      // console.log("here 132")
      navigate(`/candidate-details/${round_id}`);

      pageChange(
        current_round_Details?.compId?._id,
        `/candidate-details/${round_id}`
      );
    } else {
      navigate(`/summary-selection/${round_id}`);

      pageChange(
        current_round_Details?.compId?._id,
        `/summary-selection/${round_id}`
      );
      // navigate(`/summary-selection/${round_id}`);
    }
  };

  /* btn name */
  const [btnName, setBtnName] = useState("Go to Next Candidate");

  useEffect(() => {
    if (
      Object.keys(current_round_Details).length > 0 &&
      targetWord &&
      word_id
    ) {

      if (current_round_Details.compId._id !== undefined && current_round_Details.compId._id !== null) {
        JoinRoom(current_round_Details.compId._id, currentUserInfo._id);
        pageChange(
          current_round_Details.compId._id,
          `/candidate-spelling-status/${round_id}/${player_id}/${word_id}`
        );
      }
      if (currentUserInfo.role === "Jury") {
        dispatch(
          GetJuryCapability({
            juryId: currentUserInfo._id,
            compId: current_round_Details.compId._id,
          })
        );
      }

      let { curSerial, nextSerial } = CurrentCandidateIndex(player_id);
      if (nextSerial) {
        const buttonText = MsgTranslator("Go to Next Candidate", language);
        setBtnName(buttonText);
      } else {
        const buttonText = MsgTranslator("Show Summary", language);
        setBtnName(buttonText);
      }
    }
  }, [current_round_Details, targetWord]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  /* message */
  useEffect(() => {
    if (round_SuccessMessage) {
      dispatch({ type: ROUND_SUCCESS_MSG_CLEAR });
    }
    if (round_ErrorMessage) {
      const text = MsgTranslator(round_ErrorMessage, language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: ROUND_ERROR_MSG_CLEAR });
      });
    }
  }, [round_SuccessMessage, round_ErrorMessage]);

  return (
    <div className="wrapper">
      <SidebarLeft />

      <div id="content-two">
        <div className="main-container-section">
          <Header />

          <div className="upcoming-title">
            <h3>
              <PageTitle
                title={"<em>Candidate Speling</em> Status"}
                lang={language}
              />
            </h3>
          </div>

          {!round_Loading &&
          Object.keys(current_round_Details).length > 0 &&
          Object.keys(candidate).length > 0 &&
          Object.keys(targetWord).length > 0 ? (
            <div className="start-section">
              <TranslatedComponent>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12">
                      <div id="txt"></div>
                      <div className="candit-sectied-from">
                        <div className="caption-one">
                          <div className="capap-t">
                            <ul>
                              <li>
                                <span>Competition Name :</span>{" "}
                                <p>
                                  {current_round_Details?.compId?.name || ""}{" "}
                                </p>
                              </li>
                              <li>
                                <span>Competition Type :</span>{" "}
                                <p>
                                  {current_round_Details?.compId?.tournamentType
                                    ? "Tournament"
                                    : "OneShot"}
                                </p>
                              </li>
                              <li>
                                <span>Current Round :</span>{" "}
                                <p>{current_round_Details?.name || ""}</p>
                              </li>
                              <li>
                                <span>Spelling Duration :</span>{" "}
                                <p>
                                  {convertSecondsToTime(
                                    targetWord?.speltTime || ""
                                  )}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div className="caption-two">
                          <div className="capap-t">
                            <ul>
                              <li>
                                <span>Candidate Number :</span>{" "}
                                <p>
                                  {
                                    <PlayerSerialNumber
                                      compId={
                                        current_round_Details?.compId?._id
                                      }
                                      playerId={player_id}
                                    />
                                  }
                                </p>
                              </li>
                              <li>
                                <span>Candidate Name :</span>{" "}
                                <p>
                                  {candidate?.playerId?.firstName ||
                                    "" + " " + candidate?.playerId?.lastName ||
                                    ""}{" "}
                                </p>
                              </li>
                              <li>
                                <span>Word Given :</span>{" "}
                                <p>{targetWord?.word || ""}</p>
                              </li>
                              <li>
                                <span>The Word Spelt :</span>{" "}
                                <p>{targetWord?.answer || ""}</p>
                              </li>
                              <li>
                                <span>Spelling Status :</span>{" "}
                                <p>
                                  {targetWord?.result ? "Correct" : "Incorrect"}
                                </p>
                              </li>
                            </ul>
                          </div>
                          {(currentUserInfo.role === "Org" ||
                            (currentUserInfo.role === "Jury" &&
                              Jury_Capabilities.includes(
                                "Go To Next Candidate"
                              ))) && (
                            <div
                              className="buttaon-lane"
                              onClick={() => handleSubmit()}
                            >
                              <a className="start-g">{btnName}</a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TranslatedComponent>
            </div>
          ) : (
            <>
              <ComponentLoader />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateSpellingStatus;
