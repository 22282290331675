/* import tytpes */
import {
  WORD_ALL_GET_SUCCESS,
  WORD_ALL_GET_ERROR,
  WORD_ORG_GET_SUCCESS,
  WORD_ORG_GET_ERROR,
  WORD_COMP_GET_SUCCESS,
  WORD_COMP_GET_ERROR,
  WORD_DETAILS_GET_SUCCESS,
  WORD_DETAILS_GET_ERROR,
  WORD_ADD_SUCCESS,
  WORD_ADD_ERROR,
  WORD_UPDATE_SUCCESS,
  WORD_UPDATE_ERROR,
  WORD_DELETE_SUCCESS,
  WORD_DELETE_ERROR,
} from "../Types/wordTypes";

import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const WordAdd = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${SERVER_URL}/word/add`, data);

      dispatch({
        type: WORD_ADD_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: WORD_ADD_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetOrgWord = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/word/get-org-word`,
        data
      );

      dispatch({
        type: WORD_ALL_GET_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: WORD_ALL_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

// export const GetOrgWord = (data) => {
//   return async (dispatch) => {
//     try {
//       const response = await axios.post(
//         `${SERVER_URL}/word/get-org-word`,
//         data
//       );

//       dispatch({
//         type: WORD_ORG_GET_SUCCESS,
//         payload: {
//           data: response.data.data,
//           successMessage: response.data.message,
//         },
//       });
//     } catch (error) {
//       let data = error?.response?.data?.message;

//       dispatch({
//         type: WORD_ORG_GET_ERROR,
//         payload: {
//           errorMessage: data,
//         },
//       });
//     }
//   };
// };

export const GetCompWord = (data) => {
  console.log(data);
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/word/get-comp-word`,
        data
      );

      localStorage.setItem(`${data.oneShotId}`, false);
      dispatch({
        type: WORD_COMP_GET_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;
      localStorage.removeItem(`${data.oneShotId}`);
      dispatch({
        type: WORD_COMP_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetWordDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/word/get-word-details`,
        data
      );

      dispatch({
        type: WORD_DETAILS_GET_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: WORD_DETAILS_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const EditWord = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(`${SERVER_URL}/word/edit-word`, data);
      
      dispatch({
        type: WORD_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: WORD_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const DeleteWord = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/word/delete-word`,
        data
      );

      dispatch({
        type: WORD_DELETE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: WORD_DELETE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};
