import React from "react";
import TranslatedComponent from "../../translator/TranslatedComponent";

const SidebarRightTwo = ({ Heading, List }) => {
  console.log(Heading);
  return (
    <>
      <div className="right-site">
        <div className="organizer-st">
          <h2>
            <img
              src={window.location.origin + "/Assets/images/organizer.png"}
              alt="Organizer"
            />
            <TranslatedComponent>{Heading}</TranslatedComponent>
          </h2>
        </div>
        {List &&
          List.length > 0 &&
          List.map((winner) => {
            return (
              <div className="user-point-r">
                <div className="user-point-r">
                  <div className="user-pic">
                    <img
                      src={
                        winner?.img
                          ? winner?.img
                          : window.location.origin +
                            "/Assets/images/blank-profile-picture.webp"
                      }
                      alt="Testimonial"
                    />
                  </div>
                  <h3>{winner?.name || ""}</h3>
                </div>
                <div className="user-point-r">
                  <TranslatedComponent>
                    <h2>Score</h2>
                  </TranslatedComponent>
                  <strong>
                    {`${winner?.totalScore || "0"}/${
                      winner?.totalWordSpelt || "0"
                    }`}
                  </strong>
                </div>
                <div className="user-point-r">
                  <TranslatedComponent>
                    <h4>Rounds Played</h4>
                  </TranslatedComponent>
                  <strong>{winner?.totalRoundPlayed || "0"}</strong>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default SidebarRightTwo;
