import React, { useState, useEffect, useMemo } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import SidebarRight from "../../../component/Sideber/SidebarRight";
import Header from "../../../component/Header/Header";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../Config/Config";

import {
  // GetAllPartcipant,
  GetCompWisePartcipants,
  ExistingParticiapntAdd,
  GetAllWinner_PartcipantInPreviousPhase,
} from "../../../Store/Action/participantAction";
import {
  PARTICIPANT_ACTION_LOADER_START,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
} from "../../../Store/Types/participantTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";
import { calculateAge } from "../../../HelperFunctions/AgeFromDob";
import Select from "react-select";
import countryList from "react-select-country-list";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const PhaseTransfer = () => {
  const options = useMemo(() => countryList().getData(), []);
  const { phase_id, comp_id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentUserInfo , currentUser} = useSelector((state) => state.Auth);
  const [playerIds, setPLayerIds] = useState([]);
  const [allSelected, setAllSelected] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [language, setLanguage] = useState("fr");

  const obj = {
    firstName: "",
    phone: "",
    country: "",
    city: "",
  };

  const [state, setState] = useState(obj);
  /* Input Change  */
  const handleInputChange = (field, value) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const {
    Participant_Loading,
    Participant_Action_Loader,
    Participants_All_Data,
    Participants_Data,
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  } = useSelector((state) => state.Participant);

  useEffect(() => {
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
    dispatch(
      GetAllWinner_PartcipantInPreviousPhase({
        createdBy: currentUserInfo._id,
        compId: comp_id,
        phaseId: phase_id,
      })
    );
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });

    dispatch(
      GetCompWisePartcipants({
        createdBy: currentUserInfo._id,
        compId: comp_id,
      })
    );
  }, [comp_id]);

  useEffect(() => {
    if (Participants_Data && Participants_Data.length > 0) {
      let ids = Participants_Data.map((sp) => {
        return sp._id;
      });
      setPLayerIds(ids);
      setAllSelected(ids);
    }
    if (Participants_All_Data && Participants_All_Data.length > 0) {
      setFilteredData(Participants_All_Data);
    }
  }, [Participants_Data, Participants_All_Data, comp_id]);

  /* checkbox selection handler */

  const handlecheckBox = (checkStatus, p_id) => {
    if (checkStatus) {
      const updatedSelected = [...allSelected];
      updatedSelected.push(p_id);
      setAllSelected(updatedSelected);
    } else {
      if (allSelected.includes(p_id)) {
        //remove the id from array
        const updatedSelected = [...allSelected];
        let newSelUpdate = updatedSelected.filter((item) => item !== p_id);
        setAllSelected(newSelUpdate);
      }
    }
  };

  const handleAllcheckBox = (checkStatus) => {
    if (checkStatus) {
      let ids = Participants_All_Data.map((sp) => {
        return sp.playerId;
      });
      setAllSelected(ids);
    } else {
      setAllSelected([]);
    }
  };

  /* Update */
  const handleSave = () => {
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
    const transformedArray = allSelected.map((id, index) => ({
      _id: id,
    }));
    const data = {
      createdBy: currentUserInfo._id,
      compId: comp_id,
      playerArray: transformedArray,
    };
    dispatch(ExistingParticiapntAdd(data));
  };

  const handleFilterSearch = (e) => {
    e.preventDefault();

    const filteredArray = Participants_All_Data.filter((participant) => {
      const CompMatch =
        !state.subCompName ||
        (participant.subCompName &&
          participant.subCompName
            .toLowerCase()
            .includes(state.subCompName.toLowerCase())) ||
        state.subCompName.trim() === "";

      const NameMatch =
        !state.name ||
        (participant.playerName &&
          participant.playerName
            .toLowerCase()
            .includes(state.name.toLowerCase())) ||
        state.name.trim() === "";

      const phoneMatch =
        !state.phone ||
        (participant.phone &&
          participant.phone
            .toLowerCase()
            .includes(state.phone.toLowerCase())) ||
        state.phone.trim() === "";

      const countryMatch =
        !state.country.label ||
        (participant.country?.label &&
          participant.country.label
            .toLowerCase()
            .includes(state.country.label.toLowerCase())) ||
        state.country.label.trim() === "";

      const cityMatch =
        !state.city ||
        (participant.city &&
          participant.city.toLowerCase().includes(state.city.toLowerCase())) ||
        state.city.trim() === "";

      // Combine conditions with OR (||) to get the final result
      return CompMatch && NameMatch && phoneMatch && countryMatch && cityMatch;
    });
    // Update the filtered data state
    setFilteredData(filteredArray);
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Participant_SuccessMessage) {
      const text = MsgTranslator(Participant_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: PARTICIPANT_SUCCESS_MSG_CLEAR });
      navigate(`/all-participant-prev-phase/${phase_id}/${comp_id}`);
    }
    
    if (Participant_ErrorMessage) {
      const text = MsgTranslator(Participant_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
    }
    
    if (Participant_Get_Error_Message) {
      const text = MsgTranslator(Participant_Get_Error_Message,language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
      });
    }
  }, [
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  ]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);


  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="candit-sectied-from">
              <TranslatedComponent>
                <h4>Existing Participant</h4>
                <div className="ft-create-section">
                  <form
                    method="Post"
                    onSubmit={(e) => handleFilterSearch(e)}
                    className="quick-9"
                  >
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="text-from-one">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-12 col-form-label"
                            >
                              Competition Name :
                            </label>
                            <div className="col-sm-12">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Competition Name"
                                name="subCompName"
                                value={state.subCompName}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="text-from-one">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-12 col-form-label"
                            >
                              Name :
                            </label>
                            <div className="col-sm-12">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                name="name"
                                value={state.name}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="text-from-one">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-12 col-form-label"
                            >
                              Phone Number :
                            </label>
                            <div className="col-sm-12">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone Number"
                                name="phone"
                                value={state.phone}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="text-from-one">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-12 col-form-label"
                            >
                              Country :
                            </label>
                            <div className="col-sm-12">
                              <Select
                                isClearable={true}
                                options={options}
                                value={state.country}
                                onChange={(e) =>
                                  handleInputChange("country", e)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="text-from-one">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-12 col-form-label"
                            >
                              City :
                            </label>
                            <div className="col-sm-12">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Time"
                                name="city"
                                value={state.city}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="search-p">
                        <i className="fa-solid fa-magnifying-glass" />
                      </button>
                    </div>
                  </form>
                </div>
                <div className="existing-section">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="column1">
                            <input
                              type="checkbox"
                              onClick={(e) => {
                                handleAllcheckBox(e.target.checked);
                              }}
                            />
                          </th>
                          <th scope="col">Competition Name</th>
                          <th scope="col">Name</th>
                          <th scope="col">Phone Number</th>
                          <th scope="col">Country</th>
                          <th scope="col">City</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!Participant_Loading ? (
                          filteredData && filteredData.length > 0 ? (
                            filteredData.map((sp, i) => {
                              // console.log(sp);
                              return (
                                <tr key={sp.playerId}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={
                                        allSelected.includes(sp.playerId)
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => {
                                        handlecheckBox(
                                          e.target.checked,
                                          sp.playerId
                                        );
                                      }}
                                    />
                                  </td>
                                  <td className="column1a">
                                    {sp?.subCompName || ""}
                                  </td>
                                  <td className="column1a">
                                    {sp?.playerName || ""}
                                  </td>
                                  <td className="column9a">
                                    {sp?.phone || ""}
                                  </td>
                                  <td className="column7a">
                                    {sp?.country?.label || ""}
                                  </td>
                                  <td className="column8a">
                                    {" "}
                                    {sp?.city || ""}
                                  </td>
                                </tr>
                              );
                            })
                          ) : null
                        ) : (
                          <tr style={{ textAlign: "center" }}>
                            <td colSpan="6">
                              <MagnifyingGlass
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="magnifying-glass-loading"
                                wrapperStyle={{ display: "inline-block" }}
                                wrapperClass="magnifying-glass-wrapper"
                                glassColor="#c0efff"
                                color="#e15b64"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {!Participant_Action_Loader ? (
                  <button className="add-p" onClick={() => handleSave()}>
                    <img
                      src={window.location.origin + `/Assets/images/add-p.png`}
                      alt="Add"
                    />{" "}
                    Save existing Participant
                  </button>
                ) : (
                  <CircularProgress
                    size={24}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "-10%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
                <div className="buttaon-lane">
                  <button className="back-g" onClick={() => navigate(-1)}>
                    Back
                  </button>
                </div>
              </TranslatedComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhaseTransfer;
