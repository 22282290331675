export const TOURNAMENT_GET_SUCCESS = "TOURNAMENT_GET_SUCCESS";
export const TOURNAMENT_GET_ERROR = "TOURNAMENT_GET_ERROR";

export const TOURNAMENT_DETAILS_GET_SUCCESS = "TOURNAMENT_DETAILS_GET_SUCCESS";
export const TOURNAMENT_DETAILS_GET_ERROR = "TOURNAMENT_DETAILS_GET_ERROR";

export const TOURNAMENT_CREATE_SUCCESS = "TOURNAMENT_CREATE_SUCCESS";
export const TOURNAMENT_CREATE_ERROR = "TOURNAMENT_CREATE_ERROR";

export const TOURNAMENT_SUBCOMPETITION_CREATE_SUCCESS = "TOURNAMENT_SUBCOMPETITION_CREATE_SUCCESS";
export const TOURNAMENT_SUBCOMPETITION_CREATE_ERROR = "TOURNAMENT_SUBCOMPETITION_CREATE_ERROR";

export const TOURNAMENT_UPDATE_SUCCESS = "TOURNAMENT_UPDATE_SUCCESS";
export const TOURNAMENT_UPDATE_ERROR = "TOURNAMENT_UPDATE_ERROR";

export const TOURNAMENT_DELETE_SUCCESS = "TOURNAMENT_DELETE_SUCCESS";
export const TOURNAMENT_DELETE_ERROR = "TOURNAMENT_DELETE_ERROR";

export const TOURNAMENT_SUCCESS_CLEAR = "TOURNAMENT_SUCCESS_CLEAR";
export const TOURNAMENT_ERROR_CLEAR = "TOURNAMENT_ERROR_CLEAR";

export const TOURNAMENT_ACTION_LOADER = "TOURNAMENT_ACTION_LOADER";

export const TOURNAMENT_DETAILS_CLEAR = "TOURNAMENT_DETAILS_CLEAR";

