import React, { useEffect, useState } from "react";
import Header from "../../../component/Header/Header";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addNewRound,
  getSpecialRoundData,
} from "../../../Store/Action/roundAction";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PlayerSerialNumber from "../../../component/PlayerSerialNumber/PlayerSerialNumber";
import PageTitle from "../../../component/PageTitle/PageTitle";
import {
  JoinRoom,
  pageChange,
  pageQuery,
} from "../../../HelperFunctions/Socket/socket";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

// Styling for body cells that are sticky
const StyledTableCell = styled(TableCell)({
  position: "sticky",
  left: 0,
  backgroundColor: "#fff", // Directly setting a white background color
  zIndex: 1,
});

const StyledNameTableCell = styled(TableCell)({
  position: "sticky",
  left: 50, // Adjust according to the checkbox width
  backgroundColor: "#fff", // Directly setting a white background color
  zIndex: 1,
});

// Styling for header cells that are sticky
const StyledHeaderTableCell = styled(StyledTableCell)({
  top: 0,
  zIndex: 3,
});

const StyledNameHeaderTableCell = styled(StyledNameTableCell)({
  top: 0,
  zIndex: 3,
});

let columns = [
  { id: "checkbox", label: "fd" },
  { id: "name", label: "Name" },
];

const SpecialRound = () => {
  const {
    round_SuccessMessage,
    specialTableData,
    createSpecialRoundError,
    createSpecialRoundSuccess,
  } = useSelector((state) => state.Round);
  const { currentUser, currentUserInfo } = useSelector((state) => state.Auth);

  const [newRoundMode, setNewRoundMode] = useState("");
  const [searchByStatus, setSearchByStatus] = useState("all");
  const [rows, setRows] = useState([]);
  const [rowLength, setRowLength] = useState(0);
  const [playerId, setPlayerId] = useState([]);
  const [parentChecked, setParentChecked] = useState(false);
  const [childCheckedState, setChildCheckedState] = useState([]);
  const [pressedNumber, setPressedNumber] = useState(0);
  const [roundData, setRoundData] = useState([]);
  const dispatch = useDispatch();
  const { comp_id } = useParams();
  const navigate = useNavigate();

  /* page state */
  const [pageState, setPageState] = useState(false);
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    if (comp_id && currentUserInfo && Object.keys(currentUserInfo).length > 0) {
      JoinRoom(comp_id, currentUserInfo._id);

      pageChange(comp_id, `/start-special-competition-round/${comp_id}`);
    }
  }, [comp_id]);

  pageQuery(({ competitionId, page }) => {
    setPageState(page);
  });

  useEffect(() => {
    console.log(pageState);
    if (pageState) {
      navigate(pageState);
    }
  }, [pageState]);

  useEffect(() => {
    setChildCheckedState(
      specialTableData.map((row) => ({ id: row._id, checked: false }))
    );
  }, [specialTableData]);

  const handleChange = (event) => {
    setNewRoundMode(event.target.value);
  };

  const handleChangeSearch = (e) => {
    const inputName = e.target.value.trim().toLowerCase();
    if (inputName === "") {
      setRows(specialTableData);
      return;
    }
    const filterData = specialTableData.filter((row) => {
      return row.name.toLowerCase().includes(inputName.toLowerCase());
    });

    setRows(filterData);
  };

  const handleStatusWiseSearch = (status) => {
    setSearchByStatus(status.target.value);
  };

  const handleParentCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setParentChecked(isChecked);
    setChildCheckedState(
      childCheckedState.map((item) => ({ ...item, checked: isChecked }))
    );
    setPlayerId(isChecked ? specialTableData.map((row) => row._id) : []);
  };

  const handleChildCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;
    setChildCheckedState(
      childCheckedState.map((item) =>
        item.id === row._id ? { ...item, checked: isChecked } : item
      )
    );
    if (isChecked) {
      setPlayerId([...playerId, row._id]);
      setParentChecked(true);
    } else {
      const updatedPlayerIds = playerId.filter((id) => id !== row._id);
      setPlayerId(updatedPlayerIds);
      if (updatedPlayerIds.length === 0) {
        setParentChecked(false);
      }
    }
  };

  const startnewRound = () => {
    if (playerId.length === 0) {
      const text = MsgTranslator(
        "Select The Participants Atleast One",
        language
      );
      notify(text, "error");
      return;
    }

    if (!comp_id) {
      const text = MsgTranslator("Oppse... Somthing Error", language);
      notify(text, "error");
      return;
    }

    if (!newRoundMode && newRoundMode.length === 0) {
      const text = MsgTranslator("Select The Round Mode Please", language);
      notify(text, "error");
      return;
    }
    const newRoundObject = {
      players: playerId,
      competitionId: comp_id,
      rankingMode: newRoundMode,
    };
    dispatch(addNewRound(newRoundObject));
  };

  const handleSearchRoundNumber = (e) => {
    const rundeNumber = e.target.value;
    if (rundeNumber.length > 0) {
      const number = parseInt(rundeNumber);
      setPressedNumber(parseInt(rundeNumber));
      if (number) {
        const filteredRounds = specialTableData
          .map((roundObject) => {
            const roundArray = roundObject.rounds.filter(
              ({ round }) => round === parseInt(rundeNumber)
            );
            return roundArray.length > 0
              ? { ...roundObject, rounds: roundArray }
              : null;
          })
          .filter(Boolean);
        setRows(filteredRounds);
        setRoundData(filteredRounds);
      }
    }
  };

  const handlePreshButton = (e) => {
    if (e.key === "Backspace") {
      setRows(specialTableData);
      setPressedNumber(0);
    }
  };

  // get rows headers number
  const getHeaders = (array) => {
    let maxRoundsLength = -1;
    const headingName = MsgTranslator("Name", language);
    columns = [
      { id: "checkbox", label: "fd" },
      { id: "id", label: "id" },
      { id: "name", label: headingName },
    ];

    array.forEach((item) => {
      const rounds = item.rounds;
      if (rounds.length > maxRoundsLength) {
        maxRoundsLength = rounds.length;
      }
    });
    setRowLength(maxRoundsLength);

    const roundName = MsgTranslator("Round", language);

    if (pressedNumber > 0) {
      columns.push({ id: `Round0`, label: `${roundName} ${pressedNumber}` });
    } else {
      for (let i = 0; i < maxRoundsLength; i++) {
        const rowObject = {
          id: `Round${i}`,
          label: `${roundName} ${i + 1}`,
        };
        columns.push(rowObject);
      }
    }
  };

  useEffect(() => {
    const set = new Set();
    if (pressedNumber === 0) {
      for (const row of specialTableData) {
        row.rounds.forEach((round) => {
          if (round.status) {
            if (round.status.toLowerCase() === searchByStatus) {
              set.add(row);
            }
          }
        });
      }
      const array =
        searchByStatus === "all" ? specialTableData : Array.from(set);
      setRows(array);
    }

    if (pressedNumber) {
      if (searchByStatus === "all") {
        setRows(roundData);
        return;
      }
      const filterRound = [];
      roundData &&
        roundData.map((row) => {
          const isPresent = row?.rounds?.some((round) => {
            return round && round?.status?.toLowerCase() === searchByStatus;
          });

          if (isPresent) filterRound.push(row);
        });
      setRows(filterRound);
    }
  }, [searchByStatus, pressedNumber, roundData]);

  useEffect(() => {
    dispatch(getSpecialRoundData({ comp_id }));
  }, [dispatch, comp_id]);

  useEffect(() => {
    setRows(specialTableData);
    if (specialTableData) {
      dispatch({ type: "ROUND_SUCCESS_MSG_CLEAR" });
    }
  }, [round_SuccessMessage, dispatch, specialTableData]);

  useEffect(() => {
    const roundName = MsgTranslator("Round", language);

    const headingName = MsgTranslator("Name", language);
    columns = [
      { id: "checkbox", label: "fd" },
      { id: "id", label: "id" },
      { id: "name", label: roundName },
    ];

    for (let i = 0; i < rowLength; i++) {
      const rowObject = {
        id: `Round${i}`,
        label: `${headingName} ${i + 1}`,
      };

      columns.push(rowObject);
    }
  }, [searchByStatus, rowLength]);
  // console.log(rows);
  useEffect(() => {
    if (searchByStatus !== "all" && pressedNumber) {
      const filterRound = [];
      roundData &&
        roundData.map((row) => {
          const isPresent = row?.rounds?.some((round) => {
            return round?.status?.toLowerCase() === searchByStatus;
          });
          if (isPresent) filterRound.push(row);
        });

      setRows(filterRound);
    }
  }, [pressedNumber]);

  useEffect(() => {
    getHeaders(specialTableData);
  }, [specialTableData]);

  useEffect(() => {
    getHeaders(rows);
  }, [rows]);

  useEffect(() => {
    if (createSpecialRoundSuccess) {
      const text = MsgTranslator(createSpecialRoundSuccess, language);
      notify(text, "success");
      dispatch({ type: "ROUND_SUCCESS_MSG_CLEAR" });
      dispatch(getSpecialRoundData({ comp_id }));
      setParentChecked(false);
      const specialRoundComp = true;
      localStorage.setItem("specialRound", true);
      pageChange(comp_id, `/start-competition-round/${comp_id}`);
      navigate(`/start-competition-round/${comp_id}`);
      setPlayerId([]);
    }
    if (createSpecialRoundError) {
      const text = MsgTranslator(createSpecialRoundError, language);
      notify(text, "error");
      dispatch({ type: "ROUND_SUCCESS_MSG_CLEAR" });
      setParentChecked(false);
      setPlayerId([]);
    }
  }, [createSpecialRoundError, createSpecialRoundSuccess, dispatch, comp_id]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />
        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                <PageTitle
                  title={"<em>Start</em> Competition"}
                  lang={language}
                />
              </h3>
            </div>

            <div className="start-section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <Paper
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <div className="sp-search-box-container">
                        <div className="sp-search-box">
                          <input
                            id="search-by-name"
                            type="text"
                            onChange={handleChangeSearch}
                            placeholder={MsgTranslator(
                              "Search by name",
                              language
                            )}
                            className="js-virtual-keyboard form-control rounded"
                            style={{ height: "3.3rem" }}
                          />
                        </div>
                        <div>
                          <input
                            id="search-by-round"
                            className="js-virtual-keyboard form-control rounded"
                            type="number"
                            placeholder={MsgTranslator(
                              "Round number",
                              language
                            )}
                            onChange={handleSearchRoundNumber}
                            onKeyDown={handlePreshButton}
                            style={{ height: "3.3rem" }}
                          />
                        </div>
                        <TranslatedComponent>
                          <div className="sp-search-box">
                            <select
                              id="status-select"
                              name="status"
                              onChange={handleStatusWiseSearch}
                              className="js-virtual-keyboard form-control rounded"
                              style={{ width: "15rem", height: "3.3rem" }}
                            >
                              <option value={"all"}>Filter by status</option>
                              <option value={"qualified"}>Qualified</option>
                              <option value={"redeemed"}>Redeemed</option>
                              <option value={"disqualified"}>
                                Disqualified
                              </option>
                              <option value={"eliminated"}>Eliminated</option>
                            </select>
                            {/* </div> */}
                          </div>
                        </TranslatedComponent>
                      </div>
                      {/* ================[filter by round]=============== */}
                      <TableContainer sx={{ maxHeight: 440, marginTop: 5 }}>
                        <Table stickyHeader sx={{ p: 1 }}>
                          <TableHead>
                            <TableRow>
                              {columns.map((column) => {
                                if (column.id === "checkbox") {
                                  return (
                                    <StyledHeaderTableCell key={column.id}>
                                      <Checkbox
                                        checked={parentChecked}
                                        onChange={handleParentCheckboxChange}
                                      />
                                    </StyledHeaderTableCell>
                                  );
                                } else if (column.id === "id") {
                                  return (
                                    <StyledNameHeaderTableCell key={column.id}>
                                      #
                                    </StyledNameHeaderTableCell>
                                  );
                                } else if (column.id === "name") {
                                  return (
                                    <StyledNameHeaderTableCell key={column.id}>
                                      {column.label}
                                    </StyledNameHeaderTableCell>
                                  );
                                } else {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      style={{ minWidth: column.minWidth }}
                                    >
                                      {column.label}
                                    </TableCell>
                                  );
                                }
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => {
                              const isChecked = childCheckedState.find(
                                (item) => item.id === row._id
                              )?.checked;
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row._id}
                                >
                                  <StyledTableCell>
                                    <Checkbox
                                      checked={isChecked || false}
                                      onChange={(e) =>
                                        handleChildCheckboxChange(e, row)
                                      }
                                    />
                                  </StyledTableCell>
                                  <StyledNameTableCell>
                                    <PlayerSerialNumber
                                      compId={comp_id}
                                      playerId={row._id}
                                    />
                                  </StyledNameTableCell>
                                  <StyledNameTableCell>
                                    {row.name}
                                  </StyledNameTableCell>
                                  {row.rounds.map((rounds, index) => (
                                    <TableCell
                                      key={index}
                                      style={{
                                        backgroundColor:
                                          rounds.status === "Qualified" ||
                                            rounds.status === "Redeemed"
                                            ? "#90EE90"
                                            : "#ff3333",
                                      }}
                                    >
                                      {rounds.status ? rounds.status : "-"}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="main-container-section button-dropdown-right"
            style={{ padding: "15px 13px 0px 11px" }}
          >
            <TranslatedComponent>
              <div className="back-name" onClick={() => navigate(-1)}>
                <button class="back-g">Back</button>
              </div>
              <div>
                <FormControl sx={{ width: 250 }}>
                  <InputLabel id="demo-simple-select-label">
                    Ranking Mode
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Age"
                    onChange={handleChange}
                  >
                    <MenuItem value="First round Redeem mode">
                      First round Redeem mode
                    </MenuItem>
                    <MenuItem value="Accidental Champion">
                      Accidental Champion
                    </MenuItem>
                    <MenuItem value="Champion  mode">Champion mode</MenuItem>
                    <MenuItem value="Words of the championship">
                      Words of the championship
                    </MenuItem>
                    <MenuItem value="Ranking mode">Ranking mode</MenuItem>
                  </Select>
                </FormControl>
                <button onClick={startnewRound} className="start-new-round">
                  Start New Round
                </button>
              </div>
            </TranslatedComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialRound;
