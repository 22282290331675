export const ROUND_GET_SUCCESS = 'ROUND_GET_SUCCESS';
export const ROUND_GET_ERROR = 'ROUND_GET_ERROR';

export const ROUND_CURRENT_GET_SUCCESS = 'ROUND_CURRENT_GET_SUCCESS';
export const ROUND_CURRENT_GET_ERROR = 'ROUND_CURRENT_GET_ERROR';

export const ROUND_UNUSED_WORD_GET_SUCCESS = 'ROUND_UNUSED_WORD_GET_SUCCESS';
export const ROUND_UNUSED_WORD_GET_ERROR = 'ROUND_UNUSED_WORD_GET_ERROR';

export const ROUND_SUMMARY_GET_SUCCESS = 'ROUND_SUMMARY_GET_SUCCESS';
export const ROUND_SUMMARY_GET_ERROR = 'ROUND_SUMMARY_GET_ERROR';

export const ROUND_ADD_SUCCESS = 'ROUND_ADD_SUCCESS';
export const ROUND_ADD_ERROR = 'ROUND_ADD_ERROR';

export const ROUND_UPDATE_SUCCESS = 'ROUND_UPDATE_SUCCESS';
export const ROUND_UPDATE_ERROR = 'ROUND_UPDATE_ERROR';

// export const ROUND_REMOVE_SUCCESS = 'ROUND_REMOVE_SUCCESS';
// export const ROUND_REMOVE_ERROR = 'ROUND_REMOVE_ERROR';

export const ROUND_DELETE_SUCCESS = 'ROUND_DELETE_SUCCESS';
export const ROUND_DELETE_ERROR = 'ROUND_DELETE_ERROR';

export const ROUND_SUCCESS_MSG_CLEAR = 'ROUND_SUCCESS_MSG_CLEAR';
export const ROUND_ERROR_MSG_CLEAR = 'ROUND_ERROR_MSG_CLEAR';

export const ROUND_ACTION_LOADER_START = 'ROUND_ACTION_LOADER_START';

/*                BY Naeem                */
export const ROUND_DLD_CSV_SUCCESS = "ROUND_DLD_CSV_SUCCESS" 
export const ROUND_DLD_CSV_ERROR = "ROUND_DLD_CSV_ERROR"

export const ROUND_OVR_ALL_COM_STATICS_SUCC = 'ROUND_OVR_ALL_COM_STATICS_SUCC'
export const ROUND_OVR_ALL_COM_STATICS_ERR = 'ROUND_OVR_ALL_COM_STATICS_ERR'

export const LIST_OF_WORDS_USED_SUCC = 'LIST_OF_WORDS_USED_SUCC'
export const LIST_OF_WORDS_USED_ERR = 'LIST_OF_WORDS_USED_ERR'

export const LIST_OF_CANDIDATE_PRST_SUCC = 'LIST_OF_CANDIDATE_PRST_SUCC'
export const LIST_OF_CANDIDATE_PRST_ERR = 'LIST_OF_CANDIDATE_PRST_ERR'


export const GET_SPECIAL_TABLE_DATA_SUCCESS_MESSAGE = 'GET_SPECIAL_TABLE_DATA_SUCCESS_MESSAGE'
export const GET_SPECIAL_TABLE_DATA_ERROR_MESSAGE = 'GET_SPECIAL_TABLE_DATA_ERROR_MESSAGE'

export const CREATE_NEW_ROUND_SUCCESS_MESSAGE = 'CREATE_NEW_ROUND_SUCCESS_MESSAGE'
export const CREATE_NEW_ROUND_ERROR_MESSAGE = 'CREATE_NEW_ROUND_ERROR_MESSAGE'


export const ROUND_DLD_CSV_CLR_MSG = "ROUND_DLD_CSV_CLR_MSG"