export const convertSecondsToTime = (seconds) => {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid input";
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedTime = ` ${minutes}m ${remainingSeconds}s`;

    return formattedTime;
  };