import { configureStore } from "@reduxjs/toolkit";
import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import { AuthReducer } from "./Reducer/authReducer";
import { UserReducer } from "./Reducer/userReducer";
import { TournamentReducer } from "./Reducer/tournamentReducer";
import { OneShotReducer } from "./Reducer/oneShotReducer";
import { ParticipantReducer } from "./Reducer/participantReducer";
import { WordReducer } from "./Reducer/wordReducer";
import { JuryReducer } from "./Reducer/juryReducer";
import { OrganizationDashboardReducer } from "./Reducer/OrgDashBoardReducer";
import { CompetitionReducer } from "./Reducer/competitionReducer";
import { RoundReducer } from "./Reducer/roundReducer";
import { CustomStateReducer } from "./Reducer/CustomStateReducer";

const rootReducer = combineReducers({
  Auth: AuthReducer,
  User: UserReducer,
  OneShot: OneShotReducer,
  Tournament: TournamentReducer,
  Participant: ParticipantReducer,
  Word: WordReducer,
  Jury: JuryReducer,
  OrgDashboard: OrganizationDashboardReducer,
  CompStart: CompetitionReducer,
  Round: RoundReducer,
  CustomState: CustomStateReducer,
});

const store = configureStore({ reducer: rootReducer });
export default store;
