import React from "react";
import { MagnifyingGlass } from "react-loader-spinner";

const   ComponentLoader = () => {
  return (
    <div className="loder-option">
      <MagnifyingGlass />
    </div>
  );
};

export default ComponentLoader;
