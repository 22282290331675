import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../component/Header/Header";

import Select from "react-select";
import countryList from "react-select-country-list";

import { useSelector, useDispatch } from "react-redux";
import { Registration } from "../../../Store/Action/authAction";
import {
  AUTH_SUCCESS_CLEAR,
  AUTH_ERROR_CLEAR,
} from "../../../Store/Types/authTypes";
import Swal from "sweetalert2";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const Register = () => {
  const navigate = useNavigate();
  const options = useMemo(() => countryList().getData(), []);

  const dispatch = useDispatch();
  const {
    authLoading,
    authenticate,
    authSuccessMessage,
    authErrorMessage,
  } = useSelector((state) => state.Auth);
  // console.log(15, authLoading, authenticate, authSuccessMessage, authErrorMessage);

  let user = {
    role: "Org",
    firstName: "",
    lastName: "",
    organizationType: "Teacher",
    organizationName: "",
    address: "",
    phone: "",
    password: "",
    country: "",
    phone: "",
    email: "",

    organizationType: "",
    organizationName: "",
    address: "",
    locality: "",
    metaDatas: [],
  };

  const [state, setState] = useState(user);
  const [condition, setCondition] = useState({
    terms: false,
    privacy: false,
  });
  const [showEmailSent, setShowEmailSent] = useState(false);

  // Function to update metaData field
  const updateMetaData = (key, value) => {
    const existingIndex = state.metaDatas.findIndex(
      (item) => item.metaKey === key
    );
    const updatedMetaData = [...state.metaDatas];

    if (existingIndex !== -1) {
      updatedMetaData[existingIndex].metaValue = value;
    } else {
      updatedMetaData.push({
        metaKey: key,
        metaValue: value,
      });
    }

    return updatedMetaData;
  };

  /* Change in FORM  */
  const handleChange = (name, value) => {
    if (name === "country") {
      setState({ ...state, [name]: value });
    } else {
      // Update metaData if the field is in the list
      if (
        [
          "organizationType",
          "organizationName",
          "address",
          "locality",
        ].includes(name)
      ) {
        const updatedMetaData = updateMetaData(name, value);
        setState({ ...state, [name]: value, metaDatas: updatedMetaData });
      } else {
        setState({ ...state, [name]: value });
      }
    }
  };

  const handleRegistration = (e) => {
    e.preventDefault();
    // console.log("Here is the registration");
    if (condition.privacy && condition.terms) {
      dispatch(Registration(state));
    } else {
      notify(
        // "You need to agree with out terms and conditions to proceed",
        "Vous devez accepter nos termes et conditions pour continuer",
        "error"
      );
    }
  };

  useEffect(() => {
    if (authSuccessMessage) {
      setShowEmailSent(true);
      // Swal.fire(
      //   {
      //     title: "A verification email is already sent to your email.Please verify your email to proceed.",
      //     showClass: {
      //       popup: "animate__animated animate__fadeInDown",
      //     },
      //     hideClass: {
      //       popup: "animate__animated animate__fadeOutUp",
      //     },
      //   },
      //   2000
      // )
    }
    if (authErrorMessage) {
      Swal.fire({
        icon: "error",
        title: "Oups...",
        text: MsgTranslator(authErrorMessage) //authErrorMessage,
      }).then(() => {
        dispatch({ type: AUTH_ERROR_CLEAR });
      });
    }
  }, [authSuccessMessage, authErrorMessage]);

  return (
    <>
      {!showEmailSent ? (
        <div className="wrapper">
          <div id="content-two">
            {/* <button type="button" id="sidebarCollapse" className="btn btn-info">
              <i className="fas fa-align-left" />
            </button> */}

            <div className="main-container-section">
              <Header />

              <div className="upcoming-title">
                <h3>
                  {/* <span>Register</span> */}
                  <span>Registre</span>
                </h3>
              </div>

              <form method="POST" onSubmit={(e) => handleRegistration(e)}>
                <div className="login-section">
                  <h3>Registre</h3>
                  {/* <h3> Register</h3> */}
                  <div className="text-from-one">
                    <div className="row">
                      {/* firstName */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* First Name : */}
                            Prénom :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="First Name"
                              placeholder="Prénom"
                              name="firstName"
                              value={state.firstName}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                      {/* lastName */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* Last Name : */}
                            Nom de famille :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Last Name"
                              placeholder="Nom de famille"
                              name="lastName"
                              value={state.lastName}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                      {/*country */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* Country : */}
                            Pays :
                          </label>

                          <div className="col-sm-7">
                            <Select
                              options={options}
                              value={state.country}
                              onChange={(e) => handleChange("country", e)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* Organization Type */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* Organization Type : */}
                            Type d'organisation :
                          </label>
                          <div className="col-sm-7">
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              name="organizationType"
                              value={state.organizationType}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            >
                              {/* <option value="Teacher">Teacher</option>
                              <option value="School">School</option>
                              <option value="NGO">NGO</option>
                              <option value="Agency">Agency</option> */}
                              <option value="Teacher">Enseignant</option>
                              <option value="School">École</option>
                              <option value="NGO">ONG</option>
                              <option value="Agency">Agence</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* Organization Name  */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* Organization Name : */}
                            Nom de l'organisation :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Organization Name"
                              placeholder="Nom de l'organisation"
                              name="organizationName"
                              value={state.organizationName}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                      {/* Address */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* Address : */}
                            Adresse :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Address"
                              placeholder="Adresse"
                              name="address"
                              value={state.address}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* phone */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* Phone Number: */}
                            Numéro de téléphone :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Phone Number"
                              placeholder="Numéro de téléphone"
                              name="phone"
                              value={state.phone}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                      {/* locality */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* Locality : */}
                            Localité :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Locality"
                              placeholder="Localité"
                              name="locality"
                              value={state.locality}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                      {/* Email */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* Email : */}
                            E-mail :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              // placeholder="Email"
                              placeholder="E-mail"
                              name="email"
                              value={state.email}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* password */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {/* Password : */}
                            Mot de passe :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="password"
                              className="form-control"
                              // placeholder="Password"
                              placeholder="Mot de passe"
                              name="password"
                              value={state.password}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                      {/* T and C */}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="check-point">
                      <div className="form-group row">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="termsCheckbox"
                          >
                            {/* I agree to the Terms of Use */}
                            J'accepte les conditions d'utilisation
                          </label>

                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="termsCheckbox"
                            checked={condition.terms}
                            onChange={() =>
                              setCondition({
                                ...condition,
                                terms: !condition.terms,
                              })
                            }
                            required
                          />
                        </div>

                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="privacyCheckbox"
                          >
                            {/* I agree to the Privacy Policy */}
                            J'accepte la politique de confidentialité
                          </label>

                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="privacyCheckbox"
                            checked={condition.privacy}
                            onChange={() =>
                              setCondition({
                                ...condition,
                                privacy: !condition.privacy,
                              })
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-outline-primary"
                    // onClick={() => handleRegistration()}
                  >
                    {/* Register */}
                    Registre
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <div className="login-section">
            <p>
              E-mail envoyé ! Vérifiez votre boîte de réception pour obtenir des
              instructions supplémentaires.
            </p>
            <p>
              Si vous ne recevez pas l'e-mail dans quelques minutes, veuillez
              vérifier votre dossier spam.
            </p>
            <p>Besoin d'aide ? Contactez notre équipe d'assistance.</p>
            {/* <p>Email sent! Check your inbox for further instructions.</p>
            <p>
              If you don't receive the email within a few minutes, please check
              your spam folder.
            </p>
            <p>Need help? Contact our support team.</p> */}
            {/* <button onClick={() => navigate('/')}>Go to Login</button> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
