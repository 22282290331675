/* import tytpes */
import {
  WORD_ALL_GET_SUCCESS,
  WORD_ALL_GET_ERROR,
  WORD_ORG_GET_SUCCESS,
  WORD_ORG_GET_ERROR,
  WORD_COMP_GET_SUCCESS,
  WORD_COMP_GET_ERROR,
  WORD_DETAILS_GET_SUCCESS,
  WORD_DETAILS_GET_ERROR,
  WORD_ADD_SUCCESS,
  WORD_ADD_ERROR,
  WORD_UPDATE_SUCCESS,
  WORD_UPDATE_ERROR,
  WORD_DELETE_SUCCESS,
  WORD_DELETE_ERROR,
  WORD_SUCCESS_CLEAR,
  WORD_ERROR_CLEAR,
  WORD_ACTION_LOADER_START,
} from "../Types/wordTypes";

import { RESET_REDUX } from "../Types/customTypes";

const initialState = {
  Word_Loading: true,
  Words_All_Data: [],
  Words_Org_Data: [],
  Words_Comp_Data: [],
  Words_Details: {},
  Word_Action_Loader: false,
  Word_SuccessMessage: null,
  Word_ErrorMessage: null,
  Word_Generate_Info: null,
  Word_Get_Error_Message: null,
};

export const WordReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /* Get */
  if (type === WORD_ORG_GET_SUCCESS) {
    return {
      ...state,
      // Word_SuccessMessage: payload.successMessage,
      Words_Org_Data: payload.data,
      Word_ErrorMessage: null,
      Word_Loading: false,
      Word_Action_Loader: false,
    };
  }
  if (type === WORD_ORG_GET_ERROR) {
    return {
      ...state,
      Word_Get_Error_Message: payload.errorMessage,
      Words_Org_Data: [],
      Word_SuccessMessage: null,
      Word_Loading: false,
      Word_Action_Loader: false,
    };
  }

  /* Get */
  if (type === WORD_COMP_GET_SUCCESS) {
    return {
      ...state,
      Word_Generate_Info : payload.successMessage,
      Word_SuccessMessage: payload.successMessage,// newly add
      Words_Comp_Data: payload.data,
      Word_ErrorMessage: null,
      Word_Loading: false,
      Word_Action_Loader: false,
    };
  }
  if (type === WORD_COMP_GET_ERROR) {
    return {
      ...state,
      Word_Get_Error_Message: payload.errorMessage,
      Words_Comp_Data: [],
      Word_SuccessMessage: null,
      Word_Loading: false,
      Word_Action_Loader: false,
    };
  }

  /* Get */
  if (type === WORD_ALL_GET_SUCCESS) {
    return {
      ...state,
      // Word_SuccessMessage: payload.successMessage,
      Words_All_Data: payload.data,
      Word_ErrorMessage: null,
      Word_Loading: false,
      Word_Action_Loader: false,
    };
  }
  if (type === WORD_ALL_GET_ERROR) {
    return {
      ...state,
      Word_Get_Error_Message: payload.errorMessage,
      Words_All_Data: [],
      Word_SuccessMessage: null,
      Word_Loading: false,
      Word_Action_Loader: false,
    };
  }

  /* Get Participant details*/
  if (type === WORD_DETAILS_GET_SUCCESS) {
    return {
      ...state,
      // Word_SuccessMessage: payload.successMessage,
      Words_Details: payload.data,
      Word_ErrorMessage: null,
      Word_Loading: false,
      Word_Action_Loader: false,
    };
  }
  if (type === WORD_DETAILS_GET_ERROR) {
    return {
      ...state,
      Word_Get_Error_Message: payload.errorMessage,
      Words_Details: {},
      Word_SuccessMessage: null,
      Word_Loading: false,
      Word_Action_Loader: false,
    };
  }

  /* Create */
  if (type === WORD_ADD_SUCCESS) {
    return {
      ...state,
      Word_SuccessMessage: payload.successMessage,
      Word_ErrorMessage: null,
      Word_Action_Loader: false,
    };
  }
  if (type === WORD_ADD_ERROR) {
    return {
      ...state,
      Word_SuccessMessage: null,
      Word_ErrorMessage: payload.errorMessage,
      Word_Action_Loader: false,
    };
  }

  /* Update*/
  if (type === WORD_UPDATE_SUCCESS) {
    return {
      ...state,
      Word_SuccessMessage: payload.successMessage,
      Word_ErrorMessage: null,
      Word_Action_Loader: false,
    };
  }
  if (type === WORD_UPDATE_ERROR) {
    return {
      ...state,
      Word_SuccessMessage: null,
      Word_ErrorMessage: payload.errorMessage,
      Word_Action_Loader: false,
    };
  }

  /* Delete*/
  if (type === WORD_DELETE_SUCCESS) {
    return {
      ...state,
      Word_SuccessMessage: payload.successMessage,
      Word_ErrorMessage: null,
      Word_Action_Loader: false,
    };
  }
  if (type === WORD_DELETE_ERROR) {
    return {
      ...state,
      Word_SuccessMessage: null,
      Word_ErrorMessage: payload.errorMessage,
      Word_Action_Loader: false,
    };
  }

  /* Loader */
  if (type === WORD_ACTION_LOADER_START) {
    return {
      ...state,
      Word_Action_Loader: true,
    };
  }

  /* Msg Clear */
  if (type === WORD_SUCCESS_CLEAR) {
    return {
      ...state,
      Word_Generate_Info:null,
      Word_SuccessMessage: null,
      Word_ErrorMessage: null,
      Word_Action_Loader: false,
    };
  }
  if (type === WORD_ERROR_CLEAR) {
    return {
      ...state,
      Word_SuccessMessage: null,
      Word_ErrorMessage: null,
      Word_Action_Loader: false,
      Word_Get_Error_Message: null,
    };
  }

  /* Reset State */
  if (type === RESET_REDUX) {
    // console.log("here to clear word state");
    return {
      ...state,
      Word_Loading: true,
      Words_All_Data: [],
      Words_Org_Data: [],
      Words_Comp_Data: [],
      Words_Details: {},
      Word_Action_Loader: false,
      Word_SuccessMessage: null,
      Word_ErrorMessage: null,
      Word_Get_Error_Message: null,
    };
  }
  // console.log("244 word state", state);
  return state;
};
