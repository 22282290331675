import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
/*------------------------------------------------------------------- */
import { Box, InputLabel, MenuItem, FormControl, Select } from "@mui/material";
/*--------------------------------------------------------------------- */
import { UpdateOneShot } from "../../../Store/Action/oneShotAction";
import {
  ONESHOT_SUCCESS_CLEAR,
  ONESHOT_ERROR_CLEAR,
} from "../../../Store/Types/oneShotTypes";
/*----------------------------------------------------------- */
import {
  GetCurrentRound,
  GetRoundSummarySelection,
  QualifyingStatusUpdate_Round,
} from "../../../Store/Action/roundAction";
import { GetJuryCapability } from "../../../Store/Action/juryAction";
import {
  ROUND_SUCCESS_MSG_CLEAR,
  ROUND_ERROR_MSG_CLEAR,
} from "../../../Store/Types/roundTypes";
/*------------------------------------------------------- */
import Swal from "sweetalert2";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";

import {
  StartSocketConnection,
  JoinRoom,
  pageChange,
  pageQuery,
  // BackToOrigin,
  // FinishedQuery,
} from "../../../HelperFunctions/Socket/socket.js";
import TranslatedComponent from "../../../translator/TranslatedComponent.jsx";
import PageTitle from "../../../component/PageTitle/PageTitle.jsx";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator.js";

const SummarySelection = () => {
  const { round_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);

  const { Jury_Loading, Jury_Action_Loader, Jury_Capabilities } = useSelector(
    (state) => state.Jury
  );

  const {
    current_round_Details,
    round_Summary_Data,
    round_SuccessMessage,
    round_ErrorMessage,
  } = useSelector((state) => state.Round);

  const { OneShot_SuccessMessage, OneShot_ErrorMessage } = useSelector(
    (state) => state.OneShot
  );

  const [language, setLanguage] = useState("fr");

  /* page state */
  const [pageState, setPageState] = useState(false);
  /* btn name */
  const [btnName, setBtnName] = useState("Start a New Round");
  /* state for all player  */
  const [participants, setParticipants] = useState([]);
  /* compOver */
  const [compOver, setCompOver] = useState(false);
  /* Checking if comp is over or not */
  const isCompOver = () => {
    const filteredParticipants = participants.filter((sp) =>
      ["Qualified", "Redeemed"].includes(sp.qualifyingStatus)
    );
    if (
      filteredParticipants.length ===
      current_round_Details.compId.qualifiedParticipants
    ) {
      setCompOver(true);
      return true;
    } else {
      setCompOver(false);
      return false;
    }
  };

  // console.log(participants)

  const handleStatusChange = (playerId, qualifyingStatus) => {
    setParticipants((prevParticipants) =>
      prevParticipants.map((player) =>
        player.playerId === playerId
          ? { ...player, qualifyingStatus: qualifyingStatus }
          : player
      )
    );
    // console.log({
    //   roundId: round_id,
    //   playerId: playerId,
    //   qualifyingStatus: qualifyingStatus,
    // });
    dispatch(
      QualifyingStatusUpdate_Round({
        roundId: round_id,
        playerId: playerId,
        qualifyingStatus: qualifyingStatus,
      })
    );
  };

  /* btn name */
  useEffect(() => {
    if (
      Object.keys(current_round_Details).length > 0 &&
      current_round_Details.participants &&
      current_round_Details.participants.length >= 0
      // current_round_Details.compId &&
      // current_round_Details.compId.qualifiedParticipants.length >= 0
    ) {
      if (currentUserInfo.role === "Jury") {
        dispatch(
          GetJuryCapability({
            juryId: currentUserInfo._id,
            compId: current_round_Details.compId._id,
          })
        );
      }

      if (
        current_round_Details.compId._id !== undefined &&
        current_round_Details.compId._id !== null
      ) {
        JoinRoom(current_round_Details.compId._id, currentUserInfo._id);
      }

      // console.log("here--------------");
      let compOver = isCompOver();
      // console.log("respone ------- is comp over ?", compOver);
      if (compOver) {
        const buttonText = MsgTranslator("Finish Competition", language);
        setBtnName(buttonText);
      } else {
        const buttonText = MsgTranslator("Start Competation", language);
        setBtnName(buttonText);
      }
    }
  }, [current_round_Details, round_Summary_Data, participants]);

  /* get round details wrt round_id */
  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(GetCurrentRound({ roundId: round_id }));
    dispatch(GetRoundSummarySelection({ roundId: round_id }));
    StartSocketConnection();
  }, [round_id]);

  useEffect(() => {
    if (
      current_round_Details &&
      Object.keys(current_round_Details) > 0 &&
      current_round_Details.compId
    ) {
      pageChange(
        current_round_Details.compId._id,
        `/summary-selection/${round_id}`
      );
    }
  }, [current_round_Details, round_id]);

  pageQuery(({ competitionId, page }) => {
    setPageState(page);
  });

  useEffect(() => {
    if (pageState) {
      navigate(pageState);
    }
  }, [pageState]);

  /* state for all player  */
  useEffect(() => {
    if (
      round_Summary_Data &&
      round_Summary_Data.participants &&
      round_Summary_Data.participants.length > 0
    ) {
      setParticipants(round_Summary_Data.participants);
    }
  }, [round_Summary_Data]);

  const handleRoute = () => {
    if (compOver) {
      /* UPDATE ISCOMPLETED TO TRUE */
      dispatch(
        UpdateOneShot({
          oneshotId: current_round_Details?.compId?._id,
          orgId: current_round_Details?.compId?.orgId,
          isCompleted: true,
        })
      );
    } else {
      /**************** if all eleminated or not ******************/
      // console.log(participants);

      const eliminatedParticipants = participants.filter(
        (participant) => participant.qualifyingStatus === "Eliminated"
      );

      // console.log(eliminatedParticipants);

      if (eliminatedParticipants.length === participants.length) {
        const updatedParticipants = participants.map((participant) => {
          if (participant.qualifyingStatus === "Eliminated") {
            return { ...participant, qualifyingStatus: "Redeemed" };
          }
          return participant;
        });

        // Update state with the updated participants array
        setParticipants(updatedParticipants);

        participants.forEach((participant) => {
          handleStatusChange(participant.playerId, "Redeemed");
        });
      }
      /* if not --- create new */
      navigate(`/start-competition-round/${current_round_Details?.compId._id}`);
      pageChange(
        current_round_Details?.compId?._id,
        `/start-competition-round/${current_round_Details?.compId._id}`
      );
    }
  };

  /* message */
  useEffect(() => {
    if (OneShot_SuccessMessage) {
      const text = MsgTranslator(OneShot_SuccessMessage, language);
      notify(text, "success");
      dispatch({ type: ONESHOT_SUCCESS_CLEAR });

      navigate(`/competition-results/${current_round_Details?.compId?._id}`);
      pageChange(
        current_round_Details?.compId?._id,
        `/competition-results/${current_round_Details?.compId?._id}`
      );
    }
    if (OneShot_ErrorMessage) {
      const text = MsgTranslator(OneShot_ErrorMessage, language);
      notify(text, "error");
      dispatch({ type: ONESHOT_ERROR_CLEAR });
    }
    if (round_SuccessMessage) {
      dispatch(GetRoundSummarySelection({ roundId: round_id }));
      dispatch(GetCurrentRound({ roundId: round_id }));
      // isCompOver();
      dispatch({ type: ROUND_SUCCESS_MSG_CLEAR });
    }
    if (round_ErrorMessage) {
      const text = MsgTranslator(round_ErrorMessage, language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: ROUND_ERROR_MSG_CLEAR });
      });
    }
  }, [
    OneShot_SuccessMessage,
    OneShot_ErrorMessage,
    round_ErrorMessage,
    round_SuccessMessage,
  ]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);
  // console.log(language)

  // console.log(256,Jury_Capabilities);
  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                <PageTitle
                  title={"<em>Summary</em> of Selection"}
                  lang={language}
                />
              </h3>
            </div>
            <div className="start-section">
              <TranslatedComponent>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-6 no-padding">
                      <div className="round-thup">
                        <div className="round-point-op">
                          <h3>Round Number</h3>
                          <p>{round_Summary_Data?.name || ""}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-6 no-padding">
                      <div className="round-thup-two">
                        <div className="round-point-op">
                          <h3>No of Participants</h3>
                          <p>{round_Summary_Data?.participants.length || ""}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <table className="ws-table-all">
                      <tbody>
                        <tr>
                          <td className="column1">
                            Qualified :{" "}
                            <span>
                              {round_Summary_Data?.statuses?.Qualified || 0}
                            </span>
                          </td>
                          <td className="column2">
                            Eliminated :{" "}
                            <span>
                              {round_Summary_Data?.statuses?.Eliminated || 0}
                            </span>
                          </td>
                          <td className="column3">
                            Redeemed :{" "}
                            <span>
                              {round_Summary_Data?.statuses?.Redeemed || 0}
                            </span>
                          </td>
                          <td className="column4">
                            Disqualified :{" "}
                            <span>
                              {round_Summary_Data?.statuses?.Disqualified || 0}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="candit-sectied-from">
                      <div className="table100 ver1 m-b-110">
                        <div className="table100-body js-pscroll">
                          <table className="gfg">
                            <thead>
                              <tr className="row100 head">
                                <th className="cell100 column1">Position</th>
                                <th className="cell100 column2">Name</th>
                                <th className="cell100 column2">Given Word</th>
                                <th className="cell100 column2">Spelt Word</th>
                                <th className="cell100 column3">Score</th>
                                <th className="cell100 column4">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {round_Summary_Data &&
                              round_Summary_Data.participants &&
                              round_Summary_Data.participants.length > 0 &&
                              participants &&
                              participants.length > 0 ? (
                                participants.map((sp, index) => {
                                  // console.log(sp);
                                  return (
                                    <tr
                                      className="row100 body"
                                      key={sp.playerId}
                                    >
                                      <td className="cell100 column1">
                                        <span className="number-uy">
                                          {index + 1}
                                        </span>
                                      </td>
                                      <td
                                        className="cell100 column2"
                                        onClick={() =>
                                          navigate(
                                            `/candidate-response/${round_id}/${sp.playerId}/${sp.words[0].wordId}`
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <span className="pp-picture">
                                          <img
                                            src={
                                              sp.img
                                                ? sp.img
                                                : window.location.origin +
                                                  "/Assets/images/profile-2.png"
                                            }
                                            alt="profile"
                                          />
                                        </span>
                                        {sp.firstName ||
                                          "" + " " + sp.lastName ||
                                          ""}
                                      </td>
                                      <td className="cell100 column3">
                                        {sp.words
                                          .map((obj) => obj.word)
                                          .join(", ")}
                                      </td>
                                      <td className="cell100 column3">
                                        {sp.words
                                          .map((obj) => obj.answer)
                                          .join(", ")}
                                      </td>
                                      <td className="cell100 column3">
                                        {sp.score}
                                      </td>
                                      <td className="cell100 column4 dropdown2">
                                        <Box sx={{ minWidth: 120 }}>
                                          <FormControl fullWidth>
                                            <Select
                                              labelId="demo-simple-select-label"
                                              id="demo-simple-select"
                                              value={sp?.qualifyingStatus || ""}
                                              label="status"
                                              onChange={(e) =>
                                                handleStatusChange(
                                                  sp.playerId,
                                                  e.target.value
                                                )
                                              }
                                              readOnly={
                                                currentUserInfo.role !==
                                                  "Org" &&
                                                currentUserInfo.role ===
                                                  "Jury" &&
                                                !Jury_Capabilities.includes(
                                                  "Start New Round"
                                                )
                                              }
                                            >
                                              <MenuItem value={"Qualified"}>
                                                Qualified
                                              </MenuItem>
                                              <MenuItem value={"Redeemed"}>
                                                Redeemed
                                              </MenuItem>
                                              <MenuItem value={"Disqualified"}>
                                                Disqualified
                                              </MenuItem>
                                              <MenuItem value={"Eliminated"}>
                                                Eliminated
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </Box>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <>
                                  <ComponentLoader />
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {(currentUserInfo.role === "Org" ||
                    (currentUserInfo.role === "Jury" &&
                      Jury_Capabilities.includes("Start New Round"))) && (
                    <button className="save-0" onClick={() => handleRoute()}>
                      {btnName}
                    </button>
                  )}

                  <button
                    style={{ marginRight: 5 }}
                    className="save-0"
                    onClick={() => {
                      pageChange(
                        current_round_Details.compId._id,
                        `/start-special-competition-round/${current_round_Details?.compId?._id}`
                      );
                      navigate(
                        `/start-special-competition-round/${current_round_Details?.compId?._id}`
                      );
                    }}
                  >
                    Special Round
                  </button>
                </div>
              </TranslatedComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SummarySelection;
