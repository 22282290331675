import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../component/Sideber/SidebarLeft";
import SidebarRightTwo from "../../component/Sideber/SidebarRightTwo";
import Header from "../../component/Header/Header";
import PlayerSerialNumber from "../../component/PlayerSerialNumber/PlayerSerialNumber";
import { useDispatch, useSelector } from "react-redux";

import { GetCompResult } from "../../Store/Action/competitionAction";

import {
  StartSocketConnection,
  JoinRoom,
  pageChange,
  pageQuery,
  leaveAllRoom,
} from "../../HelperFunctions/Socket/socket";

import ComponentLoader from "../../component/ComponentLoader/ComponentLoader";
import CompetitionResultDetails from "../../component/CompetitionResultDetails/CompetitionResultDetails";
import TranslatedComponent from "../../translator/TranslatedComponent";
import PageTitle from "../../component/PageTitle/PageTitle";
import { Language } from "@mui/icons-material";

const CompetitionResults = () => {
  const navigate = useNavigate();
  const { currentUserInfo , currentUser} = useSelector((state) => state.Auth);
  const { comp_id } = useParams();
  const dispatch = useDispatch();

  /* showDetail */
  const [clickedPlayerId, setClickedPlayerId] = useState([]);

  /* page state */
  // const [pageState, setPageState] = useState(false);
  /* winner heading */
  const [heading, setHeading] = useState("Winner");
  /* winner list */
  const [list, setList] = useState([]);
  const [language , setLanguage] = useState('fr');

  const handlePlayerResultDetailsClicked = (playerId) => {
    if (clickedPlayerId.includes(playerId)) {
      setClickedPlayerId((prev) => prev.filter((id) => id !== playerId));
    } else {
      setClickedPlayerId((prev) => [...prev, playerId]);
    }
  };

  const { comp_start_Loading, comp_results } = useSelector(
    (state) => state.CompStart
  );
  useEffect(() => {
    dispatch(GetCompResult({ orgId: currentUserInfo._id, compId: comp_id }));
    StartSocketConnection();
  }, [comp_id]);

  // pageQuery(({ competitionId, page }) => {
  //   setPageState(page);
  // });

  // useEffect(() => {
  //   if (pageState) {
  //     navigate(pageState);
  //   }
  // }, [pageState]);

  useEffect(() => {
    if (Object.keys(comp_results).length > 0 && comp_results?.results) {
      // JoinRoom(comp_id, currentUserInfo._id);

      pageChange(comp_id, `/competition-results/${comp_id}`);
      const winnerList = comp_results?.results.slice(
        0,
        comp_results.qualifiedParticipants
      );
      setList(winnerList);
      if (winnerList.length === 1) {
        setHeading("Winner");
      } else {
        setHeading("Winners");
      }
      leaveAllRoom(currentUserInfo._id);
    }
  }, [comp_results]);

  // console.log(list)

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <TranslatedComponent>
        <div id="content">
          <div className="main-container-section">
            <Header />
            {!comp_start_Loading ? (
              <>
                <div className="upcoming-title">
                  <h3>
                    {/* <span>
                      <em>Competition</em> Results
                    </span> */}
                      <PageTitle title={"<em>Competition</em> Results"} lang={language} />
                  </h3>
                </div>
                <div className="competition-title">
                  <p>
                    Competition Name :{" "}
                    <span>{comp_results?.compName || ""}</span>{" "}
                  </p>
                </div>

                <div className="candit-sectied-from competition-results-two-point">
                  <div className="table100 ver2 m-b-110">
                    <div className="table100-head">
                      <table>
                        <thead>
                          <tr className="row100 head">
                            <th className="column1">Position</th>
                            <th className="column-2">
                              <span>#</span>
                            </th>
                            <th className="column-3">
                              <span>Name</span>
                            </th>
                            <th className="column-4">
                              <span>Score</span>
                            </th>
                            <th className="column-5"></th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div className="table100-body js-pscroll">
                      <table className="gfg">
                        <tbody>
                          {comp_results?.results &&
                          comp_results?.results.length > 0
                            ? comp_results.results.map((sp, index) => {
                                return (
                                  <>
                                    <tr
                                      onClick={() =>
                                        handlePlayerResultDetailsClicked(
                                          sp.playerId
                                        )
                                      }
                                    >
                                      <td className="column1">
                                        <span className="number-teu">
                                          {index + 1}
                                        </span>
                                      </td>
                                      <td className="column-2">
                                        {
                                          <PlayerSerialNumber
                                            compId={comp_id}
                                            playerId={sp.playerId}
                                          />
                                        }
                                      </td>
                                      <td className="column-3">{sp.name}</td>
                                      <td className="column-4">
                                        {sp.totalScore}
                                      </td>
                                      <td className="column-5">
                                        <div className="picture-oi">
                                          <img
                                            src={ sp?.img ? sp?.img :
                                              window.location.origin +
                                              "/Assets/images/blank-profile-picture.webp"
                                            }
                                            alt="Testimonial"
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                    {clickedPlayerId.includes(sp.playerId) && (
                                      <tr className="geeks">
                                        <td
                                          colSpan={5}
                                          style={{ border: "0", padding: "0" }}
                                        >
                                          <CompetitionResultDetails
                                            compId={comp_id}
                                            playerId={sp.playerId}
                                          />
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })
                            : null}
                        </tbody>
                      </table>

                      <div className="buttaon-lane">
                        <div className="back-name">
                          <button
                            className="back-g"
                            onClick={() => {
                              currentUserInfo.role === "Org"
                                ? navigate("/org-dashboard")
                                : navigate("/jury-dashboard");
                            }}
                          >
                            Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <ComponentLoader />
            )}
          </div>
        </div>
        </TranslatedComponent>

        {heading && list && list.length > 0 && (
          <SidebarRightTwo Heading={heading} List={list} />
        )}
      </div>
    </>
  );
};

export default CompetitionResults;
