/* import tytpes */
import {
  //   ROUND_GET_SUCCESS,
  //   ROUND_GET_ERROR,
  ROUND_CURRENT_GET_SUCCESS,
  ROUND_CURRENT_GET_ERROR,
  ROUND_SUMMARY_GET_SUCCESS,
  ROUND_SUMMARY_GET_ERROR,
  ROUND_UNUSED_WORD_GET_SUCCESS,
  ROUND_UNUSED_WORD_GET_ERROR,
  ROUND_ADD_SUCCESS,
  ROUND_ADD_ERROR,
  ROUND_UPDATE_SUCCESS,
  ROUND_UPDATE_ERROR,
  //   ROUND_REMOVE_SUCCESS,
  //   ROUND_REMOVE_ERROR,
  //   ROUND_DELETE_SUCCESS,
  //   ROUND_DELETE_ERROR,
  ROUND_SUCCESS_MSG_CLEAR,
  ROUND_ERROR_MSG_CLEAR,
  ROUND_ACTION_LOADER_START,
  ROUND_DLD_CSV_ERROR,
  ROUND_DLD_CSV_SUCCESS,
  ROUND_OVR_ALL_COM_STATICS_ERR,
  ROUND_OVR_ALL_COM_STATICS_SUCC,
  LIST_OF_WORDS_USED_ERR,
  LIST_OF_WORDS_USED_SUCC,
  LIST_OF_CANDIDATE_PRST_ERR,
  LIST_OF_CANDIDATE_PRST_SUCC,
  GET_SPECIAL_TABLE_DATA_SUCCESS_MESSAGE,
  GET_SPECIAL_TABLE_DATA_ERROR_MESSAGE,
  CREATE_NEW_ROUND_ERROR_MESSAGE,
  CREATE_NEW_ROUND_SUCCESS_MESSAGE,
} from "../Types/roundTypes";

import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const AddRound_GetCurrentRound = (data) => {
  data.isSpecialRound = localStorage.getItem("specialRound");
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/round/create-round`,
        data
      );

      dispatch({
        type: ROUND_ADD_SUCCESS,
        payload: {
          successMessage: "true",
          data: response.data.round,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ROUND_ADD_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetCurrentRound = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/round/get-current-roundDetails`,
        data
      );
      
      localStorage.removeItem("specialRound");
      dispatch({
        type: ROUND_CURRENT_GET_SUCCESS,
        payload: {
          successMessage: response.data.message,
          data: response.data.round,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;
      localStorage.removeItem("specialRound");
      dispatch({
        type: ROUND_CURRENT_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetUnusedWordForCurrentRound = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/round/get-unused-word-for-round`,
        data
      );

      dispatch({
        type: ROUND_UNUSED_WORD_GET_SUCCESS,
        payload: {
          successMessage: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;
      
      dispatch({
        type: ROUND_UNUSED_WORD_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const Record_Word_Answer = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/round/record-word-answer-for-participant`,
        data
      );

      dispatch({
        type: ROUND_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ROUND_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const Update_Result_for_round = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/round/update-participant-result`,
        data
      );

      dispatch({
        type: ROUND_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ROUND_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetRoundSummarySelection = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/round/get-round-summary-selection`,
        data
      );

      dispatch({
        type: ROUND_SUMMARY_GET_SUCCESS,
        payload: {
          successMessage: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ROUND_SUMMARY_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const QualifyingStatusUpdate_Round = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/round/update-qualifying-status`,
        data
      );

      dispatch({
        type: ROUND_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ROUND_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const qualifiedCondidateCSV = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/round/qualified-condidate?compId=${id}`
      );
      dispatch({
        type: ROUND_DLD_CSV_SUCCESS,
        payload: {
          message: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let message = error?.response?.data?.message;
      dispatch({
        type: ROUND_DLD_CSV_ERROR,
        payload: {
          message: message,
        },
      });
    }
  };
};

export const OverallCompetationStatistics = (compId) => {
  // console.log(compId)
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/round/overall-competition-statistics?compId=${compId}`
      );

      dispatch({
        type: ROUND_OVR_ALL_COM_STATICS_SUCC,
        payload: {
          message: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let message = error?.response?.data?.message;
      dispatch({
        type: ROUND_OVR_ALL_COM_STATICS_ERR,
        payload: {
          message: message,
        },
      });
    }
  };
};

/* ----------------------[list of words used]---------------------- */
export const listOfWordsUsedCSV = (compId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/round/list-of-words-used?compId=${compId}`
      );

      dispatch({
        type: LIST_OF_WORDS_USED_SUCC,
        payload: {
          message: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let message = error?.response?.data?.message;
      dispatch({
        type: LIST_OF_WORDS_USED_ERR,
        payload: {
          message: message,
        },
      });
    }
  };
};

export const listOfTheCandidatePresent = (compId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/round/list-of-candidate-present?compId=${compId}`
      );

      dispatch({
        type: LIST_OF_CANDIDATE_PRST_SUCC,
        payload: {
          message: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let message = error?.response?.data?.message;
      dispatch({
        type: LIST_OF_CANDIDATE_PRST_ERR,
        payload: {
          message: message,
        },
      });
    }
  };
};

export const getSpecialRoundData = ({ comp_id }) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/round/players-status-wise-data?competationId=${comp_id}`
      );
      dispatch({
        type: GET_SPECIAL_TABLE_DATA_SUCCESS_MESSAGE,
        payload: {
          message: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_SPECIAL_TABLE_DATA_ERROR_MESSAGE,
        payload: {
          message: error.response?.data?.message || error?.message,
        },
      });
    }
  };
};

export const addNewRound = (credentials) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/round/add-special-round`,
        credentials
      );

      dispatch({
        type: CREATE_NEW_ROUND_SUCCESS_MESSAGE,
        payload: {
          message: response.data.message,
          createdSpecialRounds: response.data.createdRound,
        },
      });
    } catch (error) {
      dispatch({
        type: CREATE_NEW_ROUND_ERROR_MESSAGE,
        payload: {
          message: error.response?.data?.message || error?.error,
        },
      });
    }
  };
};
