import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/Header/Header";
import { jwtDecode } from "jwt-decode";

import { useSelector, useDispatch } from "react-redux";
import { EmailVerification } from "../../../Store/Action/authAction";
import {
  AUTH_SUCCESS_CLEAR,
  AUTH_ERROR_CLEAR,
} from "../../../Store/Types/authTypes";

import Swal from "sweetalert2";

const VerifyAccount = () => {
  const dispatch = useDispatch();
  const {
    authLoading,
    authenticate,
    currentUserInfo,
    authSuccessMessage,
    authErrorMessage,
  } = useSelector((state) => state.Auth);

  const { token } = useParams();

  const decodedData = JSON.parse(atob(token));

  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]); // State to store OTP digits
  const inputRefs = [useRef(), useRef(), useRef(), useRef()]; // Refs for each input field

  const handleOtpChange = (index, value) => {
    // Update the OTP state
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field
    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleVerification = () => {
    const enteredOtp = otp.join("");
    // console.log("Entered OTP:", enteredOtp, decodedData.otp);

    if (enteredOtp == decodedData.otp) {
      dispatch(
        EmailVerification({
          userId: decodedData.userId,
          email: decodedData.email,
          isActive: true,
        })
      );
    } else if (enteredOtp !== decodedData.otp) {
      Swal.fire({
        icon: "error",
        title: "Oups...",
        text: "Vous avez entré un mauvais otp. Veuillez revérifier.",
      });
    }
  };

  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("Text");
    if (pastedData.length === otp.length) {
      setOtp(pastedData.split(""));
    }
  };

  useEffect(() => {
    if (authSuccessMessage) {
      Swal.fire(
        {
          // title: "Email Sent",
          title: "E-mail envoyé",
          text: authSuccessMessage,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        },
        2000
      ).then(() => {
        dispatch({ type: AUTH_SUCCESS_CLEAR });
        if (currentUserInfo.role == "Org") {
          navigate("/org-dashboard");
        } else if (currentUserInfo.role == "Jury") {
          navigate("/jury-dashboard");
        }
      });
    }
    if (authErrorMessage) {
      Swal.fire({
        icon: "error",
        title: "Oups...",
        text: authErrorMessage,
      }).then(() => {
        dispatch({ type: AUTH_ERROR_CLEAR });
      });
    }
  }, [authSuccessMessage, authErrorMessage]);
  

  return (
    <>
      <div className="wrapper">
        <div id="content-two">
          <div className="main-container-section">
            <Header />
            <div className="upcoming-title">
              <h3>
                {/* <span>Verify </span>Account */}
                <span>Vérifier </span>le compte
              </h3>
            </div>
            <div className="login-section">
              <h3>Vérifier le compte</h3>
              <p>
              Veuillez saisir le code de vérification envoyé à votre{" "}
                {decodedData.email}
              </p>
              <div className="login-page">
                <div className="verify-from">
                  <form action="" className="verify-section">
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        className="otp"
                        type="text"
                        value={digit}
                        maxLength={1}
                        onChange={(e) =>
                          handleOtpChange(index, e.target.value)
                        }
                        ref={inputRefs[index]}
                        onPaste={handlePaste}
                      />
                    ))}
                  </form>
                  <button onClick={handleVerification}>Vérifier</button>
                  {/* <button onClick={handleVerification}>Verify</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyAccount;
