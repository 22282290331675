import React from "react";
import TranslatedComponent from "../../../translator/TranslatedComponent";

const Warning = ({ msg, link }) => {
  const contentStyle = {
    marginTop: "10%",
    textAlign: "center",
    padding: "20px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    backgroundColor: "#fff",
  };

  const headingStyle = {
    fontSize: "2em",
    color: "#721c24", // Heading color for unauthorized access
  };

  const messageStyle = {
    fontSize: "1.2em",
    color: "#721c24",
  };

  return (
    <div>
      <div style={contentStyle}>
        <TranslatedComponent>
          <h1 style={headingStyle}>Warning</h1>
          <p style={messageStyle}>{msg}</p>
          {link && (
            <a href={link} style={{ color: "blue" }}>
              Ok
            </a>
          )}
        </TranslatedComponent>
      </div>
    </div>
  );
};

export default Warning;
