import React, { useState, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../../Config/Config";
import ComponentLoader from "../ComponentLoader/ComponentLoader";
import TranslatedComponent from "../../translator/TranslatedComponent";

const CompetitionResultDetails = ({ compId, playerId }) => {
  const [loading, setloading] = useState(true);
  const [data, setDtata] = useState([]);
  useEffect(() => {
    if (compId && playerId) {
      axios
        .post(`${SERVER_URL}/oneshot/get-comp_result`, { compId, playerId })
        .then((response) => {
          // console.log(response.data);
          setDtata(response.data.data);
          setloading(false);
        })
        .catch((err) => {
          setDtata([]);
          setloading(false);
        });
    }
  }, [compId, playerId]);
  // console.log(data);
  return (
    <>
      {" "}
      <div className={`dropdown3 slideDown tournament-details`}>
        <div id="myDropdown" className="dropdown-content-two">
          <table className="table table-stripped">
            {!loading && data.length > 0 ? (
              <>
                <thead>
                  <TranslatedComponent>
                  <tr>
                    <th scope="col">Round Number</th>
                    <th scope="col">Word Given</th>
                    <th scope="col">Word Spelt</th>
                    <th scope="col">Result</th>
                    <th scope="col">Status</th>
                  </tr>
                  </TranslatedComponent>
                </thead>

                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.roundName || ""}</td>
                      <td>{item.word || ""}</td>
                      <td>{item.answer || ""}</td>
                      <td>{item.result || ""}</td>
                      <td>{item.qualifyingStatus || ""}</td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <ComponentLoader />
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default CompetitionResultDetails;
