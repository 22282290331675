import React from 'react';
import TranslatedComponent from '../../../translator/TranslatedComponent';

const PageNotFound = () => {
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa', // Background color
  };

  const contentStyle = {
    textAlign: 'center',
    padding: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', // Box shadow for a subtle effect
    borderRadius: '8px', // Border radius for rounded corners
    backgroundColor: '#ffffff', // Content background color
  };

  const headingStyle = {
    fontSize: '2em',
    color: '#343a40', // Heading color
  };

  const messageStyle = {
    fontSize: '1.2em',
    color: '#495057', // Message color
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <TranslatedComponent>
        <h1 style={headingStyle}>404 - Page Not Found</h1>
        <p style={messageStyle}>Sorry, the page you are looking for might be in another castle.</p>
        </TranslatedComponent>
        {/* You can add additional content or links here */}
      </div>
    </div>
  );
};

export default PageNotFound;
