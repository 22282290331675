export const WORD_ALL_GET_SUCCESS = "WORD_ALL_GET_SUCCESS";
export const WORD_ALL_GET_ERROR = "WORD_ALL_GET_ERROR";

export const WORD_ORG_GET_SUCCESS = "WORD_ORG_GET_SUCCESS";
export const WORD_ORG_GET_ERROR = "WORD_ORG_GET_ERROR";

export const WORD_COMP_GET_SUCCESS = "WORD_COMP_GET_SUCCESS";
export const WORD_COMP_GET_ERROR = "WORD_COMP_GET_ERROR";

export const WORD_DETAILS_GET_SUCCESS = "WORD_DETAILS_GET_SUCCESS";
export const WORD_DETAILS_GET_ERROR = "WORD_DETAILS_GET_ERROR";

export const WORD_ADD_SUCCESS = "WORD_ADD_SUCCESS";
export const WORD_ADD_ERROR = "WORD_ADD_ERROR";

export const WORD_UPDATE_SUCCESS = "WORD_UPDATE_SUCCESS";
export const WORD_UPDATE_ERROR = "WORD_UPDATE_ERROR";

export const WORD_DELETE_SUCCESS = "WORD_DELETE_SUCCESS";
export const WORD_DELETE_ERROR = "WORD_DELETE_ERROR";

export const WORD_SUCCESS_CLEAR = "WORD_SUCCESS_CLEAR";
export const WORD_ERROR_CLEAR = "WORD_ERROR_CLEAR";

export const WORD_ACTION_LOADER_START = "WORD_ACTION_LOADER_START";

