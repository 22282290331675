import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import Swal from "sweetalert2";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import { GetJuryCapability } from "../../../Store/Action/juryAction";
import { AddRound_GetCurrentRound } from "../../../Store/Action/roundAction";
import {
  ROUND_SUCCESS_MSG_CLEAR,
  ROUND_ERROR_MSG_CLEAR,
} from "../../../Store/Types/roundTypes";

import { UpdateOneShot } from "../../../Store/Action/oneShotAction";
import { ONESHOT_ERROR_CLEAR } from "../../../Store/Types/oneShotTypes";

import {
  StartSocketConnection,
  JoinRoom,
  pageChange,
  pageQuery,
} from "../../../HelperFunctions/Socket/socket.js";
import TranslatedComponent from "../../../translator/TranslatedComponent.jsx";
import OneShot_SubComp_Name from "../../../component/CompetitionName/OneShot_SubComp_Name.jsx";
import PageTitle from "../../../component/PageTitle/PageTitle.jsx";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator.js";

const Round = () => {
  const { comp_id, specialRoundComp } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUserInfo ,currentUser} = useSelector((state) => state.Auth);
  const { OneShot_ErrorMessage,OneShotDetails } = useSelector((state) => state.OneShot);

  const [pageState, setPageState] = useState(false);

  const {
    round_Loading,
    current_round_Details,
    // round_Action_Loader,
    round_SuccessMessage,
    round_ErrorMessage,
    // round_Get_Error_Message,
  } = useSelector((state) => state.Round);

  const { Jury_Loading, Jury_Action_Loader, Jury_Capabilities } = useSelector(
    (state) => state.Jury
  );

  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });

    dispatch(AddRound_GetCurrentRound({ compId: comp_id }));
    StartSocketConnection();

    // socket connection

    if(comp_id){
      JoinRoom(comp_id, currentUserInfo._id);
      pageChange(comp_id, `/start-competition-round/${comp_id}`);
    }

    if (
      Object.keys(currentUserInfo).length > 0 &&
      currentUserInfo.role === "Jury"
    ) {
      dispatch(
        GetJuryCapability({
          juryId: currentUserInfo._id,
          compId: comp_id,
        })
      );
    }
  }, [comp_id]);

  pageQuery(({ competitionId, page }) => {
    setPageState(page);
  });

  useEffect(() => {
    if (pageState) {
      navigate(pageState);
    }
  }, [pageState]);

  const handleRoute = async () => {
    console.log("compid on page change",comp_id);
    pageChange(comp_id, `/candidate-details/${current_round_Details._id}`);
    navigate(`/candidate-details/${current_round_Details._id}`);
  };

  useEffect(() => {
    if (round_SuccessMessage) {
      dispatch(
        UpdateOneShot({
          oneshotId: comp_id,
          orgId: currentUserInfo._id,
          isStarted: true,
        })
      );
      dispatch({ type: ROUND_SUCCESS_MSG_CLEAR });
    }
    if (round_ErrorMessage) {
      const text = MsgTranslator(round_ErrorMessage,language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: ROUND_ERROR_MSG_CLEAR });
      });
    }
    if (OneShot_ErrorMessage) {
      const text = MsgTranslator(OneShot_ErrorMessage,language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: ONESHOT_ERROR_CLEAR });
      });
    }
  }, [round_SuccessMessage, round_ErrorMessage, OneShot_ErrorMessage]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);
  

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />
        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em></em>{" "}
                  </TranslatedComponent>
                  <TranslatedComponent></TranslatedComponent>
                </span> */}
                  <PageTitle title={"<em>Start</em> Competition"} lang={language} />
              </h3>
            </div>

            <div className="start-section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    {!round_Loading && current_round_Details?.participants ? (
                      <div className="candit-sectied-from">
                        <TranslatedComponent>
                          <h3><OneShot_SubComp_Name compId = {comp_id} isheader = {true} /></h3>
                          <div className="round-section">
                            <strong>
                              Round{" "}
                              <span>
                                {" "}
                                # {current_round_Details?.name || ""}
                              </span>
                            </strong>
                          </div>
                          <p className="number-st">
                            Number of participants :
                            <strong>
                              {" "}
                              {current_round_Details?.participants.length || ""}
                            </strong>
                          </p>
                        </TranslatedComponent>
                        {currentUserInfo.role === "Org" ||
                        (currentUserInfo.role === "Jury" &&
                          Jury_Capabilities.includes(
                            "View list of Candidate"
                          )) ? (
                          <div className="start-7">
                            <TranslatedComponent>
                              <button
                                className="save-23"
                                onClick={() => handleRoute()}
                              >
                                Start
                              </button>
                            </TranslatedComponent>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="start-section">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="candit-sectied-from">
                                <ComponentLoader />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Round;
