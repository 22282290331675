import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../component/Header/Header';

import { useSelector, useDispatch } from "react-redux";
import { ResetPasswordEmailSent } from "../../../Store/Action/authAction";
import { AUTH_SUCCESS_CLEAR, AUTH_ERROR_CLEAR } from "../../../Store/Types/authTypes";

import Swal from "sweetalert2";
import { MsgTranslator } from '../../../HelperFunctions/MsgTranslator';


const ForgotPassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { authLoading, authenticate, currentUser, authSuccessMessage, authErrorMessage } = useSelector((state) => state.Auth);

  const [showEmailSent, setShowEmailSent] = useState(false);
  const [email, setEmail] = useState(currentUser ? currentUser.email : "");
  const [language, setLanguage] = useState("fr");

  const handleSendEmail = (e) => {
    e.preventDefault();
    // console.log("Send email", email);
    /* dispatch call for sending email */
    dispatch(ResetPasswordEmailSent(email));
  };

  useEffect(() => {
    // console.log(authSuccessMessage, authErrorMessage);
    if (authSuccessMessage) {

      // dispatch({ type: AUTH_SUCCESS_CLEAR });
      // conditional rendering
      setShowEmailSent(true);
      // console.log(authSuccessMessage)
    }
    if (authErrorMessage) {
      Swal.fire({
        icon: "error",
        title:  MsgTranslator("Oops...",language),
        text: MsgTranslator(authErrorMessage,language),
      }).then(() => {
        dispatch({ type: AUTH_ERROR_CLEAR });
      })
    }

  }, [authSuccessMessage, authErrorMessage]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      {!showEmailSent ? (
        <div className="wrapper">

          <div id="content-two">

            {/* <button type="button" id="sidebarCollapse" className="btn btn-info">
              <i className="fas fa-align-left"></i>
            </button> */}


            <div className="main-container-section">
              <Header />
              <div className="upcoming-title">
                {/* <h3><span>Forgot Password</span></h3> */}
                <h3><span>"Mot de passe oublié</span></h3>
              </div>
              <div className="login-section">
                {/* <h3>Forgot Password</h3> */}
                <h3>"Mot de passe oublié</h3>
                <p>Entrez votre e-mail/téléphone existant pour réinitialiser votre mot de passe</p>
                <div className="login-page">
                  <div className="form">
                    <form>
                      <div className="form-group row">
                        <label className="text-login col-sm-4">E-mail :</label>
                        <div className="col-sm-8">
                          <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                      </div>
                      <button onClick={(e) => handleSendEmail(e)}>Envoyer un e-mail</button>
                      {/* <button onClick={(e) => handleSendEmail(e)}>Send Email</button> */}

                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      ) : (
        <div className="wrapper">
          <div className="login-section">
            <p>E-mail envoyé ! Vérifiez votre boîte de réception pour obtenir des instructions supplémentaires.</p>
            <p>Si vous ne recevez pas l'e-mail dans quelques minutes, veuillez vérifier votre dossier spam.</p>
            <p>Besoin d'aide ? Contactez notre équipe d'assistance.</p>
            {/* <p>Email sent! Check your inbox for further instructions.</p>
            <p>If you don't receive the email within a few minutes, please check your spam folder.</p>
            <p>Need help? Contact our support team.</p> */}
            {/* <button onClick={() => navigate('/')}>Go to Login</button> */}
          </div>
        </div>
      )}

    </>
  )
}

export default ForgotPassword