export const COMP_START_DATA_GET_SUCCESS = 'COMP_START_DATA_GET_SUCCESS';
export const COMP_START_DATA_GET_ERROR = 'COMP_START_DATA_GET_ERROR';

export const COMP_START_DATA_UPDATE_SUCCESS = 'COMP_START_DATA_UPDATE_SUCCESS';
export const COMP_START_DATA_UPDATE_ERROR = 'COMP_START_DATA_UPDATE_ERROR';

export const COMP_RESULT_GET_SUCCESS = 'COMP_RESULT_GET_SUCCESS';
export const COMP_RESULT_GET_ERROR = 'COMP_RESULT_GET_ERROR';

export const COMP_START_SUCCESS_MSG_CLEAR = 'COMP_START_SUCCESS_MSG_CLEAR';
export const COMP_START_ERROR_MSG_CLEAR = 'COMP_START_ERROR_MSG_CLEAR';

export const COMP_START_ACTION_LOADER_START = 'COMP_START_ACTION_LOADER_START';
