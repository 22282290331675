import React, { useState, useEffect } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../Config/Config";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import {
  GetCompWisePartcipants,
  RemovePartcipants,
  BulkRemovePartcipants,
} from "../../../Store/Action/participantAction";
import {
  PARTICIPANT_ACTION_LOADER_START,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
} from "../../../Store/Types/participantTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";
import { calculateAge } from "../../../HelperFunctions/AgeFromDob";
import FileUploader from "../../../component/FileUploader/FileUploader";

import OneShot_SubComp_Name from "../../../component/CompetitionName/OneShot_SubComp_Name";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AllParticipantFromPrevPhase = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const params = useParams();
  const { phase_id, comp_id } = params;

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const {
    Participant_Loading,
    Participant_Action_Loader,
    Participants_Data,
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  } = useSelector((state) => state.Participant);
  const {
    OneShot_Loading,
    OneShotDetails,
    OneShot_SuccessMessage,
    OneShot_ErrorMessage,
    OneShot_Get_Error_Message,
  } = useSelector((state) => state.OneShot);

  // console.log(OneShotDetails);

  const [rows, setrows] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    if (!Participant_Loading && Participants_Data) {
      const transformedData = Participants_Data.map((participant, i) => ({
        index: String(i + 1).padStart(2, "0"),
        id: participant._id,
        // createdBy: participant.createdBy,
        firstName: participant.firstName,
        lastName: participant.lastName,
        dob: participant.dob,
        age: participant.dob ? calculateAge(participant.dob) || null : null,
        grade: participant.grade,
        // sex: participant.sex,
        country: participant.country ? participant.country.label : null,
        school: participant.school,
        city: participant.city,
        phone: participant.phone,
        email: participant.email,
        // img: participant.img,
        // isDeleted: participant.isDeleted,
        // additionalNote: participant.additionalNote,
        // createdAt: participant.createdAt,
        // updatedAt: participant.updatedAt,
      }));
      setrows(transformedData);
    }
  }, [Participants_Data, Participant_Loading]);

  useEffect(() => {
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
    dispatch(
      GetCompWisePartcipants({
        createdBy: currentUserInfo._id,
        compId: comp_id,
      })
    );
  }, [comp_id]);

  /* Delete Participant */
  const handleParticipantDelete = (participantId) => {
    Swal.fire({
      title: MsgTranslator("Are you sure?",language),
      text: MsgTranslator("You won't be able to revert this!",language),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: MsgTranslator("Yes, delete it!",language),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          RemovePartcipants({
            createdBy: currentUserInfo._id,
            compId: comp_id,
            participantId: participantId,
          })
        ).then(() => {
          dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
          dispatch(
            GetCompWisePartcipants({
              createdBy: currentUserInfo._id,
              compId: comp_id,
            })
          );
        });
      }
    });
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Participant_SuccessMessage) {
      const text = MsgTranslator(Participant_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: PARTICIPANT_SUCCESS_MSG_CLEAR });
      dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
      dispatch(
        GetCompWisePartcipants({
          createdBy: currentUserInfo._id,
          compId: comp_id,
        })
      );
    }
    
    if (Participant_ErrorMessage) {
      const text = MsgTranslator(Participant_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
    }
    
    if (Participant_Get_Error_Message) {
      const text = MsgTranslator(Participant_Get_Error_Message,language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
      });
    }
  }, [
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  ]);

  const handleBulkDelte = () => {
    Swal.fire({
      title: MsgTranslator("Are you sure?",language),
      text: MsgTranslator("You won't be able to revert this!",language),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: MsgTranslator("Yes, delete it!",language),
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire({
        //   title: "Deleted!",
        //   text: "Your file has been deleted.",
        //   icon: "success"
        // });
        dispatch(
          BulkRemovePartcipants({
            createdBy: currentUserInfo._id,
            compId: comp_id,
            participantIdArray: selectedParticipants,
          })
        ).then(() => {
          dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
          dispatch(
            GetCompWisePartcipants({
              createdBy: currentUserInfo._id,
              compId: comp_id,
            })
          );
        });
      }
    });
  };

  const handleCellClick = async (e) => {
    if (e.field != "__check__") {
      navigate(`/phase/participant-details/${e.id}`);
    }
  };

  const columns = [
    {
      field: "index",
      headerName: "",
      width: 90,
      renderCell: (params) => (
        <span style={{ fontWeight: "bold" }}>{params.row.index}</span>
      ),
    },
    {
      field: "firstName",
      headerName: <TranslatedComponent>First Name</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "lastName",
      headerName: <TranslatedComponent>Last Name</TranslatedComponent>,
      width: 200,
    },
    {
      field: "dob",
      headerName: <TranslatedComponent>Birthday</TranslatedComponent>,
      width: 200,
    },
    {
      field: "age",
      headerName: <TranslatedComponent>Age</TranslatedComponent>,
      width: 200,
    },
    {
      field: "school",
      headerName: <TranslatedComponent>School</TranslatedComponent>,
      width: 200,
    },
    {
      field: "grade",
      headerName: <TranslatedComponent>Grade</TranslatedComponent>,
      width: 200,
    },
    {
      field: "country",
      headerName: <TranslatedComponent>Country</TranslatedComponent>,
      width: 200,
    },
    {
      field: "city",
      headerName: <TranslatedComponent>City</TranslatedComponent>,
      width: 200,
    },
    {
      field: "phone",
      headerName: <TranslatedComponent>Phone</TranslatedComponent>,
      width: 200,
    },
    {
      field: "email",
      headerName: <TranslatedComponent>Email</TranslatedComponent>,
      width: 200,
    },
    // {
    //   field: "edit",
    //   headerName: "Edit",
    //   width: 200,
    //   headerAlign: "right",
    //   align: "right",
    //   renderCell: (params) => (
    //     <button
    //       className="btn-with-pic"
    //       onClick={() => {
    //         if (window.location.href.includes("/phase/")) {
    //           navigate(`/phase/participant-details/${params.row.id}`);
    //         } else {
    //           navigate(`/participant-details/${params.row.id}`);
    //         }
    //       }}
    //     >
    //       <img
    //         src={window.location.origin + `/Assets/images/edit-dreen.png`}
    //         alt="Edit Dreen"
    //       />
    //     </button>
    //   ),
    // },
    // {
    //   field: "delete",
    //   headerName: "Delete",
    //   width: 200,
    //   headerAlign: "right",
    //   align: "right",
    //   renderCell: (params) => (
    //     <button
    //       className="btn-with-pic"
    //       onClick={() => {
    //         handleParticipantDelete(params.row.id);
    //       }}
    //     >
    //       <img
    //         src={window.location.origin + `/Assets/images/delete-green.png `}
    //         alt="Delete Green"
    //       />
    //     </button>
    //   ),
    // },
  ];


  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {OneShotDetails?.tournamentId ? (
            <TranslatedComponent>
              <Box sx={style}>
                <Box
                  sx={{ border: 4, m: 2, p: 2 }}
                  onClick={() => {
                    navigate(
                      `/phase/existing-participant/${phase_id}/${comp_id}`
                    );
                  }}
                >
                  Add Excisting Participants{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
              </Box>
            </TranslatedComponent>
          ) : (
            <Box sx={style}>
              <TranslatedComponent>
                <Box
                  sx={{ border: 4, m: 2, p: 2 }}
                  onClick={() => {
                    navigate(`/manual-add-participant/${comp_id}`);
                  }}
                >
                  Manual Add{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
                <Box
                  sx={{ border: 4, m: 2, p: 2 }}
                  onClick={() => {
                    navigate(`/quick-mode-add-participant/${comp_id}`);
                  }}
                >
                  Quick Add{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
                <Box
                  sx={{ border: 4, m: 2, p: 2 }}
                  onClick={() => {
                    navigate(`/existing-participant/${comp_id}`);
                  }}
                >
                  Add Excisting Participants{" "}
                  <i
                    className="fa -regular fa-arrow-right"
                    style={{ color: "#000000" }}
                  ></i>
                </Box>
                {/* <Box sx={{ border: 4, m: 2, p: 2 }} onClick={() => { navigate(``) }}>Import CSV or Excel <i className="fa -regular fa-arrow-right" style={{ color: "#000000" }}></i></Box> */}
                <FileUploader compId={comp_id} />
              </TranslatedComponent>
            </Box>
          )}
        </Modal>
      </div>

      <div className="wrapper">
        <SidebarLeft />
        <TranslatedComponent>
          <div id="content-thrr">
            {!Participant_Loading ? (
              <div className="main-container-section">
                <Header />

                <div className="upcoming-title">
                  <h3>
                    <PageTitle
                      title={"<em>All</em> Participant"}
                      lang={language}
                    />
                    <span>
                      {/* <TranslatedComponent>
                      <em>All</em>
                    </TranslatedComponent>
                    <TranslatedComponent>Participant</TranslatedComponent>{" "} */}
                      <OneShot_SubComp_Name compId={comp_id} />
                    </span>
                  </h3>
                </div>

                <div className="candit-sectied-from">
                  <div className="table1002 ver2 m-b-110">
                    <Box sx={{ height: 500, width: "100%" }}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        // autoHeight={true}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10, 20, 30, 40, 50]}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onCellClick={(event) => handleCellClick(event)}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                          setSelectedParticipants(newRowSelectionModel);
                        }}
                      />
                    </Box>
                  </div>
                  <div className="buttaon-lane">
                    <div className="back-name">
                      <button className="back-g" onClick={() => navigate(-1)}>
                        Back
                      </button>
                    </div>
                    <div className="right-edit">
                      <button className="start-g" onClick={handleOpen}>
                        Add More Participant
                      </button>
                      <button
                        className="start-g"
                        onClick={() =>
                          window.location.href.includes("/phase/")
                            ? navigate(`/phase/organiser-word-list/${comp_id}`)
                            : navigate(`/organiser-word-list/${comp_id}`)
                        }
                        // onClick={() => navigate(`/organiser-word-list/${comp_id}`)}
                      >
                        WordList
                      </button>
                      <button
                        className="start-g"
                        onClick={() =>
                          window.location.href.includes("/phase/")
                            ? navigate(`/phase/all-jury/${comp_id}`)
                            : navigate(`/all-jury/${comp_id}`)
                        }
                      >
                        Jury Members
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <ComponentLoader />
            )}
          </div>
        </TranslatedComponent>
      </div>
    </>
  );
};

export default AllParticipantFromPrevPhase;
