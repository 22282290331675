// https://www.npmjs.com/package/react-simple-keyboard
// https://hodgef.com/simple-keyboard/documentation/

import React, { useRef, useState } from "react";
import KioskBoard from "kioskboard";
import { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";

import {
  // StartSocketConnection,
  // JoinRoom,
  // pageChange,
  // pageQuery,
  // timerStateChange,
  // getTimerState,
  curentTextChange,
  getCurrentText,
} from "../../HelperFunctions/Socket/socket.js";
import { MsgTranslator } from "../../HelperFunctions/MsgTranslator.js";

const inputStyle = {
  width: "100%",
  height: "100px",
  padding: "20px",
  fontSize: "20px",
  border: "none",
  boxSizing: "border-box",
};

const keyboardStyle = {
  maxWidth: "850px",
};

function isMobileDevice() {
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/* if (isMobileDevice()) {
  console.log("You are using a mobile device.");
} else {
  console.log("You are using a PC or non-mobile device.");
} */


// Select the input or the textarea element(s) to run the KioskBoard
function Keyboard({ onInputChange, competitionId }) {
  // const inputRef = useRef(null);
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  KioskBoard.init({
    /*!
     * Required
     * An Array of Objects has to be defined for the custom keys. Hint: Each object creates a row element (HTML) on the keyboard.
     * e.g. [{"key":"value"}, {"key":"value"}] => [{"0":"A","1":"B","2":"C"}, {"0":"D","1":"E","2":"F"}]
     */
    keysArrayOfObjects: [
      {
        0: "'",
        1: "Ë",
        2: "Ï",
        3: "Ô",
        4: "-",
        5: "Ü",
        6: "Æ",
        7: "Ö",
      },
      {
        0: "Ù",
        1: "É",
        2: "È",
        3: "Ê",
        4: "Ç",
        5: "À",
        6: "Â",
        7: "Ä",
        8: "Î",
        9: "Û",
        10: "Ÿ",
        11: "Œ",
      },
      {
        0: "A",
        1: "Z",
        2: "E",
        3: "R",
        4: "T",
        5: "Y",
        6: "U",
        7: "I",
        8: "O",
        9: "P",
      },
      {
        0: "Q",
        1: "S",
        2: "D",
        3: "F",
        4: "G",
        5: "H",
        6: "J",
        7: "K",
        8: "L",
        9: "M",
      },
      {
        0: "W",
        1: "X",
        2: "C",
        3: "V",
        4: "B",
        5: "N",
      },
    ],

    /*!
     * Required only if "keysArrayOfObjects" is "null".
     * The path of the "kioskboard-keys-${langugage}.json" file must be set to the "keysJsonUrl" option. (XMLHttpRequest to get the keys from JSON file.)
     * e.g. '/Content/Plugins/KioskBoard/dist/kioskboard-keys-english.json'
     */
    // keysJsonUrl: null,

    /*
     * Optional: An Array of Strings can be set to override the built-in special characters.
     * e.g. ["#", "€", "%", "+", "-", "*"]
     */
    keysSpecialCharsArrayOfStrings: null,

    /*
     * Optional: An Array of Numbers can be set to override the built-in numpad keys. (From 0 to 9, in any order.)
     * e.g. [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
     */
    keysNumpadArrayOfNumbers: null,

    // Optional: (Other Options)

    // Language Code (ISO 639-1) for custom keys (for language support) => e.g. "de" || "en" || "fr" || "hu" || "tr" etc...
    language: "fr",

    // The theme of keyboard => "light" || "dark" || "flat" || "material" || "oldschool"
    theme: "flat",

    // Scrolls the document to the top or bottom(by the placement option) of the input/textarea element. Prevented when "false"
    autoScroll: true,

    // Uppercase or lowercase to start. Uppercased when "true"
    capsLockActive: true,

    /*
     * Allow or prevent real/physical keyboard usage. Prevented when "false"
     * In addition, the "allowMobileKeyboard" option must be "true" as well, if the real/physical keyboard has wanted to be used.
     */
    allowRealKeyboard: true,

    // Allow or prevent mobile keyboard usage. Prevented when "false"
    allowMobileKeyboard: isMobileDevice() ? false : true, //for testing true--make both false

    // CSS animations for opening or closing the keyboard
    cssAnimations: true,

    // CSS animations duration as millisecond
    cssAnimationsDuration: 360,

    // CSS animations style for opening or closing the keyboard => "slide" || "fade"
    cssAnimationsStyle: "slide",

    // Enable or Disable Spacebar functionality on the keyboard. The Spacebar will be passive when "false"
    keysAllowSpacebar: true,

    // Text of the space key (Spacebar). Without text => " "
    keysSpacebarText: " ",

    // Font family of the keys
    keysFontFamily: "sans-serif",

    // Font size of the keys
    keysFontSize: "22px",

    // Font weight of the keys
    keysFontWeight: "normal",

    // Size of the icon keys
    keysIconSize: "25px",

    // Text of the Enter key (Enter/Return). Without text => " "
    keysEnterText: "Enter",

    // The callback function of the Enter key. This function will be called when the enter key has been clicked.
    keysEnterCallback: undefined,

    // The Enter key can close and remove the keyboard. Prevented when "false"
    keysEnterCanClose: true,
  });

  // const [text, setText] = useState("");
  const [language , setLanguage] = useState('fr');

  useEffect(() => {
    const inputField = document.querySelector(".js-virtual-keyboard");
    inputField.addEventListener("input", (event) => {
      console.log("input");
      const typedValue = event.target.value;
      // console.log(typedValue);
      onInputChange(typedValue);
      // setText(typedValue);
      // curentTextChange(competitionId, typedValue);
      dispatch({type: "CHANGE_TEXT",payload:{text: typedValue}})
    });

    inputField.addEventListener("change", (event) => {
      console.log("change")
      const typedValue = event.target.value;
      // console.log(typedValue);
      onInputChange(typedValue);
      // setText(typedValue);
      // curentTextChange(competitionId, typedValue);
      dispatch({type: "CHANGE_TEXT",payload:{text: typedValue}})
    });

    KioskBoard.run(".js-virtual-keyboard");
  });

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      {/* <div
        style={{
          display: "inline-block",
          position: "relative",
          zIndex: "9999",
          top: -100,
          left: 50,
          maxWidth: "200px",
        }}
      >
        <p style={{ backgroundColor: "#DFDFDF", color: "red" }}>{text}</p>
      </div> */}
      <input
        style={{ width: "100%", height: "60px" }}
        className="js-virtual-keyboard form-control rounded"
        data-kioskboard-type="keyboard"
        data-kioskboard-placement="bottom"
        data-kioskboard-specialcharacters="false"
        placeholder={MsgTranslator("Type here...",language)}
        name="keyboard"
      ></input> 
    </>
  );
}

export default Keyboard;
