import React from 'react';
import TranslatedComponent from '../../../translator/TranslatedComponent';

const UnauthorizedAccess = () => {
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f8d7da', // Background color for unauthorized access
  };

  const contentStyle = {
    textAlign: 'center',
    padding: '20px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    backgroundColor: '#fff',
  };

  const headingStyle = {
    fontSize: '2em',
    color: '#721c24', // Heading color for unauthorized access
  };

  const messageStyle = {
    fontSize: '1.2em',
    color: '#721c24',
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <TranslatedComponent>
        <h1 style={headingStyle}>Unauthorized Access</h1>
        <p style={messageStyle}>Sorry, you do not have permission to access this page.</p>
        </TranslatedComponent>
        {/* You can add additional content or links here */}
      </div>
    </div>
  );
};

export default UnauthorizedAccess;
