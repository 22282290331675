import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  GetAllOneShot,
  GetOneShotDetails,
} from "../../Store/Action/oneShotAction";
import {
  ONESHOT_SUCCESS_CLEAR,
  ONESHOT_ERROR_CLEAR,
} from "../../Store/Types/oneShotTypes";

const PlayerSerialNumber = ({ compId, playerId }) => {
  
  //compId -- oneshot/ subcompId
  const dispatch = useDispatch();
  const { currentUserInfo } = useSelector((state) => state.Auth);
  const {
    OneShot_Loading,
    OneShot_Data,
    OneShotDetails,
    OneShot_SuccessMessage,
    OneShot_Get_Error_Message,
    OneShot_ErrorMessage,
  } = useSelector((state) => state.OneShot);
  const [serial, setSerial] = useState();

  useEffect(() => {
    dispatch(GetOneShotDetails({ orgId: currentUserInfo._id, oneshotId: compId }));
  }, [compId, playerId]);

  useEffect(() => {
    if (OneShotDetails && Object.keys(OneShotDetails).length > 0) {
      // console.log(OneShotDetails.participants, playerId);
      let index = OneShotDetails.participants.findIndex((sp) => 
        sp.playerId.toString() === playerId
      );
      if (index != -1) {
        setSerial(index + 1);
      }
    }
  }, [OneShotDetails]);

  /* success and error msg handle */
  useEffect(() => {
    if (OneShot_Get_Error_Message) {
      dispatch({ type: ONESHOT_ERROR_CLEAR });
    }
  }, [OneShot_Get_Error_Message]);
  return serial;
};

export default PlayerSerialNumber;
