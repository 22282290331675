/* import tytpes */
import { CHANGE_TEXT } from "../Types/CustomStateTypes";

import { RESET_REDUX } from "../Types/customTypes";


// console.log("||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||")
const initialState = {
  text: "",
  timeTaken: 0
};

export const CustomStateReducer = (state = initialState, action) => {
  const { payload, type } = action;
  if (type === CHANGE_TEXT) {
    return {
      ...state,
      text: payload.text,
    };
  }
  if (type === "CHANGE_TIME_TAKEN") {
    // console.log("*********************************", payload.timetaken)
    return {
      ...state,
      timeTaken: payload.timeTaken,
    };
  }
  // if (type === RESET_REDUX) {
  //   return {
  //     ...state,
  //     text: "",
  //   };
  // }
  return state;
};
