import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SidebarLeft from "../../../../component/Sideber/SidebarLeft";
import Header from "../../../../component/Header/Header";
import { useSelector, useDispatch } from "react-redux";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { Create_Tournament } from "../../../../Store/Action/tournamentAction";
import {
  TOURNAMENT_ACTION_LOADER,
  TOURNAMENT_SUCCESS_CLEAR,
  TOURNAMENT_ERROR_CLEAR,
} from "../../../../Store/Types/tournamentTypes";

/* Toast Msg */
import { notify } from "../../../../component/ToastMsg/ToastMsg";
import Swal from "sweetalert2";

import axios from "axios";
import { SERVER_URL } from "../../../../Config/Config";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import TranslatedComponent from "../../../../translator/TranslatedComponent";
import PageTitle from "../../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../../HelperFunctions/MsgTranslator";

const CreateTournament = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentUserInfo ,currentUser} = useSelector((state) => state.Auth);
  const {
    tournament_New_Id,
    tournament_Action_Loader,
    tournament_SuccessMessage,
    tournament_ErrorMessage,
  } = useSelector((state) => state.Tournament);

  const obj = {
    adminId: currentUserInfo?._id || null,
    orgId: currentUserInfo?._id || null,
    tournamentName: "",
    wordDifficulty: "Easy",
    startDate: "",
    endDate: "",
    wordTheme: "",
    wordContext: "",
    noOfPhase: 1,
    time: "9:00 am",
    phases: [],
    participantList: [],
  };

  const [state, setState] = useState(obj);

  const [themeContextOptions, setThemeContext] = useState({
    themes: [],
    contexts: [],
  });

  useEffect(() => {
    axios.get(`${SERVER_URL}/word/get-theme-context`)
      .then((res) => {
        const uniqueThemes = [...new Set(res.data.data.themes.map(theme => theme.toLowerCase()))];
        const uniqueContexts = [...new Set(res.data.data.contexts.map(context => context.toLowerCase()))];
        setThemeContext({
          themes: uniqueThemes,
          contexts: uniqueContexts
        });

      })
      .catch((err) => {
        console.log("Error: " + err);
      });
  }, []);

  useEffect(() => {
    // Check if there are existing phases
    let existingPhases = state.phases.length > 0 ? state.phases : [];
    let initialPhases = [];

    // console.log(state.noOfPhase, existingPhases.length);
    if (state.noOfPhase < existingPhases.length) {
      // console.log("first");
      // setState(prevState => {
      //   const updatedPhases = [...prevState.phases];
      //   updatedPhases.pop();
      //   return {
      //       ...prevState,
      //       phases: updatedPhases
      //   };
      // });
      setState((prevState) => {
        const updatedPhases = [...prevState.phases];
        updatedPhases.pop();
        // console.log(updatedPhases);
        return {
          ...prevState,
          phases: updatedPhases,
        };
      });
    } else {
      // If there are existing phases, retain them
      if (existingPhases.length > 0) {
        initialPhases = existingPhases.map((phase, index) => ({
          ...phase,
          index: index + 1,
        }));
      } else {
        // If there are no existing phases, create a new one
        initialPhases.push({
          index: 1,
          phaseName: "p1",
          noOfSubComp: 1,
          subCompIds: [],
        });
      }

      // Add new phases if necessary
      for (let i = initialPhases.length; i < state.noOfPhase; i++) {
        initialPhases.push({
          index: i + 1,
          phaseName: "",
          noOfSubComp: 1,
          subCompIds: [],
        });
      }

      // Update the state with the updated phases array
      setState((prevState) => ({
        ...prevState,
        phases: initialPhases,
      }));
    }
  }, [state.noOfPhase]);

  const handleChange = (name, value) => {
    if (name === "startDate" || name === "endDate") {
      const formattedDate = dayjs(value).format("DD-MM-YYYY");
      setState({
        ...state,
        [name]: formattedDate,
      });
    } else if (name === "time") {
      const formattedDate = dayjs(value).format("hh:mm a");
      setState({
        ...state,
        [name]: formattedDate,
      });
    } else if (name === "noOfPhase") {
      if (value > state.noOfPhase) {
        setState({
          ...state,
          [name]: value ? value.trim() : "",
        });
      }
    } else {
      setState({
        ...state,
        [name]: value ? value.trim() : "",
      });
    }
  };

  const handlePhaseChange = (i, name, value) => {
    // console.log(i, name, value);
    setState((prevState) => {
      const updatedPhases = [...prevState.phases];
      updatedPhases[i] = {
        ...updatedPhases[i],
        [name]: value,
      };
      return {
        ...prevState,
        phases: updatedPhases,
      };
    });
  };

  const HandleDeletePhase = (index) => {
    const text = MsgTranslator("Minimum 1 phase is needed to start a tournament.",language)
    if (state.noOfPhase === 1) {
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      });
      return;
    }
    let prev_phases = [...state.phases];
    let new_phases = prev_phases.filter((sp, i) => i !== index);
    let new_no_of_phase = state.noOfPhase - 1;
    setState({ ...state, noOfPhase: new_no_of_phase, phases: new_phases });
  };

  const dateFormat = (date) => {
    return date.split("-").reverse().join("-");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(state);
    if (state.startDate == "") {
      const text = MsgTranslator("Start Date is required",language)
      notify(text, "error");
      return;
    }
    if (state.endDate == "") {
      const text = MsgTranslator("End Date is required",language)
      notify(text, "error");
      return;
    }
    if (state.time == "") {
      const text = MsgTranslator("Time is required",language)
      // console.log(150, state.time);
      notify(text, "error");
    }
    // console.log(state);
    dispatch(Create_Tournament(state));
  };
  
  useEffect(() => {
    if (tournament_SuccessMessage) {
      const text = MsgTranslator(tournament_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: TOURNAMENT_SUCCESS_CLEAR });
    }
    if (tournament_ErrorMessage) {
      const text = MsgTranslator(tournament_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: TOURNAMENT_ERROR_CLEAR });
    }
  }, [tournament_SuccessMessage, tournament_ErrorMessage]);

  // console.log(state.time);
  const [language,setLanguage] = useState("fr");
  useEffect(() =>{
    if(currentUser && Object.keys(currentUser).length > 0){
      const {language} = currentUser;
      setLanguage(language);
    }
  },[currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />
        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard", language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                {
                  page: MsgTranslator("Create Competition", language),
                  link: "/competition-type",
                },
                {
                  page: MsgTranslator("Create Tournament", language),
                  link: "/create-tournament",
                },
              ]}
            />
            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em>  </em>
                  </TranslatedComponent>
                  <TranslatedComponent></TranslatedComponent>
                </span> */}
                  <PageTitle title={"<em>Create</em> Tournament"} lang={language} />
              </h3>
            </div>
            <TranslatedComponent>
              <div className="candit-sectied-from">
                <div className="ft-create-section">
                  <form onSubmit={handleSubmit}>
                    <div className="text-from-one">
                      <div className="row">
                        {/* Tournament name */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              htmlFor="tournamentName"
                              className="col-sm-5 col-form-label"
                            >
                              Tournament Name :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                id="tournamentName"
                                placeholder="Tournament Name"
                                name="tournamentName"
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        {/* Tournament type */}
                        {/* <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Tournament Type :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              className="form-control"
                              id="inputEmail3"
                              placeholder="Tournament Type"
                            />
                          </div>
                        </div>
                      </div> */}

                        {/* word diffiulty */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="WordDif"
                              className="col-sm-5 col-form-label"
                            >
                              Word Difficulty :{" "}
                            </label>
                            <div className="col-sm-7">
                              <select
                              id="WordDif"
                                className="form-select"
                                aria-label="Default select example"
                                name="wordDifficulty"
                                value={state.wordDifficulty}
                                onChange={(e) => {
                                  handleChange(e.target.name, e.target.value);
                                }}
                                required
                              >
                                <option value="Easy">Easy</option>
                                <option value="Moderate">Moderate</option>
                                <option value="Hard">Hard</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* start date */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              // for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              From Date :
                            </label>
                            <div className="col-sm-7">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["DatePicker", "DatePicker"]}
                                >
                                  <DatePicker
                                    value={
                                      state.startDate
                                        ? dayjs(dateFormat(state.startDate))
                                        : null
                                    }
                                    onChange={(e) =>
                                      handleChange("startDate", e)
                                    }
                                    format="DD-MM-YYYY"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                        {/* end date */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              className="col-sm-5 col-form-label"
                            >
                              To Date :
                            </label>
                            <div className="col-sm-7">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["DatePicker", "DatePicker"]}
                                  >
                                  <DatePicker
                               
                                    value={
                                      state.endDate
                                        ? dayjs(dateFormat(state.endDate))
                                        : null
                                    }
                                    onChange={(e) => handleChange("endDate", e)}
                                    format="DD-MM-YYYY"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* word theme */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="combo-box-demo"
                              className="col-sm-5 col-form-label"
                            >
                              Word Theme :
                            </label>
                            <div className="col-sm-7">
                              {/* <input
                              type="text"
                              className="form-control"
                              id="inputEmail3"
                              placeholder="Word Theme"
                              name="wordTheme"
                              value={state.wordTheme}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            /> */}
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                // className="form-control"
                                placeholder="Theme of The Words"
                                name="wordTheme"
                                value={state.wordTheme}
                                options={themeContextOptions?.themes.filter(
                                  Boolean
                                )}
                                // sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // label={"Select a theme"}
                                    required
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  handleChange("wordTheme", newValue);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {/* word context */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="WordContext"
                              className="col-sm-5 col-form-label"
                            >
                              Word Context :
                            </label>
                            <div className="col-sm-7">
                              {/* <input
                              type="text"
                              className="form-control"
                              id="inputEmail3"
                              placeholder="Word Context"
                              name="wordContext"
                              value={state.wordContext}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                              required
                            /> */}
                              <Autocomplete
                                disablePortal
                                id="WordContext"
                                // className="form-control"
                                placeholder="Theme of The Words"
                                name="wordContext"
                                value={state.wordContext}
                                options={themeContextOptions?.contexts.filter(
                                  Boolean
                                )}
                                // sx={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    // label={"Select a Context"}
                                    required
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  handleChange("wordContext", newValue); // Pass the name directly
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* Phase */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              for="NoOfPhase"
                              className="col-sm-5 col-form-label"
                            >
                              No Of Phase :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="number"
                                className="form-control"
                                id="NoOfPhase"
                                placeholder="No Of Phase"
                                name="noOfPhase"
                                value={state.noOfPhase}
                                min={1} // Setting the minimum value to 1
                                onChange={(e) =>
                                  handleChange(e.target.name, e.target.value)
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                        {/* alloted time */}
                        <div className="col-lg-4">
                          <div className="form-group row">
                            <label
                              // for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Start Time :
                            </label>
                            <div className="col-sm-7">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={["TimePicker", "TimePicker"]}
                                >
                                  <TimePicker
                                    value={dayjs(state.time, "hh:mm a")}
                                    onChange={(newValue) =>
                                      handleChange("time", newValue)
                                    }
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* conditional block -- depends on no of phase */}
                      {state.phases.length > 0
                        ? state.phases.map((sp, i) => {
                            return (
                              <div className="row" key={i}>
                                {/* Name of phase */}
                                <div className="col-lg-4">
                                  <div className="form-group row">
                                    <label
                                      for="nameOfPhase"
                                      className="col-sm-5 col-form-label"
                                    >
                                      Name of phase :
                                    </label>
                                    <div className="col-sm-7">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="nameOfPhase"
                                        placeholder="Name of phase"
                                        name="phaseName"
                                        value={sp.phaseName}
                                        onChange={(e) => {
                                          handlePhaseChange(
                                            i,
                                            e.target.name,
                                            e.target.value
                                          );
                                        }}
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* No of comp */}
                                <div className="col-lg-4">
                                  <div className="form-group row">
                                    <label
                                      for="noOfCompetation"
                                      className="col-sm-5 col-form-label"
                                    >
                                      No Of Competition :{" "}
                                    </label>
                                    <div className="col-lg-7 col-sm-7 col-12">
                                      <div className="row">
                                        <div className="col-lg-9 col-sm-9 col-9">
                                          <input
                                            type="number"
                                            className="form-control"
                                            id="noOfCompetation"
                                            placeholder="No Of Competition"
                                            name="noOfSubComp"
                                            value={sp.noOfSubComp}
                                            onChange={(e) => {
                                              handlePhaseChange(
                                                i,
                                                e.target.name,
                                                e.target.value
                                              );
                                            }}
                                            required
                                          />
                                        </div>
                                        <div className="col-lg-3 col-sm-3 col-3">
                                          <IconButton
                                            id="close-ham-menu"
                                            aria-label="close"
                                            onClick={() => HandleDeletePhase(i)}
                                            className="delete-red"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/*Delete btn*/}
                              </div>
                            );
                          })
                        : null}
                    </div>

                    <div className="buttaon-lane">
                      <div className="back-name">
                        <button className="back-g" onClick={() => navigate(-1)}>
                          Back
                        </button>
                      </div>
                      <div className="right-edit">
                        <button className="start-g" type="submit">
                          Create Sub Competition
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </TranslatedComponent>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTournament;
