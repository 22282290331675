/* import tytpes */
import {
  TOURNAMENT_GET_SUCCESS,
  TOURNAMENT_GET_ERROR,
  TOURNAMENT_DETAILS_GET_SUCCESS,
  TOURNAMENT_DETAILS_GET_ERROR,
  TOURNAMENT_CREATE_SUCCESS,
  TOURNAMENT_CREATE_ERROR,
  TOURNAMENT_SUBCOMPETITION_CREATE_SUCCESS,
  TOURNAMENT_SUBCOMPETITION_CREATE_ERROR,
  TOURNAMENT_UPDATE_SUCCESS,
  TOURNAMENT_UPDATE_ERROR,
  TOURNAMENT_DELETE_SUCCESS,
  TOURNAMENT_DELETE_ERROR,
  TOURNAMENT_SUCCESS_CLEAR,
  TOURNAMENT_ERROR_CLEAR,
  TOURNAMENT_ACTION_LOADER,
  TOURNAMENT_DETAILS_CLEAR
} from "../Types/tournamentTypes";

import { RESET_REDUX } from "../Types/customTypes";


const initialState = {
  tournament_Loading: true,
  tournament_Data: [],
  tournamentDetails: {},
  tournament_Action_Loader: false,
  tournament_SuccessMessage: null,
  tournament_ErrorMessage: null,
  tournament_Get_Error_Message: null,
  tournament_New_Id: null,
};

export const TournamentReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /* Get */
  if (type === TOURNAMENT_GET_SUCCESS) {
    return {
      ...state,
      // tournament_SuccessMessage: payload.successMessage,
      tournament_Data: payload.data,
      tournament_ErrorMessage: null,
      tournament_Loading: false,
      tournament_Loading: false,
    };
  }
  if (type === TOURNAMENT_GET_ERROR) {
    return {
      ...state,
      tournament_Get_Error_Message: payload.errorMessage,
      tournament_Data: [],
      tournament_SuccessMessage: null,
      tournament_Loading: false,
      tournament_Loading: false,
    };
  }

  /* Get */
  if (type === TOURNAMENT_DETAILS_GET_SUCCESS) {
    return {
      ...state,
      // tournament_SuccessMessage: payload.successMessage,
      tournamentDetails: payload.data,
      tournament_ErrorMessage: null,
      tournament_Loading: false,
    };
  }
  if (type === TOURNAMENT_DETAILS_GET_ERROR) {
    return {
      ...state,
      tournament_Get_Error_Message: payload.errorMessage,
      tournamentDetails: {},
      tournament_SuccessMessage: null,
      tournament_Loading: false,
    };
  }

  /* Create */
  if (type === TOURNAMENT_CREATE_SUCCESS) {
    return {
      ...state,
      tournament_SuccessMessage: payload.successMessage,
      tournament_ErrorMessage: null,
      tournament_Action_Loader: false,
      tournament_New_Id: payload.newCompId,
    };
  }
  if (type === TOURNAMENT_CREATE_ERROR) {
    return {
      ...state,
      tournament_SuccessMessage: null,
      tournament_ErrorMessage: payload.errorMessage,
      tournament_Action_Loader: false,
    };
  }

  /* CREATE SUB COMP */
  if (type === TOURNAMENT_SUBCOMPETITION_CREATE_SUCCESS) {
    return {
      ...state,
      tournament_SuccessMessage: payload.successMessage,
      tournament_ErrorMessage: null,
      tournament_Action_Loader: false,
    };
  }
  if (type === TOURNAMENT_SUBCOMPETITION_CREATE_ERROR) {
    return {
      ...state,
      tournament_SuccessMessage: null,
      tournament_ErrorMessage: payload.errorMessage,
      tournament_Action_Loader: false,
    };
  }

  /* Update*/
  if (type === TOURNAMENT_UPDATE_SUCCESS) {
    return {
      ...state,
      tournament_SuccessMessage: payload.successMessage,
      tournament_ErrorMessage: null,
      tournament_Action_Loader: false,
    };
  }
  if (type === TOURNAMENT_UPDATE_ERROR) {
    return {
      ...state,
      tournament_SuccessMessage: null,
      tournament_ErrorMessage: payload.errorMessage,
      tournament_Action_Loader: false,
    };
  }

    /*Delete*/
    if (type === TOURNAMENT_DELETE_SUCCESS) {
      return {
        ...state,
        tournament_SuccessMessage: payload.successMessage,
        tournament_ErrorMessage: null,
        tournament_Action_Loader: false,
      };
    }
    if (type === TOURNAMENT_DELETE_ERROR) {
      return {
        ...state,
        tournament_SuccessMessage: null,
        tournament_ErrorMessage: payload.errorMessage,
        tournament_Action_Loader: false,
      };
    }
  

  /* Loader */
  if (type === TOURNAMENT_ACTION_LOADER) {
    return {
      ...state,
      tournament_Action_Loader: true,
    };
  }

  /* Msg Clear */
  if (type === TOURNAMENT_SUCCESS_CLEAR) {
    return {
      ...state,
      tournament_SuccessMessage: null,
      tournament_ErrorMessage: null,
      tournament_Action_Loader: false,
      tournament_New_Id: null,
    };
  }
  if (type === TOURNAMENT_ERROR_CLEAR) {
    return {
      ...state,
      tournament_SuccessMessage: null,
      tournament_ErrorMessage: null,
      tournament_Action_Loader: false,
      tournament_Get_Error_Message: null,
    };
  }

  /* Tournament Details Clear */
  if(type === TOURNAMENT_DETAILS_CLEAR){
    return {
      ...state,
      tournamentDetails: {}
    }
  }

    /* Reset State */
    if (type === RESET_REDUX) {
    // console.log("here to clear tournament reducer");

      return {
        ...state,
        tournament_Loading: true,
        tournament_Data: [],
        tournamentDetails: {},
        tournament_Action_Loader: false,
        tournament_SuccessMessage: null,
        tournament_ErrorMessage: null,
        tournament_Get_Error_Message: null,
        tournament_New_Id: null,
      };
    }

  return state;
};
