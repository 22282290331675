import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function Progressbar({ isloading }) {
    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(true);

    useEffect(() => {
        let timer;

        if (isloading) {
            timer = setInterval(() => {
                setProgress((oldProgress) => {
                    const diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 90);
                });
            }, 2000);
        } else {
            setProgress(100);
            
            // adding some delay 
            setTimeout(() => {
                setShowProgress(false);
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };

    }, [isloading]);

    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progress}
                sx={{
                    paddingBottom: "10px",
                    marginBottom: '20px',
                    display: showProgress ? 'block' : 'none'
                }} />
        </Box>
    );
}