import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import { BASE_URL } from "../../../Config/Config";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";
import countryList from "react-select-country-list";

import OneShot_SubComp_Name from "../../../component/CompetitionName/OneShot_SubComp_Name";

import {
  GetCompWiseJury,
  RemoveJury,
  BulkRemoveJury,
} from "../../../Store/Action/juryAction";
import {
  JURY_ACTION_LOADER_START,
  JURY_SUCCESS_MSG_CLEAR,
  JURY_ERROR_MSG_CLEAR,
} from "../../../Store/Types/juryTypes";
import Swal from "sweetalert2";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";

import Modal from "@mui/material/Modal";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AllJury = () => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const params = useParams();
  const { comp_id } = params;

  const options = useMemo(() => countryList().getData(), []);

  const dispatch = useDispatch();
  const { currentUserInfo ,currentUser } = useSelector((state) => state.Auth);
  const {
    Jury_Loading,
    Jury_Action_Loader,
    Jury_Comp_Data,
    Jury_SuccessMessage,
    Jury_ErrorMessage,
    Jury_Get_Error_Message,
  } = useSelector((state) => state.Jury);

  const [rows, setrows] = useState([]);
  const [selectedJury, setSelectedJury] = useState([]);
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(
      GetCompWiseJury({ orgId: currentUserInfo._id, oneshotId: comp_id })
    );
  }, [comp_id]);

  useEffect(() => {
    if (!Jury_Loading && Jury_Comp_Data) {
      const transformedData = Jury_Comp_Data.map((jury, i) => ({
        index: String(i + 1).padStart(2, "0"),
        id: jury._id,
        firstName: jury.firstName || "",
        lastName: jury.lastName || "",
        role: jury.juryRole || "",
        country: jury.country ? jury.country.label : null,
      }));
      setrows(transformedData);
    }
  }, [Jury_Comp_Data, Jury_Loading]);

  /* handle remove jury */
  const handleRemoveJury = (jury) => {
    const title = MsgTranslator("Are you sure?",language)
    const text = MsgTranslator("You won't be able to revert this!",language)
    const btnText = MsgTranslator("Remove from this competition.",language)
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:btnText,
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = {
          orgId: currentUserInfo._id,
          oneShotId: comp_id,
          juryId: jury,
        };
        dispatch(RemoveJury(obj)).then(() => {
          GetCompWiseJury({ orgId: currentUserInfo._id, oneshotId: comp_id });
        });
        // Swal.fire({
        //   title: "Deleted!",
        //   text: "Jury removed successfully.",
        //   icon: "success",
        // });
      }
    });
  };

  /* handle bulk remove jury */
  const handleBulkRemoveJury = (jury) => {
    const title = MsgTranslator("Are you sure?",language)
    const text = MsgTranslator("You won't be able to revert this!",language)
    const confirmButtonText = MsgTranslator("Remove from this competition.",language)
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        const obj = {
          orgId: currentUserInfo._id,
          oneShotId: comp_id,
          juryIdArray: selectedJury,
        };
        dispatch(BulkRemoveJury(obj)).then(() => {
          GetCompWiseJury({ orgId: currentUserInfo._id, oneshotId: comp_id });
        });
        // Swal.fire({
        //   title: "Deleted!",
        //   text: "Jury removed successfully.",
        //   icon: "success",
        // });
      }
    });
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Jury_SuccessMessage) {
      const text = MsgTranslator(Jury_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: JURY_SUCCESS_MSG_CLEAR });
      // navigate(`/all-jury/${comp_id}`)
      dispatch(
        GetCompWiseJury({ orgId: currentUserInfo._id, oneshotId: comp_id })
      );
    }
    if (Jury_ErrorMessage) {
      const text = MsgTranslator(Jury_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: JURY_ERROR_MSG_CLEAR });
    }
    if (Jury_Get_Error_Message) {
      const text = MsgTranslator(Jury_Get_Error_Message,language)
      notify(text, "error");
      dispatch({ type: JURY_ERROR_MSG_CLEAR });
    }
  }, [Jury_SuccessMessage, Jury_ErrorMessage]);

  const columns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: "index",
      headerName: "",
      width: 90,
      renderCell: (params) => (
        <span style={{ fontWeight: "bold" }}>{params.row.index}</span>
      ),
    },
    {
      field: "fullName",
      headerName: MsgTranslator("Name",language),
      // description: "This column has a value getter and is not sortable.",
      // sortable: false,
      width: 200,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "role",
      headerName: MsgTranslator("Role",language),
      width: 200,
      //align: 'center',
    },
    {
      field: "country",
      headerName: MsgTranslator("Country",language),
      width: 200,
      //align: 'center',
    },
    {
      field: "edit",
      headerName:  MsgTranslator("Edit",language),
      width: 200,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => (
        <button
          className="btn-with-pic"
          onClick={() => {
            window.location.href.includes("/phase/")
              ? navigate(
                  `/phase/jury-members-edit/${comp_id}/${params.row.id}`
                )
              : navigate(`/jury-members-edit/${comp_id}/${params.row.id}`);
          }}
        >
          <img
            src={window.location.origin + `/Assets/images/edit-dreen.png`}
            alt="Edit Dreen"
          />
        </button>
      ),
    },
    {
      field: "delete",
      headerName:  MsgTranslator("Delete",language),
      width: 200,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => (
        <button
          className="btn-with-pic"
          onClick={() => {
            handleRemoveJury(params.row.id);
          }}
        >
          <img
            src={window.location.origin + `/Assets/images/delete-green.png `}
            alt="Remove Green"
          />
        </button>
      ),
    },
  ];


  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              className="participants-mobel-option"
              onClick={() => {
                window.location.href.includes("/phase/")
                  ? navigate(`/phase/jury-members-add/${comp_id}`)
                  : navigate(`/jury-members-add/${comp_id}`);
              }}
              >
              <TranslatedComponent>Manual Add Jury</TranslatedComponent>
              <i
                className="fa -regular fa-arrow-right"
                style={{ color: "#000000" }}
              ></i>
            </Box>
            <Box
              className="participants-mobel-option"
              onClick={() => {
                window.location.href.includes("/phase/")
                  ? navigate(`/phase/add-existing-jury/${comp_id}`)
                  : navigate(`/add-existing-jury/${comp_id}`);
                // navigate(`/add-existing-jury/${comp_id}`);
              }}
            >
              <TranslatedComponent>Add Excisting Jury</TranslatedComponent>
              <i
                className="fa -regular fa-arrow-right"
                style={{ color: "#000000" }}
              ></i>
            </Box>
          </Box>
        </Modal>
      </div>

      <div className="wrapper">
        <SidebarLeft />

        <div id="content-thrr">
          {/* <button type="button" id="sidebarCollapse" className="btn btn-info">
            <i className="fas fa-align-left" />
          </button> */}

          {!Jury_Loading ? (
            <div className="main-container-section">
              <Header
                breadcrumb={[
                  {
                    page: MsgTranslator("Dashboard",language),
                    link:
                      currentUserInfo.role === "Org"
                        ? "/org-dashboard"
                        : "/jury-dashboard",
                  },
                  ...(window.location.href.includes("/phase/")
                    ? [
                        {
                          page: MsgTranslator("Phase Manage",language),
                          link: 1,
                        },
                      ] // Include this item only if the condition is met
                    : []), // Empty array if the condition is not met, effectively skipping this item
                  {
                    page: MsgTranslator("Jury List",language),
                    link: 0,
                  },
                ]}
              />
              <div className="upcoming-title">
                <h3>
                    <PageTitle title={"<em>All</em> Jury"} lang={language} />
                  <OneShot_SubComp_Name compId={comp_id} />
                </h3>
              </div>
              <div className="candit-sectied-from">
                {selectedJury && selectedJury.length > 0 && (
                  <div>
                    <button
                      className="btn-with-pic"
                      onClick={() => handleBulkRemoveJury()}
                    >
                      <img
                        src={
                          window.location.origin +
                          `/Assets/images/delete-green.png `
                        }
                        alt="Remove Green"
                      />
                    </button>
                  </div>
                )}
                <div className="table1002 ver2 m-b-110">
                  <Box sx={{ height: 500, width: "100%" }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      // autoHeight={true}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20, 30, 40, 50]}
                      checkboxSelection
                      disableRowSelectionOnClick
                      onRowSelectionModelChange={(newRowSelectionModel) => {
                        // console.log(newRowSelectionModel);
                        setSelectedJury(newRowSelectionModel);
                      }}
                    />
                  </Box>
                </div>
                <TranslatedComponent>
                <div className="buttaon-lane">
                  <div className="back-name">
                    <button className="back-g" onClick={() => navigate(-1)}>
                      Back
                    </button>
                  </div>

                  <div className="right-edit">
                    <button
                      className="back-g"
                      onClick={() =>
                        window.location.href.includes("/phase/")
                          ? navigate(`/phase/all-participant/${comp_id}`)
                          : navigate(`/all-participant/${comp_id}`)
                      }
                    >
                      Participants
                    </button>
                    <button
                      className="back-g"
                      onClick={() =>
                        window.location.href.includes("/phase/")
                          ? navigate(`/phase/organiser-word-list/${comp_id}`)
                          : navigate(`/organiser-word-list/${comp_id}`)
                      }
                    >
                      WordList
                    </button>

                    <button className="start-g" onClick={() => handleOpen()}>
                      Add jury Members
                    </button>
                  </div>
                </div>
                </TranslatedComponent>
              </div>
            </div>
          ) : (
            <ComponentLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default AllJury;
