/* import tytpes */
import {
    JURY_GET_SUCCESS,
    JURY_GET_ERROR,
    JURY_ALL_GET_SUCCESS,
    JURY_ALL_GET_ERROR,
    JURY_DETAILS_GET_SUCCESS,
    JURY_DETAILS_GET_ERROR,
    JURY_ADD_SUCCESS,
    JURY_ADD_ERROR,
    JURY_UPDATE_SUCCESS,
    JURY_UPDATE_ERROR,
    JURY_REMOVE_SUCCESS,
    JURY_REMOVE_ERROR,
    JURY_DELETE_SUCCESS,
    JURY_DELETE_ERROR,
    JURY_CAPABILITY_GET_SUCCESS,
    JURY_CAPABILITY_GET_ERROR,
    GET_JURY_DASHBOARD_ERR,
    GET_JURY_DASHBOARD_SUCC
} from "../Types/juryTypes";


import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";

export const userAuth = (token) => {
    axios.interceptors.request.use(
        (config) => {
            config.headers.authorization = `Bearer ${token}`;
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

export const juryDashboardData = (juryId) => {
    // console.log(juryId);

    return async (dispatch) => {
        try {
            const response = await axios.get(`${SERVER_URL}/jury/view-jury-dashboard?userId=${juryId}`);
            console.log(43, response.data.data);

            dispatch({
                type: GET_JURY_DASHBOARD_SUCC,
                dispatch: ({
                    data: response.data.data,
                    message: response.data.message
                })
            })
        } catch (error) {
            let message = error?.response?.data?.message
            dispatch({
                type: GET_JURY_DASHBOARD_ERR,
                payload: {
                    message: message
                }
            })
        }
    }
};

export const ManuallyJuryAdd = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/jury/manual-add-jury`, data);

            dispatch({
                type: JURY_ADD_SUCCESS,
                payload: {
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_ADD_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const ExistingJuryAdd = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/jury/add-existing-jury`, data);

            dispatch({
                type: JURY_ADD_SUCCESS,
                payload: {
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_ADD_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const GetCompWiseJury = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/jury/get-oneshot-jury`, data);

            dispatch({
                type: JURY_GET_SUCCESS,
                payload: {
                    data: response.data.data,
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_GET_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const GetJuryDetails = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/jury/get-jury-details`, data);

            dispatch({
                type: JURY_DETAILS_GET_SUCCESS,
                payload: {
                    data: response.data.data,
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_DETAILS_GET_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const GetAllJury = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/jury/get-all-jury`, data);

            dispatch({
                type: JURY_ALL_GET_SUCCESS,
                payload: {
                    data: response.data.data,
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_ALL_GET_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const UpdateJury = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.patch(`${SERVER_URL}/jury/update-jury`, data);

            dispatch({
                type: JURY_UPDATE_SUCCESS,
                payload: {
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_UPDATE_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const RemoveJury = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.put(`${SERVER_URL}/jury/remove-jury`, data);

            dispatch({
                type: JURY_REMOVE_SUCCESS,
                payload: {
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_REMOVE_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const BulkRemoveJury = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.put(`${SERVER_URL}/jury/bulk-remove-jury`, data);

            dispatch({
                type: JURY_REMOVE_SUCCESS,
                payload: {
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_REMOVE_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const DeleteJury = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.patch(`${SERVER_URL}/jury/delete-jury`, data);

            dispatch({
                type: JURY_UPDATE_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    data: response.data.data
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_UPDATE_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}

export const GetJuryCapability = (data) => {

    return async (dispatch) => {
        try {
            const response = await axios.post(`${SERVER_URL}/jury/get-jury-responsibility`, data);
            // console.log(response.data.data)
            dispatch({
                type: JURY_CAPABILITY_GET_SUCCESS,
                payload: {
                    data: response.data.data,
                    successMessage: response.data.message
                },
            });

        } catch (error) {
            let data = error?.response?.data?.message

            dispatch({
                type: JURY_CAPABILITY_GET_ERROR,
                payload: {
                    errorMessage: data
                }
            })
        }
    }
}
