import { SERVER_URL, BASE_URL } from "../../Config/Config";

import { jwtDecode } from "jwt-decode";

/* import tytpes */
import {
  REGESTRATION_SUCCESS,
  REGESTRATION_ERROR,
  // REGESTRATION_SUCCESS_CLEAR,
  // REGESTRATION_ERROR_CLEAR,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  // VERIFY_EMAIL_SUCCESS_CLEAR,
  // VERIFY_EMAIL_ERROR_CLEAR,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  // LOGIN_SUCCESS_CLEAR,
  // LOGIN_ERROR_CLEAR,
  PASSWORD_RESET_MAIL_SUCCESS,
  PASSWORD_RESET_MAIL_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  AUTH_SUCCESS_CLEAR,
  AUTH_ERROR_CLEAR,
  SET_CURRENT_USER_SUCCESS,
  SET_CURRENT_USER_ERROR,
} from "../Types/authTypes";

const initialState = {
  authLoading: true,
  authenticate: false,
  authSuccessMessage: null,
  authErrorMessage: null,
  token: null,
  currentUserInfo: null,
  currentUser: null,
  language: "en",
};

const tokenDecode = (token) => {
  const tokenDecoded = jwtDecode(token);
  const expTime = new Date(tokenDecoded.exp * 1000);
  if (new Date() > expTime) {
    localStorage.clear();
    return null;
  }
  return tokenDecoded;
};

const getToken = localStorage.getItem("token");
if (getToken) {
  const getInfo = tokenDecode(getToken);

  if (getInfo) {
    localStorage.setItem("userId", getInfo._id);
    initialState.currentUserInfo = getInfo;
    initialState.authenticate = true;
    initialState.authLoading = false;
  }
}

export const AuthReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /* registration */
  if (type === REGESTRATION_SUCCESS) {
    return {
      ...state,
      authSuccessMessage: payload.successMessage,
      authErrorMessage: null,
    };
  }
  if (type === REGESTRATION_ERROR) {
    return {
      ...state,
      authErrorMessage: payload.errorMessage,
      authSuccessMessage: null,
    };
  }
  // if (type === REGESTRATION_ERROR_CLEAR) {
  //     return {
  //         ...state,
  //         authErrorMessage: null,
  //         // authSuccessMessage: null,
  //     };
  // }
  // if (type === REGESTRATION_SUCCESS_CLEAR) {
  //     return {
  //         ...state,
  //         // authErrorMessage: null,
  //         authSuccessMessage: null,
  //     };
  // }

  /* verify email */
  if (type === VERIFY_EMAIL_SUCCESS) {
    return {
      ...state,
      authSuccessMessage: payload.successMessage,
      authErrorMessage: null,
      token: payload.token,
      authenticate: true,
      currentUserInfo: payload.user,
    };
  }
  if (type === VERIFY_EMAIL_ERROR) {
    return {
      ...state,
      authErrorMessage: payload.errorMessage,
      authSuccessMessage: null,
    };
  }
  // if (type === VERIFY_EMAIL_ERROR_CLEAR) {
  //     return {
  //         ...state,
  //         authErrorMessage: null,
  //         // authSuccessMessage: null,
  //     };
  // }
  // if (type === VERIFY_EMAIL_SUCCESS_CLEAR) {
  //     return {
  //         ...state,
  //         // authErrorMessage: null,
  //         authSuccessMessage: null,
  //     };
  // }

  /* login */
  if (type === LOGIN_SUCCESS) {
    return {
      ...state,
      authSuccessMessage: payload.successMessage,
      authErrorMessage: null,
      token: payload.token,
      authenticate: true,
      currentUser: payload.user,
      currentUserInfo: payload.user,
    };
  }
  if (type === LOGIN_ERROR) {
    return {
      ...state,
      authErrorMessage: payload.errorMessage,
      authSuccessMessage: null,
    };
  }
  // if (type === LOGIN_SUCCESS_CLEAR) {
  //     return {
  //         ...state,
  //         authErrorMessage: null,
  //         // authSuccessMessage: null,
  //     };
  // }
  // if (type === LOGIN_ERROR_CLEAR) {
  //     return {
  //         ...state,
  //         // authErrorMessage: null,
  //         authSuccessMessage: null,
  //     };
  // }

  /* PASSWORD RESET MAIL */
  if (type === PASSWORD_RESET_MAIL_SUCCESS) {
    return {
      ...state,
      authSuccessMessage: payload.successMessage,
      authErrorMessage: null,
    };
  }
  if (type === PASSWORD_RESET_MAIL_ERROR) {
    return {
      ...state,
      authErrorMessage: payload.errorMessage,
      authSuccessMessage: null,
    };
  }

  /* RESET PASSWORD */
  if (type === RESET_PASSWORD_SUCCESS) {
    return {
      ...state,
      authSuccessMessage: payload.successMessage,
      authErrorMessage: null,
    };
  }
  if (type === RESET_PASSWORD_ERROR) {
    return {
      ...state,
      authErrorMessage: payload.errorMessage,
      authSuccessMessage: null,
    };
  }

  /* SUCCESS AND ERROR MSG CLEAR */
  if (type === AUTH_SUCCESS_CLEAR) {
    return {
      ...state,
      // authErrorMessage: null,
      authSuccessMessage: null,
    };
  }

  if (type === AUTH_ERROR_CLEAR) {
    return {
      ...state,
      authErrorMessage: null,
      // authSuccessMessage: null,
    };
  }

  /* SETTING LOGGEDIN USER */
  if (type === SET_CURRENT_USER_SUCCESS) {
    return {
      ...state,
      currentUser: payload.data,
      authenticate: true,
    };
  }

  if (type === "SET_LANG") {
    // console.log(221,payload); 
    return {
      ...state,
      language: payload
    };
  }

  return state;
};
