import React, { useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";

export default function BreadCrumbComponent({ breadcrumb }) {
  // console.log(breadcrumb);
  const navigate = useNavigate();

  function handleClick(event, link) {
    event.preventDefault();
    // console.log(event)
    // console.info("You clicked a breadcrumb.");
    if (typeof link === "number") {
      navigate(-link);
    } else {
      navigate(link);
    }
  }
  // const initialbreadcrumbs = [
  //   <Link
  //     underline="hover"
  //     key="1"
  //     color="inherit"
  //     href="/"
  //     onClick={handleClick}
  //   >
  //     Dashboard
  //   </Link>,
  //   <Link
  //     underline="hover"
  //     key="2"
  //     color="inherit"
  //     href="/material-ui/getting-started/installation/"
  //     onClick={handleClick}
  //   >
  //     Core
  //   </Link>,
  //   <Typography key="3" color="text.primary">
  //     Breadcrumb
  //   </Typography>,
  // ];
  const [breadcrumbState, setBreadCrumbState] = useState([]);

  useEffect(() => {
    if (breadcrumb && breadcrumb.length > 0) {
      let data = [];
      breadcrumb.map((sb, index) => {
        if (index === 0 && sb.length !== index + 1) {
          data.push(
            <Link
              underline="hover"
              key={index + 1}
              color="inherit"
              href={sb.link}
              onClick={(event) => handleClick(event, sb.link)}
            >
              {sb.page}
            </Link>
          );
        } else if (sb.length !== index + 1) {
          data.push(
            <Link
              underline="hover"
              key={index + 1}
              color="inherit"
              href={sb.link}
              onClick={(event) => handleClick(event, sb.link)}
            >
              {sb.page}
            </Link>
          );
        } else if (sb.length === index + 1) {
          data.push(
            <Typography key={index} color="text.primary">
              {sb.page}
            </Typography>
          );
        }
      });
      setBreadCrumbState(data);
    }
  }, [breadcrumb]);

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="medium" />}
        aria-label="breadcrumb"
      >
        {breadcrumbState}
      </Breadcrumbs>
      {/* <Breadcrumbs separator="-" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs> */}
    </Stack>
  );
}
