import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import Keyboard from "../../../component/Keyboard/keyboard";
import CountDownTimer from "../../../component/CountDownTimer/CountDownTimer";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";

import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { AiCall } from "../../../HelperFunctions/AiCall";

import {
  GetCurrentRound,
  Record_Word_Answer,
  GetUnusedWordForCurrentRound,
} from "../../../Store/Action/roundAction";
import {
  ROUND_SUCCESS_MSG_CLEAR,
  ROUND_ERROR_MSG_CLEAR,
} from "../../../Store/Types/roundTypes";

import { GetWordDetails } from "../../../Store/Action/wordAction";
import { WORD_ERROR_CLEAR } from "../../../Store/Types/wordTypes";

import Swal from "sweetalert2";
import OrgView from "../Helping_Pages/OrgView";
import JuryView from "../Helping_Pages/JuryView";

import {
  StartSocketConnection,
  JoinRoom,
  pageChange,
  pageQuery,
  timerStateChange,
  getTimerState,
  curentTextChange,
  getCurrentText,
  reloadPage,
} from "../../../HelperFunctions/Socket/socket.js";
import TranslatedComponent from "../../../translator/TranslatedComponent.jsx";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator.js";
import PageTitle from "../../../component/PageTitle/PageTitle.jsx";

const CandidateResponseOrganizer = () => {
  const { round_id, player_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    round_Loading,
    current_round_Details,
    current_round_unused_word,
    round_SuccessMessage,
    round_ErrorMessage,
    round_Get_Error_Message,
    No_Words_Error_Message,
  } = useSelector((state) => state.Round);

  const { Word_Loading, Words_Details, Word_Get_Error_Message } = useSelector(
    (state) => state.Word
  );

  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);

  const [pageState, setPageState] = useState(false);
  const [pageReload, setPageReload] = useState(false);
  const [language, setLanguage] = useState("fr");

  /* for keyboard */
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (value) => {
    // console.log(value);
    // setInputValue(value);
    curentTextChange(current_round_Details.compId._id, value);
  };

  getCurrentText(({ competitionId, text }) => {
    setInputValue(text);
  });

  pageQuery(({ competitionId, page }) => {
    // console.log({ page, pageReload });
    // console.log(page, `${window.location.pathname}/?reload=1`);
    // console.log(page === `${window.location.pathname}/?reload=1`);

    if (page === `${window.location.pathname}/?reload=1` &&
      current_round_Details !== undefined &&
      Object.keys(current_round_Details).length > 0 &&
      current_round_Details.compId !== undefined &&
      Object.keys(current_round_Details.compId).length > 0 &&
      current_round_Details.compId._id !== undefined) {
      let rediretUrl = page.replace("/?reload=1", "")
      window.location.href = rediretUrl;
      pageChange(current_round_Details.compId._id, `${window.location.pathname.replace("/?reload=1", "")}`);
      setPageReload(false); //::::=> Reset state after triggering the reload
    } else {
      setPageState(page);
    }
  });

  useEffect(() => {
    if (pageState) {
      if (pageState.includes("/?reload=1")) {
        let rediretUrl = pageState.replace("/?reload=1", "")
        window.location.href = rediretUrl;
      }
      navigate(pageState);
    }
  }, [pageState]);

  useEffect(() => {
    if (pageReload) {
      pageChange(current_round_Details.compId._id, `${window.location.pathname}/?reload=1`);
    }
  }, [pageReload, current_round_Details.compId]);

  /* current Player */
  let [curPlayer, setCurPlayer] = useState({});

  useEffect(() => {
    if (
      Object.keys(current_round_Details).length > 0 &&
      current_round_Details.compId &&
      player_id
    ) {

      if (current_round_Details.compId._id !== undefined && current_round_Details.compId._id !== null) {
        JoinRoom(current_round_Details.compId._id, currentUserInfo._id);
        pageChange(
          current_round_Details.compId._id,
          `/candidate-response-organizer/${round_id}/${player_id}`
        );
      }

      const candidateIndex =
        current_round_Details.compId.participants.findIndex(
          (sp) => sp.playerId === player_id
        );
      // console.log(candidateIndex);
      const candidateDetails = current_round_Details?.participants?.filter(
        (sp) => sp.playerId._id === player_id
      );
      // console.log(candidateDetails);
      if (candidateIndex !== -1) {
        setCurPlayer({
          ...candidateDetails[0].playerId,
          index: candidateIndex + 1,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: MsgTranslator("Oops...", language),
          text: MsgTranslator("Candidate not found", language),
        });
      }
    }
  }, [current_round_Details]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  /* get round details wrt round_id */
  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(GetCurrentRound({ roundId: round_id }));
    dispatch(GetUnusedWordForCurrentRound({ roundId: round_id }));
    StartSocketConnection();
  }, [round_id]);

  /* Get unused Words and get word Details */
  useEffect(() => {
    if (current_round_unused_word && current_round_unused_word.length > 0) {
      dispatch(GetWordDetails({ wordId: current_round_unused_word[0] }));
    }
    /*  else if(current_round_unused_word && current_round_unused_word.length === 0){
       Swal.fire("You are out Of words!Please add word to proceed.")
     } */
  }, [current_round_unused_word]);

  /* stop timer -- frop prop */
  const [stopTimer, setStopTimer] = useState(false);
  /* for taking remaing timer */
  const [timeTaken, setTimeTaken] = useState(0);

  /* submit */
  const [submitting, setSubmitting] = useState(false);

  // console.log(inputValue);

  // useEffect(() => {
  if (submitting) {
    const obj = {
      compId: current_round_Details.compId,
      playerId: player_id,
      roundId: round_id,
      wordId: Words_Details._id || "",
      word: Words_Details.word || "",
      answer: inputValue || "",
      speltTime: timeTaken || 0,
    };
    // console.log("**********************150***************", obj.answer);
    dispatch(Record_Word_Answer(obj));
    setSubmitting(false); // Reset submitting state to false again
    setTimeTaken(0);
  }
  // }, [submitting, timeTaken]);

  const handleSubmit = () => {
    // console.log("submit hited");
    setStopTimer(true);
    /* socket call to update stop timer */
    timerStateChange(current_round_Details.compId._id, true);
  };

  getTimerState(({ competitionId, timer }) => {
    // console.log(timer);
    if (timer) {
      setStopTimer(true); //timer is boolean value
    }
  });

  /* accept stop timer response from socket  */

  /* message */
  useEffect(() => {
    if (round_SuccessMessage) {
      dispatch({ type: ROUND_SUCCESS_MSG_CLEAR });
      pageChange(
        current_round_Details.compId._id,
        `/jury-validate/${round_id}/${player_id}`
      );
      navigate(`/jury-validate/${round_id}/${player_id}`);
    }
    if (Word_Get_Error_Message) {
      const text = MsgTranslator(Word_Get_Error_Message, language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: WORD_ERROR_CLEAR });
      });
    }
    if (round_ErrorMessage) {
      const text = MsgTranslator(round_ErrorMessage, language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: ROUND_ERROR_MSG_CLEAR });
      });
    }
    if (No_Words_Error_Message) {
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        // text: No_Words_Error_Message,
        text: MsgTranslator(No_Words_Error_Message, language),
      }).then(() => {
        dispatch({ type: ROUND_ERROR_MSG_CLEAR });
        navigate(`/organiser-word-list/${current_round_Details.compId._id}`);
      });
    }
  }, [
    Word_Get_Error_Message,
    round_SuccessMessage,
    round_ErrorMessage,
    No_Words_Error_Message,
  ]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />
        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em>Candidate</em>{" "}
                  </TranslatedComponent>
                  <TranslatedComponent>Response</TranslatedComponent>
                   <em>Candidate</em> Response
                </span> */}
                <PageTitle
                  title={"<em>Candidate</em> Response"}
                  lang={language}
                />
              </h3>
            </div>
            {current_round_Details &&
              current_round_Details.compId &&
              current_round_Details.compId._id &&
              currentUserInfo.role === "Org" && (
                <OrgView
                  curPlayer={curPlayer}
                  current_round_Details={current_round_Details}
                  Words_Details={Words_Details}
                  handleSubmit={handleSubmit}
                  setTimeTaken={setTimeTaken}
                  setSubmitting={setSubmitting}
                  Word_Loading={Word_Loading}
                  round_Loading={round_Loading}
                  stopTimer={stopTimer}
                  SidebarLeft={SidebarLeft}
                  Header={Header}
                  CountDownTimer={CountDownTimer}
                  competitionId={current_round_Details?.compId?._id}
                  Keyboard={Keyboard}
                  handleInputChange={handleInputChange}
                  ComponentLoader={ComponentLoader}
                  setPageReload={setPageReload}
                />
              )}

            {current_round_Details &&
              current_round_Details.compId &&
              current_round_Details.compId._id &&
              currentUserInfo.role === "Jury" && (
                <JuryView
                  curPlayer={curPlayer}
                  current_round_Details={current_round_Details}
                  Words_Details={Words_Details}
                  handleSubmit={handleSubmit}
                  setTimeTaken={setTimeTaken}
                  setSubmitting={setSubmitting}
                  Word_Loading={Word_Loading}
                  round_Loading={round_Loading}
                  stopTimer={stopTimer}
                  SidebarLeft={SidebarLeft}
                  Header={Header}
                  CountDownTimer={CountDownTimer}
                  competitionId={current_round_Details.compId._id}
                  Keyboard={Keyboard}
                  handleInputChange={handleInputChange}
                  ComponentLoader={ComponentLoader}
                  currentUserInfo={currentUserInfo}
                  setPageReload={setPageReload}
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateResponseOrganizer;
