import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../component/Header/Header";
import { jwtDecode } from "jwt-decode";

import { useSelector, useDispatch } from "react-redux";
import { ResetPasswordAction } from "../../../Store/Action/authAction";
import {
  AUTH_SUCCESS_CLEAR,
  AUTH_ERROR_CLEAR,
} from "../../../Store/Types/authTypes";

import Swal from "sweetalert2";

const ResetPassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    authLoading,
    authenticate,
    currentUser,
    authSuccessMessage,
    authErrorMessage,
  } = useSelector((state) => state.Auth);

  const { user } = useParams();

  /*  base64 -> jwt -> data */
  // console.log(user);
  // const decodedToken = atob(user);
  // const decodedUser = jwtDecode(decodedToken);
  // console.log(decodedUser);

  /* base64 -> data */
  const decodedUser = JSON.parse(atob(user));
  // console.log(decodedUser);

  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();

    if (state.password != state.confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "OUPS....",
        // text: "Your password and confirm password is not same",
        text: "Votre mot de passe et votre mot de passe de confirmation ne sont pas les mêmes",
      }).then(() => {
        // dispatch({ type: LOGIN_ERROR_CLEAR });
      });
    } else if (state.password == state.confirmPassword) {
      /* dispatch call for resetinng your password */
      // console.log(state.password, decodedUser.userId);
      dispatch(
        ResetPasswordAction({
          password: state.password,
          userId: decodedUser.userId,
        })
      );
    }
  };

  useEffect(() => {
    if (authSuccessMessage) {
      // console.log(authSuccessMessage);

      // conditional rendering
      Swal.fire({
        imageUrl: require("./suuporting_assets/reset_password.gif"),
        imageHeight: 200,
        imageAlt: "Réinitialisation du mot de passe réussie",
        // imageAlt: "password reset success full",
      });
      navigate("/");
      dispatch({ type: AUTH_SUCCESS_CLEAR });
    }
    if (authErrorMessage) {
      Swal.fire({
        icon: "error",
        title: "OUPS....",
        text: authErrorMessage,
      }).then(() => {
        dispatch({ type: AUTH_ERROR_CLEAR });
      });
    }
  }, [authSuccessMessage, authErrorMessage]);

  return (
    <>
      <div className="wrapper">
        <div id="content-two">
          {/* <button type="button" id="sidebarCollapse" className="btn btn-info">
            <i className="fas fa-align-left"></i>
          </button> */}

          <div className="main-container-section">
            <Header />
            <div className="upcoming-title">
              <h3>
                {/* <span>Forgot Password</span> */}
                <span>"Mot de passe oublié</span>
              </h3>
            </div>
            <div className="login-section">
              {/* <h3>Forgot Password</h3> */}
              <h3>"Mot de passe oublié</h3>
              <p>Entrez votre nouveau mot de passe</p>
              {/* <p>Enter your new password here</p> */}
              <div className="login-page">
                <div className="form">
                  <form>
                    <div className="form-group row">
                      <label className="text-login col-sm-4">
                        Nouveau mot de passe:
                      </label>
                      {/* <label className="text-login col-sm-4">
                        New password:
                      </label> */}
                      <div className="col-sm-8">
                        <input
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={state.password}
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                      <label className="text-login col-sm-4">
                        Confirmez le nouveau mot de passe:
                        {/* Confirm new password: */}
                      </label>
                      <div className="col-sm-8">
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          value={state.confirmPassword}
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <button onClick={(e) => handleResetPassword(e)}>
                      Réinitialiser
                    </button>
                    {/* <button onClick={(e) => handleResetPassword(e)}>
                      Reset
                    </button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
