/* import tytpes */
import {
  PARTICIPANT_GET_SUCCESS,
  PARTICIPANT_GET_ERROR,
  PARTICIPANT_ALL_GET_SUCCESS,
  PARTICIPANT_ALL_GET_ERROR,
  PARTICIPANT_DETAILS_GET_SUCCESS,
  PARTICIPANT_DETAILS_GET_ERROR,
  PARTICIPANT_ADD_SUCCESS,
  PARTICIPANT_ADD_ERROR,
  PARTICIPANT_UPDATE_SUCCESS,
  PARTICIPANT_UPDATE_ERROR,
} from "../Types/participantTypes";

import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const ManuallyParticiapntAdd = (data) => {
  return async (dispatch) => {
    try {
      console.log(35, ...data);
      const response = await axios.post(`${SERVER_URL}/participant/manual-add-participant`, data);
    //   const response = await fetch(
    //     `${SERVER_URL}/participant/manual-add-participant`,
    //     {
    //       method: "POST",
    //       body: data,
    //     //   headers: {
    //     //     "Content-Type": "multipart/form-data",
    //     //   },
    //     }
    //   );

      dispatch({
        type: PARTICIPANT_ADD_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      }); // const response = await axios.post(`${SERVER_URL}/participant/manual-add-participant`, data);

      // dispatch({
      //     type: PARTICIPANT_ADD_SUCCESS,
      //     payload: {
      //         successMessage: response.data.message
      //     },
      // });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_ADD_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const QuickParticiapntAdd = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/participant/quick-add-participant`,
        data
      );

      dispatch({
        type: PARTICIPANT_ADD_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_ADD_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const ExistingParticiapntAdd = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/participant/add-existing-participant`,
        data
      );

      dispatch({
        type: PARTICIPANT_ADD_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_ADD_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const Excel_Or_Csv_ParticiapntAdd = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/participant/add-participant-csv-excel`,
        data
      );

      dispatch({
        type: PARTICIPANT_ADD_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_ADD_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetCompWisePartcipants = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/participant/get-comp-participant`,
        data
      );

      dispatch({
        type: PARTICIPANT_GET_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetPartcipantDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/participant/get-participant-details`,
        data
      );

      dispatch({
        type: PARTICIPANT_DETAILS_GET_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_DETAILS_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetAllPartcipant = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/participant/get-all-participant`,
        data
      );

      dispatch({
        type: PARTICIPANT_ALL_GET_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_ALL_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetAllWinner_PartcipantInPreviousPhase = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/participant/prev-phase-winner-participant`,
        data
      );

      dispatch({
        type: PARTICIPANT_ALL_GET_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_ALL_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const UpdatePartcipants = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/participant/update-participant`,
        data
      );

      dispatch({
        type: PARTICIPANT_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};
/*soft delete */
export const DeletePartcipants = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/participant/delete-participant`,
        data
      );

      dispatch({
        type: PARTICIPANT_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const RemovePartcipants = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/participant/remove-participant`,
        data
      );

      dispatch({
        type: PARTICIPANT_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const BulkRemovePartcipants = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/participant/bulk-remove-participant`,
        data
      );

      dispatch({
        type: PARTICIPANT_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PARTICIPANT_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};
