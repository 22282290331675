import React from 'react'

const JuryViewRoleWise = ({heading,data}) => {
  return (
    <div className='elephant-numver-box'>
      <div className='elephant-text-word'>
      {/* {heading} */}
      {data}
      </div>
      </div>
  )
}

export default JuryViewRoleWise