/* import tytpes */
import {
  COMP_START_DATA_GET_SUCCESS,
  COMP_START_DATA_GET_ERROR,
  COMP_START_DATA_UPDATE_SUCCESS,
  COMP_START_DATA_UPDATE_ERROR,
  COMP_RESULT_GET_SUCCESS,
  COMP_RESULT_GET_ERROR,
  COMP_START_SUCCESS_MSG_CLEAR,
  COMP_START_ERROR_MSG_CLEAR,
  COMP_START_ACTION_LOADER_START,
} from "../Types/competitionTypes";

import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const GetCompStartData = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/competition/start-competations`,
        data
      );

      dispatch({
        type: COMP_START_DATA_GET_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: COMP_START_DATA_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetCompStartDataAttendanceUpdate = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/competition/start-competations/attendance`,
        data
      );

      dispatch({
        type: COMP_START_DATA_UPDATE_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: COMP_START_DATA_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetCompStartData_All_AttendanceUpdate = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/competition/start-competations/all-attendance`,
        data
      );

      dispatch({
        type: COMP_START_DATA_UPDATE_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: COMP_START_DATA_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetCompResult = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/competition/view-comp-result`,
        data
      );

      dispatch({
        type: COMP_RESULT_GET_SUCCESS,
        payload: {
          data: response.data.data,
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;
      dispatch({
        type: COMP_RESULT_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};
