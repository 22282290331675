import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import common_en from './en/common.json'
import common_fr from './fr/common.json'

// const selectedLanguage = localStorage.getItem('s_lang') || 'en';
i18n
    // .use(Backend)
    .use(initReactI18next)
    .init({
        // fallbackLng: selectedLanguage,
        debug: true,
        lng: 'en',
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        react: {
            useSuspense: false
        },
        resources: {
            en: {
               common: common_en,
            },
            fr: {
                common: common_fr
            }
        }
    });
    

export default i18n;