import React, { useEffect } from "react";
import { SERVER_URL, BASE_URL } from "../../Config/Config";

/* import tytpes */
import {
  ONESHOT_GET_SUCCESS,
  ONESHOT_GET_ERROR,
  ONESHOT_DETAILS_GET_SUCCESS,
  ONESHOT_DETAILS_GET_ERROR,
  ONESHOT_CREATE_SUCCESS,
  ONESHOT_CREATE_ERROR,
  ONESHOT_UPDATE_SUCCESS,
  ONESHOT_UPDATE_ERROR,
  ONESHOT_DELETE_SUCCESS,
  ONESHOT_DELETE_ERROR,
  ONESHOT_SUCCESS_CLEAR,
  ONESHOT_ERROR_CLEAR,
  ONESHOT_ACTION_LOADER,
  CLEAR_ONE_SHOT_DETAILS,
} from "../Types/oneShotTypes";

import { RESET_REDUX } from "../Types/customTypes";

const initialState = {
  OneShot_Loading: true,
  OneShot_Data: [],
  OneShotDetails: {}, 
  OneShot_Action_Loader: false,
  OneShot_SuccessMessage: null,
  OneShot_ErrorMessage: null,
  OneShot_Get_Error_Message: null,
  OneShot_New_Id: null,
};

export const OneShotReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /* Get */
  if (type === ONESHOT_GET_SUCCESS) {
    return {
      ...state,
      // OneShot_SuccessMessage: payload.successMessage,
      OneShot_Data: payload.data,
      OneShot_ErrorMessage: null,
      OneShot_Loading: false,
    };
  }
  if (type === ONESHOT_GET_ERROR) {
    return {
      ...state,
      OneShot_Get_Error_Message: payload.errorMessage,
      OneShot_Data: [],
      OneShot_SuccessMessage: null,
      OneShot_Loading: false,
    };
  }

  /* Get */
  if (type === ONESHOT_DETAILS_GET_SUCCESS) {
    return {
      ...state,
      // OneShot_SuccessMessage: payload.successMessage,
      OneShotDetails: payload?.data,
      OneShot_ErrorMessage: null,
      OneShot_Loading: false,
    };
  }
  if (type === ONESHOT_DETAILS_GET_ERROR) {
    return {
      ...state,
      OneShot_Get_Error_Message: payload.errorMessage,
      OneShotDetails: {},
      OneShot_SuccessMessage: null,
      OneShot_Loading: false,
    };
  }

  /* Create */
  if (type === ONESHOT_CREATE_SUCCESS) {
    return {
      ...state,
      OneShot_SuccessMessage: payload.successMessage,
      OneShot_ErrorMessage: null,
      OneShot_Action_Loader: false,
      OneShot_New_Id: payload.newCompId,
    };
  }
  if (type === ONESHOT_CREATE_ERROR) {
    return {
      ...state,
      OneShot_SuccessMessage: null,
      OneShot_ErrorMessage: payload.errorMessage,
      OneShot_Action_Loader: false,
    };
  }

  /* Update*/
  if (type === ONESHOT_UPDATE_SUCCESS) {
    return {
      ...state,
      OneShot_SuccessMessage: payload.successMessage,
      OneShot_ErrorMessage: null,
      OneShot_Action_Loader: false,
    };
  }
  if (type === ONESHOT_UPDATE_ERROR) {
    return {
      ...state,
      OneShot_SuccessMessage: null,
      OneShot_ErrorMessage: payload.errorMessage,
      OneShot_Action_Loader: false,
    };
  }

  /* Delete*/
  if (type === ONESHOT_DELETE_SUCCESS) {
    return {
      ...state,
      OneShot_SuccessMessage: payload.successMessage,
      OneShot_ErrorMessage: null,
      OneShot_Action_Loader: false,
    };
  }
  if (type === ONESHOT_DELETE_ERROR) {
    return {
      ...state,
      OneShot_SuccessMessage: null,
      OneShot_ErrorMessage: payload.errorMessage,
      OneShot_Action_Loader: false,
    };
  }

  /* Loader */
  if (type === ONESHOT_ACTION_LOADER) {
    return {
      ...state,
      OneShot_Action_Loader: true,
    };
  }

  /* Msg Clear */
  if (type === ONESHOT_SUCCESS_CLEAR) {
    return {
      ...state,
      OneShot_SuccessMessage: null,
      OneShot_ErrorMessage: null,
      OneShot_Action_Loader: false,
      OneShot_New_Id: null,
    };
  }
  if (type === ONESHOT_ERROR_CLEAR) {
    return {
      ...state,
      OneShot_SuccessMessage: null,
      OneShot_ErrorMessage: null,
      OneShot_Action_Loader: false,
      OneShot_Get_Error_Message: null,
    };
  }

  /* One Shot Details clear*/
  if (type === CLEAR_ONE_SHOT_DETAILS) {
    return {
      ...state,
      OneShotDetails: {},
    };
  }

    /* Reset State */
    if (type === RESET_REDUX) {
      return {
        ...state,
        OneShot_Loading: true,
        OneShot_Data: [],
        OneShotDetails: {},
        OneShot_Action_Loader: false,
        OneShot_SuccessMessage: null,
        OneShot_ErrorMessage: null,
        OneShot_Get_Error_Message: null,
        OneShot_New_Id: null,
      };
    }

  return state;
};
