import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import PlayerSerialNumber from "../../../component/PlayerSerialNumber/PlayerSerialNumber";

import {
  GetCurrentRound,
  Record_Word_Answer,
  Update_Result_for_round,
} from "../../../Store/Action/roundAction";
import {
  ROUND_SUCCESS_MSG_CLEAR,
  ROUND_ERROR_MSG_CLEAR,
} from "../../../Store/Types/roundTypes";

import { GetWordDetails } from "../../../Store/Action/wordAction";
import { WORD_ERROR_CLEAR } from "../../../Store/Types/wordTypes";

import Swal from "sweetalert2";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";

import {
  StartSocketConnection,
  JoinRoom,
  pageChange,
  pageQuery,
} from "../../../HelperFunctions/Socket/socket.js";
import TranslatedComponent from "../../../translator/TranslatedComponent.jsx";
import PageTitle from "../../../component/PageTitle/PageTitle.jsx";
import { Language } from "@mui/icons-material";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator.js";

const CandidateResponse = () => {
  const { round_id, player_id, word_id } = useParams();
  const { currentUserInfo , currentUser} = useSelector((state) => state.Auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    round_Loading,
    current_round_Details,
    round_Action_Loader,
    round_SuccessMessage,
    round_ErrorMessage,
  } = useSelector((state) => state.Round);

  const { Word_Loading, Words_Details, Word_Get_Error_Message } = useSelector(
    (state) => state.Word
  );

  /* Current candidate state */
  const [candidate, setCandidate] = useState({});
  /* Trget Word */
  const [targetWord, setTargetWord] = useState({});
  const [language, setLanguage] = useState("fr");

  /* get current candidate index */
  const CurrentCandidateIndex = (player_id) => {
    const PlayerSerial = current_round_Details?.participants.findIndex(
      (sp) => sp.playerId._id.toString() === player_id
    );
    // console.log(
    //   current_round_Details?.participants[PlayerSerial + 1]?.playerId?._id
    // );
    if (
      PlayerSerial != -1 &&
      current_round_Details?.participants.length > PlayerSerial + 1
    ) {
      const nextPlayerId =
        current_round_Details?.participants[PlayerSerial + 1]?.playerId?._id;
        
      return {
        curSerial: PlayerSerial + 1,
        nextSerial: nextPlayerId,
      };
    } else if (
      PlayerSerial != -1 &&
      current_round_Details?.participants.length === PlayerSerial + 1
    ) {
      return { curSerial: PlayerSerial + 1, nextSerial: null };
    } else {
      return {
        curSerial: null,
        nextSerial: null,
      };
    }
  };

  /* get round details wrt round_id */
  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(GetCurrentRound({ roundId: round_id }));
    StartSocketConnection();
  }, [round_id]);

  pageQuery(({ competitionId, page }) => {
    navigate(page);
  });

  useEffect(() => {
    dispatch(
      GetWordDetails({
        wordId: word_id,
      })
    );
  }, [word_id]);

  useEffect(() => {
    if (
      Object.keys(current_round_Details).length > 0 &&
      current_round_Details.participants &&
      current_round_Details.participants.length > 0
    ) {
      // JoinRoom(current_round_Details.compId._id, currentUserInfo._id);
      // pageChange(
      //   current_round_Details.compId._id,
      //   `/candidate-response/${round_id}/${player_id}/${word_id}`
      // );

      let targetParticipant = current_round_Details.participants.find(
        (participant) => participant.playerId._id.toString() === player_id
      );
      setCandidate(targetParticipant);

      if (targetParticipant && targetParticipant.words) {
        const wordStatus = targetParticipant.words.find(
          (sw) => sw.wordId === word_id
        );
        setTargetWord(wordStatus || null);
      }
    }
  }, [current_round_Details]);

  /* handle submit */
  const handleSubmit = () => {
    // console.log("submitting...");
    let { curSerial, nextSerial } = CurrentCandidateIndex(player_id);
    // console.log("0.1", curSerial, nextSerial);
    const obj = {
      compId: current_round_Details.compId._id,
      playerId: player_id,
      wordId: targetWord.wordId,
      roundId: round_id,
      isCompleted: nextSerial ? false : true,
    };
    // console.log("0.2");
    // console.log(obj);
    // console.log(1);
    dispatch(Update_Result_for_round(obj));

    if (nextSerial) {
      navigate(`/candidate-details/${round_id}`);
      pageChange(
        current_round_Details.compId._id,
        `/candidate-details/${round_id}`
      );
    } else {
      navigate(`/summary-selection/${round_id}`);
      pageChange(
        current_round_Details.compId._id,
        `/summary-selection/${round_id}`
      );
    }
  };

  /* message */
  useEffect(() => {
    if (round_SuccessMessage) {
      dispatch({ type: ROUND_SUCCESS_MSG_CLEAR });
    }
    if (round_ErrorMessage) {
      const text = MsgTranslator(round_ErrorMessage,language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: ROUND_ERROR_MSG_CLEAR });
      });
    }
  }, [round_SuccessMessage, round_ErrorMessage]);

  // console.log(current_round_Details);
  // console.log(candidate);
  // console.log(targetWord);
  // console.log(Words_Details);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                  <PageTitle title={"<em>Candidate</em> Response"} lang={language} />
                {/* <span>
                  <em>Candidate</em> Response
                </span> */}
              </h3>
            </div>

            <div className="start-section">
              <div className="container-fluid">
                <div className="row">
                  <TranslatedComponent>
                  <div className="col-lg-12">
                    <div id="txt"></div>
                    <div className="candit-sectied-from">
                      <div className="profile-picture">
                        <img
                          src={
                            candidate?.playerId?.img ? candidate?.playerId?.img : window.location.origin +
                            "/Assets/images/blank-profile-picture.webp"
                          }
                          alt="Testimonial"
                        />
                      </div>
                      <div className="caption-one">
                        <div className="capap-t">
                          <ul>
                            <li>
                              <span>Candidate Name :</span>{" "}
                              <p>
                                {candidate?.playerId?.firstName ||
                                  "" + " " + candidate?.playerId?.lastName ||
                                  ""}
                              </p>
                            </li>
                            <li>
                              <span>Competition Type :</span>{" "}
                              {current_round_Details?.compId?.tournamentId ? (
                                <p>Tournament</p>
                              ) : (
                                <p>One Shot</p>
                              )}
                            </li>
                            <li>
                              <span>Current Round :</span>{" "}
                              <p>{current_round_Details?.name || ""}</p>
                            </li>
                            <li>
                              <span>Spelling Duration :</span>{" "}
                              <p>
                                {targetWord?.speltTime
                                  ? `${targetWord?.speltTime} sec`
                                  : ""}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="caption-two">
                        <div className="capap-t">
                          <ul>
                            <li>
                              <span>Candidate Number :</span>{" "}
                              <p>
                                {(
                                  <PlayerSerialNumber
                                    compId={current_round_Details?.compId?._id}
                                    playerId={candidate?.playerId?._id}
                                  />
                                ) || ""}
                              </p>
                            </li>
                            <li>
                              <span>Word Given :</span>{" "}
                              <p> {targetWord?.word || ""} </p>
                            </li>
                            <li>
                              <span>The Word Spelt :</span>{" "}
                              <p className="green-i">
                                {targetWord?.answer || ""}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="dt-one">
                        {targetWord?.result ? (
                          <p className="corret-t">Correct</p>
                        ) : (
                          <p className="incorret-t">Incorrect</p>
                        )}
                      </div>
                    </div>
                    <div className="candit-sectied-from-two">
                      <h3>Word Details</h3>
                      <ul className="candidat-pt-kky">
                        <li>
                          <span>Origin:</span>{" "}
                          <p>{Words_Details?.origin || ""}</p>
                        </li>
                        <li>
                          <span>Nature :</span>{" "}
                          <p>{Words_Details?.nature || ""} </p>
                        </li>
                        <li>
                          <span>Definition :</span>{" "}
                          <p>{Words_Details?.definition || ""}</p>
                        </li>
                        <li>
                          <span>Illustrative Sentences :</span>{" "}
                          <p>{Words_Details?.illustrativeSentence || ""}</p>
                        </li>
                        <li>
                          <span>Homonyms :</span>{" "}
                          <p>{Words_Details?.homonyms || ""}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="buttaon-lane">
                    <div className="back-name">
                      <button className="back-g" onClick={() => navigate(-1)}>
                        Back
                      </button>
                    </div>
                  </div>
                  </TranslatedComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateResponse;
