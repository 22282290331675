/* import tytpes */
import {
  ORG_DB_DATA_GET_SUCCESS,
  ORG_DB_DATA_GET_ERROR,
} from "../Types/OrgDashBoardTypes";

import { RESET_REDUX } from "../Types/customTypes";

const initialState = {
  Org_Db_Loading: true,
  Org_Db_Data: [],
  Org_Db_SuccessMessage: null,
  Org_Db_ErrorMessage: null,
};

export const OrganizationDashboardReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /* Get */
  if (type === ORG_DB_DATA_GET_SUCCESS) {
    return {
      ...state,
      // Org_Db_SuccessMessage: payload.successMessage,
      Org_Db_Data: payload.data,
      Org_Db_ErrorMessage: null,
      Org_Db_Loading: false,
    };
  }
  if (type === ORG_DB_DATA_GET_ERROR) {
    return {
      ...state,
      Org_Db_ErrorMessage: payload.errorMessage,
      Org_Db_Data: [],
      Org_Db_SuccessMessage: null,
      Org_Db_Loading: false,
    };
  }

  /* Reset State */
  if (type === RESET_REDUX) {
    return {
      ...state,
      Org_Db_Loading: true,
      Org_Db_Data: [],
      Org_Db_SuccessMessage: null,
      Org_Db_ErrorMessage: null,
    };
  }
  return state;
};
