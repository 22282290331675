/* import tytpes */
import {
  JURY_GET_SUCCESS,
  JURY_GET_ERROR,
  JURY_ALL_GET_SUCCESS,
  JURY_ALL_GET_ERROR,
  JURY_DETAILS_GET_SUCCESS,
  JURY_DETAILS_GET_ERROR,
  JURY_ADD_SUCCESS,
  JURY_ADD_ERROR,
  JURY_UPDATE_SUCCESS,
  JURY_UPDATE_ERROR,
  JURY_REMOVE_SUCCESS,
  JURY_REMOVE_ERROR,
  JURY_DELETE_SUCCESS,
  JURY_DELETE_ERROR,
  JURY_SUCCESS_MSG_CLEAR,
  JURY_ERROR_MSG_CLEAR,
  JURY_ACTION_LOADER_START,
  JURY_CAPABILITY_GET_SUCCESS,
  JURY_CAPABILITY_GET_ERROR,
  GET_JURY_DASHBOARD_SUCC,
  CLEAR_JURY_DASHBOARD_SUCC,
} from "../Types/juryTypes";

import { RESET_REDUX } from "../Types/customTypes";

const initialState = {
  Jury_Loading: true,
  Jury_All_Data: [],
  Jury_Comp_Data: [],
  Jury_Dashboard_Data: [],
  Jury_Details: {},
  Jury_Capabilities: [],
  Jury_Action_Loader: false,
  Jury_SuccessMessage: null,
  Jury_ErrorMessage: null,
  Jury_Get_Error_Message: null,
};

export const JuryReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /* Get */
  if (type === JURY_GET_SUCCESS) {
    return {
      ...state,
      // Jury_SuccessMessage: payload.successMessage,
      Jury_Comp_Data: payload.data,
      Jury_ErrorMessage: null,
      Jury_Loading: false,
      Jury_Action_Loader: false,
    };
  }
  if (type === JURY_GET_ERROR) {
    return {
      ...state,
      Jury_Get_Error_Message: payload.errorMessage,
      Jury_Comp_Data: [],
      Jury_SuccessMessage: null,
      Jury_Loading: false,
      Jury_Action_Loader: false,
    };
  }

  /* Get */
  if (type === JURY_ALL_GET_SUCCESS) {
    return {
      ...state,
      // Jury_SuccessMessage: payload.successMessage,
      Jury_All_Data: payload.data,
      Jury_ErrorMessage: null,
      Jury_Loading: false,
      Jury_Action_Loader: false,
    };
  }
  if (type === JURY_ALL_GET_ERROR) {
    return {
      ...state,
      Jury_Get_Error_Message: payload.errorMessage,
      Jury_All_Data: [],
      Jury_SuccessMessage: null,
      Jury_Loading: false,
      Jury_Action_Loader: false,
    };
  }

  /* Get capability */
  if (type === JURY_CAPABILITY_GET_SUCCESS) {
    return {
      ...state,
      Jury_Capabilities: payload.data,
      Jury_ErrorMessage: null,
      Jury_Loading: false,
      Jury_Action_Loader: false,
    };
  }
  if (type === JURY_CAPABILITY_GET_ERROR) {
    return {
      ...state,
      Jury_Get_Error_Message: payload.errorMessage,
      Jury_Capabilities: [],
      Jury_SuccessMessage: null,
      Jury_Loading: false,
      Jury_Action_Loader: false,
    };
  }

  /* Get Jury details*/
  if (type === JURY_DETAILS_GET_SUCCESS) {
    return {
      ...state,
      Jury_Details: payload.data,
      Jury_ErrorMessage: null,
      Jury_Loading: false,
      Jury_Action_Loader: false,
    };
  }
  if (type === JURY_DETAILS_GET_ERROR) {
    return {
      ...state,
      Jury_Get_Error_Message: payload.errorMessage,
      Jury_Details: {},
      Jury_SuccessMessage: null,
      Jury_Loading: false,
      Jury_Action_Loader: false,
    };
  }

  /* Create */
  if (type === JURY_ADD_SUCCESS) {
    return {
      ...state,
      Jury_SuccessMessage: payload.successMessage,
      Jury_ErrorMessage: null,
      Jury_Action_Loader: false,
    };
  }
  if (type === JURY_ADD_ERROR) {
    return {
      ...state,
      Jury_SuccessMessage: null,
      Jury_ErrorMessage: payload.errorMessage,
      Jury_Action_Loader: false,
    };
  }

  /* Update*/
  if (
    type === JURY_UPDATE_SUCCESS ||
    type === JURY_REMOVE_SUCCESS ||
    type === JURY_DELETE_SUCCESS
  ) {
    return {
      ...state,
      Jury_SuccessMessage: payload.successMessage,
      Jury_ErrorMessage: null,
      Jury_Action_Loader: false,
    };
  }
  if (
    type === JURY_UPDATE_ERROR ||
    type === JURY_REMOVE_ERROR ||
    type === JURY_DELETE_ERROR
  ) {
    return {
      ...state,
      Jury_SuccessMessage: null,
      Jury_ErrorMessage: payload.errorMessage,
      Jury_Action_Loader: false,
    };
  }

  /* Loader */
  if (type === JURY_ACTION_LOADER_START) {
    return {
      ...state,
      Jury_Action_Loader: true,
    };
  }

  /* Msg Clear */
  if (type === JURY_SUCCESS_MSG_CLEAR) {
    return {
      ...state,
      Jury_SuccessMessage: null,
      Jury_ErrorMessage: null,
      Jury_Action_Loader: false,
    };
  }
  
  if (type === JURY_ERROR_MSG_CLEAR) {
    return {
      ...state,
      Jury_SuccessMessage: null,
      Jury_ErrorMessage: null,
      Jury_Action_Loader: false,
      Jury_Get_Error_Message: null,
    };
  }

  /* Reset State */
  if (type === RESET_REDUX) {
    return {
      ...state,
      Jury_Loading: true,
      Jury_All_Data: [],
      Jury_Comp_Data: [],
      Jury_Details: {},
      Jury_Action_Loader: false,
      Jury_SuccessMessage: null,
      Jury_ErrorMessage: null,
      Jury_Get_Error_Message: null,
    };
  }



  // -------------------[JURY DASHBOARD]-----------------

  if (type === GET_JURY_DASHBOARD_SUCC) {
    
    return {
      ...state,
      Jury_Dashboard_Data: action.dispatch.data,
      Jury_SuccessMessage: action.dispatch.message,
    }
  }

  if(type === CLEAR_JURY_DASHBOARD_SUCC){
    return {
      ...state,
      Jury_SuccessMessage:null
    }
  }



  return state;
};
