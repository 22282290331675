import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { GetTournamentDetails } from "../../Store/Action/tournamentAction";
import {
  TOURNAMENT_SUCCESS_CLEAR,
  TOURNAMENT_ERROR_CLEAR,
} from "../../Store/Types/tournamentTypes";

const TournamentName = ({ compId }) => {
  const dispatch = useDispatch();
  const { currentUserInfo } = useSelector((state) => state.Auth);
  const {
    Tournament_Loading,
    tournamentDetails,
    Tournament_SuccessMessage,
    Tournament_ErrorMessage,
    Tournament_Get_Error_Message,
  } = useSelector((state) => state.Tournament);

  const [name, setName] = useState("");

  useEffect(() => {
    dispatch(
      GetTournamentDetails({ orgId: currentUserInfo._id, tournamentId: compId })
    );
  }, []);

  useEffect(() => {
    if (tournamentDetails && Object.keys(tournamentDetails).length > 0) {
      // console.log(tournamentDetails);
      setName(tournamentDetails.tournamentName);
    }
  }, [tournamentDetails]);

  return <em>{name}</em>;
};

export default TournamentName;
