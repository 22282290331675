import React from "react";

/* import types */
import {
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USER_SUCCESS_CLEAR,
  USER_ERROR_CLEAR,
} from "../Types/userTypes";

import { RESET_REDUX } from "../Types/customTypes";

const initialState = {
  userLoading: true,
  userSuccessMessage: null,
  userErrorMessage: null,
  userDelSuccessMessage: null,
  userDelErrorMessage: null,
};

export const UserReducer = (state = initialState, action) => {
  const { payload, type } = action;
  /*User Update */
  if (type === USER_UPDATE_SUCCESS) {
    return {
      ...state,
      userSuccessMessage: payload.successMessage,
      userErrorMessage: null,
    };
  }
  if (type === USER_UPDATE_ERROR) {
    return {
      ...state,
      userErrorMessage: payload.errorMessage,
      userSuccessMessage: null,
    };
  }

  /*User Delete */
  if (type === USER_DELETE_SUCCESS) {
    return {
      ...state,
      userDelSuccessMessage: payload.successMessage,
      userDelErrorMessage: null,
    };
  }
  if (type === USER_DELETE_ERROR) {
    return {
      ...state,
      userDelErrorMessage: payload.errorMessage,
      userDelSuccessMessage: null,
    };
  }

  /* SUCCESS AND ERROR MSG CLEAR */
  if (type === USER_SUCCESS_CLEAR) {
    return {
      ...state,
      // userErrorMessage: null,
      userSuccessMessage: null,
      userDelSuccessMessage: null,
    };
  }

  if (type === USER_ERROR_CLEAR) {
    return {
      ...state,
      userErrorMessage: null,
      // userSuccessMessage: null,
      userDelErrorMessage: null,
    };
  }

  /* Reset State */
  if (type === RESET_REDUX) {
    // console.log("here to clear user reducer");

    return {
      ...state,
      userLoading: true,
      userSuccessMessage: null,
      userErrorMessage: null,
      userDelSuccessMessage: null,
      userDelErrorMessage: null,
    };
  }

  return state;
};
