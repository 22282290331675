import React, { useEffect, useState } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import SidebarRight from "../../../component/Sideber/SidebarRight";
import Header from "../../../component/Header/Header";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import { juryDashboardData } from "../../../Store/Action/juryAction";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import TranslatedComponent from "../../../translator/TranslatedComponent";

import { leaveAllRoom } from "../../../HelperFunctions/Socket/socket";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { Language } from "@mui/icons-material";

const JuryDashboard = () => {
  const { Jury_Dashboard_Data } = useSelector((data) => data.Jury);

  const [products, setProducts] = useState([]);
  // console.log(Jury_Dashboard_Data)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const [language, setLanguage] = useState("fr");

  const { t } = useTranslation();

  const StartCompatation = (compId) => {
    // notify(compId, "success")
    navigate(`/one-shot-details/${compId}`);
  };

  useEffect(() => {
    dispatch(juryDashboardData(currentUserInfo._id));
    leaveAllRoom(currentUserInfo._id);
  }, [currentUserInfo._id]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);
  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />
            <TranslatedComponent>
              <div className="upcoming-title">
                <h3>
                  {/* <span>
                    <em> </em>
                  </span> */}
                  <PageTitle title={"<em>Jury</em> Dashboard"} lang={language} />
                </h3>
              </div>
              <div className="upcoming-box scrollable">
                <div>
                  <table className="table gfg">
                    <thead>
                      <tr className="green-line-3">
                        <th />
                        <th>
                          <span className="icon-name-st">
                            Competition Involved
                          </span>
                        </th>
                        <th>
                          <span className="icon-name-type">Type</span>
                        </th>
                        <th>
                          <span className="icon-name-date">Date</span>
                        </th>
                        <th>
                          <span className="icon-name-time">Time</span>
                        </th>
                        <th>
                          <span className="icon-name-time">Role</span>
                        </th>
                        <th>
                          <span className="icon-name-time">Organizer Name</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {Jury_Dashboard_Data &&
                        Jury_Dashboard_Data.length > 0 &&
                        Jury_Dashboard_Data.map((d_data, inx) => (
                          <tr className="geeks" key={inx}>
                            <td>{inx + 1}</td>
                            <td>{d_data.name}</td>
                            <td
                              onClick={() => StartCompatation(d_data._id)}
                              style={{ cursor: "pointer" }}
                            >
                              {d_data.tournamentId === null
                                ? "One Short"
                                : "Tournament"}
                            </td>
                            <td>{d_data.date}</td>
                            <td>{d_data.time}</td>
                            <td>{d_data.juryRole}</td>
                            <td>{d_data.orgName}</td>
                            <td>
                              {" "}
                              {d_data.isStarted
                                ? !d_data.isCompleted && (
                                  <button
                                    className="resume"
                                    style={{ width: "100%" }}
                                    onClick={() =>
                                      navigate(
                                        `/start-competition/${d_data._id}`
                                      )
                                    }
                                  >
                                    Join
                                  </button>
                                )
                                : !d_data.isCompleted && (
                                  <button className="resume" style={{ width: "100%" }}>
                                    Not Started
                                  </button>
                                )}
                              {d_data.isCompleted && (
                                <button className="resume" style={{ width: "100%" }}>Completed</button>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </TranslatedComponent>
            {/* <div className="buttaon-lane">
                <button className="back-g" onClick={() => navigate(-1)}>
                  Back
                </button>
                <button className="start-g">Start Competation</button>
              </div> */}
          </div>
        </div>
        <SidebarRight />
      </div>
    </>
  );
};

export default JuryDashboard;
