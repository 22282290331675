import React from "react";
import { useSelector, useDispatch } from "react-redux";
import TranslatedComponent from "../../translator/TranslatedComponent";

const SidebarRight = () => {
  const { currentUser, currentUserInfo } = useSelector((state) => state.Auth);

  return (
    <>
      <TranslatedComponent>
        <div className="right-site">
          <div className="organizer-st">
            {currentUserInfo.role === "Org" ? (
              <h2>
                <img
                  src={window.location.origin + "/Assets/images/organizer.png"}
                  alt="Organizer"
                />
                Organizer
              </h2>
            ) : (
              <h2>
                <img
                  src={window.location.origin + "/Assets/images/organizer.png"}
                  alt="Jury"
                />
                Jury
              </h2>
            )}
          </div>
          <div className="name-section">
            <h2>Name</h2>
            {currentUser ? (
              <p> {currentUser.firstName + " " + currentUser.lastName}</p>
            ) : null}
          </div>
          <div className="name-section">
            <h2>Country</h2>
            {currentUser && currentUser.country ? (
              <p> {currentUser.country.label}</p>
            ) : null}
          </div>
          <div className="name-section">
            <h2>Email</h2>
            {currentUser ? <p> {currentUser.email}</p> : null}
          </div>
        </div>
      </TranslatedComponent>
    </>
  );
};

export default SidebarRight;
