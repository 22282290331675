/* import tytpes */
import {
  PARTICIPANT_GET_SUCCESS,
  PARTICIPANT_GET_ERROR,
  PARTICIPANT_ALL_GET_SUCCESS,
  PARTICIPANT_ALL_GET_ERROR,
  PARTICIPANT_DETAILS_GET_SUCCESS,
  PARTICIPANT_DETAILS_GET_ERROR,
  PARTICIPANT_ADD_SUCCESS,
  PARTICIPANT_ADD_ERROR,
  PARTICIPANT_UPDATE_SUCCESS,
  PARTICIPANT_UPDATE_ERROR,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
  PARTICIPANT_ACTION_LOADER_START,
} from "../Types/participantTypes";

import { RESET_REDUX } from "../Types/customTypes";

const initialState = {
  Participant_Loading: true,
  Participants_All_Data: [],
  Participants_Data: [],
  participant_Details: {},
  Participant_Action_Loader: false,
  Participant_SuccessMessage: null,
  Participant_ErrorMessage: null,
  Participant_Get_Error_Message: null,
};

export const ParticipantReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /* Get */
  if (type === PARTICIPANT_GET_SUCCESS) {
    return {
      ...state,
      // Participant_SuccessMessage: payload.successMessage,
      Participants_Data: payload.data,
      Participant_ErrorMessage: null,
      Participant_Loading: false,
      Participant_Action_Loader: false,
    };
  }
  if (type === PARTICIPANT_GET_ERROR) {
    return {
      ...state,
      Participant_Get_Error_Message: payload.errorMessage,
      Participants_Data: [],
      Participant_SuccessMessage: null,
      Participant_Loading: false,
      Participant_Action_Loader: false,
    };
  }

  /* Get */
  if (type === PARTICIPANT_ALL_GET_SUCCESS) {
    return {
      ...state,
      // Participant_SuccessMessage: payload.successMessage,
      Participants_All_Data: payload.data,
      Participant_ErrorMessage: null,
      Participant_Loading: false,
      Participant_Action_Loader: false,
    };
  }
  if (type === PARTICIPANT_ALL_GET_ERROR) {
    return {
      ...state,
      Participant_Get_Error_Message: payload.errorMessage,
      Participants_All_Data: [],
      Participant_SuccessMessage: null,
      Participant_Loading: false,
      Participant_Action_Loader: false,
    };
  }

  /* Get Participant details*/
  if (type === PARTICIPANT_DETAILS_GET_SUCCESS) {
    return {
      ...state,
      // Participant_SuccessMessage: payload.successMessage,
      participant_Details: payload.data,
      Participant_ErrorMessage: null,
      Participant_Loading: false,
      Participant_Action_Loader: false,
    };
  }
  if (type === PARTICIPANT_DETAILS_GET_ERROR) {
    return {
      ...state,
      Participant_Get_Error_Message: payload.errorMessage,
      participant_Details: {},
      Participant_SuccessMessage: null,
      Participant_Loading: false,
      Participant_Action_Loader: false,
    };
  }

  /* Create */
  if (type === PARTICIPANT_ADD_SUCCESS) {
    return {
      ...state,
      Participant_SuccessMessage: payload.successMessage,
      Participant_ErrorMessage: null,
      Participant_Action_Loader: false,
    };
  }
  if (type === PARTICIPANT_ADD_ERROR) {
    return {
      ...state,
      Participant_SuccessMessage: null,
      Participant_ErrorMessage: payload.errorMessage,
      Participant_Action_Loader: false,
    };
  }

  /* Update*/
  if (type === PARTICIPANT_UPDATE_SUCCESS) {
    return {
      ...state,
      Participant_SuccessMessage: payload.successMessage,
      Participant_ErrorMessage: null,
      Participant_Action_Loader: false,
    };
  }
  if (type === PARTICIPANT_UPDATE_ERROR) {
    return {
      ...state,
      Participant_SuccessMessage: null,
      Participant_ErrorMessage: payload.errorMessage,
      Participant_Action_Loader: false,
    };
  }

  /* Loader */
  if (type === PARTICIPANT_ACTION_LOADER_START) {
    return {
      ...state,
      Participant_Action_Loader: true,
    };
  }

  /* Msg Clear */
  if (type === PARTICIPANT_SUCCESS_MSG_CLEAR) {
    return {
      ...state,
      Participant_SuccessMessage: null,
      Participant_ErrorMessage: null,
      Participant_Action_Loader: false,
    };
  }
  if (type === PARTICIPANT_ERROR_MSG_CLEAR) {
    return {
      ...state,
      Participant_SuccessMessage: null,
      Participant_ErrorMessage: null,
      Participant_Action_Loader: false,
      Participant_Get_Error_Message: null,
    };
  }

  /* Reset State */
  if (type === RESET_REDUX) {
    return {
      ...state,
      Participant_Loading: true,
      Participants_All_Data: [],
      Participants_Data: [],
      participant_Details: {},
      Participant_Action_Loader: false,
      Participant_SuccessMessage: null,
      Participant_ErrorMessage: null,
      Participant_Get_Error_Message: null,
    };
  }

  return state;
};
