/* import types */
import {
  REGESTRATION_SUCCESS,
  REGESTRATION_ERROR,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  PASSWORD_RESET_MAIL_SUCCESS,
  PASSWORD_RESET_MAIL_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SET_CURRENT_USER_SUCCESS,
  SET_CURRENT_USER_ERROR,
} from "../Types/authTypes";

import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const Registration = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/user/user-registration`,
        data
      );

      dispatch({
        type: REGESTRATION_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: REGESTRATION_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const EmailVerification = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/user/verify-email`,
        data
      );

      // console.log(62, response);

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user._id);

      dispatch({
        type: VERIFY_EMAIL_SUCCESS,
        payload: {
          successMessage: response.data.message,
          token: response.data.token,
          user: response.data.user,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: VERIFY_EMAIL_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const LoginAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${SERVER_URL}/user/user-login`, data);

      if (response.data.user.role === "Admin") {
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            errorMessage: "Login with valid credentials",
          },
        });
      } else {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.user._id);

        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            successMessage: response.data.message,
            token: response.data.token,
            user: response.data.user,
          },
        });
      }
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: LOGIN_ERROR,
        payload: {
          errorMessage: data, 
        },
      });
    }
  };
};

export const GetUserDetails = (UserId) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${SERVER_URL}/user/user-details`, {
        userId: UserId,
      });

      dispatch({
        type: SET_CURRENT_USER_SUCCESS,
        payload: {
          data: response.data.data,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: SET_CURRENT_USER_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const ResetPasswordEmailSent = (email) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${SERVER_URL}/user/forget-password`, {
        email: email,
      });
      // console.log(121,response.data.message);
      dispatch({
        type: PASSWORD_RESET_MAIL_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: PASSWORD_RESET_MAIL_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const ResetPasswordAction = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(`${SERVER_URL}/user/reset-password`, {
        userId: data.userId,
        password: data.password,
      });

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};
