export const calculateAge = (dobString) => {

  if(dobString){
    // Parse the input string to obtain day, month, and year values
    const [day, month, year] = dobString.split('-').map(Number);

    // Create Date objects for the current date and the birthdate
    const currentDate = new Date();
    const birthDate = new Date(year, month - 1, day);

    // Calculate the difference in years and months
    const yearDiff = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    // Adjust the age if the current date has not reached the birthdate in the current year
    const adjustedYearDiff = currentDate < new Date(currentDate.getFullYear(), birthDate.getMonth(), birthDate.getDate())
      ? yearDiff - 1
      : yearDiff;

    const adjustedMonthDiff = monthDiff < 0 ? 12 + monthDiff : monthDiff;

    // Construct the age string
    const ageString = `${adjustedYearDiff} ${adjustedYearDiff === 1 ? 'year' : 'years'} ${adjustedMonthDiff} ${adjustedMonthDiff === 1 ? 'month' : 'months'}`;

    return ageString;
  }
}

