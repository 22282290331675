import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import {
  GetCompStartData,
  GetCompStartDataAttendanceUpdate,
  GetCompStartData_All_AttendanceUpdate,
} from "../../../Store/Action/competitionAction";
import {
  COMP_START_SUCCESS_MSG_CLEAR,
  COMP_START_ERROR_MSG_CLEAR,
} from "../../../Store/Types/competitionTypes";

import Swal from "sweetalert2";
import {
  StartSocketConnection,
  JoinRoom,
  pageChange,
  pageQuery,
} from "../../../HelperFunctions/Socket/socket.js";

import { GetJuryCapability } from "../../../Store/Action/juryAction";
import TranslatedComponent from "../../../translator/TranslatedComponent.jsx";
import PageTitle from "../../../component/PageTitle/PageTitle.jsx";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator.js";

const canStartCompetition = (compData) => {
  const { playerArray, juryData } = compData;

  // Check if more than 1 participant has attendance set to true
  const participantsWithAttendance = playerArray.filter(
    (player) => player.attendance === true
  );

  // Check if at least 1 jury member has attendance set to true
  const juryWithAttendance = juryData.filter(
    (jury) => jury.attendance === true
  );

  // Condition to check if competition can start
  return participantsWithAttendance.length > 1 && juryWithAttendance.length > 0;
};

const StartCompetition = () => {
  const { comp_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    comp_start_Loading,
    comp_start_Data,
    comp_start_ErrorMessage,
    comp_start_SuccessMessage,
  } = useSelector((state) => state.CompStart);

  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);

  // const { Jury_Loading, Jury_Action_Loader, Jury_Capabilities } = useSelector(
  //   (state) => state.Jury
  // );

  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    if (
      Object.keys(currentUserInfo).length > 0 &&
      currentUserInfo.role === "Jury"
    ) {
      dispatch(
        GetJuryCapability({
          juryId: currentUserInfo._id,
          compId: comp_id,
        })
      );
      // pageChange(
      //   comp_id,
      //   `/start-competition-round/${comp_id}`
      // );
    }
  }, [comp_id]);

  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(GetCompStartData({ compId: comp_id }));
    StartSocketConnection();
    if (comp_id && currentUserInfo && Object.keys(currentUserInfo).length > 0) {
      JoinRoom(comp_id, currentUserInfo._id);
    }
  }, [comp_id]);

  pageQuery(({ page }) => {
    navigate(page);
  });

  const AttendanceUpdate = (type, id, attendance) => {
    if (type === "Player") {
      const data = {
        compId: comp_id,
        playerId: id,
        attendance: attendance,
      };
      dispatch(GetCompStartDataAttendanceUpdate(data));
    } else if (type === "Jury") {
      const data = {
        compId: comp_id,
        juryId: id,
        attendance: attendance,
      };
      dispatch(GetCompStartDataAttendanceUpdate(data));
    }
  };

  const All_AttendanceUpdate = (type, attendance) => {
    if (type === "Player") {
      const playerArray = comp_start_Data.playerArray.map((sp) => sp._id);
      const data = {
        compId: comp_id,
        playerArray: playerArray,
        attendance: attendance,
      };
      dispatch(GetCompStartData_All_AttendanceUpdate(data));
    } else if (type === "Jury") {
      const juryArray = comp_start_Data.juryData.map((sj) => sj.juryId);
      const data = {
        compId: comp_id,
        juryArray: juryArray,
        attendance: attendance,
      };
      dispatch(GetCompStartData_All_AttendanceUpdate(data));
    }
  };

  const startCompHandler = () => {
    if (canStartCompetition(comp_start_Data)) {
      pageChange(comp_id, `/start-competition-round/${comp_id}`);
      navigate(`/start-competition-round/${comp_id}`);
    } else {
      // console.log("Competition cannot start!");
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: MsgTranslator(
          "A competition requires at least 1 jury member and more than 1 participant to start.",
          language
        ),
      });
    }
  };

  useEffect(() => {
    if (comp_start_SuccessMessage) {
      dispatch(GetCompStartData({ compId: comp_id })).then(() => {
        dispatch({ type: COMP_START_SUCCESS_MSG_CLEAR });
      });
    }
    if (comp_start_ErrorMessage) {
      const text = MsgTranslator(comp_start_ErrorMessage, language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: COMP_START_ERROR_MSG_CLEAR });
      });
    }
  }, [comp_start_SuccessMessage, comp_start_ErrorMessage]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />
        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em></em>{' '}
                  </TranslatedComponent>
                  <TranslatedComponent></TranslatedComponent>
                </span> */}
                <PageTitle
                  title={"<em>Start</em> Competition"}
                  lang={language}
                />
              </h3>
            </div>

            <div className="start-section">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4">
                    {!comp_start_Loading ? (
                      <div className="competition">
                        <TranslatedComponent>
                          {/* <TranslatedComponent></TranslatedComponent> */}
                          <ul>
                            <li>
                              <span>Competition Name :</span>{" "}
                              <p>{comp_start_Data?.competitionName || ""} </p>
                            </li>
                            <li>
                              <span>Competition Type :</span>{" "}
                              <p>
                                {comp_start_Data?.tournamentId
                                  ? "Tournament"
                                  : "OneShot"}
                              </p>
                            </li>
                            <li>
                              <span>Date :</span>{" "}
                              <p>{comp_start_Data?.date || ""}</p>
                            </li>
                            <li>
                              <span>Time :</span>{" "}
                              <p>{comp_start_Data?.time || ""}</p>
                            </li>
                          </ul>
                        </TranslatedComponent>
                      </div>
                    ) : (
                      <ComponentLoader />
                    )}
                  </div>
                  <div className="col-lg-8">
                    {currentUserInfo.role === "Org" && (
                      <div className="cap-tion-point">
                        <TranslatedComponent>
                          <h3>Number of candidates</h3>
                          <button
                            className="present-poi"
                            onClick={() => All_AttendanceUpdate("Player")}
                          >
                            All present
                          </button>
                        </TranslatedComponent>
                      </div>
                    )}

                    {/* {currentUserInfo.role === "Org" ||
                    (currentUserInfo.role === "Jury" &&
                      Jury_Capabilities.includes("View list of Candidate")) ? ( */}
                    <TranslatedComponent>
                      <div className="candited-st">
                        <table className="table gfg table-responsive">
                          <thead>
                            <tr className="green-line-3">
                              <th />
                              <th>
                                <span>Name</span>
                              </th>
                              <th>
                                <span>Grade</span>
                              </th>
                              <th>
                                <span>Gender</span>
                              </th>
                              <th />
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {!comp_start_Loading &&
                            comp_start_Data.playerArray &&
                            comp_start_Data.playerArray.length > 0 ? (
                              comp_start_Data.playerArray.map((sp, index) => {
                                return (
                                  <tr className="geeks" key={sp._id}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {sp?.firstName ||
                                        "" + " " + sp?.lastname ||
                                        ""}
                                    </td>
                                    <td>{sp?.grade || ""}</td>
                                    <td>{sp?.gender || ""}</td>
                                    <td>
                                      {" "}
                                      {currentUserInfo.role === "Org" && (
                                        <button
                                          className={
                                            sp.attendance
                                              ? "present-poi-active"
                                              : "present-poi"
                                          }
                                          onClick={() =>
                                            AttendanceUpdate(
                                              "Player",
                                              sp._id,
                                              true
                                            )
                                          }
                                        >
                                          Present
                                        </button>
                                      )}
                                    </td>
                                    <td>
                                      {" "}
                                      {currentUserInfo.role === "Org" && (
                                        <button
                                          className={
                                            !sp.attendance
                                              ? "absent-poi-active"
                                              : "absent-poi"
                                          }
                                          onClick={() =>
                                            AttendanceUpdate(
                                              "Player",
                                              sp._id,
                                              false
                                            )
                                          }
                                        >
                                          Absent
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <ComponentLoader />
                            )}
                          </tbody>
                        </table>
                      </div>
                    </TranslatedComponent>
                    {/* ) : null} */}

                    {/* {currentUserInfo.role === "Org" && ( */}
                    <>
                      <TranslatedComponent>
                        <div className="cap-tion-point">
                          <h3>Jury members</h3>
                          {currentUserInfo.role === "Org" && (
                            <button
                              className="present-poi"
                              onClick={() => All_AttendanceUpdate("Jury")}
                            >
                              All present
                            </button>
                          )}
                        </div>
                        <div className="candited-st">
                          <table className="table gfg table-responsive">
                            <thead>
                              <tr className="green-line-3">
                                <th />
                                <th>
                                  <span>Name</span>
                                </th>
                                <th>
                                  <span>Role</span>
                                </th>
                                <th />
                                <th />
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              {!comp_start_Loading &&
                              comp_start_Data.juryData.length >= 0 ? (
                                comp_start_Data.juryData.map((sj, index) => {
                                  return (
                                    <tr className="geeks" key={sj.juryId}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {sj.firstName ||
                                          "" + " " + sj.lastName ||
                                          ""}
                                      </td>
                                      <td> {sj?.role || ""}</td>
                                      <td>
                                        {" "}
                                        {currentUserInfo.role === "Org" && (
                                          <button
                                            className={
                                              sj.attendance
                                                ? "present-poi-active"
                                                : "present-poi"
                                            }
                                            onClick={() =>
                                              AttendanceUpdate(
                                                "Jury",
                                                sj.juryId,
                                                true
                                              )
                                            }
                                          >
                                            Present
                                          </button>
                                        )}
                                      </td>
                                      <td>
                                        {" "}
                                        {currentUserInfo.role === "Org" && (
                                          <button
                                            className={
                                              !sj.attendance
                                                ? "absent-poi-active"
                                                : "absent-poi"
                                            }
                                            onClick={() =>
                                              AttendanceUpdate(
                                                "Jury",
                                                sj.juryId,
                                                false
                                              )
                                            }
                                          >
                                            Absent
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <ComponentLoader />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </TranslatedComponent>
                    </>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>

            {currentUserInfo.role === "Org" && (
              <div className="buttaon-lane">
                <TranslatedComponent>
                  <button className="back-g" onClick={() => navigate(-1)}>
                    Back
                  </button>
                  <div className="right-edit">
                    <button
                      className="start-g"
                      onClick={() => startCompHandler()}
                    >
                      Start Competition
                    </button>
                  </div>
                </TranslatedComponent>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StartCompetition;
