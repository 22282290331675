import React, { useEffect } from 'react';
import { GetUserDetails } from '../Store/Action/authAction';
import { useSelector, useDispatch } from "react-redux";

function TranslatedComponent({ children }) {

  const { currentUser } = useSelector((state) => state.Auth);

  const currLanguage = currentUser ? currentUser?.language : "en"

  // // Recursively traverse children and translate them
  const common = require(`../translator/${currLanguage}/common.json`)

  const translateChildren = (child) => {

    if (typeof child === 'string') {
      // If the child is a string, translate it

      // const translation = child.split('.').reduce((obj, key) => obj[key], common.translation);
      const translation = child.split('.').reduce((obj, key) => {
        // Ensure obj is not undefined before accessing its property
        if (obj && obj[key]) {
          return obj[key];
        } else {
          // Return undefined if the property is not found
          return undefined;
        }
      }, common.translation);

      return translation || child;

    } else if (React.isValidElement(child)) {
      // If the child is a React element, recursively translate its children
      return React.cloneElement(child, {
        children: React.Children.map(child.props.children, translateChildren),
      });
    } else {
      // Otherwise, return the child as is
      return child;
    }
  };

  // Translate all children
  const translatedChildren = React.Children.map(children, translateChildren);
  return <>{translatedChildren}</>;
}

export default TranslatedComponent;