import React, { useState, useEffect, useRef } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import SidebarRight from "../../../component/Sideber/SidebarRight";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetOneShotDetails } from "../../../Store/Action/oneShotAction";
import OneShot_SubComp_Name from "../../../component/CompetitionName/OneShot_SubComp_Name";

import { GetCompWord, DeleteWord } from "../../../Store/Action/wordAction";
import {
  // WORD_ACTION_LOADER_START,
  WORD_SUCCESS_CLEAR,
  WORD_ERROR_CLEAR,
} from "../../../Store/Types/wordTypes";
import { CLEAR_ONE_SHOT_DETAILS } from "../../../Store/Types/oneShotTypes";
// import Swal from "sweetalert2";
// import CircularProgress from "@mui/material/CircularProgress";
import { MagnifyingGlass } from "react-loader-spinner";
import { notify } from "../../../component/ToastMsg/ToastMsg";
// import { BASE_URL } from "../../../Config/Config";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Swal from "sweetalert2";
import Warning from "../Helping_Pages/Warning";
import Progressbar from "./Progressbar";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OrganiserWordList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { comp_id } = params;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showCustomMsg, setShowCustomMsg] = useState(false);
  const [startFetchingWordList, setStartFetchingWordList] = useState(true);
  const [compWords, setCompWords] = useState([]);

  const dispatch = useDispatch();
  const { currentUserInfo ,currentUser } = useSelector((state) => state.Auth);
  const {
    Word_Loading,
    // Word_Action_Loader,
    // Words_All_Data,
    Words_Comp_Data,
    Word_SuccessMessage,
    Word_ErrorMessage,
    Word_Get_Error_Message,
  } = useSelector((state) => state.Word);

  const {
    // OneShot_Loading,
    // OneShot_Data,
    OneShotDetails,
    // OneShot_SuccessMessage,
    // OneShot_ErrorMessage,
    // OneShot_Get_Error_Message,
  } = useSelector((state) => state.OneShot);

  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    setCompWords(Words_Comp_Data);
  }, [Words_Comp_Data]);

  useEffect(() => {
    dispatch(
      GetOneShotDetails({ orgId: currentUserInfo._id, oneshotId: comp_id })
    );
  }, [comp_id]);

  // console.log(Word_Loading);
  useEffect(() => {
    if (
      OneShotDetails &&
      Object.keys(OneShotDetails).length > 0 &&
      OneShotDetails.participants &&
      OneShotDetails.participants.length < OneShotDetails.totalParticipants
    ) {
      const text = MsgTranslator("Please select all participants",language)
      Swal.fire({
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(({ isConfirmed, isDenied, isDismissed, value }) => {
        if (isConfirmed) {
          window.location.href.includes("/phase/")
            ? navigate(`/phase/all-participant/${comp_id}`)
            : navigate(`/all-participant/${comp_id}`);
          // navigate(`/all-participant/${comp_id}`);
        } else {
          setShowCustomMsg(true);
        }
      });
    } else if (
      OneShotDetails &&
      Object.keys(OneShotDetails).length > 0 &&
      OneShotDetails.themeOfWords &&
      OneShotDetails.wordContext &&
      OneShotDetails.totalParticipants &&
      OneShotDetails.participants &&
      OneShotDetails.participants.length > 0
    ) {
      const val = localStorage.getItem(`${comp_id}`) ?? false;
      // console.log("value of generating word before calling ---------->", val);
      if (val !== "true") {
        localStorage.setItem(`${comp_id}`, true);
        // console.log(
        //   "********************** here we are generating word ***********************"
        // );
        setStartFetchingWordList(true);
        dispatch(
          GetCompWord({
            createdBy: currentUserInfo._id,
            oneShotId: comp_id,
            theme: OneShotDetails.themeOfWords,
            context: OneShotDetails.wordContext,
            numberOfWordsNeeded: OneShotDetails.participants.length * (currentUser?.word_PerCandidate || 5),
          })
        ).then(() => {
          dispatch({ type: CLEAR_ONE_SHOT_DETAILS });
        });
        setShowCustomMsg(false);
      }
    }
  }, [OneShotDetails]);

  const handleViewWordDetails = (swId) => {
    let Ids = compWords.map((sw) => sw._id);
    localStorage.setItem("IdArray", JSON.stringify(Ids));
    window.location.href.includes("/phase/")
      ? navigate(`/phase/word-details/${swId}`)
      : navigate(`/word-details/${swId}`);
    // navigate(`/word-details/${swId}`);
  };

  const handleDeleteWord = (word) => {
    dispatch(DeleteWord({ createdBy: currentUserInfo._id, wordId: word })).then(
      () => {
        setCompWords((prev) => prev.filter((sw) => sw._id !== word));
      }
    );
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Word_SuccessMessage) {
      // console.log(Word_SuccessMessage);
      localStorage.setItem(`${comp_id}`, false);

      setStartFetchingWordList(false);
      dispatch({ type: WORD_SUCCESS_CLEAR });
    }
    if (Word_ErrorMessage) {
      const text = MsgTranslator(Word_ErrorMessage,language);
      notify(text, "error");
      dispatch({ type: WORD_ERROR_CLEAR });
    }
    if (Word_Get_Error_Message) {
      localStorage.setItem(`${comp_id}`, false);
      
      const text = MsgTranslator(Word_Get_Error_Message,language);
      setStartFetchingWordList(false);
      notify(text, "error");
      dispatch({ type: WORD_ERROR_CLEAR });
    }
  }, [Word_SuccessMessage, Word_ErrorMessage, Word_Get_Error_Message]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
          <TranslatedComponent>
            <Box
              className="participants-mobel-option"
              onClick={() => {
                window.location.href.includes("/phase/")
                  ? navigate(`/phase/jury-members-add/${comp_id}`)
                  : navigate(`/jury-members-add/${comp_id}`);
              }}
              // navigate(`/jury-members-add/${comp_id}`);
            >
              Manual Add Jury{" "}
              <i
                className="fa -regular fa-arrow-right"
                style={{ color: "#000000" }}
              ></i>
            </Box>
            <Box
              className="participants-mobel-option"
              onClick={() => {
                window.location.href.includes("/phase/")
                  ? navigate(`/phase/add-existing-jury/${comp_id}`)
                  : navigate(`/add-existing-jury/${comp_id}`);
                // navigate(`/add-existing-jury/${comp_id}`);
              }}
            >
              Add Existing Jury{" "}
              <i
                className="fa -regular fa-arrow-right"
                style={{ color: "#000000" }}
              ></i>
            </Box>
            </TranslatedComponent>
          </Box>
        </Modal>
      </div>

      <div className="wrapper">
        <SidebarLeft />
        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard",language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                ...(window.location.href.includes("/phase/")
                  ? [
                      {
                        page: MsgTranslator("Phase Manage",language),
                        link: 1,
                      },
                    ]
                  : []), // conditional
                {
                  page: MsgTranslator("Word List",language),
                  link: 0,
                },
              ]}
            />
            {showCustomMsg ? (
              <Warning
                msg="Please select all participants"
                link={`/all-participant/${comp_id}`}
              />
            ) : (
              <>
                <div className="upcoming-title">
                  <h3>
                    <span>
                      {/* <TranslatedComponent>
                        <em></em>{" "}
                      </TranslatedComponent>
                      <TranslatedComponent></TranslatedComponent>{" "} */}
                      <PageTitle title={"<em>Organiser</em> WordList"} lang={language} />
                      <OneShot_SubComp_Name compId={comp_id} />
                    </span>
                  </h3>
                </div>
                <div className="candit-sectied-from">
                  <div>
                    <Progressbar isloading={Word_Loading} />
                  </div>
                  <div className="slimScroll">
                    <div className="word-list-section">
                      {!Word_Loading ? (
                        compWords && compWords.length > 0 ? (
                          compWords.map((sw, index) => {
                            return (
                              <div key={sw._id}>
                                <TranslatedComponent>
                                <div className="word-top">
                                  <ul>
                                    <li>
                                      <img
                                        src={
                                          window.location.origin +
                                          `/Assets/images/word-list.png`
                                        }
                                        alt="wrd"
                                      />
                                      Words
                                    </li>
                                    <li>
                                      <img
                                        src={
                                          window.location.origin +
                                          `/Assets/images/word-list.png`
                                        }
                                        alt="wrd"
                                      />
                                      Details
                                    </li>
                                  </ul>
                                </div>
                                <div className="word-listbox" key={sw._id}>
                                  <div className="number-red">{index + 1}</div>
                                  <div className="row">
                                    <div className="ett-box">
                                      <button
                                        onClick={() =>
                                          handleViewWordDetails(sw._id)
                                        }
                                      >
                                        <img
                                          src={
                                            window.location.origin +
                                            `/Assets/images/edit-dreen.png`
                                          }
                                          alt="Edit Dreen"
                                        />
                                      </button>
                                      <button
                                        onClick={() => handleDeleteWord(sw._id)}
                                      >
                                        <img
                                          src={
                                            window.location.origin +
                                            `/Assets/images/delete-green.png`
                                          }
                                          alt="Delete Green"
                                        />
                                      </button>
                                    </div>
                                    <div className="col-lg-2">
                                      <div className="word-name-mobile">
                                        <img
                                          src={
                                            window.location.origin +
                                            `/Assets/images/word-list.png`
                                          }
                                          alt="word"
                                        />
                                        Words
                                      </div>
                                      <p className="name-p">{sw.word}</p>
                                    </div>
                                    <div className="col-lg-4">
                                      <div className="details-name-mobile">
                                        <img
                                          src={
                                            window.location.origin +
                                            `/Assets/images/word-list.png`
                                          }
                                          alt="word"
                                        />
                                        Details
                                      </div>
                                      <div className="word-iu">
                                        <ul>
                                          <li>
                                            <span>Word :</span> {sw.word}
                                          </li>
                                          <li>
                                            <span>Word phonetics :</span>{" "}
                                            {sw.word_phonetics}
                                          </li>
                                          <li>
                                            <span>Spelling Alternative :</span>{" "}
                                            {sw.spellingAlternatives}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="word-iu">
                                        <ul>
                                          <li>
                                            <span>Nature :</span> {sw.nature}
                                          </li>
                                          <li>
                                            <span>Origin :</span> {sw.origin}
                                          </li>
                                          <li>
                                            <span>Gender :</span> {sw.gender}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <div className="col-lg-3">
                                      <div className="word-iu">
                                        <ul>
                                          <li>
                                            <span>Homonyms :</span>{" "}
                                            {sw.homonyms}
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </TranslatedComponent>
                              </div>
                            );
                          })
                        ) : (
                          <TranslatedComponent>
                          <div style={{ border: "1px solid green" }}>
                            <h2 style={{ textAlign: "center" }}>Sorry!</h2>
                            <p style={{ textAlign: "center" }}>
                              There is no word in word bank that match your
                              requirements(theme and context)
                            </p>
                            <p style={{ textAlign: "center" }}>
                              Please Add new word or contact support for Ai
                              generated word
                            </p>
                          </div>
                          </TranslatedComponent>
                        )
                      ) : (
                        <div className="candit-sectied-from">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100vh",
                            }}
                          >
                            <MagnifyingGlass
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="magnifying-glass-loading"
                              wrapperStyle={{ display: "inline-block" }}
                              wrapperClass="magnifying-glass-wrapper"
                              glassColor="#c0efff"
                              color="#e15b64"
                            />
                            <TranslatedComponent>
                            <h6>
                              We are currently working on it you can do other
                              work and comback later
                            </h6>
                            </TranslatedComponent>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="buttaon-lane">
                  <TranslatedComponent>
                    <div className="back-name">
                      <button className="back-g" onClick={() => navigate(-1)}>
                        Back
                      </button>
                    </div>

                    <div className="right-edit">
                      <button
                        className="back-g"
                        onClick={() =>
                          window.location.href.includes("/phase/")
                            ? navigate(`/phase/all-participant/${comp_id}`)
                            : navigate(`/all-participant/${comp_id}`)
                        }
                      >
                        Participants
                      </button>
                      {!startFetchingWordList && (
                        <button
                          className="start-g"
                          onClick={() =>
                            window.location.href.includes("/phase/")
                              ? navigate(`/phase/add-word-details/${comp_id}`)
                              : navigate(`/add-word-details/${comp_id}`)
                          }
                          // onClick={() =>
                          //   navigate(`/add-word-details/${comp_id}`)
                          // }
                        >
                          Add Words
                        </button>
                      )}
                      <button
                        className="start-g"
                        // onClick={() => handleOpen()}
                        onClick={() =>
                          window.location.href.includes("/phase/")
                            ? navigate(`/phase/all-jury/${comp_id}`)
                            : navigate(`/all-jury/${comp_id}`)
                        }
                      >
                        Jury Members
                      </button>
                    </div>
                      </TranslatedComponent>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <SidebarRight />
      </div>
    </>
  );
};

export default OrganiserWordList;
