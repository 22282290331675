import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GetTournamentDetails } from "../../Store/Action/tournamentAction";
import {
  TOURNAMENT_SUCCESS_CLEAR,
  TOURNAMENT_ERROR_CLEAR,
  TOURNAMENT_DETAILS_CLEAR,
} from "../../Store/Types/tournamentTypes";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { MsgTranslator } from "../../HelperFunctions/MsgTranslator";

const Tournament_DB_Details = ({ tournamentId }) => {
  // console.log(tournamentId);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const {
    tournament_Loading,
    tournamentDetails,
    tournament_SuccessMessage,
    tournament_ErrorMessage,
    tournament_Get_Error_Message,
  } = useSelector((state) => state.Tournament);

  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    if (tournamentId) {
      dispatch({ type: TOURNAMENT_DETAILS_CLEAR });
      dispatch(
        GetTournamentDetails({
          orgId: currentUserInfo._id,
          tournamentId: tournamentId,
        })
      );
    }
  }, [tournamentId]);

  useEffect(() => {
    if (tournament_Get_Error_Message) {
      // const text = MsgTranslator()
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: MsgTranslator(tournament_Get_Error_Message, language),
      }).then(() => {
        dispatch({ type: TOURNAMENT_ERROR_CLEAR });
      });
    }
  }, [tournament_Get_Error_Message]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      {" "}
      {tournamentDetails &&
      tournamentDetails.phases &&
      tournamentDetails.phases.length > 0 ? (
        <div className={`dropdown3 slideDown tournament-details`}>
          <div id="myDropdown" className="dropdown-content-two">
            <table className="table table-stripped">
              <thead>
                <tr>
                  <th scope="col">Phase name</th>
                  <th scope="col">Sub Competition Name</th>
                  <th scope="col">Start date</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {tournamentDetails &&
                tournamentDetails.phases &&
                tournamentDetails.phases.length > 0
                  ? tournamentDetails.phases.map((sp) => {
                      // console.log(sp);
                      return sp.subCompIds && sp.subCompIds.length > 0
                        ? sp.subCompIds.map((ss) => {
                            // console.log(ss);
                            return (
                              <tr key={ss._id}>
                                {" "}
                                <td>{sp.phaseName}</td>
                                <td>{ss.name}</td> <td>{ss.date}</td>
                                <td>
                                  <div className="dropdown">
                                    <button
                                      className="dropbtn dropdown-toggle tree-dute"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                      }}
                                    >
                                      <img
                                        src={
                                          window.location.origin +
                                          "/Assets/images/ellipsis-green.png"
                                        }
                                        alt="ellipsis-green"
                                      />
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          navigate(`/all-participant/${ss._id}`)
                                        }
                                      >
                                        Paricipants
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          navigate(
                                            `/organiser-word-list/${ss._id}`
                                          )
                                        }
                                      >
                                        Wordlist
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          navigate(`/all-jury/${ss._id}`)
                                        }
                                      >
                                        Jury Members
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        onClick={() =>
                                          navigate(
                                            `/competition-results/${ss._id}`
                                          )
                                        }
                                      >
                                        Result
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        : null;
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Tournament_DB_Details;
