import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import { BASE_URL } from "../../../Config/Config";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";
import countryList from "react-select-country-list";

import { GetAllJury, DeleteJury } from "../../../Store/Action/juryAction";
import {
  JURY_ACTION_LOADER_START,
  JURY_SUCCESS_MSG_CLEAR,
  JURY_ERROR_MSG_CLEAR,
} from "../../../Store/Types/juryTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const JuryMembersList = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const {
    Jury_Loading,
    Jury_Action_Loader,
    // Jury_Comp_Data,
    Jury_All_Data,
    Jury_SuccessMessage,
    Jury_ErrorMessage,
    Jury_Get_Error_Message,
  } = useSelector((state) => state.Jury);

  const [rows, setrows] = useState([]);
  const [language, setLanguage] = useState("fr");

  /* formatting data at the time we get data */
  useEffect(() => {
    if (!Jury_Loading && Jury_All_Data) {
      const transformedData = Jury_All_Data.map((jury, i) => ({
        index: String(i + 1).padStart(2, "0"),
        id: jury._id,
        firstName: jury.firstName || "",
        lastName: jury.lastName || "",
        email: jury.email || "",
        phone: jury.phone || "",
        country: jury.country ? jury.country.label : null,
      }));
      setrows(transformedData);
    }
  }, [Jury_All_Data, Jury_Loading]);

  /* get all jury at the time of stafrting the page */
  useEffect(() => {
    dispatch(GetAllJury({ orgId: currentUserInfo._id }));
  }, []);

  //Delete
  const handleDeleteJury = (jury) => {
    dispatch(DeleteJury({ juryId: jury }));
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Jury_SuccessMessage) {
      const text = MsgTranslator(Jury_SuccessMessage, language);
      notify(text, "success");
      dispatch({ type: JURY_SUCCESS_MSG_CLEAR });
      dispatch(GetAllJury({ orgId: currentUserInfo._id }));
    }
    if (Jury_ErrorMessage) {
      const text = MsgTranslator(Jury_ErrorMessage, language);
      notify(text, "error");
      dispatch({ type: JURY_ERROR_MSG_CLEAR });
    }
    if (Jury_Get_Error_Message) {
      const text = MsgTranslator(Jury_Get_Error_Message, language);
      notify(text, "error");
      dispatch({ type: JURY_ERROR_MSG_CLEAR });
    }
  }, [Jury_SuccessMessage, Jury_ErrorMessage, Jury_Get_Error_Message]);

  /* column structure */
  const columns = [
    // { field: '_id', headerName: 'ID', width: 90 },
    {
      field: "index",
      headerName: "",
      width: 90,
      renderCell: (params) => (
        <span style={{ fontWeight: "bold" }}>{params.row.index}</span>
      ),
    },
    {
      field: "fullName",
      headerName: <TranslatedComponent>Name</TranslatedComponent>,
      // description: "This column has a value getter and is not sortable.",
      // sortable: false,
      width: 200,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "email",
      headerName: <TranslatedComponent>Email</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "phone",
      headerName: <TranslatedComponent>Phone</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "country",
      headerName: <TranslatedComponent>Country</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "delete",
      headerName: <TranslatedComponent>Delete</TranslatedComponent>,
      width: 200,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => (
        <button
          className="btn-with-pic"
          onClick={() => {
            handleDeleteJury(params.row.id);
          }}
        >
          <img
            src={window.location.origin + `/Assets/images/delete-green.png `}
            alt="Remove Green"
          />
        </button>
      ),
    },
  ];

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />
        <div id="content-thrr">
          {/* <button type="button" id="sidebarCollapse" className="btn btn-info">
            <i className="fas fa-align-left" />
          </button> */}
          {!Jury_Loading ? (
            <div className="main-container-section">
              <Header
                breadcrumb={[
                  {
                    page: MsgTranslator("Dashboard", language),
                    link:
                      currentUserInfo.role === "Org"
                        ? "/org-dashboard"
                        : "/jury-dashboard",
                  },
                  {
                    page: MsgTranslator("All Jury List", language),
                    link: `/jury-list`,
                  },
                ]}
              />

              <div className="upcoming-title">
                <h3>
                  {/* <span>
                      <em>Jury}</em>{list
                  </span> */}
                  <PageTitle title={"<em>Jury</em> list"} lang={language} />
                </h3>
              </div>
              <div className="candit-sectied-from">
                <div className="table1002 ver2 m-b-110">
                  <Box sx={{ height: 500, width: "100%" }}>
                    <TranslatedComponent>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        // autoHeight={true}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10, 20, 30, 40, 50]}
                        // checkboxSelection
                        disableRowSelectionOnClick
                        // components={{ Toolbar: GridToolbar }}
                      />
                    </TranslatedComponent>
                  </Box>
                </div>
                <div className="buttaon-lane">
                  <div className="back-name">
                    <TranslatedComponent>
                      <button className="back-g" onClick={() => navigate(-1)}>
                        Back
                      </button>
                    </TranslatedComponent>
                  </div>
                  {/* <button className="start-g">Save</button> */}
                </div>
              </div>
            </div>
          ) : (
            <ComponentLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default JuryMembersList;
