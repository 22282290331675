import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../../../component/Sideber/SidebarLeft";
import Header from "../../../../component/Header/Header";
import SidebarRight from "../../../../component/Sideber/SidebarRight";

import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { MagnifyingGlass } from "react-loader-spinner";

import { useSelector, useDispatch } from "react-redux";

import { DeleteOneShotAction } from "../../../../Store/Action/oneShotAction";
import {
  GetTournamentDetails,
  Update_Tournament_Phase_Status,
} from "../../../../Store/Action/tournamentAction";
import {
  ONESHOT_SUCCESS_CLEAR,
  ONESHOT_ERROR_CLEAR,
} from "../../../../Store/Types/oneShotTypes";
import {
  TOURNAMENT_SUCCESS_CLEAR,
  TOURNAMENT_ERROR_CLEAR,
} from "../../../../Store/Types/tournamentTypes";
import Swal from "sweetalert2";
import FileUploader from "../../../../component/FileUploader/FileUploader";
import ComponentLoader from "../../../../component/ComponentLoader/ComponentLoader";
import {
  OverallCompetationStatistics,
  listOfTheCandidatePresent,
  listOfWordsUsedCSV,
  qualifiedCondidateCSV,
} from "../../../../Store/Action/roundAction";
import TranslatedComponent from "../../../../translator/TranslatedComponent";
import { MsgTranslator } from "../../../../HelperFunctions/MsgTranslator";

const PhaseDiv = styled("div")({
  color: "Blue",
  padding: "0.5rem",
  borderRadius: "0.5rem",
  margin: "0.5rem 0",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const SubCompDiv = styled("div")({
  backgroundColor: "lightblue",
  padding: "0.5rem",
  borderRadius: "0.5rem",
  margin: "0.5rem 0",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function reorderData(data) {
  // console.log(data.phases);
  // Iterate over each phase
  data.phases.forEach((phase) => {
    // Sort the subCompIds array based on date and time
    phase.subCompIds.sort((a, b) => {
      // Parse date strings into ISO format ('YYYY-MM-DD')
      const isoDateA = a.date.split("-").reverse().join("-");
      const isoDateB = b.date.split("-").reverse().join("-");

      // Compare dates
      const dateComparison = new Date(isoDateA) - new Date(isoDateB);
      if (dateComparison !== 0) {
        return dateComparison; // If dates are different, return the comparison result
      }
      // If dates are the same, compare times
      return new Date(a.time) - new Date(b.time);
    });
  });

  // console.log(data)
  // console.log(data.phases);
  return data;
}

const translationMap = {
  Surname: "Nom de famille",
  "First Names": "Prénoms",
  Gender: "Genre",
  Age: "Âge",
  level: "niveau",
  Establishment: "Établissement",
  Department: "Département",
  "Number of Words Spelled": "Nombre de mots épelés",
  "Number of Correct Spellings": "Nombre de mots correctement épelés",
  "Average Spelling Time (sec)": "Temps moyen d'épellation (sec)",
  "Total Spelling Time (sec)": "Temps total d'épellation (sec)",
  "Student Number": "Numéro d'étudiant",
  "First Names": "Prénoms",
  Round: "Tour",
  "Given Spelling": "Orthographe donnée",
  "Correct Spelling": "Orthographe correcte",
  "Duration of Spelling (sec)": "Durée de l'orthographe (sec)",
  "Sanction for Spelling": "Sanction pour l'orthographe",
  "Rank Occupied": "Rang occupé",
  "Candidate Number": "Numéro de candidat",
  Spelling: "Orthographe",
  "Alternative Spelling": "Orthographe alternative",
  "Used in Round Number": "Utilisé au tour numéro",
  "Number of the Candidate Who Spelled It": "Numéro du candidat qui l'a épelé",
  "Spelling Given by the Said Candidate":
    "Orthographe donnée par le dit candidat",
  "Round Number": "Numéro de tour",
  "Number of Candidates at the Beginning": "Nombre de candidats au début",
  "Number of Candidates at the End": "Nombre de candidats à la fin",
  "Number of Words Used": "Nombre de mots utilisés",
  "Number of Correct Spellings": "Nombre de mots correctement épelés",
  "Duration of the Competition Per Round (min)":
    "Durée de la compétition par tour (min)",
  "Total Duration (min)": "Durée totale (min)",
  "Average Duration of Spelling Per Candidate (sec)":
    "Durée moyenne de l'orthographe par candidat (sec)",
    "CandidateNumber":"Numéro de candidat"
};

const PhaseManage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);

  const {
    CSVarray,
    OverallCompetitionStatistics,
    listOfWords,
    download_Success,
    listOfCandidatePresent,
  } = useSelector((state) => state.Round);
  const {
    OneShot_Loading,
    OneShot_Data,
    OneShot_SuccessMessage,
    OneShot_ErrorMessage,
  } = useSelector((state) => state.OneShot);
  const {
    tournament_Loading,
    tournamentDetails,
    tournament_SuccessMessage,
    tournament_ErrorMessage,
    tournament_Get_Error_Message,
  } = useSelector((state) => state.Tournament);

  const [state, setState] = useState({});
  const [language,setLanguage] = useState('fr')
  

  useEffect(() => {
    dispatch(
      GetTournamentDetails({ orgId: currentUserInfo._id, tournamentId: id })
    );
  }, [id]);

  useEffect(() => {
    if (tournamentDetails && Object.keys(tournamentDetails).length > 0) {
      let data = JSON.parse(JSON.stringify(tournamentDetails));
      let sortedData = reorderData(data);
      setState({ ...state, ...sortedData });
    }
  }, [tournamentDetails]);

  const DeleteOneShot = (oneShotId) => {
    dispatch(
      DeleteOneShotAction({ compId: oneShotId, orgId: currentUserInfo._id })
    );
  };

  const checkColor = (compDetails) => {
    if (
      compDetails.participants.length > compDetails.totalParticipants ||
      compDetails.participants.length === 0
    ) {
      return { backgroundColor: "#ffc107" };
    } else if (compDetails.jury.length === 0) {
      return { backgroundColor: "#ffc107" };
    } else if (
      compDetails.words &&
      compDetails.words.length < compDetails.participants.length * 2
    ) {
      return { backgroundColor: "#ffc107" };
    } else {
      return null;
    }
  };

  const handleStart = (compDetails) => {
    
    if (compDetails?.participants.length === 0) {
      const translatedText = MsgTranslator("You supposed to have [_participants_] participants");
      const text = translatedText.replace("[_participants_]",compDetails.totalParticipants);

      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      });
    } else if (
      compDetails.participants.length > compDetails.totalParticipants
    ) {
      // const alertSuffixMessage = MsgTranslator("You added",language)
      // const alertPriixMessage = MsgTranslator("extra participants",language)
      // const alertBetweenMessage = MsgTranslator("participants but you supposede to have",language)
      const _a = compDetails.participants.length - compDetails.totalParticipants;
      const _b = compDetails.participants.length;
      const _c = compDetails.totalParticipants
      const translatedTest = MsgTranslator("You added [_a_] extra participants . You added [_b_] participants but you supposede to have [_c_] participants");
      const text = translatedTest.replace("[_a_]",_a).replace("[_b_]",_b).replace("[_c_]",_c );
      
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text
      });
    } else if (compDetails.jury.length === 0) {
      const swalMessage = MsgTranslator("You must have jury to start the competition.",language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: swalMessage,
      });
    } else if (
      compDetails.words &&
      compDetails.words.length < compDetails.participants.length * 2
    ) {
      const swalMessage = MsgTranslator("You must have enough words to start the competition.",language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: swalMessage,
      });
    } else {
      navigate(`/start-competition/${compDetails._id}`);
    }
  };

  const handlePhaseEnd = (tournamentId, phaseId) => {
    const title = MsgTranslator("Are you sure?",language);
    const text = MsgTranslator("You won't be able to revert this!",language)
    const confirmButtonText = MsgTranslator("Yes, End Phase!",language)
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: confirmButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          Update_Tournament_Phase_Status({
            tournamentId: tournamentId,
            phaseId: phaseId,
            orgId: currentUserInfo._id,
          })
        );
      }
    });
  };

  useEffect(() => {
    if (OneShot_SuccessMessage) {
      dispatch(
        GetTournamentDetails({ orgId: currentUserInfo._id, tournamentId: id })
      );
      dispatch({ type: ONESHOT_SUCCESS_CLEAR });
    }
    if (OneShot_ErrorMessage) {
      const text = MsgTranslator(OneShot_ErrorMessage,language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: ONESHOT_ERROR_CLEAR });
      });
    }
    if (tournament_SuccessMessage) {
      // Swal.fire({
      //   icon: "success",
      //   text: tournament_SuccessMessage,
      // }).then(() => {
      dispatch(
        GetTournamentDetails({ orgId: currentUserInfo._id, tournamentId: id })
      );
      dispatch({ type: TOURNAMENT_SUCCESS_CLEAR });
      // });
    }
    if (tournament_ErrorMessage) {
      const text = MsgTranslator(tournament_ErrorMessage,language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: TOURNAMENT_ERROR_CLEAR });
      });
    }
  }, [
    OneShot_SuccessMessage,
    OneShot_ErrorMessage,
    tournament_SuccessMessage,
    tournament_ErrorMessage,
  ]);
  useEffect(() =>{
    if(currentUser && Object.keys(currentUser).length > 0){
      const {language} = currentUser;
      setLanguage(language);
    }
  },[currentUser]);

  /* ==========================|| DOWNLOAD CSV FILE ||============================= */
  const qualifiedCandidateCSV = (data) => {
    // const csvData = data.map((row, index) => {
    //   return {
    //     ...row,
    //     "Student Number": index + 1,
    //   };
    // });

    // // Getting headers from the first row
    // const headers = Object.keys(csvData[0]);

    // // Reordering headers so that id comes first and numberOfCorrectSpell comes last
    // const reorderedHeaders = [
    //   "Student Number",
    //   ...headers.filter(
    //     (header) =>
    //       header !== "Student Number" && header !== "Total Spelling Time (sec)"
    //   ),
    //   "Total Spelling Time (sec)",
    // ];

    // // Generating CSV string
    // let csv = reorderedHeaders.join(",") + "\n";
    // csv += csvData
    //   .map((row) =>
    //     reorderedHeaders.map((fieldName) => row[fieldName]).join(",")
    //   )
    //   .join("\n");
    // return csv;
    if (currentUser.language === "fr") {
      function translateObjectKeys(obj, translationMap) {
        const translatedObj = {};
        for (const [key, value] of Object.entries(obj)) {
          const translatedKey = translationMap[key] || key;
          translatedObj[`${translatedKey}`] = value;
        }
        return translatedObj;
      }
      data = data.map((sd) => translateObjectKeys(sd, translationMap));
    }
    /*--------------------------------- */
    const translatedStudentNumber =
      currentUser.language === "fr"
        ? translationMap["Student Number"] || "Student Number"
        : "Student Number";
    const translatedTotalSpellingTime =
      currentUser.language === "fr"
        ? translationMap["Total Spelling Time (sec)"] ||
          "Total Spelling Time (sec)"
        : "Total Spelling Time (sec)";
    // console.log(translatedStudentNumber);
    // console.log(translationMap["Student Number"]);
    const csvData = data.map((row, index) => {
      return {
        ...row,
        [translatedStudentNumber]: index + 1,
      };
    });

    // Getting headers from the first row
    const headers = Object.keys(csvData[0]);

    // Reordering headers so that Student Number comes first and Total Spelling Time (sec) comes last
    const reorderedHeaders = [
      translatedStudentNumber,
      ...headers.filter(
        (header) =>
          header !== translatedStudentNumber &&
          header !== translatedTotalSpellingTime
      ),
      translatedTotalSpellingTime,
    ];

    // Generating CSV string
    let csv = reorderedHeaders.join(",") + "\n";
    csv += csvData
      .map((row) =>
        reorderedHeaders
          .map((fieldName) =>
            row[fieldName] !== undefined ? row[fieldName] : ""
          )
          .join(",")
      )
      .join("\n");
    return csv;
  };

  const OverAllCompetationObjects = (data) => {
    // const csvData = data.map((row, index) => {
    //   return {
    //     "Round Number": index + 1,
    //     ...row,
    //   };
    // });

    // // Getting headers from the first row
    // const headers = Object.keys(csvData[0]);

    // // Convert data to CSV format
    // let csv = headers.join(",") + "\n";
    // csv += csvData
    //   .map((row) => headers.map((header) => row[header]).join(","))
    //   .join("\n");
    // // console.log(csv)

    // return csv;

    if (currentUser.language === "fr") {
      function translateObjectKeys(obj, translationMap) {
        const translatedObj = {};
        for (const [key, value] of Object.entries(obj)) {
          const translatedKey = translationMap[key] || key;
          translatedObj[`${translatedKey}`] = value;
        }
        return translatedObj;
      }
      data = data.map((sd) => translateObjectKeys(sd, translationMap));
    }

    const translatedRoundNumber =
      currentUser.language === "fr"
        ? translationMap["Round Number"] || "Round Number"
        : "Round Number";

    const csvData = data.map((row, index) => {
      return {
        [translatedRoundNumber]: index + 1,
        ...row,
      };
    });

    // Getting headers from the first row
    const headers = Object.keys(csvData[0]);

    // Convert data to CSV format
    let csv = headers.join(",") + "\n";
    csv += csvData
      .map((row) =>
        headers
          .map((header) => (row[header] !== undefined ? row[header] : ""))
          .join(",")
      )
      .join("\n");

    return csv;
  };

  const listOfWordsUsedObjects = (data) => {
    // const csvData = data.map((row, index) => {
    //   return {
    //     "Round Number": index + 1,
    //     ...row,
    //   };
    // });

    // // Getting headers from the first row
    // const headers = Object.keys(data[0]);

    // // Convert data to CSV format
    // let csv = headers.join(",") + "\n";
    // csv += csvData
    //   .map((row) => headers.map((header) => row[header]).join(","))
    //   .join("\n");
    // return csv;

    if (currentUser.language === "fr") {
      function translateObjectKeys(obj, translationMap) {
        const translatedObj = {};
        for (const [key, value] of Object.entries(obj)) {
          const translatedKey = translationMap[key] || key;
          translatedObj[`${translatedKey}`] = value;
        }
        return translatedObj;
      }
      data = data.map((sd) => translateObjectKeys(sd, translationMap));
    }

    const translatedRoundNumber =
      currentUser.language === "fr"
        ? translationMap["Round Number"] || "Round Number"
        : "Round Number";

    const csvData = data.map((row, index) => {
      return {
        [translatedRoundNumber]: index + 1,
        ...row,
      };
    });

    // Getting headers from the first row
    const headers = Object.keys(csvData[0]);

    // Convert data to CSV format
    let csv = headers.join(",") + "\n";
    csv += csvData
      .map((row) =>
        headers
          .map((header) => (row[header] !== undefined ? row[header] : ""))
          .join(",")
      )
      .join("\n");

    return csv;
  };

  const listOfcandidates = (data) => {

    if (currentUser.language === "fr") {
      function translateObjectKeys(obj, translationMap) {
        // obj.CandidateNumber = delete;

        const translatedObj = {};
        for (const [key, value] of Object.entries(obj)) {
          const translatedKey = translationMap[key] || key;
          translatedObj[`${translatedKey}`] = value;
        }
        return translatedObj;
      }
      data = data.map((sd) => translateObjectKeys(sd, translationMap));
    } 

    const translatedCandidateNumber =
      currentUser.language === "fr"
        ? translationMap["Candidate Number"] || "Candidate Number"
        : "Candidate Number";

    const csvData = data.map((row, index) => {
      const newRow = {
        [translatedCandidateNumber]: row.CandidateNumber,
        ...row,
      };

      delete newRow.CandidateNumber;

      return newRow;
    });

    // Getting headers from the first row
    const headers = Object.keys(csvData[0]);

    // Convert data to CSV format
    let csv = headers.join(",") + "\n";
    csv += csvData
      .map((row) =>
        headers
          .map((header) => (row[header] !== undefined ? row[header] : ""))
          .join(",")
      )
      .join("\n");

    return csv;
  };
  

  const qualifiedCondidate = (id) => {
    dispatch(qualifiedCondidateCSV(id));
  };

  const handleOverAllCompStattics = (id) => {
    dispatch(OverallCompetationStatistics(id));
  };

  const listOfWordsUsed = (id) => {
    dispatch(listOfWordsUsedCSV(id));
  };
  const listOfTheCandidatePresents = (id) => {
    dispatch(listOfTheCandidatePresent(id));
  };
  /* =================================================================================== */

  useEffect(() => {
    if (CSVarray.length) {
      // console.log("first")
      const csvData = CSVarray.map((row, index) => {
        const newRow = { ...row };
        delete newRow._id; // Remove _id field
        return newRow;
      });

      const csv = qualifiedCandidateCSV(csvData);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "quelified-condidate.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch({ type: "ROUND_DLD_CSV_CLR_MSG" });
    }

    if (OverallCompetitionStatistics.length) {
      const csv = OverAllCompetationObjects(OverallCompetitionStatistics);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "competationStatics.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: "ROUND_DLD_CSV_CLR_MSG" });
    }

    if (listOfWords.length) {
      const csv = listOfWordsUsedObjects(listOfWords);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ListOfWordsUsed.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: "ROUND_DLD_CSV_CLR_MSG" });
    }

    if (listOfCandidatePresent.length) {
      // console.log(listOfCandidatePresent)
      const csv = listOfcandidates(listOfCandidatePresent);
      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ListOfCandidatePresent.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch({ type: "ROUND_DLD_CSV_CLR_MSG" });
    }
  }, [download_Success]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content">
          <div className="main-container-section">
            <Header />
            <div className="upcoming-title">
              <h3>
                <span>{state?.tournamentName || ""}</span>
              </h3>
            </div>

            <Box>
              <div>
                {state?.phases && state?.phases.length > 0 ? (
                  state.phases.map((sp, i) => {
                    return (
                      <TranslatedComponent>
                        <Box key={sp._id}>
                          <PhaseDiv>
                            <h3 className="phase-title">
                              {sp.phaseName || ""}{" "}
                              {`(${sp?.subCompIds?.length} / ${sp?.noOfSubComp})`}
                            </h3>
                            <div className="phase-btn">
                              {i !== 0 &&
                                (!sp.isCompleted &&
                                state?.phases[i - 1].isCompleted ? (
                                  <button
                                    className="end-phase"
                                    onClick={() =>
                                      handlePhaseEnd(state._id, sp._id)
                                    }
                                  >
                                    {" "}
                                    <i
                                      class="fa-solid fa-stop"
                                      style={{ color: "#F5200C" }}
                                    ></i>{" "}
                                    End Phase
                                  </button>
                                ) : !sp.isCompleted ? null : (
                                  <p
                                    className="phase-title"
                                    style={{ color: "red" }}
                                  >
                                    Finished
                                  </p>
                                ))}
                              {i === 0 &&
                                (!sp.isCompleted ? (
                                  <button
                                    className="end-phase"
                                    onClick={() =>
                                      handlePhaseEnd(state._id, sp._id)
                                    }
                                  >
                                    {" "}
                                    <i
                                      class="fa-solid fa-stop"
                                      style={{ color: "#F5200C" }}
                                    ></i>{" "}
                                    End Phase
                                  </button>
                                ) : (
                                  <p
                                    className="phase-title"
                                    style={{ color: "red" }}
                                  >
                                    Finished
                                  </p>
                                ))}

                              {sp?.noOfSubComp !== sp.subCompIds.length &&
                                !sp.isCompleted && (
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/create-sub-competition/${id}/${sp._id}`
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        window.location.origin +
                                        `/Assets/images/pluse.png`
                                      }
                                      alt="operation"
                                    />
                                  </IconButton>
                                )}
                            </div>
                          </PhaseDiv>

                          <div className="upcoming-box scrollable top-mmg">
                            <div>
                              <table className="table gfg ">
                                <thead>
                                  <tr className="green-line-3">
                                    <th></th>
                                    <th>
                                      <span className="icon-name-st">
                                        Competition Name
                                      </span>
                                    </th>
                                    <th>
                                      <span className="icon-name-type">
                                        Type
                                      </span>
                                    </th>
                                    <th>
                                      <span className="icon-name-date">
                                        Date
                                      </span>
                                    </th>
                                    <th>
                                      <span className="icon-name-time">
                                        Time
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sp?.subCompIds && sp?.subCompIds.length > 0
                                    ? sp.subCompIds.map((sos, index) => (
                                        <tr className="geeks" key={sos._id}>
                                          <td>{index + 1}</td>
                                          <td
                                            onClick={() => {
                                              navigate(
                                                `/one-shot-details/${sos._id}`
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {sos?.name || ""}
                                          </td>
                                          <td>Sub Competition</td>
                                          <td>{sos?.date || ""}</td>
                                          <td>{sos.time}</td>
                                          <td className="dropdown">
                                            <button
                                              className="dropbtn dropdown-toggle tree-dute"
                                              type="button"
                                              id="dropdownMenuButton"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <img
                                                src={
                                                  window.location.origin +
                                                  "/Assets/images/point-arrow.png"
                                                }
                                                alt="Point Arrow"
                                              />
                                            </button>
                                            {!sos.isCompleted ? (
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    i !== 0
                                                      ? navigate(
                                                          `/all-participant-prev-phase/${sp._id}/${sos._id}`
                                                        )
                                                      : navigate(
                                                          `/phase/all-participant/${sos._id}`
                                                        );
                                                  }}
                                                >
                                                  Participants
                                                </a>

                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/phase/organiser-word-list/${sos._id}`
                                                    )
                                                  }
                                                >
                                                  Wordlist
                                                </a>

                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/phase/all-jury/${sos._id}`
                                                    )
                                                  }
                                                >
                                                  Jury Members
                                                </a>

                                                <a
                                                  className="dropdown-item"
                                                  style={{ color: "red" }}
                                                  onClick={() =>
                                                    DeleteOneShot(sos._id)
                                                  }
                                                >
                                                  Delete
                                                </a>
                                              </div>
                                            ) : (
                                              <div
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuButton"
                                              >
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    navigate(
                                                      `/competition-results/${sos._id}`
                                                    )
                                                  }
                                                >
                                                  Result
                                                </a>

                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    qualifiedCondidate(sos._id);
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Qualified Condidate
                                                </a>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    handleOverAllCompStattics(
                                                      sos._id
                                                    );
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  Over All Competation Statics
                                                </a>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    listOfWordsUsed(sos._id);
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  List Of Words Used
                                                </a>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    listOfTheCandidatePresents(
                                                      sos._id
                                                    );
                                                  }}
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  List Of Candidate Present
                                                </a>
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            <button className="shear-one">
                                              Share
                                            </button>
                                          </td>
                                          <td>
                                            {sos.isStarted === true ? (
                                              sos.isCompleted ? (
                                                <button
                                                  className="resume"
                                                  onClick={() =>
                                                    navigate(
                                                      `/competition-results/${sos._id}`
                                                    )
                                                  }
                                                >
                                                  view
                                                </button>
                                              ) : (
                                                <button
                                                  className="resume"
                                                  onClick={() =>
                                                    navigate(
                                                      `/start-competition-round/${sos._id}`
                                                    )
                                                  }
                                                >
                                                  Resume
                                                </button>
                                              )
                                            ) : (
                                              <button
                                                className="resume"
                                                // onClick={() =>
                                                //   navigate(
                                                //     `/start-competition/${sos._id}`
                                                //   )
                                                // }
                                                onClick={() => handleStart(sos)}
                                                style={checkColor(sos)}
                                              >
                                                Start
                                              </button>
                                            )}
                                            {/* <button
                                              className="resume"
                                              onClick={() =>
                                                navigate(
                                                  `/start-competition/${sos._id}`
                                                )
                                              }
                                            >
                                              Start
                                            </button> */}
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Box>
                      </TranslatedComponent>
                    );
                  })
                ) : (
                  <ComponentLoader />
                )}
              </div>
            </Box>
          </div>
        </div>
        <SidebarRight />
      </div>
    </>
  );
};

export default PhaseManage;
