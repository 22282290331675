import React, { useState, useEffect } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import SidebarRight from "../../../component/Sideber/SidebarRight";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { GetOrgWord } from "../../../Store/Action/wordAction";
import {
  WORD_ACTION_LOADER_START,
  WORD_SUCCESS_CLEAR,
  WORD_ERROR_CLEAR,
} from "../../../Store/Types/wordTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { MagnifyingGlass } from "react-loader-spinner";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { BASE_URL } from "../../../Config/Config";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const WordList = () => {
  const navigate = useNavigate();
  // const params = useParams();
  // const { comp_id } = params;

  const dispatch = useDispatch();
  const { currentUserInfo ,currentUser} = useSelector((state) => state.Auth);
  const {
    Word_Loading,
    Word_Action_Loader,
    Words_All_Data,
    Word_SuccessMessage,
    Word_ErrorMessage,
    Word_Get_Error_Message,
  } = useSelector((state) => state.Word);

  useEffect(() => {
    dispatch(GetOrgWord({ createdBy: currentUserInfo._id }));
  }, []);

  const handleViewWordDetails = (swId) => {
    let Ids = Words_All_Data.map((sw) => sw._id);
    localStorage.setItem("IdArray", JSON.stringify(Ids));
    navigate(`/word-details/${swId}`);

  };
  const [language, setLanguage] = useState("fr");

  /* success msg and error msg handling */
  useEffect(() => {
    // if (Word_SuccessMessage) {
    //   notify(Word_SuccessMessage, "success");
    //   dispatch({ type: WORD_SUCCESS_CLEAR });
    //   navigate(`/word-list`);
    // }
    if (Word_ErrorMessage) {
      const text = MsgTranslator(Word_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: WORD_ERROR_CLEAR });
    }
    if (Word_Get_Error_Message) {
      const text = MsgTranslator(Word_Get_Error_Message,language)
      notify(text, "error");
      dispatch({ type: WORD_ERROR_CLEAR });
    }
  }, [Word_SuccessMessage, Word_ErrorMessage, Word_Get_Error_Message]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em></em>{" "}
                  </TranslatedComponent>
                  <TranslatedComponent></TranslatedComponent>
                </span> */}
                  <PageTitle title={"<em>Word</em> List"} lang={language} />
              </h3>
            </div>

            <div className="candit-sectied-from">
              <div className="slimScroll">
                <div className="word-list-section">
                  {!Word_Loading ? (
                    Words_All_Data && Words_All_Data.length > 0 ? (
                      Words_All_Data.map((sw, index) => {
                        return (
                          <div key={sw._id}>
                            <TranslatedComponent>
                              <div className="word-top">
                                <ul>
                                  <li>
                                    <img
                                      src={
                                        window.location.origin +
                                        `/Assets/images/word-list.png`
                                      }
                                      alt="word"
                                    />
                                    Words
                                  </li>
                                  <li>
                                    <img
                                      src={
                                        window.location.origin +
                                        `/Assets/images/word-list.png`
                                      }
                                      alt="wrd"
                                    />
                                    Details
                                  </li>
                                </ul>
                              </div>
                            </TranslatedComponent>
                            <div
                              className="word-listbox"
                              key={sw._id}
                              onClick={() => handleViewWordDetails(sw._id)}
                            >
                              <div className="number-red">{index + 1}</div>
                              <div className="row">
                                <TranslatedComponent>
                                  <div className="col-lg-2">
                                    <div className="word-name-mobile">
                                      <img
                                        src={
                                          window.location.origin +
                                          `/Assets/images/word-list.png`
                                        }
                                        alt="word"
                                      />
                                      Words
                                    </div>
                                    <p className="name-p">{sw.word}</p>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="details-name-mobile">
                                      <img
                                        src={
                                          window.location.origin +
                                          `/Assets/images/word-list.png`
                                        }
                                        alt="word"
                                      />
                                      Details
                                    </div>
                                    <div className="word-iu">
                                      <ul>
                                        <li>
                                          <span>Word :</span> {sw.word}
                                        </li>
                                        <li>
                                          <span>Word phonetics :</span>{" "}
                                          {sw.word_phonetics}
                                        </li>
                                        <li>
                                          <span>Spelling Alternative :</span>{" "}
                                          {sw.spellingAlternatives}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="word-iu">
                                      <ul>
                                        <li>
                                          <span>Nature :</span> {sw.nature}
                                        </li>
                                        <li>
                                          <span>Origin :</span> {sw.origin}
                                        </li>
                                        <li>
                                          <span>Gender :</span> {sw.gender}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-lg-3">
                                    <div className="word-iu">
                                      <ul>
                                        <li>
                                          <span>Homonyms :</span> {sw.homonyms}
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </TranslatedComponent>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : null
                  ) : (
                    <div className="candit-sectied-from">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                        }}
                      >
                        <MagnifyingGlass
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="magnifying-glass-loading"
                          wrapperStyle={{ display: "inline-block" }}
                          wrapperClass="magnifying-glass-wrapper"
                          glassColor="#c0efff"
                          color="#e15b64"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SidebarRight />
      </div>
    </>
  );
};

export default WordList;
