/* import types */
import {
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
} from "../Types/userTypes";

import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";

// export const userAuth = (token) => {
//     axios.interceptors.request.use(
//         (config) => {
//             config.headers.authorization = `Bearer ${token}`;
//             return config;
//         },
//         (error) => {
//             return Promise.reject(error);
//         }
//     );
// };

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      // Check if the token is available before setting the header
      if (token) {
        config.headers.authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const UserDataUpdate = (data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    try {
      userAuth(token);

      // const config = {
      //     headers: {
      //         'Content-Type': 'application/josn',
      //         adminId: data.adminId,
      //     }
      // }

      // console.log(data)

      const response = await axios.patch(
        `${SERVER_URL}/user/user-update`,
        data
      );

      dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: USER_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const DeleteUser = (data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    try {
      userAuth(token);

      const response = await axios.patch(
        `${SERVER_URL}/user/user-delete`,
        data
      );

      localStorage.clear();

      dispatch({
        type: USER_DELETE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: USER_DELETE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};
