import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import { BASE_URL } from "../../../Config/Config";

import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";
import countryList from "react-select-country-list";

import { GetAllJury, GetJuryDetails } from "../../../Store/Action/juryAction";
import {
  JURY_ACTION_LOADER_START,
  JURY_SUCCESS_MSG_CLEAR,
  JURY_ERROR_MSG_CLEAR,
} from "../../../Store/Types/juryTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const JuryMembersDitails = () => {
  const { comp_id, jury_id } = useParams();

  /* useSelector -- to get data */
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentUserInfo , currentUser } = useSelector((state) => state.Auth);
  const {
    Jury_Loading,
    Jury_Action_Loader,
    Jury_Comp_Data,
    Jury_All_Data,
    Jury_Details,
    Jury_SuccessMessage,
    Jury_ErrorMessage,
    Jury_Get_Error_Message,
  } = useSelector((state) => state.Jury);

  const [language, setLanguage] = useState("fr");

  /* get details every time page is open */
  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(
      GetJuryDetails({
        orgId: currentUserInfo._id,
        oneShotId: comp_id,
        juryId: jury_id,
      })
    );
  }, []);

  /* success and error handling */
  useEffect(() => {
    if (Jury_Get_Error_Message) {
      const text = MsgTranslator(Jury_Get_Error_Message,language) 
      notify(text, "error");
      dispatch({ type: JURY_ERROR_MSG_CLEAR });
    }
  }, [Jury_Get_Error_Message]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          {!Jury_Loading ? (
            <div className="main-container-section">
              <Header
                breadcrumb={[
                  {
                    page: MsgTranslator("Dashboard",language),
                    link:
                      currentUserInfo.role === "Org"
                        ? "/org-dashboard"
                        : "/jury-dashboard",
                  },
                  {
                    page: MsgTranslator("Jury List",language),
                    link: 1,
                  },
                  {
                    page: MsgTranslator("Jury Details",language),
                    link: 0,
                  },
                ]}
              />

              <div className="upcoming-title">
                <h3>
                  {/* <TranslatedComponent>
                    <em> </em>
                  </TranslatedComponent>
                  <TranslatedComponent></TranslatedComponent> */}

                  <PageTitle title={"<em>Jury</em> Members Details"} lang={language} />
           
                </h3>
              </div>
              {/* {!Jury_Loading ? ( */}
              <div className="candit-sectied-from">
                {/* <h2>Add Jury</h2> */}
                <TranslatedComponent>
                  <div className="select-rt">
                    <span>Role</span>
                    <div className="selet-dl">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        name="JuryRole"
                        value={Jury_Details?.juryRole || ""}
                      >
                        <option value="">No Role Is Set</option>
                        {/* <option value="Main Pronouncer">Main Pronouncer</option> */}
                        <option value="Animator">Animator</option>
                        <option value="Pronouncer">Pronouncer</option>
                        <option value="Assistant Pronouncer">
                          Assistant Pronouncer
                        </option>
                        <option value="Judge">Judge</option>
                        <option value="Assistant Judge">Assistant Judge</option>
                        <option value="Protractor">Protractor</option>
                      </select>
                    </div>
                  </div>
                  <div className="ft-create-section">
                    <div className="text-from-two">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Name :
                            </label>
                            <div className="col-sm-7">
                              <p>
                                {Jury_Details?.firstName +
                                  " " +
                                  Jury_Details?.lastName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Country :
                            </label>
                            <div className="col-sm-7">
                              <p>{Jury_Details?.country?.label || ""}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Email :
                            </label>
                            <div className="col-sm-7">
                              <p>{Jury_Details?.email || ""}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Phone No :
                            </label>
                            <div className="col-sm-7">
                              <p>{Jury_Details?.phone || ""}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Password :
                            </label>
                            <div className="col-sm-7">
                              <p>***********</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label line-li"
                            >
                              Responsibility :
                            </label>
                            <div className="col-sm-7">
                              <p>
                                {Jury_Details?.capabilities?.join(", ") || ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="buttaon-lane">
                        <div className="back-name">
                          <button
                            className="back-g"
                            onClick={() => navigate(-1)}
                          >
                            Back
                          </button>
                        </div>
                        <div className="right-edit">
                          <button
                            className="start-g"
                            onClick={() =>
                              window.location.href.includes("/phase/")
                                ? navigate(
                                    `/phase/jury-members-edit/${comp_id}/${jury_id}`
                                  )
                                : navigate(
                                    `/jury-members-edit/${comp_id}/${jury_id}`
                                  )
                            }
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TranslatedComponent>
              </div>
              {/* ) : (
                <div className="candit-sectied-from">
                  <MagnifyingGlass />
                </div>
              )} */}
            </div>
          ) : (
            <ComponentLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default JuryMembersDitails;
