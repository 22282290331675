// import { lazy } from "react";

// /* AUTH */
// const Register = lazy(() => import("../pages/Auth/Register"));
// const VerifyAccount = lazy(() => import("../pages/Auth/VerifyAccount"));
// const Login = lazy(() => import("../pages/Auth/Login"));
// const ForgotPassword = lazy(() => import("../pages/Auth/ForgotPassword"));
// const ResetPassword = lazy(() => import("../pages/Auth/ResetPassword"));
// const MyAccountOrganizer = lazy(() =>
//   import("../pages/Auth/MyAccountOrganizer")
// );

// /* DASHBOARD */
// const OrgDashboard = lazy(() => import("../pages/Dashboard/OrgDashboard"));
// const JuryDashboard = lazy(() => import("../pages/Dashboard/JuryDashboard"));

// /* ONESHOT */
// const CreateOneShotCompetition = lazy(() =>
//   import("../pages/Competition/OneShot/CreateOneShotCompetition")
// );
// const EditOneShotCompetition = lazy(() =>
//   import("../pages/Competition/OneShot/EditOneShotCompetition")
// );
// const OneShotDetails = lazy(() =>
//   import("../pages/Competition/OneShot/OneShotDetails")
// );

// /* TOURNAMENT */
// const CreateSubCompetition = lazy(() =>
//   import("../pages/Competition/Tournament/CreateSubCompetition")
// );
// const CreateTournament = lazy(() =>
//   import("../pages/Competition/Tournament/CreateTournament")
// );
// const EditTournamentDetails = lazy(() =>
//   import("../pages/Competition/Tournament/EditTournamentDetails")
// );
// const PhaseManage = lazy(() =>
//   import("../pages/Competition/Tournament/PhaseManage")
// );
// const TournamentDetails = lazy(() =>
//   import("../pages/Competition/Tournament/TournamentDetails")
// );

// /* JURY */
// const AddExistingJury = lazy(() => import("../pages/Jury/AddExistingJury"));
// const AllJury = lazy(() => import("../pages/Jury/AllJury"));
// const JuryMemberEdit = lazy(() => import("../pages/Jury/EditJuryMember"));
// const JuryMembersAdd = lazy(() => import("../pages/Jury/JuryMembersAdd"));
// const JuryMembersDitails = lazy(() =>
//   import("../pages/Jury/JuryMembersDitails")
// );
// const JuryMembersList = lazy(() => import("../pages/Jury/JuryMembersList"));
// const JuryOneShotDetails = lazy(() =>
//   import("../pages/Jury/JuryOneShotDetails")
// );
// const JuryTournamentDetails = lazy(() =>
//   import("../pages/Jury/JuryTournamentDetails")
// );

// /* PARTICIPANT */
// const AddParticipant = lazy(() =>
//   import("../pages/Participants/AddParticipant")
// );
// const AllParticipant = lazy(() =>
//   import("../pages/Participants/AllParticipant")
// );
// const EditParticipantDetails = lazy(() =>
//   import("../pages/Participants/EditParticipantDetails")
// );
// const ExistingParticipant = lazy(() =>
//   import("../pages/Participants/ExistingParticipant")
// );
// const ParticipantDetails = lazy(() =>
//   import("../pages/Participants/ParticipantDetails")
// );
// const ParticipantList = lazy(() =>
//   import("../pages/Participants/ParticipantList")
// );
// const QuickMode = lazy(() => import("../pages/Participants/QuickMode"));

// /* WORDS */
// const AddWordDetails = lazy(() => import("../pages/Word/AddWordDetails"));
// const EditWordDetails = lazy(() => import("../pages/Word/EditWordDetails"));
// const OrganiserWordList = lazy(() => import("../pages/Word/OrganiserWordList"));
// const WordDetails = lazy(() => import("../pages/Word/WordDetails"));
// const WordList = lazy(() => import("../pages/Word/WordList"));

// /* START COMP */
// const CandidateDetails = lazy(() =>
//   import("../pages/StartCompetition/CandidateDetails")
// );
// const CandidateResponse = lazy(() =>
//   import("../pages/StartCompetition/CandidateResponse")
// );
// const CandidateResponseOrganizer = lazy(() =>
//   import("../pages/StartCompetition/CandidateResponseOrganizer")
// );
// const CandidateSpellingStatus = lazy(() =>
//   import("../pages/StartCompetition/CandidateSpellingStatus")
// );
// const JuryValidate = lazy(() =>
//   import("../pages/StartCompetition/JuryValidate")
// );
// const Round = lazy(() => import("../pages/StartCompetition/Round"));
// const StartCompetition = lazy(() =>
//   import("../pages/StartCompetition/StartCompetition")
// );
// const SummarySelection = lazy(() =>
//   import("../pages/StartCompetition/SummarySelection")
// );

// const CompetitionResults = lazy(() => import("../pages/CompetitionResults"));
// const CompetitionType = lazy(() => import("../pages/CompetitionType"));

// /* PAGE NOT FOUND */
// const PageNotFound = lazy(() => import("../pages/Helping_Pages/PageNotFound"));

// /* UNAUTHORIZE ACCESS */
// const UnauthorizeAccess = lazy(() =>
//   import("../pages/Helping_Pages/UnauthorizeAccess")
// );

import Register from "../pages/Auth/Register";
import VerifyAccount from "../pages/Auth/VerifyAccount";
import Login from "../pages/Auth/Login";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import MyAccountOrganizer from "../pages/Auth/MyAccountOrganizer";
import Settings from "../pages/Auth/Settings";

import OrgDashboard from "../pages/Dashboard/OrgDashboard"; // done
import JuryDashboard from "../pages/Dashboard/JuryDashboard"; // done

import CreateOneShotCompetition from "../pages/Competition/OneShot/CreateOneShotCompetition"; // done
import EditOneShotCompetition from "../pages/Competition/OneShot/EditOneShotCompetition"; // done
import OneShotDetails from "../pages/Competition/OneShot/OneShotDetails"; // done

import CreateTournament from "../pages/Competition/Tournament/CreateTournament"; // done
import TournamentDetails from "../pages/Competition/Tournament/TournamentDetails"; // done 
import PhaseManage from "../pages/Competition/Tournament/PhaseManage"; // done
import EditTournamentDetails from "../pages/Competition/Tournament/EditTournamentDetails"; // done
import CreateSubCompetition from "../pages/Competition/Tournament/CreateSubCompetition"; // done

import AddExistingJury from "../pages/Jury/AddExistingJury"; // done
import JuryMembersAdd from "../pages/Jury/JuryMembersAdd"; // done
import JuryMembersDitails from "../pages/Jury/JuryMembersDitails"; // done
import AllJury from "../pages/Jury/AllJury"; // done
import JuryMembersList from "../pages/Jury/JuryMembersList"; // done
import JuryOneShotDetails from "../pages/Jury/JuryOneShotDetails";
import JuryTournamentDetails from "../pages/Jury/JuryTournamentDetails";
import JuryValidate from "../pages/StartCompetition/JuryValidate"; // resumed
import JuryMemberEdit from "../pages/Jury/EditJuryMember"; // done

import AddParticipant from "../pages/Participants/AddParticipant"; // done
import QuickMode from "../pages/Participants/QuickMode";
import ExistingParticipant from "../pages/Participants/ExistingParticipant";
import EditParticipantDetails from "../pages/Participants/EditParticipantDetails";
import ParticipantDetails from "../pages/Participants/ParticipantDetails";
import AllParticipant from "../pages/Participants/AllParticipant";
import ParticipantList from "../pages/Participants/ParticipantList";
import AllParticipantFromPrevPhase from "../pages/Participants/AllParticipantFromPrevPhase";
import PhaseTransfer from "../pages/Participants/PhaseTransfer";

import AddWordDetails from "../pages/Word/AddWordDetails";
import WordDetails from "../pages/Word/WordDetails";
import WordList from "../pages/Word/WordList";
import OrganiserWordList from "../pages/Word/OrganiserWordList";

import CandidateResponse from "../pages/StartCompetition/CandidateResponse";
import CandidateResponseOrganizer from "../pages/StartCompetition/CandidateResponseOrganizer";
import CompetitionResults from "../pages/CompetitionResults";
import EditWordDetails from "../pages/Word/EditWordDetails";

import SummarySelection from "../pages/StartCompetition/SummarySelection";
import CompetitionType from "../pages/CompetitionType";
import StartCompetition from "../pages/StartCompetition/StartCompetition";

import CandidateDetails from "../pages/StartCompetition/CandidateDetails";
import Round from "../pages/StartCompetition/Round";

import PageNotFound from "../pages/Helping_Pages/PageNotFound";
import UnauthorizeAccess from "../pages/Helping_Pages/UnauthorizeAccess";

import CandidateSpellingStatus from "../pages/StartCompetition/CandidateSpellingStatus";

import ComponentLoader from "../../component/ComponentLoader/ComponentLoader";
import SpecialRound from "../pages/StartCompetition/SpecialRound";

const routeConfig = [
  /***************************************************** AUTH ******************************************/
  //SIGN UP
  {
    path: "/register",
    component: Register,
    permission: ["public", "Jury", "Org"], // Public route
  },
  //VERIFY ACCOUNT
  {
    path: "/verify-account/:token",
    component: VerifyAccount,
    permission: ["public", "Jury", "Org"], // Public route
  },
  //SIGN IN
  {
    path: "/",
    component: Login,
    permission: ["public", "Jury", "Org"], // Public route
  },
  //FORGOT PASSWORD
  {
    path: "/forgot-password",
    component: ForgotPassword,
    permission: ["public", "Jury", "Org"], // Public route
  },
  //RESET PASSWORD
  {
    path: "/reset-password/:user",
    component: ResetPassword,
    permission: ["public", "Jury", "Org"], // Public route
  },
  //MY ACCOUNT
  {
    path: "/my-account-organizer",
    component: MyAccountOrganizer,
    permission: ["Jury", "Org"], // Requires user authentication
  },
  // MY SETTINGS
  {
    path: "/my-account-settings",
    component: Settings,
    permission: ["Org"], // Requires user authentication
  },
  /***************************************************** DASHBOARD ***********************************/
  //ORG-DASHBOARD
  {
    path: "/org-dashboard",
    component: OrgDashboard,
    permission: ["Org"],
  },
  //JURY-DASHBOARD
  {
    path: "/jury-dashboard",
    component: JuryDashboard,
    permission: ["Jury"],
  },

  /*********************************************** COMPETITION *******************************************/
  // COMP TYPE SELECTION
  {
    path: "/competition-type",
    component: CompetitionType,
    permission: ["Org"],
  },
  // ONESHOT CREATE
  {
    path: "/create-one-shot-competition",
    component: CreateOneShotCompetition,
    permission: ["Org"],
  },
  //ONESHOT VIEW -DETAILS
  {
    path: "/one-shot-details/:id",
    component: OneShotDetails,
    permission: ["Org","Jury"],
  },
  //ONESHOT UPDATE
  {
    path: "/edit-one-shot-competition/:id",
    component: EditOneShotCompetition,
    permission: ["Org"],
  },
  // TOURNAMENT CREATE
  {
    path: "/create-tournament",
    component: CreateTournament,
    permission: ["Org"],
  },
  //ONESHOT VIEW -DETAILS
  {
    path: "/tournament-details/:id",
    component: TournamentDetails,
    permission: ["Org"],
  },
  //PHASE MANGE --- INTERMIDIARY PAGE NEW
  {
    path: "/phase-manage/:id",
    component: PhaseManage,
    permission: ["Org"],
  },
  //TOURNAMENT-SUB-COMP-CREATE
  {
    path: "/create-sub-competition/:toornamentId/:phaseId",
    component: CreateSubCompetition,
    permission: ["Org"],
  },
  //EDIT TOURNAMENT
  {
    path: "/edit-tournament/:id",
    component: EditTournamentDetails,
    permission: ["Org"],
  },
  /**************************************************** JURY **********************************************/
  // JURY MEM ADD *
  {
    path: "/jury-members-add/:comp_id",
    component: JuryMembersAdd,
    permission: ["Org"],
  },
  {
    path: "phase/jury-members-add/:comp_id",
    component: JuryMembersAdd,
    permission: ["Org"],
  },
  // ADD EXISTING JURY
  {
    path: "/add-existing-jury/:comp_id",
    component: AddExistingJury,
    permission: ["Org"],
  },
  {
    path: "/phase/add-existing-jury/:comp_id",
    component: AddExistingJury,
    permission: ["Org"],
  },
  // EDIT JURY
  {
    path: "/jury-members-edit/:comp_id/:jury_id",
    component: JuryMemberEdit,
    permission: ["Org"],
  },
  {
    path: "phase/jury-members-edit/:comp_id/:jury_id",
    component: JuryMemberEdit,
    permission: ["Org"],
  },
  // ALL JURY OF COMPID
  {
    path: "/all-jury/:comp_id",
    component: AllJury,
    permission: ["Org"],
  },
  {
    path: "/phase/all-jury/:comp_id",
    component: AllJury,
    permission: ["Org"],
  },
  // JURY MEM EDIT
  {
    path: "/jury-members-edit/:comp_id/:jury_id",
    component: JuryMemberEdit,
    permission: ["Org"],
  },
  {
    path: "phase/jury-members-edit/:comp_id/:jury_id",
    component: JuryMemberEdit,
    permission: ["Org"],
  },

  // JURY MEM DETAILS
  {
    path: "/jury-members-ditails/:comp_id/:jury_id",
    component: JuryMembersDitails,
    permission: ["Org"],
  },
  {
    path: "/phase/jury-members-ditails/:comp_id/:jury_id",
    component: JuryMembersDitails,
    permission: ["Org"],
  },

  // ALL JURY MEM LIST OF ORG
  {
    path: "/jury-members-list",
    component: JuryMembersList,
    permission: ["Org"],
  },

  //  //
  //  {
  //   path: "/jury-one-shot-details",
  //   component: JuryOneShotDetails,
  //   permission: ["Org"],
  // },
  // //
  // {
  //   path: "/jury-tournament-details",
  //   component: JuryTournamentDetails,
  //   permission: ["Org"],
  // },

  /*******************************************Participant*********************************************/
  {
    path: "/participant-list",
    component: ParticipantList,
    permission: ["Org"],
  },
  {
    path: "/participant-details/:participant_id",
    component: ParticipantDetails,
    permission: ["Org"],
  },
  {
    path: "/phase/participant-details/:participant_id",
    component: ParticipantDetails,
    permission: ["Org"],
  },
  {
    path: "/manual-add-participant/:comp_id",
    component: AddParticipant,
    permission: ["Org"],
  },
  {
    path: "/quick-mode-add-participant/:comp_id",
    component: QuickMode,
    permission: ["Org"],
  },
  {
    path: "/existing-participant/:comp_id",
    component: ExistingParticipant,
    permission: ["Org"],
  },
  // ******* DUPLICATE FOR BREADCRUMB
  {
    path: "phase/existing-participant/:comp_id",
    component: ExistingParticipant,
    permission: ["Org"],
  },
  {
    path: "/all-participant/:comp_id",
    component: AllParticipant,
    permission: ["Org"],
  },
  // ******* DUPLICATE FOR BREADCRUMB
  {
    path: "phase/all-participant/:comp_id",
    component: AllParticipant,
    permission: ["Org"],
  },
  {
    path: "/all-participant-prev-phase/:phase_id/:comp_id",
    component: AllParticipantFromPrevPhase,
    permission: ["Org"],
  },
  {
    path: "/phase/existing-participant/:phase_id/:comp_id",
    component: PhaseTransfer,
    permission: ["Org"],
  },

  /* */
  // {
  //   path: "/candidate-response",
  //   component: CandidateResponse,
  //   permission: ["Org"],
  // },

  {
    path: "/competition-results/:comp_id",
    component: CompetitionResults,
    permission: ["Org", "Jury"],
  },

  {
    path: "/edit-participant-details/:participant_id",
    component: EditParticipantDetails,
    permission: ["Org"],
  },

  /***************************************************** word *****************************************/
  {
    path: "/edit-word-details/:word_id",
    component: EditWordDetails,
    permission: ["Org"],
  },
  {
    path: "/phase/edit-word-details/:word_id",
    component: EditWordDetails,
    permission: ["Org"],
  },
  {
    path: "/word-details/:word_id",
    component: WordDetails,
    permission: ["Org"],
  },
  {
    path: "/phase/word-details/:word_id",
    component: WordDetails,
    permission: ["Org"],
  },
  {
    path: "/word-list",
    component: WordList,
    permission: ["Org"],
  },
  {
    path: "/add-word-details/:comp_id",
    component: AddWordDetails,
    permission: ["Org"],
  },
  {
    path: "/phase/add-word-details/:comp_id",
    component: AddWordDetails,
    permission: ["Org"],
  },
  {
    path: "/add-word-details/:comp_id",
    component: AddWordDetails,
    permission: ["Org"],
  },
  {
    path: "phase/add-word-details/:comp_id",
    component: AddWordDetails,
    permission: ["Org"],
  },
  {
    path: "/organiser-word-list/:comp_id",
    component: OrganiserWordList,
    permission: ["Org"],
  },
  {
    path: "/phase/organiser-word-list/:comp_id",
    component: OrganiserWordList,
    permission: ["Org"],
  },
  /* START COMP */
  //1- START COMP
  {
    path: "/start-competition/:comp_id",
    component: StartCompetition,
    permission: ["Org", "Jury"],
  },
  //2.0- START ROUND
  {
    path: "/start-competition-round/:comp_id",
    component: Round,
    permission: ["Org", "Jury"],
  },
  //2.1- START SPECIAL ROUND
  {
    path: "/start-special-competition-round/:comp_id",
    component: SpecialRound,
    permission: ["Org", "Jury"],
  },
  //3- CANDIDATE DETAILS
  {
    path: "/candidate-details/:round_id",
    component: CandidateDetails,
    permission: ["Org", "Jury"],
  },
  //4- CANDIDATE RESPONSE ORGANIZER
  {
    path: "/candidate-response-organizer/:round_id/:player_id",
    component: CandidateResponseOrganizer,
    permission: ["Org", "Jury"],
  },
  //5-JURY VALIDATE - IN START COMP
  {
    path: "/jury-validate/:round_id/:player_id",
    component: JuryValidate,
    permission: ["Org", "Jury"],
  },
  //6-JURY VALIDATE - IN START COMP
  {
    path: "/candidate-spelling-status/:round_id/:player_id/:word_id",
    component: CandidateSpellingStatus,
    permission: ["Org", "Jury"],
  },
  //7- SUMMARY OF SELECTION -FOR A ROUND
  {
    path: "/summary-selection/:round_id",
    component: SummarySelection,
    permission: ["Org", "Jury"],
  },
  {
    path: "/candidate-response/:round_id/:player_id/:word_id",
    component: CandidateResponse,
    permission: ["Org", "Jury"],
  },
  {
    path: "/*",
    component: PageNotFound,
    permission: ["public", "Jury", "Org"],
  },
  {
    path: "/unauthorize-access",
    component: UnauthorizeAccess,
    permission: ["public", "Jury", "Org"],
  },

  {
    path: "/loader",
    component: ComponentLoader,
    permission: ["public", "Jury", "Org"],
  },
];

export default routeConfig;

// import Register from "../pages/Auth/Register";
// import VerifyAccount from "../pages/Auth/VerifyAccount";
// import Login from "../pages/Auth/Login";
// import ForgotPassword from "../pages/Auth/ForgotPassword";
// import ResetPassword from "../pages/Auth/ResetPassword";
// import MyAccountOrganizer from "../pages/Auth/MyAccountOrganizer";

// import OrgDashboard from "../pages/Dashboard/OrgDashboard";
// import JuryDashboard from "../pages/Dashboard/JuryDashboard";

// import CreateOneShotCompetition from "../pages/Competition/OneShot/CreateOneShotCompetition";
// import EditOneShotCompetition from "../pages/Competition/OneShot/EditOneShotCompetition";

// import CreateTournament from "../pages/Competition/Tournament/CreateTournament";
// import TournamentDetails from "../pages/Competition/Tournament/TournamentDetails";
// import PhaseManage from "../pages/Competition/Tournament/PhaseManage";
// import EditTournamentDetails from "../pages/Competition/Tournament/EditTournamentDetails";
// import CreateSubCompetition from "../pages/Competition/Tournament/CreateSubCompetition";

// import AddExistingJury from "../pages/Jury/AddExistingJury";
// import JuryMembersAdd from "../pages/Jury/JuryMembersAdd";
// import JuryMembersDitails from "../pages/Jury/JuryMembersDitails";
// import AllJury from "../pages/Jury/AllJury";
// import JuryMembersList from "../pages/Jury/JuryMembersList";
// import JuryOneShotDetails from "../pages/Jury/JuryOneShotDetails";
// import JuryTournamentDetails from "../pages/Jury/JuryTournamentDetails";
// import JuryValidate from "../pages/StartCompetition/JuryValidate";
// import JuryMemberEdit from "../pages/Jury/EditJuryMember";

// import AddParticipant from "../pages/Participants/AddParticipant";
// import AllParticipant from "../pages/Participants/AllParticipant";
// import ParticipantDetails from "../pages/Participants/ParticipantDetails";
// import ParticipantList from "../pages/Participants/ParticipantList";
// import AllParticipantFromPrevPhase from "../pages/Participants/AllParticipantFromPrevPhase";
// import PhaseTransfer from "../pages/Participants/PhaseTransfer";

// import AddWordDetails from "../pages/Word/AddWordDetails";
// import WordDetails from "../pages/Word/WordDetails";
// import WordList from "../pages/Word/WordList";
// import OrganiserWordList from "../pages/Word/OrganiserWordList";

// import CandidateResponse from "../pages/StartCompetition/CandidateResponse";
// import CandidateResponseOrganizer from "../pages/StartCompetition/CandidateResponseOrganizer";
// import CompetitionResults from "../pages/CompetitionResults";
// import EditParticipantDetails from "../pages/Participants/EditParticipantDetails";
// import EditWordDetails from "../pages/Word/EditWordDetails";
// import ExistingParticipant from "../pages/Participants/ExistingParticipant";
// import OneShotDetails from "../pages/Competition/OneShot/OneShotDetails";
// import QuickMode from "../pages/Participants/QuickMode";
// import SummarySelection from "../pages/StartCompetition/SummarySelection";
// import CompetitionType from "../pages/CompetitionType";
// import StartCompetition from "../pages/StartCompetition/StartCompetition";

// import CandidateDetails from "../pages/StartCompetition/CandidateDetails";
// import Round from "../pages/StartCompetition/Round";

// import PageNotFound from "../pages/Helping_Pages/PageNotFound";
// import UnauthorizeAccess from "../pages/Helping_Pages/UnauthorizeAccess";

// import CandidateSpellingStatus from "../pages/StartCompetition/CandidateSpellingStatus";

// const routeConfig = [
//   /***************************************************** AUTH ******************************************/
//   //SIGN UP
//   {
//     path: "/register",
//     component: Register,
//     permission: ["public", "Jury", "Org"], // Public route
//   },
//   //VERIFY ACCOUNT
//   {
//     path: "/verify-account/:token",
//     component: VerifyAccount,
//     permission: ["public", "Jury", "Org"], // Public route
//   },
//   //SIGN IN
//   {
//     path: "/",
//     component: Login,
//     permission: ["public", "Jury", "Org"], // Public route
//   },
//   //FORGOT PASSWORD
//   {
//     path: "/forgot-password",
//     component: ForgotPassword,
//     permission: ["public", "Jury", "Org"], // Public route
//   },
//   //RESET PASSWORD
//   {
//     path: "/reset-password/:user",
//     component: ResetPassword,
//     permission: ["public", "Jury", "Org"], // Public route
//   },
//   //MY ACCOUNT
//   {
//     path: "/my-account-organizer",
//     component: MyAccountOrganizer,
//     permission: ["Jury", "Org"], // Requires user authentication
//   },
//   /***************************************************** DASHBOARD ***********************************/
//   //ORG-DASHBOARD
//   {
//     path: "/org-dashboard",
//     component: OrgDashboard,
//     permission: ["Org"],
//   },
//   //JURY-DASHBOARD
//   {
//     path: "/jury-dashboard",
//     component: JuryDashboard,
//     permission: ["Jury"],
//   },

//   /*********************************************** COMPETITION *******************************************/
//   // COMP TYPE SELECTION
//   {
//     path: "/competition-type",
//     component: CompetitionType,
//     permission: ["Org"],
//   },
//   // ONESHOT CREATE
//   {
//     path: "/create-one-shot-competition",
//     component: CreateOneShotCompetition,
//     permission: ["Org"],
//   },
//   //ONESHOT VIEW -DETAILS
//   {
//     path: "/one-shot-details/:id",
//     component: OneShotDetails,
//     permission: ["Org","Jury"],
//   },
//   //ONESHOT UPDATE
//   {
//     path: "/edit-one-shot-competition/:id",
//     component: EditOneShotCompetition,
//     permission: ["Org"],
//   },
//   // TOURNAMENT CREATE
//   {
//     path: "/create-tournament",
//     component: CreateTournament,
//     permission: ["Org"],
//   },
//   //ONESHOT VIEW -DETAILS
//   {
//     path: "/tournament-details/:id",
//     component: TournamentDetails,
//     permission: ["Org","Jury"]
//   },
//   //PHASE MANGE --- INTERMIDIARY PAGE NEW
//   {
//     path: "/phase-manage/:id",
//     component: PhaseManage,
//     permission: ["Org"],
//   },
//   //TOURNAMENT-SUB-COMP-CREATE
//   {
//     path: "/create-sub-competition/:toornamentId/:phaseId",
//     component: CreateSubCompetition,
//     permission: ["Org"],
//   },
//   //EDIT TOURNAMENT
//   {
//     path: "/edit-tournament/:id",
//     component: EditTournamentDetails,
//     permission: ["Org"],
//   },
//   /**************************************************** JURY **********************************************/
//   // JURY MEM ADD *
//   {
//     path: "/jury-members-add/:comp_id",
//     component: JuryMembersAdd,
//     permission: ["Org"],
//   },
//   // ADD EXISTING JURY
//   {
//     path: "/add-existing-jury/:comp_id",
//     component: AddExistingJury,
//     permission: ["Org"],
//   },
//   // EDIT JURY
//   {
//     path: "/jury-members-edit/:comp_id/:jury_id",
//     component: JuryMemberEdit,
//     permission: ["Org"],
//   },
//   // ALL JURY OF COMPID
//   {
//     path: "/all-jury/:comp_id",
//     component: AllJury,
//     permission: ["Org"],
//   },
//   // ALL JURY MEM LIST OF ORG
//   {
//     path: "/jury-members-list",
//     component: JuryMembersList,
//     permission: ["Org"],
//   },
//   // JURY MEM DETAILS
//   {
//     path: "/jury-members-ditails/:comp_id/:jury_id",
//     component: JuryMembersDitails,
//     permission: ["Org"],
//   },
//   //
//   {
//     path: "/jury-one-shot-details",
//     component: JuryOneShotDetails,
//     permission: ["Org"],
//   },
//   //
//   {
//     path: "/jury-tournament-details",
//     component: JuryTournamentDetails,
//     permission: ["Org"],
//   },

//   /* Participant */
//   {
//     path: "/participant-list",
//     component: ParticipantList,
//     permission: ["Org"],
//   },
//   {
//     path: "/participant-details/:participant_id",
//     component: ParticipantDetails,
//     permission: ["Org"],
//   },
//   {
//     path: "/manual-add-participant/:comp_id",
//     component: AddParticipant,
//     permission: ["Org"],
//   },
//   {
//     path: "/quick-mode-add-participant/:comp_id",
//     component: QuickMode,
//     permission: ["Org"],
//   },
//   {
//     path: "/existing-participant/:comp_id",
//     component: ExistingParticipant,
//     permission: ["Org"],
//   },
//   {
//     path: "/all-participant/:comp_id",
//     component: AllParticipant,
//     permission: ["Org"],
//   },
//   {
//     path: "/all-participant-prev-phase/:phase_id/:comp_id",
//     component: AllParticipantFromPrevPhase,
//     permission: ["Org"],
//   },
//   {
//     path: "/existing-participant/:phase_id/:comp_id",
//     component: PhaseTransfer,
//     permission: ["Org"],
//   },
//   /* */
//   {
//     path: "/candidate-response/:round_id/:player_id/:word_id",
//     component: CandidateResponse,
//     permission: ["Org"],
//   },

//   {
//     path: "/competition-results/:comp_id",
//     component: CompetitionResults,
//     permission: ["Org"],
//   },

//   {
//     path: "/edit-participant-details/:participant_id",
//     component: EditParticipantDetails,
//     permission: ["Org"],
//   },

//   /* word */
//   {
//     path: "/edit-word-details/:word_id",
//     component: EditWordDetails,
//     permission: ["Org"],
//   },
//   {
//     path: "/word-details/:word_id",
//     component: WordDetails,
//     permission: ["Org"],
//   },
//   {
//     path: "/word-list",
//     component: WordList,
//     permission: ["Org"],
//   },
//   {
//     path: "/add-word-details/:comp_id",
//     component: AddWordDetails,
//     permission: ["Org"],
//   },
//   {
//     path: "/organiser-word-list/:comp_id",
//     component: OrganiserWordList,
//     permission: ["Org"],
//   },
//   /* START COMP */
//   //1- START COMP
//   {
//     path: "/start-competition/:comp_id",
//     component: StartCompetition,
//     permission: ["Org","Jury"],
//   },
//   //2- START ROUND
//   {
//     path: "/start-competition-round/:comp_id",
//     component: Round,
//     permission: ["Org","Jury"],
//   },
//   //3- CANDIDATE DETAILS
//   {
//     path: "/candidate-details/:round_id",
//     component: CandidateDetails,
//     permission: ["Org","Jury"],
//   },
//   //4- CANDIDATE RESPONSE ORGANIZER
//   {
//     path: "/candidate-response-organizer/:round_id/:player_id",
//     component: CandidateResponseOrganizer,
//     permission: ["Org","Jury"],
//   },
//   //5-JURY VALIDATE - IN START COMP
//   {
//     path: "/jury-validate/:round_id/:player_id",
//     component: JuryValidate,
//     permission: ["Org","Jury"],
//   },
//   //6-JURY VALIDATE - IN START COMP
//   {
//     path: "/candidate-spelling-status/:round_id/:player_id/:word_id",
//     component: CandidateSpellingStatus,
//     permission: ["Org","Jury"],
//   },
//   //7- SUMMARY OF SELECTION -FOR A ROUND
//   {
//     path: "/summary-selection/:round_id",
//     component: SummarySelection,
//     permission: ["Org","Jury"],
//   },
//   {
//     path: "/*",
//     component: PageNotFound,
//     permission: ["public", "Jury", "Org"],
//   },
//   {
//     path: "/unauthorize-access",
//     component: UnauthorizeAccess,
//     permission: ["public", "Jury", "Org"],
//   },
// ];

// export default routeConfig;
