export const ONESHOT_GET_SUCCESS = "ONESHOT_GET_SUCCESS";
export const ONESHOT_GET_ERROR = "ONESHOT_GET_ERROR";

export const ONESHOT_DETAILS_GET_SUCCESS = "ONESHOT_DETAILS_GET_SUCCESS";
export const ONESHOT_DETAILS_GET_ERROR = "ONESHOT_DETAILS_GET_ERROR";

export const ONESHOT_CREATE_SUCCESS = "ONESHOT_CREATE_SUCCESS";
export const ONESHOT_CREATE_ERROR = "ONESHOT_CREATE_ERROR";

export const ONESHOT_UPDATE_SUCCESS = "ONESHOT_UPDATE_SUCCESS";
export const ONESHOT_UPDATE_ERROR = "ONESHOT_UPDATE_ERROR";

export const ONESHOT_DELETE_SUCCESS = "ONESHOT_DELETE_SUCCESS";
export const ONESHOT_DELETE_ERROR = "ONESHOT_DELETE_ERROR";

export const ONESHOT_SUCCESS_CLEAR = "ONESHOT_SUCCESS_CLEAR";
export const ONESHOT_ERROR_CLEAR = "ONESHOT_ERROR_CLEAR";

export const ONESHOT_ACTION_LOADER = "ONESHOT_ACTION_LOADER";

export const CLEAR_ONE_SHOT_DETAILS = "CLEAR_ONE_SHOT_DETAILS";