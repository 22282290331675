import React, { useState, useEffect } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  GetOneShotDetails,
  // GetAllOneShot,
} from "../../../Store/Action/oneShotAction";
import { WordAdd } from "../../../Store/Action/wordAction";
import {
  WORD_ACTION_LOADER_START,
  WORD_SUCCESS_CLEAR,
  WORD_ERROR_CLEAR,
} from "../../../Store/Types/wordTypes";
// import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { AiCall } from "../../../HelperFunctions/AiCall";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const AddWordDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { comp_id } = params;

  const dispatch = useDispatch();
  const { currentUserInfo ,currentUser} = useSelector((state) => state.Auth);
  const { Word_Action_Loader, Word_SuccessMessage, Word_ErrorMessage } =
    useSelector((state) => state.Word);
  const {
    // OneShot_Loading,
    // OneShot_Data,
    OneShotDetails,
    // OneShot_SuccessMessage,
    // OneShot_ErrorMessage,
    // OneShot_Get_Error_Message,
  } = useSelector((state) => state.OneShot);

  const obj = {
    createdBy: currentUserInfo._id,
    oneShotId: comp_id,
    theme: "",
    context: "",
    nature: "",
    word: "",
    origin: "",
    spellingAlternatives: "",
    definition: "",
    word_phonetics: "",
    illustrativeSentence: "",
    homonyms: "",
    gender: "",
    difficulty: "Easy",
    isApproved: false,
    isDeleted: false,
  };

  const [state, setState] = useState(obj);
  const [AiBtn, setAiBtn] = useState({
    definition: false,
    illustrativeSentence: false,
  });
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    dispatch(
      GetOneShotDetails({ orgId: currentUserInfo._id, oneshotId: comp_id })
    );
  }, [comp_id]);

  useEffect(() => {
    if (
      OneShotDetails &&
      OneShotDetails.themeOfWords &&
      OneShotDetails.wordContext
    ) {
      setState({
        ...state,
        ["context"]: OneShotDetails.wordContext,
        ["theme"]: OneShotDetails.themeOfWords,
        ["difficulty"]: OneShotDetails.difficulty,
      });
    }
  }, [OneShotDetails]);

  /* input change */
  const handleInputChange = (name, value) => {
    // console.log(name, value);
    setState({ ...state, [name]: value });
  };

  /* Add */
  const handleAddWord = () => {
    dispatch({ type: WORD_ACTION_LOADER_START });
    dispatch(WordAdd(state));
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Word_SuccessMessage) {
      const text = MsgTranslator(Word_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: WORD_SUCCESS_CLEAR });
      navigate(-1);
    }
    if (Word_ErrorMessage) {
      const text = MsgTranslator(Word_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: WORD_ERROR_CLEAR });
    }
  }, [Word_SuccessMessage, Word_ErrorMessage]);

  /* Ai response handler */
  const handleAIResponse = async (fieldname) => {
    // console.log(fieldname);
    setAiBtn({ ...AiBtn, [fieldname]: true });

    const prompt = `I want to generate only the field ${fieldname} for the word ${state.word}.Try to answer simple and short.The ${fieldname} should never reference a word different from ${state.word}, not even derivatives. It should be the word itself.`;
    let AiRes = await AiCall(prompt);
    // console.log(114,AiRes);
    setState({ ...state, [fieldname]: AiRes });

    setAiBtn({ ...AiBtn, [fieldname]: false });
  };
  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard",language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                ...(window.location.href.includes("/phase/")
                  ? [
                      {
                        page: MsgTranslator("Phase Manage",language),
                        link: 1,
                      },
                    ]
                  : []), // conditional
                {
                  page: MsgTranslator("Word List",language),
                  link: `/organiser-word-list/${comp_id}`,
                },
                {
                  page: MsgTranslator("Add Word",language),
                  link: 0,
                },
              ]}
            />
            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em>Add</em>{" "}
                  </TranslatedComponent>
                  <TranslatedComponent></TranslatedComponent>
                </span> */}
                  <PageTitle title={"<em>Add</em> Word"} lang={language} />
              </h3>
            </div>
            <div className="candit-sectied-from">
              <TranslatedComponent>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="theme-m">
                      <strong>Theme :</strong>
                      <p>{state.theme}</p>
                    </div>
                    <div className="theme-m">
                      <strong>Context :</strong>
                      <p>{state.context}</p>
                    </div>
                  </div>
                  <div className="col-lg-6"></div>
                </div>

                <div className="ft-create-section">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="text-from-four">
                        <form>
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Word :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Word"
                                name="word"
                                value={state.word}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Spelling Alternatives :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Spelling Alternatives"
                                name="spellingAlternatives"
                                value={state.spellingAlternatives}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Word Phonetics :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Number of The Participants"
                                name="word_phonetics"
                                value={state.word_phonetics}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Origin :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Origin"
                                name="origin"
                                value={state.origin}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Nature :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Nature"
                                name="nature"
                                value={state.nature}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              {" "}
                              Homonyms :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Homonyms"
                                name="homonyms"
                                value={state.homonyms}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Gender :
                            </label>
                            <div className="col-sm-7">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Gender"
                                name="gender"
                                value={state.gender}
                                onChange={(e) =>
                                  handleInputChange(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="text-from-one">
                        {/* <form>
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {" "}
                            Definition :
                          </label>
                          <div className="col-sm-7">
                            <textarea
                              className="form-control"
                              rows="3"
                              name="definition"
                              value={state.definition}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            {" "}
                            Illustrative Sentence :
                          </label>
                          <div className="col-sm-7">
                            <textarea
                              className="form-control"
                              rows="3"
                              name="illustrativeSentence"
                              value={state.illustrativeSentence}
                              onChange={(e) =>
                                handleInputChange(e.target.name, e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                       
                      </form> */}
                        <form>
                          {/* difficulty */}
                          <div className="form-group row">
                            <label
                              for="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Word difficulty level :
                            </label>
                            <div className="col-sm-7">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="difficulty"
                                value={state.difficulty}
                                // onChange={(e) =>
                                //   handleInputChange(e.target.name, e.target.value)
                                // }
                                required
                                disabled
                              >
                                <option value="Easy">Easy</option>
                                <option value="Moderate">Moderate</option>
                                <option value="Hard">Hard</option>
                              </select>
                            </div>
                          </div>
                          {/* definition */}
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Definition :
                            </label>
                            <div className="col-sm-7">
                              <div
                                className="st-6-stt"
                                style={{ height: "unset" }}
                              >
                                <div className="st-2-sr">
                                  <textarea
                                    type="text"
                                    rows="5"
                                    placeholder="Definition"
                                    className="form-control"
                                    name="definition"
                                    value={state.definition}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                    style={{ resize: "none", border: "unset" }}
                                  />
                                </div>
                                {state.word ? (
                                  <div className="st-edit-fild">
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleAIResponse("definition");
                                      }}
                                    >
                                      {AiBtn.definition ? (
                                        <div>
                                          <CircularProgress
                                            style={{
                                              height: "1rem",
                                              width: "1rem",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          src={
                                            window.location.origin +
                                            `/./Assets/images/refrace.png`
                                          }
                                          alt="ai"
                                        />
                                      )}
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {/* illustrative sentence */}
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail3"
                              className="col-sm-5 col-form-label"
                            >
                              Illustrative Sentence :
                            </label>
                            <div className="col-sm-7">
                              <div
                                className="st-6-stt"
                                style={{ height: "unset" }}
                              >
                                <div className="st-2-sr">
                                  <textarea
                                    rows="5"
                                    type="text"
                                    placeholder="Illustrative Sentence"
                                    className="form-control"
                                    name="illustrativeSentence"
                                    value={state.illustrativeSentence}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                    style={{ resize: "none", border: "unset" }}
                                  />
                                </div>
                                {state.word ? (
                                  <div className="st-edit-fild">
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleAIResponse(
                                          "illustrativeSentence"
                                        );
                                      }}
                                    >
                                      {AiBtn.illustrativeSentence ? (
                                        <div>
                                          <CircularProgress
                                            style={{
                                              height: "1rem",
                                              width: "1rem",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <img
                                          src={
                                            window.location.origin +
                                            `/./Assets/images/refrace.png`
                                          }
                                          alt="ai"
                                        />
                                      )}
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="buttaon-lane">
                  <div className="back-name">
                    <button className="back-g" onClick={() => navigate(-1)}>
                      Back
                    </button>
                  </div>
                  <div className="right-edit">
                    <button className="start-g" onClick={() => handleAddWord()}>
                      {Word_Action_Loader && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "-10%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                      Save
                    </button>
                  </div>
                </div>
              </TranslatedComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWordDetails;
