import React, { useState, useEffect, useMemo } from "react";
import { Modal, Box } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { BASE_URL } from "../../../Config/Config";

import { Excel_Or_Csv_ParticiapntAdd } from "../../Store/Action/participantAction";
import {
  PARTICIPANT_ACTION_LOADER_START,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
} from "../../Store/Types/participantTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../ToastMsg/ToastMsg";
import TranslatedComponent from "../../translator/TranslatedComponent";
import { MsgTranslator } from "../../HelperFunctions/MsgTranslator";

const FileUploader = ({ compId }) => {
  const dispatch = useDispatch();
  const { currentUserInfo , currentUser} = useSelector((state) => state.Auth);
  const {
    Participant_Loading,
    Participant_Action_Loader,
    Participants_Data,
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  } = useSelector((state) => state.Participant);

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [language,setLanguage] = useState('fr');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (file) {
      dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
      const fromData = new FormData();
      fromData.append("file", file);
      fromData.append("createdBy", currentUserInfo._id);
      fromData.append("compId", compId);

      dispatch(Excel_Or_Csv_ParticiapntAdd(fromData));
    } else {
      console.error("No file selected.");
      const text = MsgTranslator("No file selected.",language)
      notify(text, "error");
    }
  };

  /* success msg and error msg handling */
  useEffect(() => {
    // if (Participant_SuccessMessage) {
    //   notify(Participant_SuccessMessage, "success");
    //   dispatch({ type: PARTICIPANT_SUCCESS_MSG_CLEAR });
    //   navigate(`/all-participant/${compId}`);
    // }

    if (Participant_ErrorMessage) {
      const text = MsgTranslator(Participant_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
    }
  }, [Participant_SuccessMessage, Participant_ErrorMessage]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <Box className="participants-mobel-option">
        <TranslatedComponent>
          <label htmlFor="file-upload" style={{ display: "inline" }}>
            Import CSV or Excel{" "}
            <i
              className="fa -regular fa-arrow-right"
              style={{ color: "#000000" }}
            ></i>
            {selectedFile && selectedFile.name && (
              <p style={{ color: "red" }}>{selectedFile.name}</p>
            )}
          </label>
        </TranslatedComponent>
      </Box>
      <TranslatedComponent>
        <input
          id="file-upload"
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <strong>Sample File</strong>
        &nbsp;&nbsp;
        <span style={{ color: "red" }}>
          <a href="/Assets/datasheet/epelle_player.csv" download="csv-sample">
            CSV
          </a>
        </span>
        &nbsp;&nbsp;
        <span style={{ color: "red" }}>
          <a
            href="/Assets/datasheet/epelle_player.xlsx"
            download="excel-sample"
          >
            Excel
          </a>
        </span>
      </TranslatedComponent>
      {Participant_Action_Loader && <CircularProgress />}
    </>
  );
};

export default FileUploader;
