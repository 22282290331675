import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllOneShot,
  GetOneShotDetails,
} from "../../Store/Action/oneShotAction";
import {
  ONESHOT_SUCCESS_CLEAR,
  ONESHOT_ERROR_CLEAR,
} from "../../Store/Types/oneShotTypes";

const OneShot_SubComp_Name = ({ compId, isheader = false }) => {
  const dispatch = useDispatch();
  const { currentUserInfo } = useSelector((state) => state.Auth);
  const {
    OneShot_Loading,
    OneShot_Data,
    OneShotDetails,
    OneShot_SuccessMessage,
    OneShot_ErrorMessage,
  } = useSelector((state) => state.OneShot);

  const [name, setName] = useState("");

  useEffect(() => {
    dispatch(
      GetOneShotDetails({ orgId: currentUserInfo._id, oneshotId: compId })
    );
  }, []);

  useEffect(() => {
    if (OneShotDetails && Object.keys(OneShotDetails).length > 0) {
      setName(OneShotDetails.name);
    }
  }, [OneShotDetails]);

  /* clr msg needed */

  return isheader ? name : <em>in {name}</em>;
};

export default OneShot_SubComp_Name;
