import React, { useEffect, useState, useMemo } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import { useNavigate, useParams } from "react-router-dom";

import { BASE_URL } from "../../../Config/Config";

import { useSelector, useDispatch } from "react-redux";

import Select from "react-select";
import countryList from "react-select-country-list";

import {
  GetCompWiseJury,
  GetAllJury,
  ExistingJuryAdd,
} from "../../../Store/Action/juryAction";
import {
  JURY_ACTION_LOADER_START,
  JURY_SUCCESS_MSG_CLEAR,
  JURY_ERROR_MSG_CLEAR,
} from "../../../Store/Types/juryTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const AddExistingJury = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { comp_id } = params;
  const options = useMemo(() => countryList().getData(), []);

  const dispatch = useDispatch();
  const { currentUserInfo ,currentUser} = useSelector((state) => state.Auth);
  const {
    Jury_Loading,
    Jury_Action_Loader,
    Jury_Comp_Data,
    Jury_All_Data,
    Jury_SuccessMessage,
    Jury_ErrorMessage,
    Jury_Get_Error_Message,
  } = useSelector((state) => state.Jury);

  const [juryIds, setJuryIds] = useState([]);
  const [allSelected, setAllSelected] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [language, setLanguage] = useState("fr");

  const obj = {
    firstName: "",
    phone: "",
    country: "",
  };

  const [state, setState] = useState(obj);
  /* Input Change  */
  const handleInputChange = (field, value) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  /* getting competition wise data */
  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(
      GetCompWiseJury({ orgId: currentUserInfo._id, oneshotId: comp_id })
    );
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(GetAllJury({ orgId: currentUserInfo._id }));
  }, [comp_id]);

  useEffect(() => {
    if (Jury_Comp_Data && Jury_Comp_Data.length > 0) {
      let ids = Jury_Comp_Data.map((sp) => {
        return sp._id;
      });
      setJuryIds(ids);
      setAllSelected(ids);
    }
    if (Jury_All_Data && Jury_All_Data.length > 0) {
      setFilteredData(Jury_All_Data);
    }
  }, [Jury_Comp_Data, Jury_All_Data, comp_id]);

  /* checkbox selection handler */
  const handlecheckBox = (checkStatus, p_id) => {
    if (checkStatus) {
      const updatedSelected = [...allSelected];
      updatedSelected.push(p_id);
      setAllSelected(updatedSelected);
    } else {
      if (allSelected.includes(p_id)) {
        //remove the id from array
        const updatedSelected = [...allSelected];
        let newSelUpdate = updatedSelected.filter((item) => item !== p_id);
        setAllSelected(newSelUpdate);
      }
    }
  };

  const handleAllcheckBox = (checkStatus) => {
    // console.log(110, filteredData);
    // console.log(111, Jury_All_Data);
    if (checkStatus) {
      if (filteredData && filteredData.length > 0) {
        let ids = filteredData.map((sp) => {
          return sp._id;
        });
        setAllSelected(ids);
      } else if (filteredData && filteredData.length === 0) {
        let ids = Jury_All_Data.map((sp) => {
          return sp._id;
        });
        setAllSelected(ids);
      }
    } else {
      setAllSelected([]);
    }
  };

  /* Data filter */
  const handleFilterSearch = (e) => {
    e.preventDefault();

    const filteredArray = Jury_All_Data.filter((jury) => {
      const firstNameMatch =
        !state.firstName ||
        (jury.firstName &&
          jury.firstName
            .toLowerCase()
            .includes(state.firstName.toLowerCase())) ||
        state.firstName.trim() === "";

      const phoneMatch =
        !state.phone ||
        (jury.phone &&
          jury.phone.toLowerCase().includes(state.phone.toLowerCase())) ||
        state.phone.trim() === "";

      const countryMatch =
        !state.country.label ||
        (jury.country?.label &&
          jury.country.label
            .toLowerCase()
            .includes(state.country.label.toLowerCase())) ||
        state.country.label.trim() === "";

      // Combine conditions with OR (||) to get the final result
      return firstNameMatch && phoneMatch && countryMatch;
    });

    // Update the filtered data state
    setFilteredData(filteredArray);
  };

  /* Update */
  const handleSave = () => {
    dispatch({ type: JURY_ACTION_LOADER_START });
    const transformedArray = allSelected.map((id, index) => ({
      juryId: id,
    }));
    // let additional_juryIds = [];
    // if (juryIds && juryIds.length > 0) {
    //   additional_juryIds = juryIds.map(id => ({juryId: id }));
    // };
    const data = {
      createdBy: currentUserInfo._id,
      oneShotId: comp_id,
      juryArray: transformedArray,
    };
    // console.log(data);
    dispatch(ExistingJuryAdd(data));
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Jury_SuccessMessage) {
      const text = MsgTranslator(Jury_SuccessMessage,language)
      notify(text, "success");
      dispatch({ type: JURY_SUCCESS_MSG_CLEAR });
      window.location.href.includes("/phase/")
      ? navigate(`/phase/all-jury/${comp_id}`)
      : navigate(`/all-jury/${comp_id}`);
    }
    if (Jury_ErrorMessage) {
      const text = MsgTranslator(Jury_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: JURY_ERROR_MSG_CLEAR });
    }
    if (Jury_Get_Error_Message) {
      const text = MsgTranslator(Jury_Get_Error_Message,language)
      notify(text, "error");
      dispatch({ type: JURY_ERROR_MSG_CLEAR });
    }
  }, [Jury_SuccessMessage, Jury_ErrorMessage]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          {/* <button type="button" id="sidebarCollapse" className="btn btn-info">
            <i className="fas fa-align-left"></i>
          </button> */}
          {!Jury_Loading && filteredData && filteredData.length >= 0 ? (
            <div className="main-container-section">
              <Header
                breadcrumb={[
                  {
                    page:  MsgTranslator("Dashboard",language),
                    link:
                      currentUserInfo.role === "Org"
                        ? "/org-dashboard"
                        : "/jury-dashboard",
                  },
                  ...(window.location.href.includes("/phase/")
                    ? [{ page:  MsgTranslator("Phase Manage",language),
                      link: 2 }] // Include this item only if the condition is met
                    : []), // Empty array if the condition is not met, effectively skipping this item
                  {
                    page: MsgTranslator("Jury List",language),
                    link: window.location.href.includes("/phase/")
                      ? `/phase/all-jury/${comp_id}`
                      : `/all-jury/${comp_id}`,
                  },
                  {
                    page: MsgTranslator("Add Existing Jury",language),
                    link: 0,
                  },
                ]}
              />

              <div className="upcoming-title">
                <h3>
                  {/* <span>
                    <em></em> 
                  </span> */}
                    <PageTitle title={"<em>Add Existing</em> Jury"} lang={language} />
                </h3>
              </div>
              <div className="candit-sectied-from">
                <h4>Jury</h4>

                <div className="ft-create-section">
                  <div className="row">
                    <TranslatedComponent>
                      <form
                        method="Post"
                        onChange={(e) => handleFilterSearch(e)}
                        onSubmit={(e) => handleFilterSearch(e)}
                        className="st-search-top-point"
                      >
                        <div className="col-lg-4 col-sm-4 col-12">
                          <div className="text-from-one">
                            {/* First Name */}
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-12 col-form-label"
                              >
                                First Name :
                              </label>
                              <div className="col-sm-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Name"
                                  name="firstName"
                                  value={state.firstName}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-12">
                          <div className="text-from-one">
                            {/* Phone */}
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-12 col-form-label"
                              >
                                Phone Number :
                              </label>
                              <div className="col-sm-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone Number"
                                  name="phone"
                                  value={state.phone}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e.target.name,
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-12">
                          <div className="text-from-one">
                            {/* Country */}
                            <div className="form-group row">
                              <label
                                htmlFor="inputEmail3"
                                className="col-sm-12 col-form-label"
                              >
                                Country :
                              </label>
                              <div className="col-sm-12">
                                <Select
                                  isClearable={true}
                                  options={options}
                                  value={state.country}
                                  onChange={(e) =>
                                    handleInputChange("country", e)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <button type="submit" className="search-p">
                          <i className="fa-solid fa-magnifying-glass" />
                        </button>
                      </form>
                    </TranslatedComponent>
                  </div>
                </div>

                    <TranslatedComponent>
                   
                <div className="existing-section scrollable">
                  {/* <div> */}
                  <table className="table table-striped table-responsive">
                    <thead>
                      <tr>
                        <th className="column1">
                          <input
                            type="checkbox"
                            onClick={(e) => {
                              handleAllcheckBox(e.target.checked);
                            }}
                          />
                        </th>
                        <th scope="col">First Name </th>
                        <th scope="col">Phone Number </th>
                        <th scope="col">Counter</th>
                        <th scope="col">Country</th>
                        {/* <th scope="col">Roles</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {!Jury_Loading ? (
                        filteredData && filteredData.length > 0 ? (
                          filteredData.map((sj, index) => {
                            return (
                              <tr key={sj._id}>
                                {/* <td className="column12a">{index + 1}</td> */}
                                <td className="column1a">
                                  <input
                                    type="checkbox"
                                    checked={
                                      allSelected.includes(sj._id)
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      handlecheckBox(e.target.checked, sj._id);
                                    }}
                                  />
                                </td>
                                <td className="column1a">
                                  {sj?.firstName ||
                                    "" + " " + sj?.lastName ||
                                    ""}
                                </td>
                                {/* <td className="column1a">{sj?.email || ""}</td> */}
                                <td className="column1a">{sj?.phone || ""}</td>
                                <td className="column1a">?</td>
                                <td className="column13a">
                                  {sj?.country?.label || ""}
                                </td>
                              </tr>
                            );
                          })
                        ) : null
                      ) : (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="6">
                            <MagnifyingGlass
                              visible={true}
                              height="80"
                              width="80"
                              ariaLabel="magnifying-glass-loading"
                              wrapperStyle={{ display: "inline-block" }}
                              wrapperClass="magnifying-glass-wrapper"
                              glassColor="#c0efff"
                              color="#e15b64"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {/* </div> */}
                </div>

                <button className="add-p" onClick={() => handleSave()}>
                  <>
                    <img
                      src={window.location.origin + `/Assets/images/add-p.png`}
                      alt="Add"
                    />
                    Save existing Jury
                  </>
                </button>
                <div className="buttaon-lane">
                  <button className="back-g" onClick={() => navigate(-1)}>
                    Back
                  </button>
                  {/* <button className="start-g">Save</button> */}
                </div>
                </TranslatedComponent>
              </div>
            </div>
          ) : (
            <ComponentLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default AddExistingJury;
