/* import tytpes */
import {
  ONESHOT_GET_SUCCESS,
  ONESHOT_GET_ERROR,
  ONESHOT_DETAILS_GET_SUCCESS,
  ONESHOT_DETAILS_GET_ERROR,
  ONESHOT_CREATE_SUCCESS,
  ONESHOT_CREATE_ERROR,
  ONESHOT_UPDATE_SUCCESS,
  ONESHOT_UPDATE_ERROR,
  ONESHOT_DELETE_SUCCESS,
  ONESHOT_DELETE_ERROR,
  ONESHOT_SUCCESS_CLEAR,
  ONESHOT_ERROR_CLEAR,
} from "../Types/oneShotTypes";

import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";
import { getInputAdornmentUtilityClass } from "@mui/material";

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const GetAllOneShot = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          adminId: data,
          orgId: data,
        },
      };

      const response = await axios.get(
        `${SERVER_URL}/oneshot/all-oneshot-details`,
        config
      );

      dispatch({
        type: ONESHOT_GET_SUCCESS,
        payload: {
          successMessage: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ONESHOT_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const GetOneShotDetails = (data) => {
  return async (dispatch) => {
    try {
      const config = {
        headers: {
          oneshotId: data.oneshotId,
          orgId: data.orgId
        },
      };

      const response = await axios.get(
        `${SERVER_URL}/oneshot/oneshot-details`,
        config
      );

      dispatch({
        type: ONESHOT_DETAILS_GET_SUCCESS,
        payload: {
          successMessage: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      console.error(93, error);
      let data = error?.response?.data?.message;

      dispatch({
        type: ONESHOT_DETAILS_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const CreateOneShot = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/oneshot/oneshot-add`,
        data
      );

      window.location.href = `/one-shot-details/${response.data.data._id}`;

      dispatch({
        type: ONESHOT_CREATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
          newCompId: response.data.data._id,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ONESHOT_CREATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const UpdateOneShot = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.patch(
        `${SERVER_URL}/oneshot/oneshot-update`,
        data
      );

      dispatch({
        type: ONESHOT_UPDATE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ONESHOT_UPDATE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};

export const DeleteOneShotAction = (data) => {
  return async (dispatch) => {
    try {
      // console.log(data);
      const config = {
        headers: {
          compId: data.compId,
          orgId: data.orgId,
        },
      };

      const response = await axios.delete(
        `${SERVER_URL}/oneshot/oneshot-delete`,
        config
      );

      dispatch({
        type: ONESHOT_DELETE_SUCCESS,
        payload: {
          successMessage: response.data.message,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ONESHOT_DELETE_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};
