import React from 'react';
// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';

import { Provider } from "react-redux";
import store from "./Store/index";
import i18n from './translator/i18n';
import { I18nextProvider } from "react-i18next";


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(

  <Provider store={store}>
    {/* <React.StrictMode> */}
    {/* <BrowserRouter> */}
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
    {/* {translatedApp} */}
    <ToastContainer />
    {/* </BrowserRouter> */}
    {/* </React.StrictMode> */}
  </Provider>
);