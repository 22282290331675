import React, { useState, useEffect } from "react";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import SidebarRight from "../../../component/Sideber/SidebarRight";
import Header from "../../../component/Header/Header";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../Config/Config";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

import { GetAllPartcipant } from "../../../Store/Action/participantAction";
import {
  PARTICIPANT_ACTION_LOADER_START,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
} from "../../../Store/Types/participantTypes";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import { MagnifyingGlass } from "react-loader-spinner";
import { calculateAge } from "../../../HelperFunctions/AgeFromDob";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle.jsx";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator.js";

const ParticipantList = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const {
    Participant_Loading,
    Participant_Action_Loader,
    Participants_All_Data,
    Participant_SuccessMessage,
    Participant_ErrorMessage,
    Participant_Get_Error_Message,
  } = useSelector((state) => state.Participant);

  const [rows, setrows] = useState([]);
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
    dispatch(GetAllPartcipant({ createdBy: currentUserInfo._id }));
  }, []);

  useEffect(() => {
    if (!Participant_Loading && Participants_All_Data) {
      const transformedData = Participants_All_Data.map((participant, i) => ({
        index: String(i + 1).padStart(2, "0"),
        id: participant._id,
        createdBy: participant.createdBy,
        firstName: participant.firstName,
        lastName: participant.lastName,
        dob: participant.dob,
        age: participant.dob ? calculateAge(participant.dob) || null : null,
        grade: participant.grade,
        sex: participant.sex,
        country: participant.country ? participant.country.label : null,
        school: participant.school,
        city: participant.city,
        phone: participant.phone,
        email: participant.email,
        img: participant.img,
        isDeleted: participant.isDeleted,
        additionalNote: participant.additionalNote,
        createdAt: participant.createdAt,
        updatedAt: participant.updatedAt,
      }));
      setrows(transformedData);
    }
  }, [Participants_All_Data, Participant_Loading]);

  /* success msg and error msg handling */
  useEffect(() => {
    if (Participant_ErrorMessage) {
      const text = MsgTranslator(Participant_ErrorMessage,language)
      notify(text, "error");
      dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
    }
    
    if (Participant_Get_Error_Message) {
      const text = MsgTranslator(Participant_Get_Error_Message,language)
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...",language),
        text: text,
      }).then(() => {
        dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
      });
    }
  }, [Participant_ErrorMessage, Participant_Get_Error_Message]);

  const columns = [
    {
      field: "index",
      headerName: "",
      width: 90,
      renderCell: (params) => (
        <span style={{ fontWeight: "bold" }}>{params.row.index}</span>
      ),
    },
    {
      field: "firstName",
      headerName: <TranslatedComponent>First Name</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "lastName",
      headerName: <TranslatedComponent>Last Name</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "dob",
      headerName: <TranslatedComponent>Birthday</TranslatedComponent>,
      type: "text",
      width: 200,
      // headerAlign: "left",
      //align: 'center',
    },
    {
      field: "age",
      headerName: <TranslatedComponent>Age</TranslatedComponent>,
      // type: "text",
      width: 200,
      // headerAlign: "left",
      //align: 'center',
    },
    // {
    //   field: "fullName",
    //   headerName: "Full Name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 200,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    // },
    {
      field: "school",
      headerName: <TranslatedComponent>School</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "grade",
      headerName: <TranslatedComponent>Grade</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "country",
      headerName: <TranslatedComponent>Country</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "city",
      headerName: <TranslatedComponent>City</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "phone",
      headerName: <TranslatedComponent>Phone</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
    {
      field: "email",
      headerName: <TranslatedComponent>Email</TranslatedComponent>,
      width: 200,
      //align: 'center',
    },
  ];

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);


  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content">
          {!Participant_Loading ? (
            <>
              <div className="main-container-section">
                <Header
                  breadcrumb={[
                    {
                      page: MsgTranslator("Dashboard",language),
                      link:
                        currentUserInfo.role === "Org"
                          ? "/org-dashboard"
                          : "/jury-dashboard",
                    },
                    {
                      page: MsgTranslator("All Participant List",language),
                      link: `/participant-list`,
                    },
                  ]}
                />

                <div className="upcoming-title ">
                  <h3>
                    {/* <span>
                      <TranslatedComponent>
                        <em>Participant</em>{" "}
                      </TranslatedComponent>
                      <TranslatedComponent>List</TranslatedComponent>
                    </span> */}
                    {/* <span dangerouslySetInnerHTML={{ __html: "<em>Participant</em> Liste" }}/> */}
                    <PageTitle title={"<em>Participant</em> List"} lang={language} />
                  </h3>
                </div>
                <div className="upcoming-box scrollable">
                  <Box sx={{ height: 500, width: "100%" }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10, 20, 30, 40, 50]}
                      // checkboxSelection
                      disableRowSelectionOnClick
                    />
                  </Box>
                  <div className="buttaon-lane">
                    <div className="back-name">
                      <TranslatedComponent>
                        <button className="back-g" onClick={() => navigate(-1)}>
                          Back
                        </button>
                      </TranslatedComponent>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ComponentLoader />
          )}
        </div>
        <SidebarRight />
      </div>
    </>
  );
};

export default ParticipantList;
