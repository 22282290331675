import React, { useState, useEffect } from 'react';
import trnslateObj from "../../translator/fr/common.json";

const PageTitle = ({ title, lang = "fr" }) => {
    // console.log(title)
    // console.log(222222,trnslateObj.translation['<em>Add Existing</em> Jury'])
    // console.log("header",lang,lang);
    const [htmlTxt, setHtmlTxt] = useState(title);
    useEffect(() => {
        let translatedTxt = title;
        if (lang === "fr") {
            translatedTxt = trnslateObj.translation[title] || title;
            // console.log(11,trnslateObj[title])
            // console.log("inside if",translatedTxt)
        }
        // console.log(translatedTxt,lang)
        setHtmlTxt(translatedTxt);
    }, [title, lang]); // Depend on title and lang to avoid unnecessary updates

    return <span dangerouslySetInnerHTML={{ __html: htmlTxt }} />;
};

export default PageTitle;
