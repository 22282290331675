import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";

import Select from "react-select";
import countryList from "react-select-country-list";

import { useSelector, useDispatch } from "react-redux";
import { UserDataUpdate, DeleteUser } from "../../../Store/Action/userAction";
import { GetUserDetails } from "../../../Store/Action/authAction";
import {
  USER_SUCCESS_CLEAR,
  USER_ERROR_CLEAR,
} from "../../../Store/Types/userTypes";
import Swal from "sweetalert2";

import { notify } from "../../../component/ToastMsg/ToastMsg";
import "react-toastify/dist/ReactToastify.css";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  // console.log(`${formattedMinutes}:${formattedSeconds}`)
  return `${formattedMinutes}:${formattedSeconds}`;
};

const Settings = () => {
  const navigate = useNavigate();

  const options = useMemo(() => countryList().getData(), []);

  const dispatch = useDispatch();
  const {
    authLoading,
    authenticate,
    currentUser,
    authSuccessMessage,
    authErrorMessage,
  } = useSelector((state) => state.Auth);

  const {
    userLoading,
    userSuccessMessage,
    userErrorMessage,
    userDelSuccessMessage,
    userDelErrorMessage,
  } = useSelector((state) => state.User);

  let user = {
    adminId: currentUser?._id || null,
    userId: currentUser?._id || null,
    role: "Org",
    word_PerCandidate: currentUser?.word_per_candidate || "",
    default_alt_Time: currentUser?.default_alt_Time || "",
    metaDatas: [],
  };

  const [state, setState] = useState(user);
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    setState({
      ...state,
      adminId: currentUser?._id || null,
      userId: currentUser?._id || null,
      role: "Org",
      word_PerCandidate: currentUser?.word_PerCandidate || "",
      default_alt_Time: currentUser?.default_alt_Time
        ? formatTime(currentUser?.default_alt_Time) || ""
        : "",
      metaDatas: [],
    });
  }, [currentUser]);

  // Function to update metaData field
  const updateMetaData = (key, value) => {
    const existingIndex = state.metaDatas.findIndex(
      (item) => item.metaKey === key
    );
    const updatedMetaData = [...state.metaDatas];

    if (existingIndex !== -1) {
      updatedMetaData[existingIndex].metaValue = value;
    } else {
      updatedMetaData.push({
        metaKey: key,
        metaValue: value,
      });
    }

    return updatedMetaData;
  };

  /* Change in FORM  */
  const handleChange = (name, value) => {
    // Update metaData if the field is in the list
    const updatedMetaData = updateMetaData(name, value);
    setState({ ...state, [name]: value, metaDatas: updatedMetaData });
  };

  const handleUserUpdate = () => {
    let totalsec = 0;
    if (state.default_alt_Time) {
      // console.log(state.default_alt_Time);
      const time = state.default_alt_Time.split(":");
      if (time.length !== 2) {
        const text = MsgTranslator(
          "Alloted time format mismatched, i.e. 03:20 - 03min 20sec",
          language
        );
        notify(
          text,
          // "Alloted time format mismatched, i.e. 03:20 - 03min 20sec",
          "error"
        );
        setState({
          ...state,
          default_alt_Time: "",
        });
        return;
      }
      let timer = {
        min: Number(time[0].trim()),
        sec: Number(time[1].trim()),
      };
      if (
        timer.min >= 60 ||
        timer.sec >= 60 ||
        time[0].trim().length > 2 ||
        time[1].trim().length !== 2
      ) {
        const text = MsgTranslator(
          "Alloted time format mismatched, i.e. 03:20 - 03min 20sec",
          language
        );
        notify(text, "error");
        setState({
          ...state,
          default_alt_Time: "",
        });
        return;
      }
      totalsec = timer.min * 60 + timer.sec;
      //find in metaKey where key is "default_alt_Time" and make keyValue to totalSec
      const updatedMetaDatas = state.metaDatas.map((meta) => {
        if (meta.metaKey === "default_alt_Time") {
          return { ...meta, metaValue: totalsec }; // Convert totalSec to string if necessary
        }
        return meta;
      });
      setState({
        ...state,
        default_alt_Time: totalsec,
        metaDatas: updatedMetaDatas,
      });
    }
    let obj = state;
    if (state.default_alt_Time && totalsec) {
      const updatedMetaDatas = state.metaDatas.map((meta) => {
        if (meta.metaKey === "default_alt_Time") {
          return { ...meta, metaValue: totalsec }; // Convert totalSec to string if necessary
        }
        return meta;
      });
      obj = { ...obj, default_alt_Time: totalsec, metaDatas: updatedMetaDatas };
    }

    dispatch(UserDataUpdate(obj));
  };

  useEffect(() => {
    if (userSuccessMessage) {
      const text = MsgTranslator(userSuccessMessage, language);
      notify(text, "success");

      if (localStorage.getItem("userId")) {
        dispatch(GetUserDetails(localStorage.getItem("userId")));
      }

      dispatch({ type: USER_SUCCESS_CLEAR });
    }
    if (userErrorMessage) {
      const text = MsgTranslator(userErrorMessage, language);
      notify(text, "error");
      dispatch({ type: USER_ERROR_CLEAR });
    }

    if (userDelSuccessMessage) {
      dispatch({ type: USER_SUCCESS_CLEAR });
      navigate("/");
    }
    if (userDelErrorMessage) {
      const text = MsgTranslator(userDelErrorMessage, language);
      notify(text, "error");
      dispatch({ type: USER_ERROR_CLEAR });
    }
  }, [
    userSuccessMessage,
    userErrorMessage,
    userDelSuccessMessage,
    userDelErrorMessage,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                <PageTitle
                  title={"<em>My Settings</em> Organizer"}
                  lang={language}
                />
              </h3>
            </div>
            <div className="candit-sectied-from">
              <TranslatedComponent>
                <div className="ft-create-section">
                  <div className="text-from-one">
                    <div className="row">
                      {/* Default Aloted Time Per Word */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            for="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Default Aloted Time Per Word :
                          </label>
                          <div className="col-sm-7">
                            <input
                              className="form-control"
                              placeholder="e.g - 02:10"
                              name="default_alt_Time"
                              value={state.default_alt_Time}
                              onChange={(e) => {
                                handleChange(e.target.name, e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Number of words multiplier per candidate */}
                      <div className="col-lg-4">
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-5 col-form-label"
                          >
                            Number of words multiplier per candidate :
                          </label>
                          <div className="col-sm-7">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="words per candidate"
                              name="word_PerCandidate"
                              value={state.word_PerCandidate}
                              onChange={(e) =>
                                handleChange(e.target.name, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dt-admin">
                  <button className="save-0" onClick={() => handleUserUpdate()}>
                    Save
                  </button>
                </div>
              </TranslatedComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
