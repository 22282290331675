/* import tytpes */
import {
  ORG_DB_DATA_GET_SUCCESS,
  ORG_DB_DATA_GET_ERROR,
} from "../Types/OrgDashBoardTypes";

import { SERVER_URL, BASE_URL } from "../../Config/Config";
import axios from "axios";

export const userAuth = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const GetAllDashBoardData = (data) => {
  return async (dispatch) => {
    try {
      // console.log(data,`${SERVER_URL}/dashboard/dashboard-view`);
      const response = await axios.post(
        `${SERVER_URL}/dashboard/dashboard-view`,
        data
      );

      dispatch({
        type: ORG_DB_DATA_GET_SUCCESS,
        payload: {
          successMessage: response.data.message,
          data: response.data.data,
        },
      });
    } catch (error) {
      let data = error?.response?.data?.message;

      dispatch({
        type: ORG_DB_DATA_GET_ERROR,
        payload: {
          errorMessage: data,
        },
      });
    }
  };
};
