import { useEffect, useState } from "react";
import Header from "../../../component/Header/Header";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { LoginAction } from "../../../Store/Action/authAction";
import {
  AUTH_SUCCESS_CLEAR,
  AUTH_ERROR_CLEAR,
} from "../../../Store/Types/authTypes";
import Swal from "sweetalert2";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    authLoading,
    authenticate,
    currentUserInfo,
    authSuccessMessage,
    authErrorMessage,
  } = useSelector((state) => state.Auth);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(LoginAction(state));
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (currentUserInfo) {
        if (currentUserInfo.role === "Org") {
          navigate("/org-dashboard");
        } else if (currentUserInfo.role === "Jury") {
          navigate("/jury-dashboard");
        }
      }
    };
  }, []);

  useEffect(() => {
    if (authSuccessMessage) {
      dispatch({ type: AUTH_SUCCESS_CLEAR });
      // console.log("login page", currentUserInfo);
      if (currentUserInfo) {
        if (currentUserInfo.role === "Org") {
          navigate("/org-dashboard");
        } else if (currentUserInfo.role === "Jury") {
          navigate("/jury-dashboard");
        }
      }
    }
    if (authErrorMessage) {
      Swal.fire({
        icon: "error",
        title: "Oups...",
        text: MsgTranslator(authErrorMessage),
      }).then(() => {
        dispatch({ type: AUTH_ERROR_CLEAR });
      });
    }
  }, [authSuccessMessage, authErrorMessage]);



  return (
    <>
      <div className="wrapper">
        <div id="content-two">
          <div className="main-container-section login-header">
            <Header />

            <div className="upcoming-title">
              <h3>
                {/* <span>Login</span> */}
                <span>Se connecter</span>
              </h3>
            </div>
            <div className="login-section">
              {/* <h3>Login</h3> */}
              <h3>Se connecter</h3>
              <div className="login-page">
                <div className="form">
                  <form>
                    <div className="form-group row">
                      {/* <label className="text-login col-sm-2 col-form-label">Email :</label> */}
                      <label className="text-login col-sm-2 col-form-label">E-mail :</label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          placeholder="E-mail"
                          value={state.email}
                          name="email"
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      {/* <label className="text-login col-sm-2 col-form-label">Password :</label> */}
                      <label className="text-login col-sm-2 col-form-label">Mot de passe :</label>
                      <div className="col-sm-10">
                        <input
                          type="password"
                          // placeholder="password"
                          placeholder="Mot de passe"
                          value={state.password}
                          name="password"
                          onChange={(e) =>
                            handleChange(e.target.name, e.target.value)
                          }
                        /></div></div>
                    <div className="ft-section">
                      <a href="/register" className="forgot-st">
                        Créer un nouveau compte
                      </a>
                      <a href="/forgot-password" className="forgot-st">
                        Mot de passe oublié
                      </a>
                      {/* <a href="/register" className="forgot-st">
                        Create New Account
                      </a>
                      <a href="/forgot-password" className="forgot-st">
                        Forgot Password
                      </a> */}
                    </div>
                    {/* <button onClick={(e) => handleLogin(e)}>login</button> */}
                    <button onClick={(e) => handleLogin(e)}>Se connecter</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
