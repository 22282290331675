export const PARTICIPANT_GET_SUCCESS = 'PARTICIPANT_GET_SUCCESS';
export const PARTICIPANT_GET_ERROR = 'PARTICIPANT_GET_ERROR';

export const PARTICIPANT_ALL_GET_SUCCESS = 'PARTICIPANT_ALL_GET_SUCCESS';
export const PARTICIPANT_ALL_GET_ERROR = 'PARTICIPANT_ALL_GET_ERROR';

export const PARTICIPANT_DETAILS_GET_SUCCESS = 'PARTICIPANT_DETAILS_GET_SUCCESS';
export const PARTICIPANT_DETAILS_GET_ERROR = 'PARTICIPANT_DETAILS_GET_ERROR';

export const PARTICIPANT_ADD_SUCCESS = 'PARTICIPANT_ADD_SUCCESS';
export const PARTICIPANT_ADD_ERROR = 'PARTICIPANT_ADD_ERROR';

export const PARTICIPANT_UPDATE_SUCCESS = 'PARTICIPANT_UPDATE_SUCCESS';
export const PARTICIPANT_UPDATE_ERROR = 'PARTICIPANT_UPDATE_ERROR';

export const PARTICIPANT_SUCCESS_MSG_CLEAR = 'PARTICIPANT_SUCCESS_MSG_CLEAR';
export const PARTICIPANT_ERROR_MSG_CLEAR = 'PARTICIPANT_ERROR_MSG_CLEAR';

export const PARTICIPANT_ACTION_LOADER_START = 'PARTICIPANT_ACTION_LOADER_START';
