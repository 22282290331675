import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
// import { BASE_URL } from "../../../Config/Config";

import { useSelector, useDispatch } from "react-redux";

import { QuickParticiapntAdd } from "../../../Store/Action/participantAction";
import {
  PARTICIPANT_ACTION_LOADER_START,
  PARTICIPANT_SUCCESS_MSG_CLEAR,
  PARTICIPANT_ERROR_MSG_CLEAR,
} from "../../../Store/Types/participantTypes";
// import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import { notify } from "../../../component/ToastMsg/ToastMsg";
import TranslatedComponent from "../../../translator/TranslatedComponent";
import PageTitle from "../../../component/PageTitle/PageTitle";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator";

const QuickMode = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { comp_id } = params;

  const dispatch = useDispatch();
  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);
  const {
    Participant_Action_Loader,
    Participant_SuccessMessage,
    Participant_ErrorMessage,
  } = useSelector((state) => state.Participant);

  const [field, setField] = useState([
    { createdBy: currentUserInfo._id, firstName: "", lastName: "" },
  ]);
  const [language, setLanguage] = useState("fr");

  const handleFieldExtend = (type, num) => {
    if (type === "pluse") {
      const newField = {
        createdBy: currentUserInfo._id,
        name: "",
        firstName: "",
        lastName: "",
      };
      // Create a new array by spreading the existing field array and adding the newField
      const updatedField = [...field, newField];
      // Update the state with the new array
      setField(updatedField);
    } else {
      const index = num;

      if (index >= 0) {
        // Create a new array without modifying the original one
        let x = [...field];
        let a = x.slice(0, index);
        let b = x.slice(index + 1);
        const updatedField = [...a, ...b];
        // console.log(updatedField);
        // Update the state with the new array
        setField(updatedField);
      } else {
        // console.log("Invalid index", index);
      }
    }
  };

  const HandleName = (index, value) => {
    // Create a copy of the field array to avoid modifying the original state directly
    const updatedField = [...field];
    // Update the name property at the specified index
    updatedField[index].name = value;
    updatedField[index].firstName = updatedField[index].name
      .split("")
      .includes(" ")
      ? updatedField[index].name.split(" ").slice(0, -1).join(" ")
      : updatedField[index].name;
    updatedField[index].lastName = updatedField[index].name
      .split("")
      .includes(" ")
      ? updatedField[index].name.split(" ").pop()
      : "";

    // Update the state with the new array
    setField(updatedField);
  };

  const handleQuickAdd = () => {
    let obj = {
      compId: comp_id,
      createdBy: currentUserInfo._id,
      playerArray: field,
    };
    // console.log(obj);
    dispatch({ type: PARTICIPANT_ACTION_LOADER_START });
    dispatch(QuickParticiapntAdd(obj));
  };

  /* success msg and error msg handling */
  useEffect(() => {
    if (Participant_SuccessMessage) {
      const text = MsgTranslator(Participant_SuccessMessage, language);
      notify(text, "success");
      dispatch({ type: PARTICIPANT_SUCCESS_MSG_CLEAR });
      navigate(`/all-participant/${comp_id}`);
    }
    if (Participant_ErrorMessage) {
      const text = MsgTranslator(Participant_ErrorMessage, language);
      notify(text, "error");
      dispatch({ type: PARTICIPANT_ERROR_MSG_CLEAR });
    }
  }, [Participant_SuccessMessage, Participant_ErrorMessage]);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  return (
    <>
      <div className="wrapper">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header
              breadcrumb={[
                {
                  page: MsgTranslator("Dashboard", language),
                  link:
                    currentUserInfo.role === "Org"
                      ? "/org-dashboard"
                      : "/jury-dashboard",
                },
                {
                  page: MsgTranslator("Participant List", language),
                  link: `/all-participant/${comp_id}`,
                },
                {
                  page: MsgTranslator("Quick Participant Add", language),
                  link: `/quick-mode-add-participant/${comp_id}`,
                },
              ]}
            />

            <div className="upcoming-title">
              <h3>
                {/* <span>
                  <TranslatedComponent>
                    <em>Ouick</em>
                  </TranslatedComponent>
                  <TranslatedComponent>Mode</TranslatedComponent>
                </span> */}
                <PageTitle title={"<em>Ouick</em> Mode"} lang={language} />
              </h3>
            </div>
            <div className="candit-sectied-from">
              <TranslatedComponent>
                <h4>Add Participant</h4>
              </TranslatedComponent>
              <div className="ft-create-section">
                {field.map((sf, i) => {
                  return (
                    <div className="participant-section" key={i}>
                      <TranslatedComponent>
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="text-from-two">
                              <form>
                                <div className="form-group row">
                                  <label
                                    htmlFor="inputEmail3"
                                    className="col-sm-5 col-form-label"
                                  >
                                    Participant Number :
                                  </label>
                                  <div className="col-sm-7">
                                    <p>{i + 1}</p>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="text-from-two">
                              <form>
                                <div className="form-group row">
                                  <label
                                    htmlFor="inputEmail3"
                                    className="col-sm-5 col-form-label"
                                  >
                                    Participant Name :
                                  </label>
                                  <div className="col-sm-7">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Participant Name"
                                      name="name"
                                      value={sf.name}
                                      onChange={(e) =>
                                        HandleName(i, e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="minus">
                              {field.length === i + 1 ? (
                                <>
                                  {i !== 0 && (
                                    <button
                                      onClick={() =>
                                        handleFieldExtend("minus", i)
                                      }
                                    >
                                      <img
                                        src={
                                          window.location.origin +
                                          `/Assets/images/minus.png`
                                        }
                                        alt="operation"
                                      />
                                    </button>
                                  )}
                                  <button
                                    onClick={() =>
                                      handleFieldExtend("pluse", field.length)
                                    }
                                  >
                                    <img
                                      src={
                                        window.location.origin +
                                        `/Assets/images/pluse.png`
                                      }
                                      alt="operation"
                                    />
                                  </button>
                                </>
                              ) : (
                                <button
                                  onClick={() => handleFieldExtend("minus", i)}
                                >
                                  <img
                                    src={
                                      window.location.origin +
                                      `/Assets/images/minus.png`
                                    }
                                    alt="operation"
                                  />
                                </button>
                              )}
                              {/* <button onClick={() => field.length === i + 1 ? handleFieldExtend("pluse", field.length) : handleFieldExtend("minus", i)}>
                                <img src={field.length === i + 1 ? window.location.origin+ `/Assets/images/pluse.png` : window.location.origin+ `/Assets/images/minus.png`} alt='Minus' />
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </TranslatedComponent>
                    </div>
                  );
                })}
              </div>
              <div className="buttaon-lane">
                <TranslatedComponent>
                  <div className="back-name">
                    <button className="back-g" onClick={() => navigate(-1)}>
                      Back
                    </button>
                  </div>
                  <div className="right-edit">
                    <button className="start-g" onClick={handleQuickAdd}>
                      {Participant_Action_Loader && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "-10%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                      Save
                    </button>
                  </div>
                </TranslatedComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickMode;
