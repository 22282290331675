import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SwithchButton from "../../translator/SwithchButton.jsx";
import BreadCrumbComponent from "../BreadCrumb/BreadCrumbComponent.jsx";
import TranslatedComponent from "../../translator/TranslatedComponent.jsx";
import PageTitle from "../PageTitle/PageTitle.jsx";
import { Language } from "@mui/icons-material";

const Header = ({ breadcrumb }) => {
  const navigate = useNavigate();
  const { authenticate, currentUser } = useSelector((state) => state.Auth);

  const [myClasses, setMyClasses] = useState(false);
  const [language , setLanguage] = useState('fr');

  const HandleLogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);

  const addClass = () => setMyClasses(!myClasses);
  return (
    <>
      <section className="header-section">
        <h2 className="top-header-A">
          {/* Welcome To<span> epelle</span> */}
          <PageTitle title={"<em>Welcome To</em> epelle"} lang={language}
            
          />
        </h2>
        {authenticate && localStorage.getItem("token") ? (
          <div
            onClick={addClass}
            className={
              myClasses
                ? "user-section-two dropdown3 show"
                : "user-section-two dropdown3"
            }
          >
            <span style={{ cursor: "pointer" }}>
              <span className="user-p-two">
                <img
                  src={
                    window.location.origin + `/Assets/images/uer-picture.png`
                  }
                  alt="loginpageImg"
                />
              </span>
              {currentUser
                ? currentUser.firstName + " " + currentUser.lastName
                : null}
            </span>
            <TranslatedComponent>
              <div className="dropdown-menu">
                <button
                  className="dropdown-item border-top"
                  onClick={() => navigate("/my-account-organizer")}
                >
                  <i className="far fa-user"></i>My Profile
                </button>
                {/* <button className="dropdown-item" onClick={() => navigate("/my-account-organizer")}>
                    <i className="far fa-edit"></i>Edit Profile</button> */}
                {currentUser && currentUser.role !== "Jury" && (
                  <button
                    className="dropdown-item"
                    onClick={() => navigate("/my-account-settings")}
                  >
                    <i className="fa fa-cog"></i>Account Settings
                  </button>
                )}
                <button
                  className="dropdown-item"
                  onClick={() => HandleLogOut()}
                >
                  <i className="fa fa-sign-out-alt"></i>Sign Out
                </button>
              </div>
            </TranslatedComponent>
          </div>
        ) : null}
      </section>
      <div style={{ display: "inline-block" }}>
        <BreadCrumbComponent breadcrumb={breadcrumb} />
      </div>
    </>
  );
};

export default Header;
