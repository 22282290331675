import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarLeft from "../../../component/Sideber/SidebarLeft";
import Header from "../../../component/Header/Header";
import PlayerSerialNumber from "../../../component/PlayerSerialNumber/PlayerSerialNumber";
import ComponentLoader from "../../../component/ComponentLoader/ComponentLoader";

import { GetCurrentRound } from "../../../Store/Action/roundAction";
import {
  ROUND_SUCCESS_MSG_CLEAR,
  ROUND_ERROR_MSG_CLEAR,
} from "../../../Store/Types/roundTypes";
import Swal from "sweetalert2";

import { GetJuryCapability } from "../../../Store/Action/juryAction";

import {
  StartSocketConnection,
  JoinRoom,
  pageChange,
  pageQuery,
} from "../../../HelperFunctions/Socket/socket.js";
import PlayerAggrigatedDetails from "../Helping_Pages/PlayerAggrigatedDetails.jsx";
import TranslatedComponent from "../../../translator/TranslatedComponent.jsx";
import PageTitle from "../../../component/PageTitle/PageTitle.jsx";
import { Language } from "@mui/icons-material";
import { MsgTranslator } from "../../../HelperFunctions/MsgTranslator.js";

const CandidateDetails = () => {
  const { round_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentUserInfo, currentUser } = useSelector((state) => state.Auth);

  const { Jury_Loading, Jury_Action_Loader, Jury_Capabilities } = useSelector(
    (state) => state.Jury
  );

  const {
    round_Loading,
    round_All_Data,
    round_Comp_Data,
    current_round_Details,
    round_Action_Loader,
    round_SuccessMessage,
    round_ErrorMessage,
    round_Get_Error_Message,
  } = useSelector((state) => state.Round);

  const [players, setPlayers] = useState([]);
  const [playerIndex, setPlayerIndex] = useState(0);
  const [loader, setLoader] = useState(true);
  const [pageState, setPageState] = useState(false);
  const [language, setLanguage] = useState("fr");

  useEffect(() => {
    dispatch({ type: "RESET_REDUX" });
    dispatch(GetCurrentRound({ roundId: round_id }));
    StartSocketConnection();
  }, [round_id]);

  pageQuery(({ competitionId, page }) => {
    setPageState(page);
  });

  useEffect(() => {
    if (pageState) {
      navigate(pageState);
    }
  }, [pageState]);

  useEffect(() => {
    if (
      Object.keys(current_round_Details).length > 0 &&
      current_round_Details.participants
    ) {
      if (currentUserInfo.role === "Jury") {
        dispatch(
          GetJuryCapability({
            juryId: currentUserInfo._id,
            compId: current_round_Details.compId._id,
          })
        );
      }

      const allUnevaluatedPlayers = current_round_Details.participants
        .filter((sp) => sp.qualifyingStatus == null)
        .map((sp) => sp.playerId);

      setPlayers(allUnevaluatedPlayers);
      setLoader(false);

      if (
        current_round_Details.compId._id !== undefined &&
        current_round_Details.compId._id !== null
      ) {
        JoinRoom(current_round_Details.compId._id, currentUserInfo._id);
        pageChange(
          current_round_Details.compId._id,
          `/candidate-details/${round_id}`
        );
      }
    }
  }, [current_round_Details]);

  useEffect(() => {
    if (round_SuccessMessage) {
      dispatch({ type: ROUND_SUCCESS_MSG_CLEAR });
    }
    if (round_ErrorMessage) {
      const text = MsgTranslator(round_ErrorMessage, language);
      Swal.fire({
        icon: "error",
        title: MsgTranslator("Oops...", language),
        text: text,
      }).then(() => {
        dispatch({ type: ROUND_ERROR_MSG_CLEAR });
      });
    }
  }, [round_SuccessMessage, round_ErrorMessage]);

  const handleRoute = () => {
    if (players[0]?._id) {
      const nextPlayerId = players[0]._id || null;
      if (current_round_Details?._id && nextPlayerId) {
        navigate(
          `/candidate-response-organizer/${current_round_Details?._id}/${nextPlayerId}`
        );

        pageChange(
          current_round_Details.compId._id,
          `/candidate-response-organizer/${current_round_Details?._id}/${nextPlayerId}`
        );
      }
    }
  };

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const { language } = currentUser;
      setLanguage(language);
    }
  }, [currentUser]);
  // console.log(players)
  return (
    <>
      <div className="wrapper start-section-one4">
        <SidebarLeft />

        <div id="content-two">
          <div className="main-container-section">
            <Header />

            <div className="upcoming-title">
              <h3>
                <PageTitle
                  title={"<em>Start</em> Competition"}
                  lang={language}
                />
              </h3>
            </div>
            {!loader && players.length > 0  && Object.keys(players[0]) && players[0].firstName ? (
              <div className="start-section">
                <div className="container-fluid">
                  <TranslatedComponent>
                    <div className="row">
                      <div className="col-lg-12">
                        <TranslatedComponent>
                          <div className="candit-sectied-from-two">
                            <h5>
                              Round Number :{current_round_Details?.name || ""}
                            </h5>
                            <div className="candidate-number">
                              <div className="text-from-five">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <div className="ct-mumber-9">
                                      <p>
                                        Candidate Number :
                                        <strong>
                                          {" "}
                                          #
                                          {(players[0]?._id && (
                                            <PlayerSerialNumber
                                              compId={
                                                current_round_Details?.compId
                                                  ?._id
                                              }
                                              playerId={players[0]?._id}
                                            />
                                          )) ||
                                            ""}
                                        </strong>{" "}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="row">
                                      <label
                                        for="inputEmail3"
                                        className="col-sm-6 col-form-label"
                                      >
                                        First Name :
                                      </label>
                                      <div className="col-sm-6">
                                        <p className="unser-piont">
                                          {players[0]?.firstName || ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="row">
                                      <label
                                        for="inputEmail3"
                                        className="col-sm-6 col-form-label"
                                      >
                                        Last Name :
                                      </label>
                                      <div className="col-sm-6">
                                        <p className="unser-piont">
                                          {players[0]?.lastName || ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="candidate-number">
                              <div className="text-from-five">
                                <div className="row">
                                  <div className="col-lg-2">
                                    <div className="row">
                                      <label
                                        for="inputEmail3"
                                        className="col-sm-6 col-form-label"
                                      >
                                        Gender :
                                      </label>
                                      <div className="col-sm-6 no-padding">
                                        <p className="unser-piont">
                                          {players[0]?.sex || ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="row">
                                      <label
                                        for="inputEmail3"
                                        className="col-sm-6 col-form-label"
                                      >
                                        Grade :
                                      </label>
                                      <div className="col-sm-6 no-padding">
                                        <p className="unser-piont">
                                          {players[0]?.grade || ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="row">
                                      <label
                                        for="inputEmail3"
                                        className="col-sm-6 col-form-label "
                                      >
                                        School :
                                      </label>
                                      <div className="col-sm-6 no-padding">
                                        <p className="unser-piont">
                                          {players[0]?.school || ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2 ">
                                    <div className="row">
                                      <label
                                        for="inputEmail3"
                                        className="col-sm-6 col-form-label"
                                      >
                                        City :
                                      </label>
                                      <div className="col-sm-6 no-padding">
                                        <p className="unser-piont">
                                          {players[0]?.city || ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-2">
                                    <div className="row">
                                      <label
                                        for="inputEmail3"
                                        className="col-sm-6 col-form-label"
                                      >
                                        Country :
                                      </label>
                                      <div className="col-sm-6 no-padding">
                                        <p className="unser-piont">
                                          {players[0]?.country?.label || ""}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="candidate-number">
                              <div className="row">
                                <div className="col-xs-12 col-lg-3">
                                  <div className="photo-p">
                                    <img
                                      // src={
                                      //   window.location.origin +
                                      //   "/Assets/images/testimonial-ic.png"
                                      // }
                                      src={
                                        players[0]?.img
                                          ? players[0]?.img
                                          : window.location.origin +
                                            "/Assets/images/blank-profile-picture.webp"
                                      }
                                    />
                                  </div>
                                </div>
                                {/* --- user aggrigated details data section --- */}
                                {/* <div className="col-lg-8">
                              <div className="participations-section">
                                <p>
                                  {" "}
                                  Number of participations :{" "}
                                  <span>Lorem Ipsum</span>{" "}
                                </p>
                                <p>
                                  Success rate: 5/7 : <span>3</span>{" "}
                                </p>
                                <p>
                                  Words correctly spelt :{" "}
                                  <span>Lorem Ipsum</span>{" "}
                                </p>
                              </div>
                              <div className="biographie-section">
                                Lorem Ipsum
                              </div>
                            </div> */}
                                <PlayerAggrigatedDetails
                                  playerId={players[0]?._id}
                                  compId={current_round_Details?.compId?._id}
                                  authorizedUserId={currentUserInfo._id}
                                />
                              </div>
                            </div>
                            {(currentUserInfo.role === "Org" ||
                              (currentUserInfo.role === "Jury" &&
                                Jury_Capabilities.includes(
                                  "View Candidate Profile And Details"
                                ))) && (
                              <div className="start-7">
                                <button
                                  className="save-23"
                                  onClick={() => handleRoute()}
                                >
                                  Proceed
                                </button>
                              </div>
                            )}
                          </div>
                        </TranslatedComponent>
                      </div>
                    </div>
                  </TranslatedComponent>
                </div>
              </div>
            ) : (
              <div className="wrapper">
                <ComponentLoader />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateDetails;
