export const JURY_GET_SUCCESS = 'JURY_GET_SUCCESS';
export const JURY_GET_ERROR = 'JURY_GET_ERROR';

export const JURY_CAPABILITY_GET_SUCCESS = 'JURY_CAPABILITY_GET_SUCCESS';
export const JURY_CAPABILITY_GET_ERROR = 'JURY_CAPABILITY_GET_ERROR';

export const JURY_ALL_GET_SUCCESS = 'JURY_ALL_GET_SUCCESS';
export const JURY_ALL_GET_ERROR = 'JURY_ALL_GET_ERROR';

export const JURY_DETAILS_GET_SUCCESS = 'JURY_DETAILS_GET_SUCCESS';
export const JURY_DETAILS_GET_ERROR = 'JURY_DETAILS_GET_ERROR';

export const JURY_ADD_SUCCESS = 'JURY_ADD_SUCCESS';
export const JURY_ADD_ERROR = 'JURY_ADD_ERROR';

export const JURY_UPDATE_SUCCESS = 'JURY_UPDATE_SUCCESS';
export const JURY_UPDATE_ERROR = 'JURY_UPDATE_ERROR';

export const JURY_REMOVE_SUCCESS = 'JURY_REMOVE_SUCCESS';
export const JURY_REMOVE_ERROR = 'JURY_REMOVE_ERROR';

export const JURY_DELETE_SUCCESS = 'JURY_DELETE_SUCCESS';
export const JURY_DELETE_ERROR = 'JURY_DELETE_ERROR';

export const JURY_SUCCESS_MSG_CLEAR = 'JURY_SUCCESS_MSG_CLEAR';
export const JURY_ERROR_MSG_CLEAR = 'JURY_ERROR_MSG_CLEAR';

export const JURY_ACTION_LOADER_START = 'JURY_ACTION_LOADER_START';


// --------------------------[JURY DASHBOARD]--------------------------
export const GET_JURY_DASHBOARD_SUCC = 'GET_JURY_DASHBOARD_SUCC'
export const CLEAR_JURY_DASHBOARD_SUCC = 'CLEAR_JURY_DASHBOARD_SUCC'

export const GET_JURY_DASHBOARD_ERR = 'GET_JURY_DASHBOARD_ERR'
export const CLEAR_JURY_DASHBOARD_ERR = 'CLEAR_JURY_DASHBOARD_ERR'
