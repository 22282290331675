export const REGESTRATION_SUCCESS = "REGESTRATION_SUCCESS";
export const REGESTRATION_ERROR = "REGESTRATION_ERROR";

// export const REGESTRATION_SUCCESS_CLEAR = "REGESTRATION_SUCCESS_CLEAR";
// export const REGESTRATION_ERROR_CLEAR = "REGESTRATION_ERROR_CLEAR";



export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";

// export const VERIFY_EMAIL_SUCCESS_CLEAR = "VERIFY_EMAIL_SUCCESS_CLEAR";
// export const VERIFY_EMAIL_ERROR_CLEAR = "VERIFY_EMAIL_ERROR_CLEAR";



export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

// export const LOGIN_SUCCESS_CLEAR = "LOGIN_SUCCESS_CLEAR";
// export const LOGIN_ERROR_CLEAR = "LOGIN_ERROR_CLEAR";



export const PASSWORD_RESET_MAIL_SUCCESS = "PASSWORD_RESET_MAIL_SUCCESS";
export const PASSWORD_RESET_MAIL_ERROR = "PASSWORD_RESET_MAIL_ERROR";


export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";


export const AUTH_SUCCESS_CLEAR = "AUTH_SUCCESS_CLEAR";
export const AUTH_ERROR_CLEAR = "AUTH_ERROR_CLEAR";


export const SET_CURRENT_USER_SUCCESS = "SET_CURRENT_USER_SUCCESS";
export const SET_CURRENT_USER_ERROR = "SET_CURRENT_USER_ERROR";
